/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
import { React, useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../../../../utils/redux/store';
import { getCookie, setCookie } from '../../../../../utils/functions/cookies';
import { commentActivity, leaveComment } from '../../functions';
import Button from '../../../../../components/UI/Button/Button';
import TextArea from '../../../../../components/UI/TextArea';
import FinishButton from '../../FinishButton';
import Answers from '../../Answers';

// Одностраничное отображение - блок информации
function InfoBlock() {
  const userAnswers = useSelector((state) => state.interview.user_answers); // ответы пользователя
  const interview = useSelector((state) => state.interview.interview);
  const isWiki = useSelector((state) => state.interview.is_wiki);
  const sequence = useSelector((state) => state.interview.sequence);
  const { questionnaire, answers, questions } = interview;
  const [countQuestions, setCountQuestions] = useState(questions?.length);
  const sequence_break = useSelector((state) => state.interview.break);

  function NextQuestion(e, id, questions, sequence, rerun) {
    const cleanedUserAnswers = userAnswers.filter((ua) => ua.answer_id || ua.comment);
    console.log('Состояние break', sequence_break);
    console.log('Полученные ответ', cleanedUserAnswers);
    try {
      var current_in_sequence = Number(getCookie('current_in_sequence'));
    } catch {
      var current_in_sequence = 0;
      setCookie('current_in_sequence', 0);
      return NextQuestion(e, id, questions, sequence, true);
    }
    // Вводим сюда
    var next_in_sequence = false;
    var next_id = false;
    var dependense = null;
    var dependence_answer = null;
    console.log('Ищем', sequence[id].break, id);
    console.log('sequence[id].dependence == null', (sequence[id].dependence == null));
    if (sequence[id].dependence == null) {
      const answerBreak = cleanedUserAnswers.find((el) => el.question_id == id);
      if (sequence[id].break === (answerBreak?.answer_id || answerBreak?.comment)) {
        // questions.length = 1
        // setCountQuestions(1)
        document.getElementById(`interview-sequence-page__question-answer__${id}`).className = 'interview__question-answer-sequence-hide';
        return createAction('SET_SEQUENCE_BREAK', true);
      }
    }

    // eslint-disable-next-line max-len, no-unused-expressions
    (current_in_sequence + 1 < Object.keys(sequence).length) ? next_in_sequence = current_in_sequence + 1 : next_in_sequence = false;

    if (next_in_sequence) {
      Object.keys(sequence).forEach((element) => {
        if (sequence[element].number == next_in_sequence) {
          next_id = Number(element);
          var currentQuestion = questions.find((el) => el.id === next_id);
          createAction('SET_CURRENT_QUESTION', currentQuestion);
          dependense = sequence[element].dependence;
          dependence_answer = sequence[element].dependence_answer;
        }
      });
    }
    if (rerun) {
      var checker_question_answer = false;
      cleanedUserAnswers.forEach((element) => {
        if (element.question_id == id) {
          if (element?.answer_id != null) {
            checker_question_answer = true;
          } else if (element.comment != '') {
            checker_question_answer = true;
          }
        }
      });
      if (!checker_question_answer) {
        alert('Не указан ответ');
      } else if (dependense) {
        var dependense_checker = false;
        cleanedUserAnswers.forEach((element) => {
          if (element.question_id == dependense) {
            if (element?.answer_id == dependence_answer) {
              dependense_checker = true;
            }
          }
        });
        if (dependense_checker) {
          document.getElementById(`interview-sequence-page__question-answer__${id}`).className = 'interview__question-answer-sequence-hide';
          document.getElementById(`interview-sequence-page__question-answer__${next_id}`).className = 'interview__question-answer-sequence';
          setCookie('current_in_sequence', next_in_sequence);
        } else {
          document.getElementById(`interview-sequence-page__question-answer__${id}`).className = 'interview__question-answer-sequence-hide';
          setCookie('current_in_sequence', next_in_sequence);
          NextQuestion(e, next_id, questions, sequence, false);
        }
      } else {
        document.getElementById(`interview-sequence-page__question-answer__${id}`).className = 'interview__question-answer-sequence-hide';
        document.getElementById(`interview-sequence-page__question-answer__${next_id}`).className = 'interview__question-answer-sequence';
        setCookie('current_in_sequence', next_in_sequence);
      }
    } else {
      document.getElementById(`interview-sequence-page__question-answer__${id}`).className = 'interview__question-answer-sequence-hide';
      document.getElementById(`interview-sequence-page__question-answer__${next_id}`).className = 'interview__question-answer-sequence';
      setCookie('current_in_sequence', next_in_sequence);
    }
  }

  return (
    <div id="interview-sequence-page__info-block" className="upu-service__info-block" onClick={() => createAction('SET_IS_SHOW_FORM_MOBILE', false)}>
      {/* function sequence () {return Number(document.cookie.split('current_in_sequence=')[1]);} */}
      {/* Заголовок анкеты */}
      <h3 id="interview__title_big" className="upu-service__title">{questionnaire.title}</h3>

      <div id="interview-sequence-page__wrapper" className="interview__wrapper">
        {questions.map((q) => (
          <div
            key={q.id}
            id={`interview-sequence-page__question-answer__${q.id}`}
            className={sequence[q.id].number === 0 ? 'interview__question-answer-sequence' : 'interview__question-answer-sequence-hide'}
          >
            {/* Содержание вопроса */}
            <div id={`question_${q.id}`} className="interview__question-content">
               {q.question.split('\n').map((row, index) => <p key={index} className="interview__question-row">{row}</p>)}
            </div>

            {/* Ответы */}
            <Answers answers={answers} question={q} />

            {/* Поле комментария */}
            {/* если режим справочника выкл, комментарии включены
            или на текущий вопрос можно ответить только комментарием */}
            {!isWiki && (questionnaire?.comments || q.output_id === 4) && (
            <div className="interview__comment-block">
              <TextArea // показывается поле для ввода комментария
                id={`interview__textarea_${q.id}`}
                placeholder="Комментарий"
                disabled={commentActivity(userAnswers, q)}
                onChange={(e) => leaveComment(e, userAnswers, q, questionnaire)}
              />
            </div>)}

            {countQuestions > (sequence[q.id].number + 1) && (
            <Button id={`btn_for_${q.id}`} onClick={(e) => NextQuestion(e, q.id, questions, sequence, true)}>
                Утвердить ответ и перейти к следующему
            </Button>)}
          </div>
        ))}
        {/* Завершить опрос */}
        <FinishButton />
      </div>
    </div>
  );
}
export default InfoBlock;
