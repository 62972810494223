import React from 'react';
import PaginationRow from './PaginationRow';
import PaginatedTHead from './PaginatedTHead';

/**
  * @component Таблица c пагинацией
  * Использование:
  * Таблица получает объект (state) с состоянием таблицы и при взаимодействии с таблицей этот объект изменяется
  * В компоненте, который рендерит таблицу, должен быть useEffect, где в зависимостях должен находится state, а в коллбэке - функция запроса данных из БД
  * При обновлении состояния state будет вызываться функция и обновлять данные
  * В качестве children в таблицу передавать строки для tbody таблицы
  * Пример useEffect(() => { getTableDataFromDB() }, [state])
  * @prop {string} id Идентификатор DOM элемента таблицы
  * @prop {TableState} state Состояние таблицы
  * @prop {function} setState Обновление состояния таблицы
  * @prop {Array<Header>} headers Массив заголовков колонок таблицы
  * @prop {number} total Общее количество строк (для расчёта кол-ва страниц)
  * @prop {OptButton} [footerButton] Опциональная кнопка в футере на всю ширину
  * @prop {OptButton} [optButton] Опциональная кнопка в строке пагинации
  *
  * @typedef {object} TableState Состояние таблицы
  * @property {number} [page = 1] Текущая страница, по умолчанию 1
  * @property {number} [limit = 100] Лимит отображения строк на странице, по умолчанию 100
  * @property {string} [title] Название таблицы
  * @property {string} [button_place = 'row'] Расположение кнопки включения фильтрации ('header' или 'row')
  * @property {SortData} [sort] Настройки сортировки
  * @property {SearchData | null} [search] Настройки поиска
  * @property {SwitchData} [switch_data] Настройки переключения данных
  *
  * @typedef {object} SortData Настройки сортировки
  * @property {string} field Сортируемое поле
  * @property {string} [direction = 'asc'] Направление сортировки по умолчанию ASC
  * @example { feild: 'initiator', direction: 'desc'}
  *
  * @typedef {Object.<Field, SearchString>} SearchData Настройки фильтрации
  * @typedef {string} Field Название поля
  * @typedef {string} SearchString Искомая строка
  * @example { initiator: 'петров'}
  *
  * @typedef {Object} SwitchData Переключение данных таблицы (кнопка в левом верхнем углу)
  * @property {string} current Текущие отображаемые данные
  * @property {Titles} titles Названия для кнопок
  *
  * @typedef {Object.<DataKey, ButtonTitle>} Titles Названия для кнопок
  * @typedef {string} DataKey Ключ отображаемых данных
  * @typedef {string} ButtonTitle Искомая строка
  * @example { active: 'Показать все', all: 'Показать активные' }
  *
  * @typedef {object} OptButton опциональная кнопка
  * @property {string} title Название кнопки
  * @property {function} handler Обработчик нажатия кнопки
  * @example { title: 'Добавить', handler: () => {} }
  */
function PaginatedTable(props) {
  const {
    id, // Идентификатор DOM элемента таблицы
    state = {}, // Состояние таблицы
    setState, // Обновление состояния таблицы
    headers, // Массив заголовков колонок таблицы
    total, // Общее количество строк (для расчёта кол-ва страниц)
    footerButton, // кнопка в футере
    footerButtonUpload, // кнопка в футере 2
    optButton, // доп кнопка в строке пагинации
    children,
    filterFields,
    filterFieldsFunc,
  } = props;

  return (
    <table id={id} className="upu-table">
    <PaginatedTHead
      id={id}
      total={total}
      state={state}
      setState={setState}
      headers={headers}
      filterFields={filterFields}
      filterFieldsFunc={filterFieldsFunc}
    />
    <tbody className="upu-table__table-body">
      {children}
    </tbody>
    <tfoot className="upu-table__table-foot">
      {footerButton && (
      <tr className="upu-table__table-row">
        <td className="upu-table__table-button" onClick={footerButton.handler}>
          {footerButton.title}
        </td>
      </tr>
      )}
            {footerButtonUpload && (
  <tr className="upu-table__table-row">
    <td className="upu-table__table-button" onClick={footerButtonUpload.handler}>
      <img
        className="instructions__upload-img"
        src="../../../icons/upload.png"
        alt="upload"
        title="Загрузить данные"
      />
    </td>
  </tr>
            )}
      <PaginationRow
        total={total}
        state={state}
        setState={setState}
        optButton={optButton}
      />
    </tfoot>
    </table>
  );
}

export default PaginatedTable;
