import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import { alertFunction } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import TextArea from '../../components/UI/TextArea';

function GradesRecrutment() {
  const [qrData, setQrData] = useState({ companies: [], users: [] });
  const [allUsers, setAllUsers] = useState([]);
  const [selectUser, setSelectUser] = useState({});
  const [grades, setGrades] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedGrades, setSelectedGrades] = useState(new Set()); // Состояние для хранения выбранных сертификатов
  const [newGrade, setNewGrade] = useState({ title: '', description: '' });

  useEffect(() => {
    awaitRequests();
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = { type: 'getAdminDataRecrutment' };
      const result = await clientSendData('POST', '/get_admin_data_recrutment', reqData);
      if (result) {
        console.log(result.data);
        const data = JSON.parse(result.data);
        console.log(data.job_level);
        setQrData(data);
        setGrades(data.job_level);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataRecrutment');
    }
  }

  const handleTitleChange = (id, newTitle) => {
    setGrades(grades.map((grd) => (grd.id === id ? { ...grd, title: newTitle } : grd)));
  };

  const handleDescriptionChange = (id, newDescription) => {
    setGrades(grades.map((grd) => (grd.id === id ? { ...grd, description: newDescription } : grd)));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  //  Функция отправки изменений на бэк
  const editGradesRecrutment = async (GradesData) => {
    console.log(GradesData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editRecrutmentGrades',
        data: GradesData,
      };
      console.log(reqData);
      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_recrutment_grades', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('save_settings', 'client'); // Уведомление об успешном обновлении
        // Обновляем состояние, чтобы перерендерить компонент
        await getPermissions();
      } else {
        await alertFunction('required_fields', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editRecrutmentGrades'); // Обработка ошибок
    }
  };

  const toggleSelectGrade = (id) => {
    setSelectedGrades((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const deleteSelectedGrades = async () => {
    const newGrades = grades.filter((grd) => !selectedGrades.has(grd.id));
    setGrades(newGrades);
    setSelectedGrades(new Set()); // Сбросить выбранные сертификаты
    await editGradesRecrutment(newGrades);
  };

  const addSelectedGrades = async () => {
    const newGrades = grades.filter((grd) => !selectedGrades.has(grd.id));
    setGrades(newGrades);
    setSelectedGrades(new Set()); // Сбросить выбранные сертификаты
    await editGradesRecrutment(grades);
  };

  // Обработчик для добавления нового сертификата
  const addNewGrade = () => {
    if (newGrade.title && newGrade.description) {
      const newGrd = {
        id: (grades.length + 1).toString(), // Генерация ID (в реальном приложении лучше использовать уникальный идентификатор)
        title: newGrade.title,
        description: newGrade.description,
      };
      setGrades([...grades, newGrd]);
      setNewGrade({ title: '', description: '' }); // Сбросить форму
    }
  };

  if (isAuthorized) {
    return (
    // <div id="staff_cv" className="service">
          <ServiceBody>
            <Table id="grade_recrutment">
              <THead title="Уровни должности" headers={headers.grades} className="table-header" />
              <TBody>
                {grades.map((el) => (
                  <TRow key={el.id}>
                    <TData className="id-cell">
                      {el.id}
                    </TData>
                    <TData className="title-cell">
                      <Input
                        type="text"
                        value={el.title}
                        onChange={(e) => handleTitleChange(el.id, e.target.value)}
                        placeholder="Грейд"
                      />
                    </TData>
                    <TData className="description-cell">
                      <TextArea
                        type="text"
                        value={el.description}
                        onChange={(e) => handleDescriptionChange(el.id, e.target.value)}
                        placeholder="Описание"
                      />
                    </TData>
                    <TData className="checkbox-cell">
                      <input
                        type="checkbox"
                        checked={selectedGrades.has(el.id)}
                        onChange={() => toggleSelectGrade(el.id)}
                      />
                    </TData>
                  </TRow>
                ))}

                 {/* Строка для добавления нового сертификата */}
                 <TRow>
                  <TData className="id-cell" />
                  <TData className="titlr-cell">
                    <Input
                      type="text"
                      value={newGrade.title}
                      onChange={(e) => setNewGrade({ ...newGrade, title: e.target.value })}
                      placeholder="Грейд"
                    />
                  </TData>
                  <TData className="description-cell">
                    <TextArea
                      type="text"
                      value={newGrade.description}
                      onChange={(e) => setNewGrade({ ...newGrade, description: e.target.value })}
                      placeholder="Описание"
                    />
                  </TData>
                  <TData className="add-button-cell">
                    <Button onClick={addNewGrade} style={{ backgroundColor: '#28B47D', color: 'white' }}>
                      Добавить
                    </Button>
                  </TData>
                 </TRow>
              </TBody>
              <TFoot>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={addSelectedGrades}
                    style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
                  >
                    Сохранить изменения
                  </Button>
                  <Button
                    onClick={deleteSelectedGrades}
                    style={{ marginLeft: '10px' }}
                  >
                    Удалить выбранные
                  </Button>
                </div>
              </TFoot>
            </Table>

          </ServiceBody>
    // </div>
    );
  }

  return <Cap />;
}

export default GradesRecrutment;
