import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import Select from '../../components/UI/Select';
import { alertFunction } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import './adminstaffcv.scss';

function CertificatesStaffCV() {
  const [qrData, setQrData] = useState({ companies: [], users: [] });
  const [allUsers, setAllUsers] = useState([]);
  const [selectUser, setSelectUser] = useState({});
  const [certificates, setCertificates] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState(new Set()); // Состояние для хранения выбранных сертификатов
  const [newCertificate, setNewCertificate] = useState({ fullName: '', shortName: '' });

  useEffect(() => {
    awaitRequests();
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = { type: 'getAdminDataStaffCV' };
      const result = await clientSendData('POST', '/get_admin_data_staff_cv', reqData);
      if (result) {
        console.log(result.data);
        setQrData(result.data);
        setCertificates(() => JSON.parse(result.certificates.value));
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataStaffCV');
    }
  }

  const handleFullNameChange = (id, newFullName) => {
    setCertificates(certificates.map((cert) => (cert.id === id ? { ...cert, fullName: newFullName } : cert)));
  };

  const handleShortNameChange = (id, newShortName) => {
    setCertificates(certificates.map((cert) => (cert.id === id ? { ...cert, shortName: newShortName } : cert)));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  //  Функция отправки изменений на бэк
  const editStaffCVCertificates = async (certificatesData) => {
    console.log(certificatesData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editStaffCVCertificates',
        data: certificatesData,
      };
      console.log(reqData);
      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_staff_cv_certificates', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('save_settings', 'client'); // Уведомление об успешном обновлении
        // Обновляем состояние, чтобы перерендерить компонент
        await getPermissions();
      } else {
        await alertFunction('required_fields', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editStaffCVCertificates'); // Обработка ошибок
    }
  };

  const toggleSelectCertificate = (id) => {
    setSelectedCertificates((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const deleteSelectedCertificates = async () => {
    const newCertificates = certificates.filter((cert) => !selectedCertificates.has(cert.id));
    setCertificates(newCertificates);
    setSelectedCertificates(new Set()); // Сбросить выбранные сертификаты
    await editStaffCVCertificates(newCertificates);
  };

  const addSelectedCertificates = async () => {
    const newCertificates = certificates.filter((cert) => !selectedCertificates.has(cert.id));
    setCertificates(newCertificates);
    setSelectedCertificates(new Set()); // Сбросить выбранные сертификаты
    await editStaffCVCertificates(certificates);
  };

  // Обработчик для добавления нового сертификата
  const addNewCertificate = () => {
    if (newCertificate.fullName && newCertificate.shortName) {
      const newCert = {
        id: (certificates.length + 1).toString(), // Генерация ID (в реальном приложении лучше использовать уникальный идентификатор)
        fullName: newCertificate.fullName,
        shortName: newCertificate.shortName,
      };
      setCertificates([...certificates, newCert]);
      setNewCertificate({ fullName: '', shortName: '' }); // Сбросить форму
    }
  };

  if (isAuthorized) {
    return (
    // <div id="staff_cv" className="service">
        <ServiceBody>
          <Table id="admin_staff_cv">
            <THead title="Сертификаты для сервиса резюме" headers={headers.certificates} className="table-header" />
            <TBody>
              {certificates.map((el) => (
                <TRow key={el.id}>
                  <TData className="id-cell">
                    {el.id}
                  </TData>
                  <TData className="full-name-cell">
                    <Input
                      type="text"
                      value={el.fullName}
                      onChange={(e) => handleFullNameChange(el.id, e.target.value)}
                      placeholder="Полное имя сертификата"
                    />
                  </TData>
                  <TData className="short-name-cell">
                    <Input
                      type="text"
                      value={el.shortName}
                      onChange={(e) => handleShortNameChange(el.id, e.target.value)}
                      placeholder="Короткое имя сертификата"
                    />
                  </TData>
                  <TData className="checkbox-cell">
                    <input
                      type="checkbox"
                      checked={selectedCertificates.has(el.id)}
                      onChange={() => toggleSelectCertificate(el.id)}
                    />
                  </TData>
                </TRow>
              ))}

               {/* Строка для добавления нового сертификата */}
               <TRow>
                <TData className="id-cell" />
                <TData className="full-name-cell">
                  <Input
                    type="text"
                    value={newCertificate.fullName}
                    onChange={(e) => setNewCertificate({ ...newCertificate, fullName: e.target.value })}
                    placeholder="Полное название"
                  />
                </TData>
                <TData className="short-name-cell">
                  <Input
                    type="text"
                    value={newCertificate.shortName}
                    onChange={(e) => setNewCertificate({ ...newCertificate, shortName: e.target.value })}
                    placeholder="Короткое название"
                  />
                </TData>
                <TData className="add-button-cell">
                  <Button onClick={addNewCertificate} style={{ backgroundColor: '#28B47D', color: 'white' }}>
                    Добавить
                  </Button>
                </TData>
               </TRow>
            </TBody>
            <TFoot>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={addSelectedCertificates}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
                >
                  Сохранить изменения
                </Button>
                <Button
                  onClick={deleteSelectedCertificates}
                  style={{ marginLeft: '10px' }}
                >
                  Удалить выбранные
                </Button>
              </div>
            </TFoot>
          </Table>

        </ServiceBody>
    // </div>
    );
  }

  return <Cap />;
}

export default CertificatesStaffCV;
