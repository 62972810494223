import React, { useState, useEffect } from 'react';
import CardBody from '../../components/Card/CardBody';
import Loader from '../../components/UI/Loader';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';

function CRMCardHistory(props) {
  const { meetingId } = props;
  const [history, setHistory] = useState([]); // история субъекта
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const columns = [
    { title: 'Создано', field: 'date', align: 'right' },
    { title: 'Создал', field: 'user', align: 'left' },
    { title: 'Сообщение', field: 'message', align: 'left' },
  ];

  useEffect(() => {
    if (meetingId) getVcHistory();
  }, [meetingId]);

  // Получить историю задач
  async function getVcHistory() {
    try {
      const reqData = {
        type: 'getVcHistory',
        meetingId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_vc_history', reqData);
      if (result?.success) setHistory(result.data); // Результат записывается в состояние
    } catch (error) {
      catchHandler(error, 'getVcHistory');
    } finally {
      setIsLoading(false);
    }
  }
  console.log(history);
  if (isLoading) return <CardBody><Loader /></CardBody>;
  if (history.length === 0) return <CardBody><h2 className="upu-service__title">История отсутствует</h2></CardBody>;
  return (
    <CardBody>
    <Table id="vcHistory__history">
      <THead
        headers={columns}
        array={history}
        setArray={setHistory}
      />
      <TBody>
      {history.map((row) => (
            <TRow key={row.id}>
              {columns.map((column) => {
                const { field, align } = column;
                if (field === 'message') {
                  return (
                    <td
                      key={field}
                      className={`upu-table__table-data${align ? ` upu-table__table-data_${align}` : ''}`}
                      dangerouslySetInnerHTML={{ __html: row[field] }}
                    />
                  );
                }
                return <TData key={field} align={align}>{row[field]}</TData>;
              })}
            </TRow>
      ))}
      </TBody>
    </Table>
    </CardBody>
  );
}

export default CRMCardHistory;
