import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import GradesRecrutment from './GradesRecrutment';
import LocationRecrutment from './LocationRecrutment';
import AccessRecrutment from './AccessRecrutment';
import LevelLanguagesRecrutment from './LevelLanguagesRecrutment';
import NotificationsRecrutment from './NotificationsRecrutment';
import MvzRecrutment from './MvzRecrutment';
import CertificatesRecrutment from './CertificatesRecrutment';
import AllEducationRecrutment from './AllEducationRecrutment';
import CompanyRecrutment from './CompanyRecrutment';

function AdminRecrutment() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const adminPages = [
    { id: 1, title: 'Грейды', component: <GradesRecrutment /> },
    { id: 2, title: 'Города', component: <LocationRecrutment /> },
    { id: 3, title: 'Уровни языков', component: <LevelLanguagesRecrutment /> },
    { id: 4, title: 'Компания', component: <CompanyRecrutment /> },
    { id: 5, title: 'Доступы', component: <AccessRecrutment /> },
    { id: 6, title: 'МВЗ', component: <MvzRecrutment /> },
    { id: 7, title: 'Сертификаты', component: <CertificatesRecrutment /> },
    { id: 8, title: 'Образование', component: <AllEducationRecrutment /> },
    // { id: 9, title: 'Уведомления', component: <NotificationsRecrutment /> },
  ];
  const [pages, setPages] = useState(adminPages);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  if (isAuthorized) {
    return <Service id="admin-staff-cv" pages={pages} horizontal />;
  } return <Cap />;
}

export default AdminRecrutment;
