import React, { useState } from 'react';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/UI/Button/Button';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import './PODFT.scss';

function NewMessageCard(props) {
  const { setShowCreateCard } = props;
  const operationTypes = [
    { id: 1, title: 'Разовая необычная операция' },
    { id: 2, title: 'Иные операции (сделки)' },
  ];
  const [newMail, setNewMail] = useState({}); // новое письмо
  const [acceptFileExtension, setAcceptFileExtension] = useState([]); // разрешения файлов
  const addAttachment = 'Добавить вложение';
  function closeHandler() {
    setShowCreateCard(false); // закрытие карточки
  }

  // Обработка прикрепленного вложения и запись в объект нового email
  function attachmentHandler(file) {
    console.log(file);
    const reader = new FileReader(); // API чтения файлов
    reader.onloadend = (e) => {
      setNewMail((prev) => ({
        ...prev, // развернуть существующее состояние
        attachment: {
          file: e.target.result, // строка в формате DataURL для записи в БД
          name: file.name, // имя файла
        },
      }));
    };
    reader.readAsDataURL(file); // Прочитать как DataURL ("data:image/png;base64,iVBORw...")
  }

  return (
    <Card setShow={closeHandler} id="new_podft_message_card">
        <CardHeader />
        <CardBody>
    <div className="card_container">
        <div className="a">
         {/* Наименование клиента */}
        <CardSetting title="Наименование клиента:">
            <Input />
        </CardSetting>

         {/* Номер договора */}
         <CardSetting title="Номер договора:">
            <Input />
         </CardSetting>

          {/* Дата выпуска отчетного документа  */}
          <CardSetting title="Дата выпуска отчетного документа :">
            <Input type="date" />
          </CardSetting>

          {/* Руководитель задания */}
         <CardSetting title="Руководитель задания:">
            <Select id="operation_supervisor" search />
         </CardSetting>

         {/* Вид операции */}
         <CardSetting title="Вид операции:">
            <Select id="operation_types" array={operationTypes} />
         </CardSetting>

         {/* Критерии выбора вида операции */}
         <CardSetting title="Критерии выбора вида операции:">
            <Select id="operation_types_criteria" />
         </CardSetting>

         {/* Содержание (характер) операции (сделки) */}
         <CardSetting title="Содержание (характер) операции:">
            <Input />
         </CardSetting>

          {/* Иные данные по операции (№ договора, и т.п.) */}
          <CardSetting title="Иные данные по операции:">
            <Input />
          </CardSetting>
        </div>

        <div className="b">
            {/* Документы, подтверждающие сделку */}
            <CardSetting title="Документы, подтверждающие сделку:">
                {/* <p style={{ marginRight: '27px', color: 'blue' }}>прикрепить</p> */}
            <SingleFileUpload options={{ handler: attachmentHandler, size: 5000000, accept: acceptFileExtension.length > 0 ? acceptFileExtension[0].value : '' }}>
             {/* <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" /> */}
             Прикрепить вложение 📑
            </SingleFileUpload>
            </CardSetting>

            {/* Дата совершения операции (сделки */}
          <CardSetting title="Дата совершения операции (сделки):">
            <Input type="date" />
          </CardSetting>

          {/* Сумма операции (сделки) */}
          <CardSetting title="Сумма операции (сделки):">
            <Input />
          </CardSetting>

           {/* Валюта проведения операции (сделки) */}
           <CardSetting title="Валюта проведения операции (сделки):">
            <Input />
           </CardSetting>

           {/* Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку) */}
           <CardSetting title="Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку):">
            <Input />
           </CardSetting>

            {/* Сведения о работнике, составившем внутреннее сообщение об операции (сделке)  */}
            <CardSetting title="Сведения о сотруднике, составившем внутреннее сообщение об операции (сделке):">
            <Select search />
            </CardSetting>
        </div>
         {/* Комментарии */}
    </div>
    <div className="card-comment">
    <CardSetting title="Комментарии:">
                        <Input />
    </CardSetting>
    </div>

        </CardBody>
        <CardFooter>
        <Button>Создать</Button>
        </CardFooter>
    </Card>
  );
}

export default NewMessageCard;
