import React, { useState, useEffect } from 'react';
import Input from '../../components/UI/Input';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import TFoot from '../../components/Table/TFoot';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import './admintvpanel.scss'; // Состояние авторизации
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
// импортируем файл со стилями

function AdminTVPanel() {
  const [dataTV, setDataTV] = useState({
    runningText: '',
    videoLink: '',
    cities: [],
    qrCodeLink: '',
  });
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    awaitRequests();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataTV((prevDataTV) => ({ ...prevDataTV, [name]: value }));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  const handleToggleCityVisibility = (index) => {
    if (dataTV.cities) {
      setDataTV((prevDataTV) => ({
        ...prevDataTV,
        cities: prevDataTV.cities.map((city, i) => (i === index ? { ...city, visible: !city.visible } : city)),
      }));
    }
  };

  const getDataForTVPanel = async () => {
    try {
      const reqData = {
        type: 'getTVPanelData',
      };
      const result = await clientSendData('POST', '/get_tv_panel_data', reqData); // запрос в БД
      if (result) {
        const parsedValue = JSON.stringify(result.data.value);
        const parsedData = JSON.parse(parsedValue);
        const obj = JSON.parse(parsedData.replace(/'/g, '"'));
        setDataTV(obj);
      }
    } catch (error) {
      catchHandler(error, 'getTVPanelData'); // обработчик ошибок
    }
  };

  useEffect(() => {
    getDataForTVPanel(); // fetch the data when the component mounts
  }, []);

  async function editTvPanelData(newData) {
    try {
      const reqData = {
        data: newData,
        type: 'editTvPanelData',
      };
      const result = await clientSendData('POST', '/edit_tv_panel_data', reqData); // запрос в БД

      if (result) {
        setDataTV(result);
      }
    } catch (error) {
      catchHandler(error, 'editTvPanelData'); // обработчик ошибок
    } finally {
      getDataForTVPanel(); // call getDataForTVPanel after editing data
    }
  }

  const handleSaveChanges = async () => {
    editTvPanelData(dataTV);
  };

  if (isAuthorized) {
    return (
      <ServiceBody id="adminTVPanel">
        <Table id="adminTVPanel">
          <THead
            title="Настройки TV панели"
            headers={[
              { title: 'Параметр' },
              { title: 'Значение' },
            ]}
          />
          <TBody>
            <TRow>
              <TData>Бегущая строка:</TData>
              <TData>
                <Input
                  type="text"
                  name="runningText"
                  value={dataTV.runningText}
                  onChange={handleInputChange}
                />
              </TData>
            </TRow>
            <TRow>
              <TData>Ссылка на видео:</TData>
              <TData>
                <Input
                  type="text"
                  name="videoLink"
                  value={dataTV.videoLink}
                  onChange={handleInputChange}
                />
              </TData>
            </TRow>
            <TRow>
              <TData>Ссылка на QR-код:</TData>
              <TData>
                <Input
                  type="text"
                  name="qrCodeLink"
                  value={dataTV.qrCodeLink}
                  onChange={handleInputChange}
                />
              </TData>
            </TRow>
          </TBody>
        </Table>
        <Table id="adminTVPanel">
          <THead
            title="Города"
            headers={[
              { title: 'Город' },
              { title: 'Часовой пояс' },
              { title: 'ID виджета погоды' },
              { title: 'Видимость' },
            ]}
          />
          <TBody id="adminTVPanelBody">
            {dataTV.cities && dataTV.cities.length > 0 && dataTV.cities.map((city, index) => (
              <TRow key={city.id}>
                <TData>
                  <Input
                    type="text"
                    name="name"
                    value={city.name}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setDataTV((prevDataTV) => ({
                          ...prevDataTV,
                          cities: prevDataTV.cities.map((c, i) => (i === index ? { ...c, name: e.target.value } : c)),
                        }));
                      }
                    }}
                  />
                </TData>
                <TData>
                  <Input
                    type="text"
                    name="timezone"
                    value={city.timezone}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setDataTV((prevDataTV) => ({
                          ...prevDataTV,
                          cities: prevDataTV.cities.map((c, i) => (i === index ? { ...c, timezone: e.target.value } : c)),
                        }));
                      }
                    }}
                  />
                </TData>
                <TData>
                  <Input
                    type="text"
                    name="id"
                    value={city.id}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setDataTV((prevDataTV) => ({
                          ...prevDataTV,
                          cities: prevDataTV.cities.map((c, i) => (i === index ? { ...c, id: e.target.value } : c)),
                        }));
                      }
                    }}
                  />
                </TData>
                <TData>
                  <input
                    type="checkbox"
                    checked={city.visible}
                    onChange={() => handleToggleCityVisibility(index)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <TRow>
                      <TData onClick={handleSaveChanges}>Сохранить изменения</TData>
            </TRow>
          </TFoot>
        </Table>
      </ServiceBody>
    );
  } return <Cap />;
}

export default AdminTVPanel;
