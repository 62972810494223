import React, { useEffect, useState, useRef } from 'react';
import {
  Document, AlignmentType, Packer, Table, TableCell, TableRow, WidthType, Paragraph, TextRun, TableBorders, BorderStyle, convertInchesToTwip, Header,
} from 'docx';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction'; // Импортируем alertFunction
import CV from './CV'; // Import the CV component
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import './staffcv.scss';
import {
  orderedKeys,
} from './common';

const defaultTableState = {
  title: 'Резюме сотрудников',
  page: 1,
  limit: 50, // количество строк в таблице
};

/**
* @component Резюме сотрудников - Интерфейс главной страницы
*/

function StaffCV() {
  const tableId = 'staff-cv';
  const [isLoading, setIsLoading] = useState(false);
  const [dataCV, setDataCV] = useState([]);
  const [fileInputVisible, setFileInputVisible] = useState(false);
  const [selectedCV, setSelectedCV] = useState({});
  const navigate = useNavigate();
  const [showCard, setShowCard] = useState(false);
  const [total, setTotal] = useState(0);
  const fileInputRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState({});
  // Проверяем, выбраны ли все резюме
  const [allChecked, setAllChecked] = useState(false);
  let documentResult = null;// Объект для хранения сгенерированных документов

  const headers = [ // Заголовки таблицы
    { id: 1, title: 'ФИО', field: 'fullName' },
    { id: 2, title: 'Грейд', field: 'grade' },
    { id: 3, title: 'Отдел', field: 'department' },
    { id: 4, title: 'МВЗ', field: 'mvz' },
    { id: 5, title: 'Сертификат', field: 'certificate' },
    { id: 6, title: 'Дата изменения', field: 'date_modifed' },
    { id: 7, title: 'Редактировать резюме', field: 'resume' },
  ];
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    getStaffCV();
  }, [tableState]);

  useEffect(() => {
    // Проверяем, есть ли вообще элементы в checkedItems
    const totalChecked = Object.keys(checkedItems).length > 0 && Object.values(checkedItems).every((isChecked) => isChecked);
    setAllChecked(totalChecked); // Устанавливаем allChecked в true, если все чекбоксы выбраны
  }, [checkedItems]);

  async function getStaffCV() {
    try {
      const reqData = {
        type: 'getStaffCV',
        tableState,
        total,
      };
      const result = await clientSendData('POST', '/get_staff_cv', reqData);
      if (result && result.data) {
        setSelectedCV(result.data);
        const formattedData = result.data.map((item) => ({
          id: item.id,
          fullName: item.cv['Ф.И.О.'],
          grade: item.extensionattribute11,
          department: item.department,
          mvz: item.extensionattribute13,
          certificate: item.cv['Номер квалификационного сертификата налогового консультанта'] || item.cv['Номер квалификационного сертификата аудитора'] || item.cv['Номер квалификационного сертификата налогового оценщика'] || item.cv[''],
          date_modifed: new Date(item.date_modifed).toLocaleDateString(),
          resume: 'Просмотреть резюме',
        }));
        setDataCV(formattedData);
        setTotal(result.total); // Обновляем общее количество записей
      }
    } catch (error) {
      catchHandler(error, 'getStaffCV');
    }
  }

  let uploadedFiles = [];
  let totalFilesCount = 0;

  const uploadFile = async (file) => {
    try {
      const reader = new FileReader();
      reader.onloadend = async (e) => {
        const binaryArray = new Uint8Array(e.target.result);
        uploadedFiles.push(binaryArray);

        if (uploadedFiles.length === totalFilesCount) {
          const reqData = {
            type: 'updateStaffCV',
            DataCV: uploadedFiles,
          };

          const result = await clientSendData('POST', '/update_staff_cv', reqData);
          if (result.success) {
            await alertFunction('file_upload_success', 'client'); // Уведомление об успешной загрузке
            setFileInputVisible(false);
            const newData = await getStaffCV(); // Получаем новые данные
            setTableState({ ...tableState, data: newData }); // Обновляем состояние с новыми данными
          } else if (result.message === 'Пользователь не найден.') {
            await alertFunction('not_user', 'client'); // Уведомление об ошибке
          } else {
            await alertFunction('file_upload_error', 'client'); // Уведомление об ошибке
          }
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      catchHandler(error, 'uploadFile');
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    totalFilesCount = files.length;

    if (files.length > 0) {
      uploadedFiles = [];
      for (const file of files) {
        // eslint-disable-next-line no-await-in-loop
        await uploadFile(file);
      }
    }
  };

  const generateWordDocument = async (id) => {
    console.log(id);
    console.log(selectedCV);
    const selectedResume = selectedCV.find((cv) => cv.id === id);
    console.log(selectedResume);
    if (!selectedResume) {
      console.error('Резюме не найдено');
      return;
    }

    const rows = [];

    const defaultFontStyle = {
      font: 'Microsoft Sans Serif', // Название шрифта
      size: 18, // Размер шрифта (в пунктах)
    };

    // Создаем колонтитул
    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: 'РЕЗЮМЕ',
              bold: true,
              font: 'Microsoft Sans Serif', // Задаем шрифт для колонтитула
              size: 22, // Размер шрифта для колонтитула (можно настроить)
            }),
          ],
          alignment: AlignmentType.RIGHT, // Выравнивание по правому краю
        }),
      ],
    });

    orderedKeys.forEach((key) => {
      if (selectedResume.cv[key] !== undefined) {
        const value = selectedResume.cv[key];

        if (Array.isArray(value) && key === 'Образование и профессиональная квалификация/сертификаты') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Сведения о трудовой деятельности') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && (key === 'Ключевые компетенции')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (Array.isArray(value) && ((key === 'В Юникон АО') || (key === 'В других организациях'))) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Номер квалификационного сертификата аудитора' || key === 'Номер квалификационного сертификата налогового консультанта' || key === 'Сертификаты'
     || key === 'Номер квалификационного сертификата оценщика' || key === 'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»' || key === 'Стаж финансово-экономической деятельности' || key === 'Стаж юридической деятельности') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
          // Извлечение даты из value
          const dateRegex = /(\d{1,2}\.\d{1,2}\.\d{4}|\d{1,2}\s+(января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)\s+\d{4})/i;
          const dateMatch = value.match(dateRegex);
          const date = dateMatch ? dateMatch[0] : 'Введите дату';

          // Извлечение оставшегося текста
          const remainingText = value.replace(dateRegex, '').trim();
          // Разделяем текст на части
          const parts = remainingText.split('Игоревна');

          // Создание ячеек
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Ячейка с оставшимся текстом
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[0].trim()} Игоревна`,
                      ...defaultFontStyle,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[1].trim()}       ${date}`, // Объединяем текст и дату
                      ...defaultFontStyle,
                    }),
                  ],
                }),
              ],
              // width: { size: 8000, type: WidthType.DXA }, // Установка ширины ячейки с оставшимся текстом
              columnSpan: 2, // Объединяем ячейку с соседней
            }),
          ]);
        } else if (key === 'Ф.И.О.') {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: value, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: Array.isArray(value) ? value.join(', ') : value, ...defaultFontStyle })] })],
            }),
          ]);
        }
      }
    });

    const table = new Table({
      rows: rows.map((row) => new TableRow({ children: row })),
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: TableBorders.NONE,
      margins: {
        top: convertInchesToTwip(0.1),
        bottom: convertInchesToTwip(0.1),
        right: convertInchesToTwip(0),
        left: convertInchesToTwip(0),
      },
    });

    const doc = new Document({
      sections: [{
        properties: {},
        headers: {
          default: header, // Добавляем колонтитул в секцию
        },
        children: [table],
      }],
    });

    // Извлечение Ф.И.О. для имени файла
    const fullName = selectedResume.cv['Ф.И.О.'] || 'Резюме';
    const sanitizedFileName = fullName.replace(/[^a-zA-Z0-9а-яА-ЯёЁ\s]/g, '').replace(/\s+/g, '_').trim();

    // Генерация документа
    const blob = await Packer.toBlob(doc);

    // Возвращаем объект с blob и именем файла
    documentResult = {
      blob,
      fileName: `${selectedResume.cv['Ф.И.О.']}.docx`,
    };
  };

  const downloadSelectedResumes = async (ids) => {
    console.log('Переданные ID:', ids);
    console.log('Выбранные резюме:', selectedCV);

    const idsArray = Object.keys(ids).filter((key) => ids[key] === true).map(Number);
    const selectedResumes = selectedCV.filter((cv) => idsArray.includes(cv.id));

    if (selectedResumes.length === 0) {
      console.error('Резюме не найдены для переданных ID:', idsArray);
      return;
    }

    if (selectedResumes.length === 1) {
      console.log(selectedResumes[0].id);
      await generateWordDocument(selectedResumes[0].id); // Вызов функции, результат будет записан в глобальную переменную
      if (documentResult) {
        const url = URL.createObjectURL(documentResult.blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = documentResult.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        console.error('Ошибка: документ не был сгенерирован.');
      }
    } else {
      const zip = new JSZip();
      const promises = selectedResumes.map(async (resume) => {
        await generateWordDocument(resume.id); // Вызов функции
        if (documentResult) {
          zip.file(documentResult.fileName, documentResult.blob);
        } else {
          console.error(`Ошибка: документ для резюме с ID ${resume.id} не был сгенерирован.`);
        }
      });

      await Promise.all(promises);

      zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'resumes.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }).catch((error) => {
        console.error('Ошибка при создании ZIP-архива:', error);
      });
    }
  };

  function openCard(id) {
    const cv = selectedCV.find((item) => item.id === id);
    setSelectedCV(cv);
    setShowCard(true);
    navigate(`/staff_cv?user=${id}`);
  }

  const handleImageClick = () => {
    fileInputRef.current.click(); // Программно вызываем клик на input
  };

  if (showCard) {
    return (
      <CV
        meetingId={selectedCV?.id}
        cvData={selectedCV}
        handler={getStaffCV}
        setShow={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }
  console.log(checkedItems);

  return (
    <div id="staff_cv" className="service">
      <div className="service__body">
<PaginatedTable
  id="cv_results"
  headers={headers}
  state={tableState}
  setState={setTableState}
  total={total}
  footerButton={{
    title: 'Скачать выбранные резюме',
    handler: () => downloadSelectedResumes(checkedItems),
  }}
  footerButtonUpload={{
    title: 'Загрузить резюме сотрудника в docx',
    handler: () => handleImageClick(),
  }}
  optButton={{
    title: allChecked ? 'Снять выделение' : 'Выбрать все резюме',
    handler: () => {
      const newCheckedItems = {};
      const newAllChecked = !allChecked; // Переключаем состояние allChecked

      dataCV.forEach((cv) => {
        newCheckedItems[cv.id] = newAllChecked; // Устанавливаем состояние для каждого резюме
      });

      setCheckedItems(newCheckedItems); // Обновляем состояние чекбоксов
      setAllChecked(newAllChecked); // Обновляем состояние allChecked
    },
  }}

>

{dataCV.map((cv) => (
      <TRow key={cv.id}>
        {headers.map((header) => {
          const { id, field } = header; // Деструктуризация объекта колонки
          const value = cv?.[field]; // Значение текущего ключа в объекте строки

          return (
            <TData key={id} loading={isLoading}>
              {header.field === 'resume' ? (
                <div onClick={() => openCard(cv.id)} style={{ cursor: 'pointer' }}>
                  <img src="../../icons/button/edit.svg" alt="file" />
                </div>
              ) : (
                // Обработка значения, если оно является массивом
                typeof value === 'object' ? value.join(', ') : value ?? '-'
              )}
            </TData>
          );
        })}

        {/* Последняя ячейка для поиска */}
        <TData loading={isLoading}>
  <div style={{ display: 'flex', alignItems: 'right' }}>
    <div onClick={() => generateWordDocument(cv.id)} style={{ cursor: 'pointer', alt: 'скачать резюме' }}>
      <img src="../../icons/file-download.png" alt="скачать резюме" />
    </div>
    <input
      type="checkbox"
      id={`checkbox-${cv.id}`}
      checked={checkedItems[cv.id] || false}
      onChange={() => setCheckedItems((prev) => ({ ...prev, [cv.id]: !prev[cv.id] }))}
      style={{ marginLeft: '10px' }}
    />
  </div>
        </TData>
      </TRow>
))}

    <TRow />

</PaginatedTable>

        <input
          type="file"
          accept=".docx"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef} // Привязываем реф к input
          style={{ display: 'none' }}
        />
      </div>
    </div>

  );
}

export default StaffCV;
