import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../components/Card/Card';
import CardFooter from '../../components/Card/CardFooter';
import CardBody from '../../components/Card/CardBody';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';

function EmployeeVacationCard(props) {
  const {
    openedVacation,
    setOpenedVacation,
    setShow,
    vacations,
    setSpareDays,
    spareDays,
    allDays,
    type,
    getVacations,
    employeeName,
    currentYear,
    groupAmount,
    calendar,
  } = props;

  const user_id = useSelector((state) => state?.oper_info?.user_id); // id текущего пользователя
  // const isAdmin = user_id === 1298;
  const isAdmin = [1298, 1287].includes(user_id);
  const [myVacations, setMyVacations] = useState([]);
  const [overlap, setOverlap] = useState(false);
  const [innerOverlap, setInnerOverlap] = useState(false);
  const [overrun, setOverrun] = useState(false);
  const [datesOrder, setDatesOrder] = useState(true);

  useEffect(() => {
    if (openedVacation.id !== 'new' && openedVacation.status !== 'Создано' && user_id === Number(openedVacation.user_id)) {
      let dateStart = 'date_start' in openedVacation ? openedVacation.date_start.split('-') : '';
      dateStart = `${dateStart[2]}-${dateStart[1]}-${dateStart[0]}`;
      let dateEnd = 'date_end' in openedVacation ? openedVacation?.date_end.split('-') : '';
      dateEnd = `${dateEnd[2]}-${dateEnd[1]}-${dateEnd[0]}`;
      setOpenedVacation(() => ({
        ...openedVacation,
        date_start: dateStart,
        date_end: dateEnd,
        previous_date_start: openedVacation.date_start,
        previous_date_end: openedVacation.date_end,
        previous_days: openedVacation.days,
      }));
    }
    if (openedVacation.id !== 'new' && isAdmin) {
      let dateStart = 'date_start' in openedVacation ? openedVacation.date_start.split('-') : '';
      dateStart = `${dateStart[2]}-${dateStart[1]}-${dateStart[0]}`;
      let dateEnd = 'date_end' in openedVacation ? openedVacation?.date_end.split('-') : '';
      dateEnd = `${dateEnd[2]}-${dateEnd[1]}-${dateEnd[0]}`;
      setOpenedVacation(() => ({
        ...openedVacation,
        date_start: dateStart,
        date_end: dateEnd,
        previous_date_start: openedVacation.date_start,
        previous_date_end: openedVacation.date_end,
        previous_days: openedVacation.days,
      }));
    }
    if (openedVacation.id === 'new') {
      setOpenedVacation(() => ({
        ...openedVacation,
        user_id: String(user_id),
        year: currentYear,
        name: employeeName,
      }));
    }
  }, []);

  function findDatesOverlap() {
    const overlappedVacations = [];
    for (const range of vacations.filter((el) => el.user_id !== openedVacation.user_id)) {
      if ('date_start' in openedVacation && 'date_end' in openedVacation) {
        const regex = /\d{2}-\d{2}-\d{4}/;
        // Даты нового отпуска
        let newDateStart = openedVacation.date_start;
        let newDateEnd = openedVacation.date_end;
        if (newDateStart.match(regex)) {
          newDateStart = newDateStart.split('-');
          newDateStart = `${newDateStart[2]}-${newDateStart[1]}-${newDateStart[0]}`;
        }
        if (newDateEnd.match(regex)) {
          newDateEnd = newDateEnd.split('-');
          newDateEnd = `${newDateEnd[2]}-${newDateEnd[1]}-${newDateEnd[0]}`;
        }
        // Даты сущестуующих отпусков
        let oldDateStart = range.date_start.split('-');
        oldDateStart = `${oldDateStart[2]}-${oldDateStart[1]}-${oldDateStart[0]}`;
        let oldDateEnd = range.date_end.split('-');
        oldDateEnd = `${oldDateEnd[2]}-${oldDateEnd[1]}-${oldDateEnd[0]}`;

        if (oldDateStart <= newDateStart && newDateStart <= oldDateEnd) {
          if (overlappedVacations.filter((el) => el.id === range.id).length === 0) {
            overlappedVacations.push(range.user_id);
            if (overlappedVacations.filter((el) => el === openedVacation.user_id).length === 0) {
              overlappedVacations.push(openedVacation.user_id);
            }
          }
        }
        if (oldDateStart <= newDateEnd && newDateEnd <= oldDateEnd) {
          if (overlappedVacations.filter((el) => el.id === range.id).length === 0) {
            overlappedVacations.push(range.user_id);
            if (overlappedVacations.filter((el) => el === openedVacation.user_id).length === 0) {
              overlappedVacations.push(openedVacation.user_id);
            }
          }
        }
        if (newDateStart < oldDateStart && oldDateEnd < newDateEnd) {
          if (overlappedVacations.filter((el) => el.id === range.id).length === 0) {
            overlappedVacations.push(range.user_id);
            if (overlappedVacations.filter((el) => el === openedVacation.user_id).length === 0) {
              overlappedVacations.push(openedVacation.user_id);
            }
          }
        }
      }
    }
    const overlaps = [...new Set(overlappedVacations)].filter((el) => el !== '1287');

    if (groupAmount > 4) {
      if (((overlaps.length / groupAmount) * 100) > 60) return true;
    } else if (groupAmount < 4) {
      if (((overlaps.length / groupAmount) * 100) > 50) return true;
    }
    return false;
  }

  function checkDatesOrder() {
    if ('date_start' in openedVacation && 'date_end' in openedVacation) {
      const regex = /\d{2}-\d{2}-\d{4}/;
      let start = openedVacation.date_start;
      let end = openedVacation.date_end;
      if (start.match(regex)) {
        start = start.split('-');
        start = `${start[2]}-${start[1]}-${start[0]}`;
      }
      if (end.match(regex)) {
        end = end.split('-');
        end = `${end[2]}-${end[1]}-${end[0]}`;
      }
      if (end <= start) return false;
    }
    return true;
  }

  function findEmployeesOverlaps() {
    for (const range of vacations.filter((el) => el.user_id === openedVacation.user_id)) {
      let oldDateStart = range.date_start.split('-');
      oldDateStart = `${oldDateStart[2]}-${oldDateStart[1]}-${oldDateStart[0]}`;
      let oldDateEnd = range.date_end.split('-');
      oldDateEnd = `${oldDateEnd[2]}-${oldDateEnd[1]}-${oldDateEnd[0]}`;
      if (range.id !== openedVacation.id) {
        if (oldDateStart <= openedVacation.date_start && openedVacation.date_start <= oldDateEnd) return true;
        if (oldDateStart <= openedVacation.date_end && openedVacation.date_end <= oldDateEnd) return true;
        if (openedVacation.date_start < oldDateStart && oldDateEnd < openedVacation.date_end) return true;
      }
    }
    return false;
  }

  function limitOverrun() {
    if (Number(openedVacation.user_id) === user_id) {
      if ('date_start' in openedVacation && 'date_end' in openedVacation) {
        const regex = /\d{2}-\d{2}-\d{4}/;
        let start = openedVacation.date_start;
        let end = openedVacation.date_end;
        if (start.match(regex)) {
          start = start.split('-');
          start = `${start[2]}-${start[1]}-${start[0]}`;
        }
        if (end.match(regex)) {
          end = end.split('-');
          end = `${end[2]}-${end[1]}-${end[0]}`;
        }
        if (Number(spareDays - countDays(start, end)) < 0) {
          return true;
        }
      }
    }
    return false;
  }

  function updateOverrun() {
    if ('date_start' in openedVacation && 'date_end' in openedVacation) {
      const regex = /\d{2}-\d{2}-\d{4}/;
      let start = openedVacation.date_start;
      let end = openedVacation.date_end;
      if (start.match(regex)) {
        start = start.split('-');
        start = `${start[2]}-${start[1]}-${start[0]}`;
      }
      if (end.match(regex)) {
        end = end.split('-');
        end = `${end[2]}-${end[1]}-${end[0]}`;
      }
      const updatedDaysTaken = countDays(start, end);
      let oldDaysTaken = 0;
      for (const vc of vacations.filter((el) => Number(el.user_id) === Number(openedVacation.user_id))) {
        if (Number(vc.id) !== Number(openedVacation.id)) {
          oldDaysTaken += Number(vc.days);
        }
      }
      const updatedDaysSum = oldDaysTaken + updatedDaysTaken;
      if (Number(allDays - updatedDaysSum) < 0) {
        return true;
      }
    }
    return false;
  }

  function checkHolidays() {
    let dayCounter = 0;
    let daysToAdd = 0;
    while (dayCounter < countDays(openedVacation.date_start, openedVacation.date_end)) {
      const regex = /\d{2}-\d{2}-\d{4}/;
      let start = openedVacation.date_start;
      let end = openedVacation.date_end;
      if (start.match(regex)) {
        start = start.split('-');
        start = `${start[2]}-${start[1]}-${start[0]}`;
      }
      if (end.match(regex)) {
        end = end.split('-');
        end = `${end[2]}-${end[1]}-${end[0]}`;
      }
      let tmpDate = new Date(start);
      tmpDate.setHours(tmpDate.getHours());
      tmpDate.setDate(tmpDate.getDate() + dayCounter);
      tmpDate = tmpDate.toISOString().split('T')[0];
      tmpDate = tmpDate.split('-');
      const tmpDay = tmpDate[2].charAt(0) === '0' ? tmpDate[2].slice(1) : tmpDate[2];
      const tmpMonth = tmpDate[1].charAt(0) === '0' ? Number(tmpDate[1].slice(1)) : Number(tmpDate[1]);
      const holidays = [];
      for (const month of calendar) {
        if (month.month === tmpMonth) {
          for (let day of month.days.split(',')) {
            if (day.match(/\+/gm)) {
              day = day.replace(/\+/, '');
              holidays.push(day);
            }
          }
        }
      }
      if (holidays.includes(tmpDay)) {
        daysToAdd += 1;
      }
      dayCounter += 1;
    }
    const regex = /\d{2}-\d{2}-\d{4}/;
    let end = openedVacation.date_end;
    if (end.match(regex)) {
      end = end.split('-');
      end = `${end[2]}-${end[1]}-${end[0]}`;
    }
    let updatedDateEnd = end;
    updatedDateEnd = new Date(updatedDateEnd);
    updatedDateEnd.setHours(updatedDateEnd.getHours());
    updatedDateEnd.setDate(updatedDateEnd.getDate() + daysToAdd);
    updatedDateEnd = updatedDateEnd.toISOString().split('T')[0];

    return updatedDateEnd;
  }

  // Добавить новые отпуска
  async function addVacationEmployee() {
    if ('date_start' in openedVacation && 'date_end' in openedVacation) {
      if (!checkDatesOrder()) {
        setDatesOrder(false);
      } else {
        setDatesOrder(true);
        const start = openedVacation.date_start;
        const end = openedVacation.date_end;
        let date_start = openedVacation.date_start.split('-');
        date_start = `${date_start[2]}-${date_start[1]}-${date_start[0]}`;
        let date_end = openedVacation.date_end.split('-');
        date_end = `${date_end[2]}-${date_end[1]}-${date_end[0]}`;
        if (myVacations.length === 0) {
          if (limitOverrun()) {
            setOverrun(true);
            setOverlap(false);
            setInnerOverlap(false);
            document.querySelector('#start').value = '';
            document.querySelector('#end').value = '';
            setOpenedVacation({
              id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
            });
          } else if (findDatesOverlap()) {
            setOverlap(true);
            setInnerOverlap(false);
            setOverrun(false);
            document.querySelector('#start').value = '';
            document.querySelector('#end').value = '';
            setOpenedVacation({
              id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
            });
          } else if (findEmployeesOverlaps()) {
            setInnerOverlap(true);
            setOverrun(false);
            setOverlap(false);
            document.querySelector('#start').value = '';
            document.querySelector('#end').value = '';
            setOpenedVacation({
              id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
            });
          } else {
            if (checkHolidays() !== openedVacation.date_end) {
              let updatedDateEnd = checkHolidays().split('-');
              updatedDateEnd = `${updatedDateEnd[2]}-${updatedDateEnd[1]}-${updatedDateEnd[0]}`;
              setOpenedVacation(
                (prev) => prev,
                openedVacation.date_start = date_start,
                openedVacation.date_end = updatedDateEnd,
                openedVacation.days = countDays(start, end),
              );
            } else {
              setOpenedVacation(
                (prev) => prev,
                openedVacation.date_start = date_start,
                openedVacation.date_end = date_end,
                openedVacation.days = countDays(start, end),
              );
            }
            setSpareDays(() => spareDays - countDays(start, end));
            setMyVacations([...myVacations, openedVacation]);
            setOpenedVacation({
              id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
            });
            document.querySelector('#start').value = '';
            document.querySelector('#end').value = '';
            setInnerOverlap(false);
            setOverlap(false);
            setOverrun(false);
          }
        } else if (myVacations.length > 0) {
          if (limitOverrun()) {
            setOverrun(true);
            document.querySelector('#start').value = '';
            document.querySelector('#end').value = '';
            setOpenedVacation({ id: 'new', user_id: String(user_id) });
          } else if (findDatesOverlap()) {
            setOverlap(true);
            document.querySelector('#start').value = '';
            document.querySelector('#end').value = '';
            setOpenedVacation({ id: 'new', user_id: String(user_id) });
          } else if (findEmployeesOverlaps()) {
            setInnerOverlap(true);
            document.querySelector('#start').value = '';
            document.querySelector('#end').value = '';
            setOpenedVacation({
              id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
            });
          } else {
            for (const vctn of myVacations) {
              let prevDateStart = vctn.date_start.split('-');
              prevDateStart = `${prevDateStart[2]}-${prevDateStart[1]}-${prevDateStart[0]}`;
              let prevDateEnd = vctn.date_end.split('-');
              prevDateEnd = `${prevDateEnd[2]}-${prevDateEnd[1]}-${prevDateEnd[0]}`;
              if (new Date(prevDateStart).getTime() !== new Date(openedVacation.date_start).getTime()
                  && new Date(prevDateEnd).getTime() !== new Date(openedVacation.date_end).getTime()) {
                if (checkHolidays() !== openedVacation.date_end) {
                  let updatedDateEnd = checkHolidays().split('-');
                  updatedDateEnd = `${updatedDateEnd[2]}-${updatedDateEnd[1]}-${updatedDateEnd[0]}`;
                  setOpenedVacation(
                    (prev) => prev,
                    openedVacation.date_start = date_start,
                    openedVacation.date_end = updatedDateEnd,
                    openedVacation.days = countDays(start, end),
                  );
                } else {
                  setOpenedVacation(
                    (prev) => prev,
                    openedVacation.date_start = date_start,
                    openedVacation.date_end = date_end,
                    openedVacation.days = countDays(start, end),
                  );
                }
                setSpareDays(() => spareDays - countDays(start, end));
                setMyVacations([...myVacations, openedVacation]);
                setOpenedVacation({
                  id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
                });
                document.querySelector('#start').value = '';
                document.querySelector('#end').value = '';
                setInnerOverlap(false);
                setOverlap(false);
                setOverrun(false);
              } else {
                // eslint-disable-next-line no-await-in-loop
                await alertFunction('vacation_unavailable', 'clientPost');
                setInnerOverlap(false);
                setOverlap(false);
                setOverrun(false);
                setOpenedVacation({
                  id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
                });
                document.querySelector('#start').value = '';
                document.querySelector('#end').value = '';
              }
            }
          }
        }
      }
    } else {
      await alertFunction('required_fields', 'clientPost');
    }
  }

  async function addVacationAdmin() {
    if ('date_start' in openedVacation && 'date_end' in openedVacation) {
      if (!checkDatesOrder()) {
        setDatesOrder(false);
      } else {
        setDatesOrder(true);
        const start = openedVacation.date_start;
        const end = openedVacation.date_end;
        let date_start = openedVacation.date_start.split('-');
        date_start = `${date_start[2]}-${date_start[1]}-${date_start[0]}`;
        let date_end = openedVacation.date_end.split('-');
        date_end = `${date_end[2]}-${date_end[1]}-${date_end[0]}`;
        if (myVacations.length === 0) {
          if (checkHolidays() !== openedVacation.date_end) {
            let updatedDateEnd = checkHolidays().split('-');
            updatedDateEnd = `${updatedDateEnd[2]}-${updatedDateEnd[1]}-${updatedDateEnd[0]}`;
            setOpenedVacation(
              (prev) => prev,
              openedVacation.date_start = date_start,
              openedVacation.date_end = updatedDateEnd,
              openedVacation.days = countDays(date_start, updatedDateEnd),
            );
          } else {
            setOpenedVacation(
              (prev) => prev,
              openedVacation.date_start = date_start,
              openedVacation.date_end = date_end,
              openedVacation.days = countDays(start, end),
            );
          }
          setSpareDays(() => spareDays - countDays(start, end));
          setMyVacations([...myVacations, openedVacation]);
          setOpenedVacation({
            id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
          });
          document.querySelector('#start').value = '';
          document.querySelector('#end').value = '';
        } else if (myVacations.length > 0) {
          for (const vctn of myVacations) {
            let prevDateStart = vctn.date_start.split('-');
            prevDateStart = `${prevDateStart[2]}-${prevDateStart[1]}-${prevDateStart[0]}`;
            let prevDateEnd = vctn.date_end.split('-');
            prevDateEnd = `${prevDateEnd[2]}-${prevDateEnd[1]}-${prevDateEnd[0]}`;
            if (new Date(prevDateStart).getTime() !== new Date(openedVacation.date_start).getTime()
                && new Date(prevDateEnd).getTime() !== new Date(openedVacation.date_end).getTime()) {
              if (checkHolidays() !== openedVacation.date_end) {
                let updatedDateEnd = checkHolidays().split('-');
                updatedDateEnd = `${updatedDateEnd[2]}-${updatedDateEnd[1]}-${updatedDateEnd[0]}`;
                setOpenedVacation(
                  (prev) => prev,
                  openedVacation.date_start = date_start,
                  openedVacation.date_end = updatedDateEnd,
                  openedVacation.days = countDays(start, end),
                );
              } else {
                setOpenedVacation(
                  (prev) => prev,
                  openedVacation.date_start = date_start,
                  openedVacation.date_end = date_end,
                  openedVacation.days = countDays(start, end),
                );
              }
              setSpareDays(() => spareDays - countDays(start, end));
              setMyVacations([...myVacations, openedVacation]);
              setOpenedVacation({
                id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
              });
              document.querySelector('#start').value = '';
              document.querySelector('#end').value = '';
              setInnerOverlap(false);
              setOverlap(false);
              setOverrun(false);
            } else {
              // eslint-disable-next-line no-await-in-loop
              await alertFunction('vacation_unavailable', 'clientPost');
              setOpenedVacation({
                id: 'new', user_id: String(user_id), year: currentYear, name: employeeName,
              });
              document.querySelector('#start').value = '';
              document.querySelector('#end').value = '';
            }
          }
        }
      }
    } else {
      await alertFunction('required_fields', 'clientPost');
    }
  }

  // Редактировать существующий отпуск
  function updateVacation() {
    if (vacations.filter((el) => Number(el.id) === openedVacation.id).length !== 0) {
      const regex = /\d{2}-\d{2}-\d{4}/;
      let start = openedVacation.date_start;
      let end = openedVacation.date_end;
      if (start.match(regex)) {
        start = start.split('-');
        start = `${start[2]}-${start[1]}-${start[0]}`;
      }
      if (end.match(regex)) {
        end = end.split('-');
        end = `${end[2]}-${end[1]}-${end[0]}`;
      }
      // Даты в нужном формате день-месяц-год для записи в объект отпуска
      let date_start = start.split('-');
      date_start = `${date_start[2]}-${date_start[1]}-${date_start[0]}`;
      let date_end = end.split('-');
      date_end = `${date_end[2]}-${date_end[1]}-${date_end[0]}`;
      if (!checkDatesOrder()) {
        setDatesOrder(false);
      } else {
        setDatesOrder(true);
        if (updateOverrun()) {
          setOverrun(true);
          document.querySelector('#start').value = '';
          document.querySelector('#end').value = '';
        //  setOpenedVacation({ id: 'new', user_id: String(user_id) });
        } else if (findDatesOverlap()) {
          setOverlap(true);
          setInnerOverlap(false);
          setOverrun(false);
          document.querySelector('#start').value = '';
          document.querySelector('#end').value = '';
          //   setOpenedVacation({ id: 'new', user_id: String(user_id) });
        } else if (findEmployeesOverlaps()) {
          setInnerOverlap(true);
          setOverlap(false);
          setOverrun(false);
          document.querySelector('#start').value = '';
          document.querySelector('#end').value = '';
        //  setOpenedVacation({ id: 'new', user_id: String(user_id) });
        } else {
          if (checkHolidays() !== openedVacation.date_end) {
            let updatedDateEnd = checkHolidays().split('-');
            updatedDateEnd = `${updatedDateEnd[2]}-${updatedDateEnd[1]}-${updatedDateEnd[0]}`;
            setOpenedVacation(
              (prev) => prev,
              openedVacation.date_start = date_start,
              openedVacation.date_end = updatedDateEnd,
              openedVacation.days = countDays(start, end),
            );
          } else {
            setOpenedVacation(
              (prev) => prev,
              openedVacation.date_start = date_start,
              openedVacation.date_end = date_end,
              openedVacation.days = countDays(start, end),
            );
          }
          const updatedTasks = vacations.map((task) => {
            if (task.id === openedVacation.id) {
              setMyVacations([openedVacation]);
              // Если задача редактировалась
              return openedVacation;
            }
            // Если задача не редактировалась
            return task;
          });
          const userVacations = updatedTasks.filter((v) => Number(v.user_id) === user_id);
          let daysTaken = 0;
          for (const uv of userVacations) {
            daysTaken += Number(uv.days);
          }
          setSpareDays(() => Number(allDays) - daysTaken);
          setInnerOverlap(false);
          setOverlap(false);
          setOverrun(false);
        }
      }
    }
  }

  // Редактировать существующий отпуск (маршрут админа)
  function updateVacationAdmin() {
    if (vacations.filter((el) => Number(el.id) === openedVacation.id).length !== 0) {
      const regex = /\d{2}-\d{2}-\d{4}/;
      let start = openedVacation.date_start;
      let end = openedVacation.date_end;
      if (start.match(regex)) {
        start = start.split('-');
        start = `${start[2]}-${start[1]}-${start[0]}`;
      }
      if (end.match(regex)) {
        end = end.split('-');
        end = `${end[2]}-${end[1]}-${end[0]}`;
      }
      // Даты в нужном формате день-месяц-год для записи в объект отпуска
      let date_start = start.split('-');
      date_start = `${date_start[2]}-${date_start[1]}-${date_start[0]}`;
      let date_end = end.split('-');
      date_end = `${date_end[2]}-${date_end[1]}-${date_end[0]}`;
      if (!checkDatesOrder()) {
        setDatesOrder(false);
      } else {
        setDatesOrder(true);
        if (checkHolidays() !== openedVacation.date_end) {
          let updatedDateEnd = checkHolidays().split('-');
          updatedDateEnd = `${updatedDateEnd[2]}-${updatedDateEnd[1]}-${updatedDateEnd[0]}`;
          setOpenedVacation(
            (prev) => prev,
            openedVacation.date_start = date_start,
            openedVacation.date_end = updatedDateEnd,
            openedVacation.days = countDays(start, end),
          );
        } else {
          setOpenedVacation(
            (prev) => prev,
            openedVacation.date_start = date_start,
            openedVacation.date_end = date_end,
            openedVacation.days = countDays(start, end),
          );
        }
        const updatedTasks = vacations.map((task) => {
          if (task.id === openedVacation.id) {
            setMyVacations([openedVacation]);
            // Если задача редактировалась
            return openedVacation;
          }
          // Если задача не редактировалась
          return task;
        });
        const userVacations = updatedTasks.filter((v) => Number(v.user_id) === Number(openedVacation.user_id));
        let daysTaken = 0;
        for (const uv of userVacations) {
          daysTaken += Number(uv.days);
        }
        setSpareDays(() => Number(allDays) - daysTaken);
      }
    }
  }

  // Сохранить новые отпуска или отредактированный существующий
  async function saveNewVacation() {
    try {
      const reqData = {
        type: 'saveNewVacationAdmin',
        newVctnData: type === 'create' ? myVacations : openedVacation,
        location: isAdmin ? 'admin' : 'employee',
        action: type,
        currentYear,
        interface_page: 'vacations',
      };
      const result = await clientSendData('POST', '/save_new_vacation_admin', reqData); // запрос в БД
      if (result) {
        await alertFunction('save_settings', 'clientPost');
        setShow(false);
      }
      await getVacations();
    } catch (error) {
      catchHandler(error, 'saveNewVacationAdmin'); // обработка ошибок
    }
  }

  async function handleApproval(action) {
    try {
      const reqData = {
        type: 'vacationHandleApproval',
        userVacation: openedVacation,
        action,
      };
      const result = await clientSendData('POST', '/vacation_handle_approval', reqData); // запрос в БД
      if (result.success) {
        await getVacations();
        await alertFunction('save_settings', 'clientPost');
        setShow(false);
      }
    } catch (error) {
      catchHandler(error, 'vacationHandleApproval'); // обработка ошибок
    }
  }

  // Подсчет оставшихся для распределения дней отпуска
  function countDays(start, end) {
    // const start = openedVacation.date_start;
    // const end1 = openedVacation.date_end;
    let start1 = start;
    let end1 = end;
    const regex = /\d{2}-\d{2}-\d{4}/;
    if (start1.match(regex)) {
      start1 = start1.split('-');
      start1 = `${start1[2]}-${start1[1]}-${start1[0]}`;
    }
    if (end1.match(regex)) {
      end1 = end1.split('-');
      end1 = `${end1[2]}-${end1[1]}-${end1[0]}`;
    }
    // let date_start = openedVacation.date_start.split('-');
    // date_start = `${date_start[2]}-${date_start[1]}-${date_start[0]}`;
    // let date_end = openedVacation.date_end.split('-');
    // date_end = `${date_end[2]}-${date_end[1]}-${date_end[0]}`;
    const date1 = new Date(end1);
    let date2 = new Date(start1);
    date2 = date2.setDate(date2.getDate() - 1);
    const Difference_In_Time = date2 - date1.getTime();
    const Difference_In_Days = Math.abs(Math.round(Difference_In_Time / (1000 * 3600 * 24)));
    return Difference_In_Days;
  }

  return (
    <Card setShow={setShow} id="employee-vacation-card">
      <CardBody>
    <div style={{
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      width: '450px',
      margin: '10px auto',
    }}
    >
      <span className="period">
      <p className="title">С:</p>
      <Input
        id="start"
        placeholder="Кол-во дней"
        onChange={(e) => setOpenedVacation({ ...openedVacation, date_start: e.target.value, date_end: e.target.value })}
        value={openedVacation.date_start}
        type="date"
      />
      </span>
      <span className="period">
       <p className="title">По:</p>
      <Input
        id="end"
        placeholder="Кол-во дней"
        onChange={(e) => setOpenedVacation({ ...openedVacation, date_end: e.target.value })}
        value={openedVacation.date_end}
        type="date"
      />
       {(type === 'edit') && <p className="add" style={{ fontSize: '20px' }} onClick={isAdmin ? updateVacationAdmin : updateVacation} title="Применить изменения">⟳</p>}
       {(type === 'create') && <p className="add" onClick={isAdmin ? addVacationAdmin : addVacationEmployee} title="Добавить даты">╋</p>}
      </span>
    </div>
    {myVacations.length > 0 && myVacations.map((v, ind) => <div key={ind}>
      <p style={{ margin: '0 auto', width: '400px' }}>{`${ind + 1}) ${v.date_start} - ${v.date_end} ✔`}</p>
                                                           </div>)}
     {(!isAdmin && type === 'edit' && !['Создано'].includes(openedVacation.status)) && <textarea
       className="shift-reason"
       placeholder="Укажите причину переноса"
       onChange={(e) => setOpenedVacation({ ...openedVacation, shift_reason: e.target.value })}
       defaultValue={openedVacation?.shift_reason}
     />}

     {overlap && <p style={{ textAlign: 'center' }}>
        Упс! Уже занято коллегами, они тоже хотят отдыхать.
        <br />
        Придется выбрать другие даты ☹️
                 </p>}
     {innerOverlap && <p style={{ textAlign: 'center', color: 'red' }}>
     ⚠︎ Выбор на эти даты пересекается с другим вашим отпуском.
                      </p>}
    {overrun && <p style={{ textAlign: 'center', color: 'red' }}> ⚠︎ Превышено допустимое количество дней для распределения</p>}
    {!datesOrder && <p style={{ textAlign: 'center', color: 'red' }}>Невозможно выбрать дату окончания отпуска меньше, чем дата начала</p>}
      </CardBody>
      <CardFooter>
       {/* {!isAdmin && openedVacation.status === 'Создано' && <Button onClick={saveNewVacation} disabled={!findDatesOverlap() || myVacations.length === 0}>Сохранить</Button>} */}
      {openedVacation.status !== 'На согласовании'
      && <Button onClick={saveNewVacation} disabled={myVacations.length === 0}>Сохранить</Button>}
       {(isAdmin && ['На согласовании', 'Перенесено'].includes(openedVacation.status)) && <div style={{
         display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'space-between',
       }}
       >
       <Button onClick={() => handleApproval('approve')}>Согласовать</Button>
       <Button onClick={() => handleApproval('dismiss')}>Отклонить</Button>
                                                                                          </div>}
      </CardFooter>
    </Card>
  );
}

export default EmployeeVacationCard;
