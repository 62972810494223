import React, { useEffect, useRef, useState } from 'react';
import { format } from 'util';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import Button from '../../components/UI/Button/Button';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import CardFooter from '../../components/Card/CardFooter';
import InputSearch from '../../components/UI/InputSearch';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import CardHeader from '../../components/Card/CardHeader';

function UnicallInterface() {
  const headers = [
    { id: 1, title: '№', field: 'id' },
    { id: 2, title: 'Событие', field: 'event' },
    { id: 3, title: 'Инициатор', field: 'initiator' },
    { id: 4, title: 'Время начала', field: 'date_start' },
    { id: 5, title: 'Время завершения', field: 'date_end' },
  ];
  const [showCard, setShowCard] = useState(false);
  const [newMeetingData, setNewMeetingData] = useState({ users: [] });
  const [selectUsers, setSelectUsers] = useState({});
  const [users, setUsers] = useState([]);
  const [participiants, setParticipiants] = useState([]); // участники видеоконференции
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [openedMeeting, setOpenedMeeting] = useState(null); // открытая в карточке видеоконференции
  const [currentEvents, setCurrentEvents] = useState([]);
  const [chosenEvent, setChosenEvent] = useState({});
  const currentDate = new Date();
  const today = currentDate.getDate() <= 9
    ? `${String(currentDate.getDate()).padStart(2, '0')}.${String(currentDate.getMonth() + 1).padStart(2, '0')}.${currentDate.getFullYear()}`
    : `${currentDate.getDate()}.${String(currentDate.getMonth() + 1).padStart(2, '0')}.${currentDate.getFullYear()}`;

  const dateTimeRef = useRef(null);

  useEffect(() => {
    const secondsTimer = setInterval(() => {
      dateTimeRef.current.innerText = (new Date().toLocaleTimeString({ hour: '2-digit', minute: '2-digit' })).slice(0, 5);
    }, 10000);
    return () => clearInterval(secondsTimer);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getCurrentEvents(); // Обновить состояние tableState каждые 10 сек
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getCurrentEvents();
  }, []);

  function openCard(id) {
    setOpenedMeeting(id);
    if (id !== 'new') {
      setChosenEvent(currentEvents.filter((item) => item.id === id)[0]);
    }
    if (id === 'new') {
      getVcCard(id);
    }
    setShowCard(true);
  }

  // async function getConfig() {
  //   try {
  //     const reqData = { type: 'getHpsliceConfig' };
  //     const result = await clientSendData('POST', '/get_hpslice_config', reqData);
  //   } catch (error) {
  //     catchHandler(error, 'getHpsliceConfig');
  //   }
  // }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'vcMeetingSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData('POST', '/vc_meeting_search_user', reqData);
        setSelectUsers({ id: e.target.value, title: e.target.value, mail: e.target.value });
        if (result?.success) setUsers(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'vcMeetingSearchUser');
      setIsSearching(false);
      return [];
    }
  }
  // Добавление пользователей
  async function addParticipiants(participiant) {
    if (Object.keys(participiant).length > 0) {
      if (participiants.filter((el) => el.id === participiant.id).length === 0) {
        setParticipiants((state) => [...state, participiant]);
        const newUsers = newMeetingData.users;
        newUsers.push(participiant.mail);
        setNewMeetingData((state) => ({ ...state, users: newUsers }));
        setSelectUsers({});
      }
    }
  }

  async function deleteParticipiants(usersId) {
    const userEmail = participiants.find((el) => el.id === usersId);
    const filteredUsers = newMeetingData.users.filter((el) => el !== userEmail.mail);
    setNewMeetingData((state) => ({ ...state, users: filteredUsers }));
    setParticipiants(participiants.filter((el) => el.id !== usersId));
  }

  // Получение данных для настройки уведомления
  async function getVcCard(id) {
    try {
      const reqData = {
        type: 'getVCCardMeetingroom',
        id, // id события
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_vc_card_meetingroom', reqData);
      setNewMeetingData({ ...newMeetingData, link: result.meeting.data.link });
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getVCCardMeetingroom');
    }
  }

  // Сохранить новую встречу для переговорной
  async function saveNewEvent() {
    try {
      const reqData = {
        type: 'saveNewMeetingroomEvent',
        newEvent: newMeetingData,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/save_new_meetingroom_event', reqData);
      if (result) {
        window.location.replace(result.link);
      }
      setIsLoading(false);
      getCurrentEvents();
    } catch (error) {
      catchHandler(error, 'saveNewMeetingroomEvent');
    }
  }

  async function getCurrentEvents() {
    try {
      const reqData = {
        type: 'getMeetingroomEvents',
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_meetingroom_events', reqData);
      if (result) {
        setCurrentEvents(result);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getMeetingroomEvents');
    }
  }

  return (
    <div id="UnicallInterface">
      <div className="date_time">
        <div className="date">
          <p>{today}</p>
        </div>
        <div className="time">
          <p ref={dateTimeRef} />
        </div>
      </div>

      <table className="meetings">
        <thead style={{ position: 'static' }}>
      <tr className="row">
       {headers.map((item) => <td key={item.id} style={{ borderBottom: '1px dashed #5b5d60' }}>
<p className="cell">{item.title}</p>
{/* <span className="splitter" /> */}
                              </td>)}
      </tr>
        </thead>
      <tbody style={{ overflowYy: 'auto' }}>
        {currentEvents?.map((row) => <tr className="row" key={row.id} onClick={() => openCard(row.id)}>

          {headers.map((item) => <td key={item.id}><p className="tdata">{row[item.field]}</p></td>)}

                                     </tr>)}
      </tbody>
      </table>
      <Button onClick={() => openCard('new')}>Создать встречу</Button>

      {showCard && <Card id="newMeeting" setShow={setShowCard} meetingId={openedMeeting}>
        <CardHeader />
        <CardBody>
          {openedMeeting === 'new' ? (
            <>
            <CardSetting title="Событие:">
            <Input
              onChange={(e) => setNewMeetingData({ ...newMeetingData, event: e.target.value })}
            />
            </CardSetting>

          <CardSetting title="Участники:">
            <div className="tags__input-block">
          <InputSearch
            placeholder="Введите пользователя Юникона или email внешнего участника"
            array={users}
            onSearch={(e) => searchWrapper(e)}
            onChoose={(user) => setSelectUsers(user)}
            defaultValue={selectUsers?.title}
          />
<div onClick={() => (addParticipiants(selectUsers))} className="tags__button">
          <img className="tags__img" src="../../icons/cancel.svg" alt="add-tag" />
</div>
            </div>

          </CardSetting>
          <div className="tags__list">
                    {participiants?.map((el) => <p className="tags__tag" key={el.id}>
                        <span onClick={() => deleteParticipiants(el.id)} className="upu__close">X</span>
                        {el.title}
                                                </p>)}
          </div>
            </>
          ) : (
            <span style={{
              fontSize: '6em',
              marginTop: '300px',
              textAlign: 'center',
              // border: '1px solid grey',
              // borderRadius: '6px',
              color: '#5b5d60',
            }}
            >
              {/* <p style={{ marginBottom: '2%' }}>Перейдите по ссылке, чтобы начать встречу:</p> */}
              <a style={{ fontWeight: 'bold', color: '#5b5d60' }} href={chosenEvent.link}>Нажмите, чтобы начать встречу</a>
            </span>
          )}

        </CardBody>

         {openedMeeting === 'new' && <Button id="save" onClick={saveNewEvent}>Создать</Button>}

                   </Card>}

    </div>
  );
}

export default UnicallInterface;
