import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ServiceBody from '../../components/Service/ServiceBody';
import CardSetting from '../../components/Card/CardSetting';
import Select from '../../components/UI/Select';
import Input from '../../components/UI/Input';
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import TextArea from '../../components/UI/TextArea';
import Button from '../../components/UI/Button/Button';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { downloadDataUrl } from '../../utils/functions/others';
import { alertFunction } from '../../utils/functions/alertFunction';
import BulkFileUpload from '../../components/UI/FileUpload/BulkFileUpload';
import Cancel from '../../components/UI/Cancel';
import Loader from '../../components/UI/Loader';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CheckBox from '../../components/UI/CheckBox';
import CardFooter from '../../components/Card/CardFooter';
import { stringMatch } from '../../utils/functions/stringHandling';
import InputSearch from '../../components/UI/InputSearch';

function Message(props) {
  const {
    messageId, users, sdlUser, dir,
  } = props;
  const currency = [
    { id: 1, title: 'Российский рубль' },
    { id: 2, title: 'Евро' },
    { id: 3, title: 'Доллар США' },
    { id: 4, title: 'Австралийский доллар' },
    { id: 5, title: 'Английский фунт' },
    { id: 6, title: 'Дирхам' },
    { id: 7, title: 'Азербайджанский манат' },
    { id: 8, title: 'Юань' },
    { id: 9, title: 'Алжирский динар' },
    { id: 10, title: 'Тунисский динар' },
    { id: 11, title: 'Армянский драм' },
    { id: 12, title: 'Тенге' },
    { id: 13, title: 'Сом' },
    { id: 14, title: 'Узбекский сум' },
    { id: 15, title: 'Белорусский рубль' },
    { id: 16, title: 'Турецкая лира' },
    { id: 17, title: 'Афгани' },
    { id: 18, title: 'Лари' },
    { id: 19, title: 'Египетский фунт' },
    { id: 20, title: 'Тунисский динар' },
    { id: 21, title: 'Рупия' },
    { id: 22, title: 'Сомони' },
    { id: 23, title: 'Новый туркменский манат' },
    { id: 24, title: 'Молдавский лей' },
  ];

  const user_id = useSelector((state) => state?.oper_info?.user_id); // id текущего пользователя
  const [editable, setEditable] = useState(null);
  const [acceptFileExtension, setAcceptFileExtension] = useState([]); // разрешения файлов
  const [searchParams, setSearchParams] = useSearchParams(''); // параметры поиска url
  const [btnVisible, setBtnVisible] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const [size, setSize] = useState('medium');
  const [currentStatus, setCurrentStatus] = useState({});
  // const [currentStatusId, setCurrentStatusId] = useState('');
  const [initiator, setInitiator] = useState({});
  const [message, setMessage] = useState({});
  const [defaultMessage, setDefaultMessage] = useState({});
  const operationTypes = [
    { id: 1, title: 'Разовая необычная операция' },
    { id: 2, title: 'Иные операции (сделки)' },
  ];
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [criteriaArr, setCriteriaArr] = useState([]); // Набор критериев выбора операции
  const [criteria, setCriteria] = useState([]);
  const [displayCriteria, setDisplayCriteria] = useState([]);
  const [defaultAttachments, setDefaultAttachments] = useState({});
  const employee = initiator.id === user_id;
  const taskSupervisor = message?.taskSupervisor?.id === user_id;
  const messageNumber = atob(messageId).split('@')[0];
  useEffect(() => {
    getMessageData();
  }, []);

  // Получить данные сообщения
  async function getMessageData() {
    try {
      const reqData = {
        type: 'getMessageData',
        messageId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_message_data', reqData);
      if (result) {
        setMessage(result.data[0].message);
        setDefaultMessage(result.data[0].message);
        setInitiator({
          id: result.data[0].initiator_id,
          name: result.data[0].initiator_title,
        });
        setCurrentStatus({
          title: result.data[0].status_title,
          id: result.data[0].status_id,
        });
        setCriteria(result.criteria);
        setDisplayCriteria(result.criteria);
        // eslint-disable-next-line guard-for-in
        for (const c of result.data[0].message.operTypeCriteria) {
          setCriteriaArr((prev) => [...prev, c.code]);
        }
        // setAttachments(result.data[0].files);
        // setDefaultAttachments(result.data[0].attachments);
        // eslint-disable-next-line guard-for-in
        if (result.data[0].attachments) {
          setAttachments([]);
          // eslint-disable-next-line guard-for-in
          for (const key in result.data[0].attachments) {
            setAttachments((prev) => [
              ...prev,
              {
                path: result.data[0].attachments[key].data,
                name: result.data[0].attachments[key].name,
              },
            ]);
            // attachments.push({
            //   path: result.data[0].attachments[key].data,
            //   name: result.data[0].attachments[key].name,
            // });
          }
        }
      }
      setBtnVisible(true);
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getMessageData');
    }
  }

  // Обработка прикрепленного вложения и запись в объект нового email
  function attachmentHandler(file) {
    for (const f of file) {
      const reader = new FileReader(); // API чтения файлов
      reader.onloadend = (e) => {
        setAttachments((prev) => [
          ...prev, // развернуть существующее состояние
          { path: e.target.result, name: f.name },
        ]);
      };
      reader.readAsDataURL(f); // Прочитать как DataURL ("data:image/png;base64,iVBORw...")
    }
  }

  async function downloadAttachment(id, fileName) {
    try {
      const reqData = { type: 'getPodftAttachment', name: fileName };
      setIsLoading(true);
      const result = await clientSendData(
        'POST',
        `/get_podft_attachment/${id}`,
        reqData,
      );
      if (result?.success) {
        const fileExtension = fileName.split('.').pop().toLowerCase(); // получаем расширение файла
        const imageFileTypes = ['jpeg', 'png', 'gif', 'bmp', 'svg']; // типы файлов, которые мы считаем изображениями
        // Если расширение файла присутствует в массиве типов файлов-изображений
        if (imageFileTypes.includes(fileExtension)) {
          setImageSrc(result.data);
          setSize('active');
        } else {
          downloadDataUrl(result.data, fileName);
        }
      }
    } catch (error) {
      catchHandler(error, 'downloadAttachment');
    } finally {
      setIsLoading(false);
    }
  }

  function deleteAttachment(deletionFile) {
    const res = attachments.filter((file) => file.name !== deletionFile);
    setAttachments(res);
  }

  // Сохранить отредактированное сообщение
  async function saveEditedMessage(type) {
    try {
      if (type === 'setToWork') {
        message.sdl_in_duty = user_id;
      }
      const reqData = {
        type: 'podftMessageSave',
        action: type,
        message,
        defaultMessage,
        attachments,
        initiator,
        employee: user_id,
        messageId,
        defaultStatus: currentStatus.title,
      };
      await clientSendData('POST', '/podft_message_save', reqData);
      await alertFunction('save_settings', 'clientPost');
      setBtnVisible(false);
      await getMessageData();
      // setSearchParams('');
    } catch (error) {
      catchHandler(error, 'podftMessageSave'); // обработка ошибок
    }
  }

  // Добавить критерий
  function addCriteria(title, criterion) {
    setCriteriaArr([...criteriaArr, title]);
    // setNewMail({ ...newMail, operTypeCriteria: [...newMail.operTypeCriteria, { code: title, title: criterion }] });
    setMessage({
      ...message,
      operTypeCriteria: [
        ...message.operTypeCriteria,
        { code: title, title: criterion },
      ],
    });
  }

  // Удалить критерий из списка
  function deleteItem(item) {
    const filtered = criteriaArr.filter((i) => i !== item.code);
    setCriteriaArr(filtered);
    setMessage({
      ...message,
      operTypeCriteria: message.operTypeCriteria.filter(
        (i) => i.code !== item.code,
      ),
    });
  }

  const findCriterion = (e) => {
    try {
      let filterResult;
      if (e.target.value.match(/^\d+$/)) {
        filterResult = criteria.filter((item) => stringMatch(e.target.value, String(item.title)));
      } else {
        filterResult = criteria.filter((item) => stringMatch(e.target.value, String(item.criterion)));
      }
      //   const filterResult = criteria.filter((item) => stringMatch(e.target.value, String(item.title)));
      if (filterResult.length > 0) {
        setDisplayCriteria(filterResult);
      } else {
        setDisplayCriteria(criteria);
      }
    } catch (error) {
      catchHandler(error, 'searchHandler');
    }
  };

  const workGroup = (employee || taskSupervisor) && [1, 2].includes(currentStatus.id);
  const sdlGroup = (sdlUser && [5, 6, 7].includes(currentStatus.id));

  if (isLoading) return <Loader />;
  if (editable === 'operTypeCriteria') {
    return (
      <Card id="new_podft_message_card" setShow={() => setEditable(null)}>
        <CardHeader>
          <p
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: '18px',
              fontWeight: '500',
              paddingBottom: '15px',
            }}
          >
            Справочник критериев выбора типа операции
          </p>
        </CardHeader>
        <CardBody>
          <div style={{ background: 'white', borderRadius: '7px' }}>
            <span style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: '15px', marginBottom: '15px',
            }}
            >
        {/* <p style={{ fontWeight: 'bold', color: 'darkgreen' }}>Поиск по коду критерия</p> */}
        <Input placeholder="Найти критерий по коду" onInput={(e) => findCriterion(e)} array={criteria} />
            </span>

            {displayCriteria.map((c) => (
              <div
                key={c.id}
                style={{
                  borderBottom: '1px solid grey',
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: '5px',
                  marginTop: '5px',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    width: '5%',
                    fontWeight: 'bold',
                    marginLeft: '15px',
                  }}
                >
                  {c.title}
                </p>
                <p style={{ width: '85%', marginRight: '15px' }}>
                  {c.criterion}
                </p>
                {(workGroup || sdlGroup) && (
                  <CheckBox
                    id={c.id}
                    onChange={() => addCriteria(c.title, c.criterion)}
                    checked={Boolean(criteriaArr.includes(c.title))}
                  />
                )}
                {/* <CardSetting title={c.title}>{c.criterion}</CardSetting> */}
                <span />
              </div>
            ))}
          </div>
        </CardBody>
        <CardFooter>
          <Button onClick={() => setEditable(null)}>Завершить выбор</Button>
        </CardFooter>
      </Card>
    );
  }

  return (
    <ServiceBody id="PODFT-message">
      <div className="chat-title">
Внутреннее сообщение
{
 (sdlUser || dir) ? ` №${messageNumber}` : ''}
<p style={{ marginLeft: '7px' }}>
(
{message?.client ? message?.client : '-'}
)
</p>
      </div>
      <div className="msg_container">
         {/* Дата создания */}
        {sdlGroup && <CardSetting title="Дата создания сообщения:">
          {editable === 'date_created' ? (
            <Input
              type="datetime-local"
              onChange={(e) => setMessage({ ...message, date_created: (e.target.value).split('T').join(' ') })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
            //  defaultValue={message?.date_created}
            />
          ) : (
            <p
              className={sdlGroup ? 'clickable' : ''}
              onClick={sdlGroup ? () => setEditable('date_created') : undefined}
            >
              {message?.date_created}
            </p>
          )}
                     </CardSetting>
        }

        {/* Инициатор */}
        <CardSetting title="Инициатор:">
          {editable === 'initiator' ? (
            <Select
              search
              array={users}
              onChoose={(e) => setInitiator({ ...initiator, name: e.title, id: e.id })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={initiator.name}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('initiator') : undefined}
            >
              {initiator?.name}
            </p>
          )}
        </CardSetting>

        {/* Наименование клиента */}
        <CardSetting title="Наименование клиента:">
          {editable === 'client' ? (
            <TextArea
              onChange={(e) => setMessage({ ...message, client: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.client}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('client') : undefined}
            >
              {message?.client}
            </p>
          )}
        </CardSetting>

        {/* Номер договора */}
        <CardSetting title="Номер договора:">
          {editable === 'contractNum' ? (
            <TextArea
              onChange={(e) => setMessage({ ...message, contractNum: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.contractNum}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('contractNum') : undefined}
            >
              {message?.contractNum}
            </p>
          )}
        </CardSetting>

        {/* Дата выпуска отчетного документа  */}
        <CardSetting title="Дата выпуска отчетного документа :">
          {editable === 'releaseDate' ? (
            <Input
              type="date"
              onChange={(e) => setMessage({ ...message, releaseDate: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.releaseDate}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('releaseDate') : undefined}
            >
              {message?.releaseDate}
            </p>
          )}
        </CardSetting>

        {/* Руководитель задания */}
        <CardSetting title="Руководитель задания:">
          {editable === 'taskSupervisor' ? (
            <Select
              search
              array={users}
              onChoose={(e) => setMessage({ ...message, taskSupervisor: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.taskSupervisor.name}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('taskSupervisor') : undefined}
            >
              {message?.taskSupervisor?.name}
            </p>
          )}
        </CardSetting>

        {/* Вид операции */}
        <CardSetting title="Вид операции:">
          {editable === 'operationType' ? (
            <Select
              array={operationTypes}
              onChoose={(e) => setMessage({ ...message, operationType: e.title })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.operationType}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('operationType') : undefined}
            >
              {message?.operationType}
            </p>
          )}
        </CardSetting>

        {/* Критерии выбора вида операции */}
        <CardSetting title="Критерии выбора вида операции:">
          <p
            className="clickable"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => setEditable('operTypeCriteria')}
          >
            {(workGroup || sdlGroup)
              ? 'Добавить критерии'
              : 'Открыть справочник критериев'}
          </p>
          <span
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'row',
              gap: '7px',
              maxHeight: '40px',
              overflowX: 'auto',
            }}
          >
            {message?.operTypeCriteria?.map((item) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                //  gap: '5px',
                  // width: '50px',
                }}
              >
                <p key={item.code} style={{ width: '40px' }}>
                  {item.code}
                </p>
                {(workGroup || sdlGroup) && (
                  <Cancel onClick={() => deleteItem(item)} />
                )}
              </div>
            ))}
          </span>
          {/* {editable === 'operTypeCriteria'
           ? <>
          <Select
            array={criteria}
            onChoose={(e) => setMessage({ ...message, operTypeCriteria: [...message.operTypeCriteria, { code: e.title, title: e.criterion }] })}
            onBlur={() => setEditable(null)}
            onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
          />
          <div style={{ maxWidth: '70px', maxHeight: '60px', overflowY: 'auto' }}>
          {message?.operTypeCriteria?.map((item) => (
                      <p>{item.code}</p>
          ))}
          </div>
             </>
           : <p className={(employee || taskSupervisor) ? '' : ''} onClick={() => setEditable('operTypeCriteria')}>

             </p>} */}
        </CardSetting>

        {/* Содержание (характер) операции (сделки) */}
        <CardSetting title="Содержание (характер) операции:">
          {editable === 'operationContent' ? (
            <TextArea
              onChange={(e) => setMessage({ ...message, operationContent: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.operationContent}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('operationContent') : undefined}
            >
              {message?.operationContent}
            </p>
          )}
        </CardSetting>

        {/* Иные данные по операции (№ договора, и т.п.) */}
        <CardSetting title="Иные данные по операции:">
          {editable === 'additionalData' ? (
            <TextArea
              onChange={(e) => setMessage({ ...message, additionalData: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.additionalData}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('additionalData') : undefined}
            >
              {message?.additionalData}
            </p>
          )}
        </CardSetting>

        {/* Комментарии */}
        <CardSetting title="Комментарии:">
          {editable === 'operationComment' ? (
            <TextArea
              onChange={(e) => setMessage({ ...message, operationComment: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.operationComment}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('operationComment') : undefined}
            >
              {message?.operationComment}
            </p>
          )}
        </CardSetting>

        {/* Документы, подтверждающие сделку */}
        <CardSetting title="Документы, подтверждающие сделку:">
          {((workGroup || sdlGroup)) && (
            <BulkFileUpload
              handler={attachmentHandler}
              accept={
                acceptFileExtension.length > 0
                  ? acceptFileExtension[0].value
                  : ''
              }
            >
              {/* <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" /> */}
              Прикрепить вложение 📑
            </BulkFileUpload>
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {attachments?.map((file) => (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p
                  key={file.name}
                  className="clickable"
                  onClick={() => downloadAttachment(messageId, file.name)}
                >
                  {file.name}
                </p>
                <Cancel onClick={(workGroup || sdlGroup) ? () => deleteAttachment(file.name) : undefined} />
              </div>
            ))}
          </div>
        </CardSetting>

        {/* Дата совершения операции (сделки */}
        <CardSetting title="Дата совершения операции (сделки):">
          {editable === 'executionDate' ? (
            <Input
              type="date"
              onChange={(e) => setMessage({ ...message, executionDate: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.executionDate}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('executionDate') : undefined}
            >
              {message?.executionDate}
            </p>
          )}
        </CardSetting>

        {/* Сумма операции (сделки) */}
        <CardSetting title="Сумма операции (сделки):">
          {editable === 'operationSumm' ? (
            <Input
              type="number"
              onChange={(e) => setMessage({ ...message, operationSumm: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.operationSumm}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('operationSumm') : undefined}
            >
              {message?.operationSumm}
            </p>
          )}
        </CardSetting>

        {/* Валюта проведения операции (сделки) */}
        <CardSetting title="Валюта проведения операции (сделки):">
          {editable === 'currency' ? (
            <Select
              id="currency"
              array={currency}
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              onChoose={(e) => setMessage({ ...message, currency: e.title })
              }
              defaultValue={message.currency}
            />
          // <TextArea
          //   onChange={(e) => setMessage({ ...message, currency: e.target.value })
          //   }
            // onBlur={() => setEditable(null)}
            // onKeyUp={(e) => {
            //   if (e.key === 'Enter') setEditable(null);
            // }}
          //   defaultValue={message.currency}
          // />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('currency') : undefined}
            >
              {message?.currency}
            </p>
          )}
        </CardSetting>

        {/* Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку) */}
        <CardSetting title="Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку):">
          {editable === 'foreignExecutor' ? (
            <TextArea
              onChange={(e) => setMessage({ ...message, foreignExecutor: e.target.value })
              }
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') setEditable(null);
              }}
              defaultValue={message.foreignExecutor}
            />
          ) : (
            <p
              className={(workGroup || sdlGroup) ? 'clickable' : ''}
              onClick={(workGroup || sdlGroup) ? () => setEditable('foreignExecutor') : undefined}
            >
              {message?.foreignExecutor}
            </p>
          )}
        </CardSetting>

        {/* Сведения о работнике, составившем внутреннее сообщение об операции (сделке)  */}
        {/* <CardSetting title="Сведения о сотруднике, составившем внутреннее сообщение об операции (сделке):">
            {editable === 'employee' ? <Select
              search
              array={users}
              onChoose={(e) => setMessage({ ...message, employee: { name: e.title, id: e.id } })}
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
              defaultValue={message.employee.name}
            /> : <p className={(employee || taskSupervisor) ? 'clickable' : ''} onClick={() => setEditable('employee')}>{message?.employee?.name}</p> }
            </CardSetting> */}
      </div>
      {((workGroup && btnVisible && [1, 2].includes(currentStatus.id)) || (sdlGroup)) && (
        <Button onClick={() => saveEditedMessage('edit')}>
          Сохранить изменения
        </Button>
      )}
      {sdlUser && btnVisible && currentStatus.id === 1 && (
        <Button onClick={() => saveEditedMessage('setToWork')}>
          Взять в работу
        </Button>
      )}
    </ServiceBody>
  );
}

export default Message;
