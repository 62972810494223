import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Document, AlignmentType, Packer, Table, TableCell, TableRow, WidthType, Paragraph, TextRun, TableBorders, BorderStyle, convertInchesToTwip, Header,
} from 'docx';
import Button from '../../components/UI/Button/Button';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import classes from '../../components/UI/Button/button.module.scss';
import './staffcv.scss';
import {
  orderedKeys, disabledFields, specialKey, topBorderKey,
} from './common';

/**
* @component Резюме сотрудников - Интерфейс страницы резюме сотрудника
*/

function CV({ cvData }) {
  const [formData, setFormData] = useState({ 'В Юникон АО': [], 'В других организациях': [] });
  const navigate = useNavigate();
  const smallCurtain = `${classes.curtain__button} ${classes.small}`;

  useEffect(() => {
    if (cvData && cvData.cv) {
      // Достаем данные из cvData
      const updatedFormData = {
        ...cvData.cv,
        'В Юникон АО': cvData.cv['В Юникон АО'] || [], // Если нет, то добавляем пустой массив
        'В других организациях': cvData.cv['В других организациях'] || [], // Если нет, то добавляем пустой массив
      };
      setFormData(updatedFormData);
    }
  }, [cvData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleArrayChange = (key, index, field, value) => {
    const updatedArray = [...formData[key]];
    updatedArray[index] = {
      ...updatedArray[index],
      [field]: value,
    };
    setFormData((prevData) => ({
      ...prevData,
      [key]: updatedArray,
    }));
  };

  const sortResumeData = (resumeData) => {
    const sortByYear = (a, b) => {
      const getYear = (yearString) => {
        const rangeMatch = yearString.match(/^(\d{4})-(\d{4})$/);
        if (rangeMatch) {
          return parseInt(rangeMatch[1], 10); // Используем начальный год диапазона
        }
        const currentMatch = yearString.match(/^(\d{4}) по наст\. вр\.$/);
        if (currentMatch) {
          return parseInt(currentMatch[1], 10);
        }
        return parseInt(yearString, 10) || 0; // Возвращаем 0, если не удалось распарсить
      };

      return getYear(b.year || '') - getYear(a.year || '');
    };

    // Фильтруем и сортируем данные
    const filteredAndSortedEducation = resumeData['Образование и профессиональная квалификация/сертификаты']
      .filter((item) => item.year && item.value) // Убираем пустые значения
      .sort(sortByYear);

    const filteredAndSortedWorkExperience = resumeData['Сведения о трудовой деятельности']
      .filter((item) => item.year && item.value) // Убираем пустые значения
      .sort(sortByYear);

    // Возвращаем отсортированные данные
    return {
      ...resumeData,
      'Образование и профессиональная квалификация/сертификаты': filteredAndSortedEducation,
      'Сведения о трудовой деятельности': filteredAndSortedWorkExperience,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sortedFormData = sortResumeData(formData);

    console.log('Отправленные данные:', sortedFormData);
    const userId = cvData?.id; // Путь к ID пользователя в cvData
    // Вызов функции для загрузки резюме
    await uploadResume(sortedFormData, userId);
  };

  const uploadResume = async (resumeData, userId) => {
    console.log(resumeData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editStaffCV',
        DataCV: resumeData,
        userId, // Добавляем ID пользователя
      };

      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_staff_cv', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('resume_edit_success', 'client'); // Уведомление об успешном обновлении
        // Обновляем состояние, чтобы перерендерить компонент
        setFormData((prevData) => ({
          ...prevData,
          ...resumeData, // Обновляем данные резюме
        }));
      } else {
        await alertFunction('resume_edit_error', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editStaffCV'); // Обработка ошибок
    }
  };

  const handleBackToResults = () => {
    navigate('/staff_cv');
    window.location.reload();
  };

  const generateWordDocument = () => {
    const rows = [];

    const defaultFontStyle = {
      font: 'Microsoft Sans Serif', // Название шрифта
      size: 18, // Размер шрифта (в пунктах)
    };

    // Создаем колонтитул
    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: 'РЕЗЮМЕ',
              bold: true,
              font: 'Microsoft Sans Serif', // Задаем шрифт для колонтитула
              size: 22, // Размер шрифта для колонтитула (можно настроить)
            }),
          ],
          alignment: AlignmentType.RIGHT, // Выравнивание по правому краю
        }),
      ],
    });

    orderedKeys.forEach((key) => {
      if (formData[key] !== undefined) {
        const value = formData[key];

        // Пропускаем пустые значения
        if (value === undefined || value === null || (Array.isArray(value) && value.length === 0) || (typeof value === 'string' && value.trim() === '')) {
          return; // Пропускаем это поле
        }

        if (Array.isArray(value) && key === 'Образование и профессиональная квалификация/сертификаты') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Сведения о трудовой деятельности') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && (key === 'Ключевые компетенции')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (Array.isArray(value) && ((key === 'В Юникон АО') || (key === 'В других организациях'))) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Номер квалификационного сертификата аудитора' || key === 'Номер квалификационного сертификата налогового консультанта' || key === 'Сертификаты'
     || key === 'Номер квалификационного сертификата оценщика' || key === 'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»' || key === 'Стаж финансово-экономической деятельности' || key === 'Стаж юридической деятельности') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
          // Извлечение даты из value
          const dateRegex = /(\d{1,2}\.\d{1,2}\.\d{4}|\d{1,2}\s+(января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)\s+\d{4})/i;
          const dateMatch = value.match(dateRegex);
          const date = dateMatch ? dateMatch[0] : 'Введите дату';

          // Извлечение оставшегося текста
          const remainingText = value.replace(dateRegex, '').trim();
          // Разделяем текст на части
          const parts = remainingText.split('Игоревна');

          // Создание ячеек
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Ячейка с оставшимся текстом
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[0].trim()} Игоревна`,
                      ...defaultFontStyle,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${parts[1].trim()}       ${date}`, // Объединяем текст и дату
                      ...defaultFontStyle,
                    }),
                  ],
                }),
              ],
              // width: { size: 7000, type: WidthType.DXA }, // Установка ширины ячейки с оставшимся текстом
              columnSpan: 2, // Объединяем ячейку с соседней
            }),
          ]);
        } else if (key === 'Ф.И.О.') {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: value, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: Array.isArray(value) ? value.join(', ') : value, ...defaultFontStyle })] })],
            }),
          ]);
        }
      }
    });

    const table = new Table({
      rows: rows.map((row) => new TableRow({ children: row })),
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: TableBorders.NONE,
      margins: {
        top: convertInchesToTwip(0.1),
        bottom: convertInchesToTwip(0.1),
        right: convertInchesToTwip(0),
        left: convertInchesToTwip(0),
      },
    });

    const doc = new Document({
      sections: [{
        properties: {},
        headers: {
          default: header, // Добавляем колонтитул в секцию
        },
        children: [table],
      }],
    });

    // Извлечение Ф.И.О. для имени файла
    const fullName = formData['Ф.И.О.'] || 'Резюме';
    const sanitizedFileName = fullName.replace(/[^a-zA-Z0-9а-яА-ЯёЁ\s]/g, '').replace(/\s+/g, '_').trim();

    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sanitizedFileName}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }).catch((error) => {
      console.error('Ошибка при создании документа:', error);
    });
  };

  const addNewField = (key) => {
    const updatedArray = [...(formData[key] || [])];

    // Условие для проверки, какой тип данных нужно добавлять
    if (key === 'Образование и профессиональная квалификация/сертификаты' || key === 'Сведения о трудовой деятельности') {
      // Добавляем новый объект для "Образование" и т.д.
      updatedArray.push({ year: '', value: '' });
    } else if (key === 'В Юникон АО' || key === 'В других организациях' || key === 'Знание иностранных языков' || key === 'Ключевые компетенции') {
      // Добавляем новую строку для проектов
      updatedArray.push(''); // Пустая строка для нового проекта
    }

    setFormData((prevData) => ({
      ...prevData,
      [key]: updatedArray,
    }));
  };

  const removeField = (key, index) => {
    const updatedArray = [...(formData[key] || [])];
    updatedArray.splice(index, 1); // Удаляем элемент по индексу
    setFormData((prevData) => ({
      ...prevData,
      [key]: updatedArray,
    }));
  };

  const renderField = (key) => {
    const value = formData[key];
    const isDisabled = disabledFields.includes(key);

    // Логика для "Достоверность сведений, представленных в резюме, подтверждаю"
    if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
      // Регулярное выражение для извлечения дат
      const dateRegex = /(\d{1,2}\.\d{1,2}\.\d{4}|\d{1,2}\s+(января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)\s+\d{4})/g;
      const dates = value.match(dateRegex) || []; // Извлекаем даты
      const remainingText = value.replace(dateRegex, '').trim(); // Извлекаем оставшийся текст

      return (
            <>
                <div style={{ marginBottom: '5px' }}>
                    <input
                      type="text"
                      value={remainingText || ''}
                      onChange={(e) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          [key]: `${e.target.value} ${dates.join(', ')}`, // Обновляем текст с датами
                        }));
                      }}
                      placeholder="Остальной текст"
                      style={{ width: '100%' }}
                      disabled={isDisabled}
                    />
                </div>
                {dates.map((date, index) => (
                    <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                        <input
                          type="text"
                          value={date}
                          onChange={(e) => {
                            const updatedDates = [...dates];
                            updatedDates[index] = e.target.value; // Обновляем дату
                            setFormData((prevData) => ({
                              ...prevData,
                              [key]: `${remainingText} ${updatedDates.join(', ')}`, // Обновляем текст с датами
                            }));
                          }}
                          placeholder="Дата"
                          style={{ marginRight: '5px', width: '150px' }}
                        />
                    </div>
                ))}
            </>
      );
    }

    // Остальная логика для других ключей
    // Логика для "Образование и профессиональная квалификация/сертификаты" и "Сведения о трудовой деятельности"
    if (key === 'Образование и профессиональная квалификация/сертификаты'
        || key === 'Сведения о трудовой деятельности') {
      return (
        <>
          {value && value.map((item, index) => (
            <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
              {typeof item === 'object' ? (
                <>
                  <input
                    type="text"
                    value={item.year || ''}
                    onChange={(e) => handleArrayChange(key, index, 'year', e.target.value)}
                    placeholder="Год"
                    style={{ marginRight: '5px', width: '150px', fontWeight: 'bolder' }}
                  />
                  <input
                    type="text"
                    value={item.value || ''}
                    onChange={(e) => handleArrayChange(key, index, 'value', e.target.value)}
                    placeholder="Значение"
                    style={{ marginRight: '5px', width: '100%' }}
                  />
                  <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)}>
                    х
                  </Button>
                </>
              ) : (
                <>
                  <input
                    type="text"
                    value={item || ''}
                    onChange={(e) => {
                      const updatedArray = [...value];
                      updatedArray[index] = e.target.value;
                      setFormData((prevData) => ({
                        ...prevData,
                        [key]: updatedArray,
                      }));
                    }}
                    placeholder="Значение"
                    style={{ marginRight: '5px', width: '100%' }}
                  />
                  <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)}>
                    х
                  </Button>
                </>
              )}
            </div>
          ))}

          <Button
            type="button"
            className={smallCurtain}
            onClick={() => addNewField(key)}
          >
            Добавить год и значение
          </Button>
        </>
      );
    }

    // Логика для "В Юникон АО" и "В других организациях"
    if (key === 'В Юникон АО' || key === 'В других организациях') {
      return (
      <>
        {value && value.map((item, index) => (
          <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              value={item || ''} // Отображаем значение проекта
              onChange={(e) => {
                const updatedArray = [...value];
                updatedArray[index] = e.target.value; // Обновляем проект
                setFormData((prevData) => ({
                  ...prevData,
                  [key]: updatedArray,
                }));
              }}
              placeholder="Введите проект"
              style={{ marginRight: '5px', width: '100%' }}
            />
            <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)}>
              х
            </Button>
          </div>
        ))}
        <Button
          type="button"
          className={smallCurtain}
          onClick={() => addNewField(key)}
        >
          Добавить проект
        </Button>
      </>
      );
    }

    // Обработка для "Знание иностранных языков"
    if (key === 'Знание иностранных языков') {
      return (
        <>
          {value && value.map((item, index) => (
            <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={item || ''}
                onChange={(e) => {
                  const updatedArray = [...value];
                  updatedArray[index] = e.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    [key]: updatedArray,
                  }));
                }}
                placeholder="Язык"
                style={{ marginRight: '5px', width: '100%' }}
              />
              <Button type="button" className={smallCurtain} onClick={() => removeField(key, index)}>
                x
              </Button>
            </div>
          ))}
          <Button
            type="button"
            className={smallCurtain}
            onClick={() => addNewField(key)}
          >
            Добавить язык
          </Button>
        </>
      );
    }

    // Остальные условия для других ключей
    if (key === 'Ключевые компетенции') {
      return (
        <>
          {value && value.map((item, index) => (
            <div key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                value={item || ''}
                onChange={(e) => {
                  const updatedArray = [...value];
                  updatedArray[index] = e.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    [key]: updatedArray,
                  }));
                }}
                placeholder="Компетенция"
                style={{ marginRight: '5px', width: '100%' }}
              />
              <Button
                type="button"
                className={smallCurtain}
                onClick={() => removeField(key, index)}
              >
                х
              </Button>
            </div>
          ))}
          <Button
            type="button"
            className={smallCurtain}
            onClick={() => addNewField(key)}
          >
            Добавить компетенции
          </Button>
        </>
      );
    }

    // Остальные условия для других ключей
    if (typeof value === 'string') {
      return (
        <input
          type="text"
          id={key}
          name={key}
          value={value || ''}
          onChange={handleChange}
          style={{ width: '100%' }}
          disabled={isDisabled}
        />
      );
    }

    return null;
  };

  console.log(cvData);
  console.log(formData);

  return (
    <div style={{ padding: '20px' }}>
      <Button onClick={handleBackToResults} className="text-button">
        <span className="arrow">←</span>
        Вернуться к резюме сотрудников
      </Button>
      <h1>Резюме</h1>
      <div style={{
        maxHeight: '70vh', overflowY: 'auto', marginBottom: '10px', paddingTop: '10px', borderTop: '1px solid black',
      }}
      >
        <form onSubmit={handleSubmit}>
          {orderedKeys.map((key) => {
            const value = formData[key];

            if ((value === undefined || value === null || value === '') && (key !== 'Основные проекты')) {
              return null; // Если значение отсутствует или ключ равен 'Основные проекты', ничего не рендерим
            }

            const isSpecialKey = specialKey.includes(key);

            const hasTopBorderKey = topBorderKey.includes(key);

            return (
              <div
                key={key}
                style={{
                  marginBottom: '15px',
                  borderTop: hasTopBorderKey ? '1px solid black' : 'none',
                  paddingTop: hasTopBorderKey ? '10px' : '0',
                }}
              >
                {isSpecialKey ? (
                  <>
                    <label htmlFor={key} style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>
                      {key}
:
                    </label>
                    {renderField(key)}
                  </>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={key} style={{ flex: '0 0 150px', fontWeight: 'bold', marginRight: '5px' }}>
                      {key}
:
                    </label>
                    <div style={{ flex: '1 1 100%' }}>
                      {renderField(key)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button type="button" onClick={generateWordDocument} style={{ marginRight: '20px' }}>
          Скачать резюме
        </Button>
        <Button type="submit" onClick={handleSubmit}>
          Сохранить изменения
        </Button>
      </div>
    </div>
  );
}

export default CV;
