import React from 'react';

/**
* @component Футер карточки
*/

function CardPageFooter(props) {
  const { children } = props;
  return <div className="upu-card-on-page__footer">{children}</div>;
}

export default CardPageFooter;
