import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TBody from '../../../components/Table/TBody';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Loader from '../../../components/UI/Loader';
import ApplicationCard from '../Employee/ApplicationCard/ApplicationCard';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import Select from '../../../components/UI/Select';
import { tableToExcel } from '../../../utils/functions/others';

function DefaultTable(props) {
  const { period, isAdmin, location } = props;
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [applications, setApplications] = useState([]); // найденные заявки
  const [allApplications, setAllApplications] = useState([]);
  const [total, setTotal] = useState(0);
  // const isSearchAllowed = !(!period?.from || !period?.to || (new Date(period?.from) > new Date(period?.to)));
  const [showCard, setShowCard] = useState(false);
  const [tableTwo, setTableTwo] = useState(false);
  const title = 'Проектные оценки';
  const id = 'evaluation__approver-table2';
  const [currentApplicationId, setCurrentApplicationId] = useState(null);

  const headers = [ // заголовки
    { id: 1, title: 'Клиент', field: 'client_title' },
    { id: 2, title: 'Проект', field: 'project_title' },
    { id: 3, title: 'Сотрудник', field: 'employee_title' },
    { id: 4, title: 'Руководитель', field: 'approver_title' },
    { id: 5, title: 'Партнер', field: 'partner_title' },
    { id: 6, title: 'Директор', field: 'director_title' },
    { id: 7, title: 'Менеджер', field: 'manager_title' },
    { id: 8, title: 'Кол-во часов', field: 'hours_amount' },
    { id: 9, title: 'Грейд', field: 'grade' },
    { id: 10, title: 'Статус', field: 'status_title' },
    { id: 11, title: 'Действие', field: 'action' },
  ];

  const defaultTableState = {
    title: 'Проектные оценки',
    page: 1,
    limit: 20, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  };
  const [tableState, setTableState] = useState(defaultTableState);
  // const [total, setTotal] = useState(0);
  useEffect(() => {
    findApplications();
  }, [tableState, period]);

  // Поиск заявлений на выбранные даты
  async function findApplications() {
    try {
      const reqData = {
        type: 'findProjectEvs',
        is_admin: true,
        period,
        tableState,
      };
      const result = await clientSendData('POST', '/find_project_evs', reqData); // запрос в БД
      if (result.success) {
        setAllApplications(result.result_full);
        setApplications(result.result);
        setTotal(result.total);
      }
    } catch (error) {
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  // Открыть заявление
  function openApplication(id, isAdmin) {
    setSearchParams({ app: id, isAdmin, location });
  }

  function openApplicationCard(id) {
    setShowCard(true);
    setCurrentApplicationId(id);
  }

  if (isLoading) return <Loader />;
  return (
    // <Table id="evaluation__approver-table" loading={isLoading}>
    //   <THead
    //     headers={headers}
    //     array={applications}
    //     setArray={setApplications}
    //     button_place="row"
    //   />
    <>
    <PaginatedTable
      id="evaluation__approver-table"
      headers={headers}
      setState={setTableState}
      state={tableState}
      button_place="row"
      total={total}
    >
      {/* <TBody> */}
        {/* Если нет результатов */}
        {applications.length === 0 ? (
        <TRow>
          <TData>Результаты отсутствуют</TData>
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
        </TRow>) : (
        // Если результаты есть
          applications.map((row) => (
          <TRow key={row.id}>
            {headers.map((item) => {
              if (item.field !== 'action' && !row.enabled) {
                return <TData key={item.field}>
                  <div style={{ color: 'red' }}>
                {row[item.field] ? row[item.field] : '-'}
                  </div>
                       </TData>;
              } if (item.field !== 'action' && row.enabled) return <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>;
              return <TData key={item.field} onClick={() => openApplication(row.id, isAdmin)}>Открыть карточку</TData>;
            })}
            <TData onClick={() => openApplicationCard(row.id)}>
              <img src="../../icons/file-text.svg" alt="edit" />
            </TData>
          </TRow>

          )))}
      {/* </TBody> */}

      {showCard && (
        <ApplicationCard
          id={currentApplicationId}
          setShowCard={setShowCard}
          findApplications={findApplications}
        />)}
    </PaginatedTable>
    <div className="excel-button">
    <div className="upu-table__table-button" title={title} onClick={() => tableToExcel(id, title)}>Cкачать в Excel</div>
    </div>

   <PaginatedTable
     id="evaluation__approver-table2"
     headers={headers}
     setState={setTableState}
     state={tableState}
     button_place="row"
     total={total}
   >
  {/* <TBody> */}
    {/* Если нет результатов */}
    {allApplications.length === 0 ? (
    <TRow>
      <TData>Результаты отсутствуют</TData>
      <TData />
      <TData />
      <TData />
      <TData />
      <TData />
      <TData />
      <TData />
      <TData />
      <TData />
      <TData />
      <TData />
    </TRow>) : (
    // Если результаты есть
      allApplications.map((row) => (
      <TRow key={row.id}>
        {headers.map((item) => {
          if (item.field !== 'action' && !row.enabled) {
            return <TData key={item.field}>
              <div style={{ color: 'red' }}>
            {row[item.field] ? row[item.field] : '-'}
              </div>
                   </TData>;
          } if (item.field !== 'action' && row.enabled) return <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>;
          return <TData key={item.field} onClick={() => openApplication(row.id, isAdmin)}>Открыть карточку</TData>;
        })}
        <TData onClick={() => openApplicationCard(row.id)}>
          <img src="../../icons/file-text.svg" alt="edit" />
        </TData>
      </TRow>

      )))}
   </PaginatedTable>
    </>
  );
}

export default DefaultTable;
