import React, { useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import ServicePanel from '../../components/Service/ServicePanel';
import Button from '../../components/UI/Button/Button';
import classes from '../../components/UI/Button/button.module.scss';
import Analysis from './Analysis';
import Results from './Results';
import './asap.scss';

/**
* @component Тестирование журнальных проводок ASAP
*/
function ASAP() {
  const pages = ['Анализ журнальных проводок', 'Просмотр результатов']; // вкладки
  const [choice, setChoice] = useState(pages[0]); // Состояние выбора сервиса
  const content = {
    'Анализ журнальных проводок': <Analysis />,
    'Просмотр результатов': <Results />,
  };
  return (
    <ServiceBody horizontal>
      <ServicePanel>
        {pages.map((page) => {
          const { asap_button, button_ghost } = classes;
          const buttonClass = choice === page ? asap_button : button_ghost;
          return (
            <Button key={page} className={buttonClass} onClick={() => setChoice(page)}>
              {page}
            </Button>
          );
        })}
      </ServicePanel>
      {content[choice]}
    </ServiceBody>
  );
}

export default ASAP;
