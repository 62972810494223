import React from 'react';

/**
* @component Тело карточки
*/

function CardPageBody(props) {
  const { children } = props;
  return <div className="upu-card-on-page__body">{children}</div>;
}

export default CardPageBody;
