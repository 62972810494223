import React, { useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/UI/Button/Button';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import InputSearch from '../../components/UI/InputSearch';
import { alertFunction } from '../../utils/functions/alertFunction';

function Step1({
  newProjectData,
  setNewProjectData,
  users,
  searchWrapper,
  deleteParticipiants,
  addParticipiants,
  selectUsers,
  setSelectUsers,
  participiants,
  // currentStep,
  chooseProjectSchema,
}) {
  const [tasks, setTasks] = useState([]);
  const templates = [
    { id: 1, title: 'НПФ', type: 'npf' },
    { id: 2, title: 'Банки', type: 'bank' },
    { id: 3, title: 'Страховые', type: 'security' },
    { id: 4, title: 'Самостоятельно', type: 'basic' },
  ];

  useEffect(() => {
    getTasks();
  }, []);

  // Получить номера заданий из аск
  async function getTasks() {
    try {
      const reqData = {
        type: 'getPODFTtasks',
      };
      const result = await clientSendData('POST', '/get_podft_tasks', reqData); // запрос в БД
      if (result.data) {
        setTasks(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getPODFTtasks'); // обработка ошибок
    }
  }

  return (
    <div>
      {/* НАЗВАНИЕ */}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CardSetting title="Название*">
          <Input
            onChange={(e) => setNewProjectData({
              ...newProjectData,
              project_name: e.target.value,
            })
            }
          />
        </CardSetting>

        {/* НОМЕР ЗАДАНИЯ ИЗ АСК */}
        <CardSetting title="№ задания из АСК*">
          <Select
            id="ASK_tasks"
            search
            array={tasks}
            defaultValue={newProjectData?.task1from_number}
            onChoose={(e) => setNewProjectData({
              ...newProjectData,
              task1from_number: e.id,
              task1form_title: e.task_title,
            })}
          />
        </CardSetting>
      </div>
      {/* ОПИСАНИЕ */}
      <CardSetting title="Описание">
        <Input
          onChange={(e) => setNewProjectData({
            ...newProjectData,
            project_description: e.target.value,
          })
          }
        />
      </CardSetting>

      {/* МЕНЕДЖЕР ПРОЕКТА */}
      <CardSetting title="Менеджер проекта*">
        {users.length > 0 && (
          <Select
            id="manager"
            array={users}
            onChoose={(e) => setNewProjectData({
              ...newProjectData,
              project_manager: {
                id: e.id, title: e.title, role: 'менеджер проекта', mail: e.mail,
              },
              project_team: [...newProjectData.project_team,
                {
                  id: e.id, title: e.title, role: 'менеджер проекта', mail: e.mail,
                }],
            })
            }
            defaultValue={
              newProjectData?.project_manager.title
              || 'Назначьте менеджера проекта'
            }
            search
          />
        )}
      </CardSetting>

      {/* КОМАНДА */}
      <CardSetting title="Команда">
        <div className="tags__input-block">
          <InputSearch
            //  array={sample}
            //  onChange={(e) => setNewMeetingData({ ...newMeetingData, initiator: e.target.value })}
            placeholder="Введите пользователя Юникона или email внешнего участника"
            array={users}
            onSearch={(e) => searchWrapper(e)}
            onChoose={(user) => setSelectUsers(user)}
            defaultValue={selectUsers?.title}
          />
          <div
            onClick={() => addParticipiants(selectUsers)}
            className="tags__button"
          >
            <img
              className="tags__img"
              src="../../icons/cancel.svg"
              alt="add-tag"
            />
          </div>
        </div>
      </CardSetting>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '50%' }}>
      <CardSetting title="Как создать проект?*">
          <Select
            id="template"
            array={templates}
            onChoose={(e) => chooseProjectSchema(e.type)}
          />
      </CardSetting>
      </div>
      <div className="tags__list">
        {participiants?.map((el) => (
          <p className="tags__tag" key={el.id}>
            <span
              onClick={() => deleteParticipiants(el.id)}
              className="upu__close"
            >
              X
            </span>
            {el.title}
          </p>
        ))}
      </div>
      </div>
    </div>
  );
}

function Step2({ projStructure, setNewProjectData, projectSchemaOption }) {
  const [selectFolders, setSelectFolders] = useState([]);
  const [newFolder, setNewFolder] = useState(null);
  const [folderId, setFolderId] = useState(1);
  function createFolder(e) {
    // setFolderId((prev) => prev + 1);
    setNewFolder({ title: e.target.value });
  }

  // async function addFolder(folder) {
  //   // setFolderId((prev) => prev + 1);
  // //  setNewFolder({ ...newFolder, id: folderId, section: folderId });
  //   if (Object.keys(folder).length > 0) {
  //     if (selectFolders.filter((el) => el.id === folder.id).length === 0) {
  //       setNewProjectData({ ...newProjectData, template: selectFolders });
  //       setSelectFolders((state) => [...state, { ...newFolder, id: folderId, section: folderId }]);
  //       const newFolders = newProjectData.template;
  //       newFolders.push(folder);
  //       setNewProjectData((state) => ({ ...state, template: newFolders }));
  //       //    setSelectFolders([]);
  //       setFolderId((prev) => prev + 1);
  //     }
  //   }
  // }

  async function deleteFolder(id) {
    const folder = selectFolders.find((el) => el.id === id);
    const filteredFolders = selectFolders.filter(
      (el) => el.id !== folder.id,
    );
    setNewProjectData((state) => ({ ...state, template: filteredFolders }));
    setSelectFolders(selectFolders.filter((el) => el.id !== id));
    setFolderId((prev) => prev - 1);
  }

  // console.log(newProjectData);
  return (
    <div>
      {projectSchemaOption === 'template' ? (
        projStructure.map((item) => (
          <div className="section-line" key={item.id}>
            <p style={{ fontWeight: 'bold' }}>{item.section}</p>
            <p>{item.title}</p>
          </div>
        ))
      ) : (
        projStructure.map((item) => (
          <div className="section-line" key={item.id}>
            <p style={{ fontWeight: 'bold' }}>{item.section}</p>
            <p>{item.title}</p>
          </div>
        ))
        // <div>
        //   <div className="tags__input-block">
        //     <Input
        //       placeholder="Добавить папку"
        //       onChange={(e) => createFolder(e)}
        //     />
        //     <div onClick={() => addFolder(newFolder)} className="tags__button">
        //       <img
        //         className="tags__img"
        //         src="../../icons/cancel.svg"
        //         alt="add-tag"
        //       />
        //     </div>
        //   </div>
        //  {selectFolders.map((item) => (
        //   <div className="section-line" key={item.id}>
        //     <p style={{ fontWeight: 'bold' }}>{item.section}</p>
        //     <p>{item.title}</p>
        //     <span
        //       onClick={() => deleteFolder(item.id)}
        //      // className="upu__close"
        //       style={{ justifySelf: 'right' }}
        //     >
        //       X
        //     </span>
        //   </div>
        //  ))}
        // </div>
      )}
    </div>
  );
}

function ProjectSchema({ chooseProjectSchema }) {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
        Как создать проект?
      </p>
      <div className="project-schema-option">
        <Button onClick={() => chooseProjectSchema('template')}>
          По шаблону
        </Button>
        <Button onClick={() => chooseProjectSchema('custom')}>
          Самостоятельно
        </Button>
      </div>
    </div>
  );
}

function NewProjectCard(props) {
  const { setShowCreateCard, getProjects } = props;
  const [newProjectData, setNewProjectData] = useState({
    project_name: '',
    project_description: '',
    project_manager: {},
    project_team: [],
    status: 'Открыт',
  });
  const [projStructure, setProjStructure] = useState([]);
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [users, setUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState({});
  const [participiants, setParticipiants] = useState([]); // участники команды
  const [currentStep, setCurrentStep] = useState(1);
  const [projectSchemaOption, setProjectSchemaOption] = useState(null);

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      const reqData = {
        type: 'getUsersPortal',
      };
      const result = await clientSendData('POST', '/get_users_portal', reqData); // запрос в БД
      if (result) {
        setUsers(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getUsersPortal'); // обработка ошибок
    }
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'clientsPortalSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData(
          'POST',
          '/clients_portal_search_user',
          reqData,
        );
        setSelectUsers({
          id: e.target.value,
          title: e.target.value,
          mail: e.target.value,
        });
        if (result?.success) setUsers(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'clientsPortalSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  // Добавление пользователей
  async function addParticipiants(participiant) {
    if (Object.keys(participiant).length > 0) {
      if (
        participiants.filter((el) => el.id === participiant.id).length === 0
      ) {
        setParticipiants((state) => [...state, participiant]);
        const newUsers = newProjectData.project_team;
        newUsers.push({
          id: participiant.id,
          title: participiant.title,
          mail: participiant.mail,
          role: 'участник',
        });
        setNewProjectData((state) => ({ ...state, project_team: newUsers }));
        setSelectUsers({});
      }
    }
  }

  async function deleteParticipiants(usersId) {
    const member = participiants.find((el) => el.id === usersId);
    const filteredUsers = newProjectData.project_team.filter(
      (el) => el.teamMember_id !== member.id,
    );
    setNewProjectData((state) => ({ ...state, project_team: filteredUsers }));
    setParticipiants(participiants.filter((el) => el.id !== usersId));
  }

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  function chooseProjectSchema(type) {
    //   setProjectSchemaOption(type);
    if (type === 'template') {
      // setNewProjectData({
      //   ...newProjectData,
      //   template: [
      //     { id: 1, section: 'А', title: 'Планирование' },
      //     {
      //       id: 2,
      //       section: 'Б',
      //       title: 'Аудиторские процедуры на уровне предпосылок',
      //     },
      //     { id: 3, section: 'В', title: 'Общие аудиторские процедуры' },
      //     { id: 4, section: 'Г', title: 'Завершение аудита' },
      //   ],
      // });
    } else if (type === 'basic') {
      setProjStructure([
        {
          parentId: 0,
          id: 1,
          section: 'А',
          title: 'Планирование',
          type: 'folder',
        },
        {
          parentId: 0,
          id: 2,
          section: 'Б',
          title: 'Аудиторские процедуры на уровне предпосылок',
          type: 'folder',
        },
        {
          parentId: 0,
          id: 3,
          section: 'В',
          title: 'Общие аудиторские процедуры',
          type: 'folder',
        },
        {
          parentId: 0,
          id: 4,
          section: 'Г',
          title: 'Завершение аудита',
          type: 'folder',
        },
      ]);
    }
    // setCurrentStep((prevStep) => prevStep + 1);
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            newProjectData={newProjectData}
            setNewProjectData={setNewProjectData}
            users={users}
            searchWrapper={searchWrapper}
            deleteParticipiants={deleteParticipiants}
            addParticipiants={addParticipiants}
            selectUsers={selectUsers}
            setSelectUsers={setSelectUsers}
            participiants={participiants}
          //  currentStep={currentStep}
            chooseProjectSchema={chooseProjectSchema}
          />
        );
      case 2:
        return (
          <Step2
            projStructure={projStructure}
            setNewProjectData={setNewProjectData}
            projectSchemaOption={projectSchemaOption}
          />
        );
      // case 3:
      //   return <Step3
      //     formData={formData}
      //     setFormData={setFormData}
      //     getData={getData}
      //     handleChoose={handleChoose}
      //     handleRemove={handleRemove}
      //     smallCurtain={smallCurtain}
      //     currentStatus={currentStatus}
      //   />;
      // case 4:
      //   return <Step4
      //     formData={formData}
      //     setFormData={setFormData}
      //     getData={getData}
      //     handleChoose={handleChoose}
      //     handleRemove={handleRemove}
      //     smallCurtain={smallCurtain}
      //     currentStatus={currentStatus}
      //   />;
      default:
        return <div>Шаг не найден</div>;
    }
  };

  async function saveNewProject() {
    try {
      const reqData = {
        type: 'clprtSaveNewProject',
        newProjectData,
        projStructure,
      };
      const result = await clientSendData('POST', '/clprt_save_new_project', reqData); // запрос в БД
      if (result.data) {
        await alertFunction('save_settings', 'clientPost');
        getProjects();
      }
    } catch (error) {
      catchHandler(error, 'clprtSaveNewProject'); // обработка ошибок
    }
  }

  return (
    <Card id="new-project-card" setShow={setShowCreateCard}>
      <CardHeader />
      <CardBody>{renderStep()}</CardBody>
      <CardFooter>
        {/* <Button onClick={nextStep}>Создать проект</Button> */}
        {/* {currentStep === 1 && (
          <ProjectSchema chooseProjectSchema={chooseProjectSchema} />
        )} */}
        <Button onClick={saveNewProject}>Сохранить</Button>
      </CardFooter>
    </Card>
  );
}

export default NewProjectCard;
