import { React, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ServiceBody from '../../../components/Service/ServiceBody';
import Table from '../../../components/Table/Table';
import Loader from '../../../components/UI/Loader';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';

export default function FinalEvaluation() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [finalEvals, setFinalEvals] = useState([]);
  const [total, setTotal] = useState(0);
  const [showCard, setShowCard] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const defaultTableState = {
    title: 'Финальные оценки',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
    button_place: 'header',
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const headers = [ // заголовки
    { id: 1, title: 'ФИО', field: 'employee_title' },
    { id: 2, title: 'Подразделение', field: 'dept' },
    { id: 3, title: 'Должность', field: 'job_title' },
    { id: 4, title: 'Грейд', field: 'grade' },
    // { id: 5, title: 'Действие', field: 'action' },
  ];

  useEffect(() => {
    findApplications();
  }, [tableState]);

  // Поиск оценок
  async function findApplications() {
    try {
      const reqData = {
        type: 'findFinalEvals',
        tableState,
      };
      const result = await clientSendData('POST', '/find_final_evals', reqData); // запрос в БД
      setFinalEvals(result);
      setTotal(result.length);
    //  setTotal(result.length);
    } catch (error) {
      catchHandler(error, 'findFinalEvals'); // обработка ошибок
    }
  }

  function openApplicationCard(id) {
  //  setShowCard(true);
    const search = { employee: id };
    setSearchParams(search);
    // setCurrentApplicationId(id);
  }

  if (isLoading) return <Loader />;
  return (
    <ServiceBody>
      <PaginatedTable
        id="final-evaluation__table"
        headers={headers}
        array={finalEvals}
        setState={setTableState}
        state={tableState}
        total={total}
      >
            {finalEvals.length === 0
              ? <TRow>
                    <TData>Результаты отсутствуют</TData>
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                </TRow>
            // Если результаты есть
              : finalEvals.map((row) => {
                const {
                  id, employee_id,
                } = row;
                return (
                        <TRow key={id}>
                            {headers.map((item) => {
                              if (item.field !== 'employee_title') {
                                return (
                                    <TData key={item.id}>{row[item.field] ? row[item.field] : '-'}</TData>
                                );
                              }
                              return (
                                    <TData key={item.id} onClick={() => openApplicationCard(employee_id)}>
                                       {/* {draft ? 'Посмотреть' : 'Заполнить'} */}
                                       {row[item.field] ? row[item.field] : '-'}
                                    </TData>
                              );
                            })}
                            {/* <TData onClick={() => openApplicationCard(id)}>
                                <img src="../../icons/file-text.svg" alt="edit" />
                            </TData> */}
                        </TRow>
                );
              })}
      </PaginatedTable>
    </ServiceBody>
  );
}
