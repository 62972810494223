import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';
import Button from '../../components/UI/Button/Button';
import CheckBox from '../../components/UI/CheckBox';
import NewTaskCard from './NewTaskCard';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import InputSearch from '../../components/UI/InputSearch';
import BulkFileUpload from '../../components/UI/FileUpload/BulkFileUpload';

function Common({ activeTask, setActiveTask, getProjectStructure }) {
  const projId = useSearchParams()[0].get('projId');
  const taskId = useSearchParams()[0].get('taskId');

  const statuses = [
    { id: 1, title: 'Новая' },
    // { id: 2, title: 'Отправлено клиенту' },
    { id: 3, title: 'Просрочена' },
    { id: 4, title: 'В работе' },
    //  { id: 5, title: 'Предоставлено клиентом' },
    //  { id: 6, title: 'На уточнении' },
    { id: 7, title: 'Отменено' },
    { id: 8, title: 'Выполнено' },
  ];
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [users, setUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState({});
  const [participiants, setParticipiants] = useState([]); // ответственные к задаче

  useEffect(() => {
    getUsers();
  }, []);

  function newStatus(status) {
    setActiveTask({ ...activeTask, status: status.title, status_id: status.id });
    const updatedTask = { ...activeTask, status: status.title, status_id: status.id };
    setIsEditing(false);
    updateStatus(updatedTask);
  }

  async function updateStatus(updatedTask) {
    try {
      const reqData = {
        type: 'clprtChangeItemStatus',
        item: updatedTask,
        itemType: 'task',
        projId,
      };
      const result = await clientSendData('POST', '/clprt_change_item_status', reqData); // запрос в БД
      if (result.success) {
        await alertFunction('save_settings', 'clientPost');
        getProjectStructure();
      }
    } catch (error) {
      catchHandler(error, 'clprtChan  temStatus'); // обработка ош  к
    }
  }

  // Пользователи для выбора
  async function getUsers() {
    try {
      const reqData = {
        type: 'getUsersPortal',
      };
      const result = await clientSendData('POST', '/get_users_portal', reqData); // запрос в БД
      if (result) {
        setUsers(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getUsersPortal'); // обработка ошибок
    }
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'clientsPortalSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData(
          'POST',
          '/clients_portal_search_user',
          reqData,
        );
        setSelectUsers({
          id: e.target.value,
          title: e.target.value,
          mail: e.target.value,
        });
        if (result?.success) setUsers(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'clientsPortalSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  // Добавление пользователей в команду проекта
  async function addParticipiants(participiant) {
    if (Object.keys(participiant).length > 0 && participiants.length === 0) {
      if (
        participiants.filter((el) => el.id === participiant.id).length === 0
      ) {
        setParticipiants((state) => [...state, participiant]);
        setSelectUsers({});
      }
    }
  }

  function deleteInduty(id) {
    const allUsers = participiants;
    const filteredUsers = allUsers.filter((el) => el.id !== id);
    setParticipiants(filteredUsers);
  }

  async function saveInduty() {
    try {
      const reqData = {
        type: 'clprtSaveInduty',
        inDuty: participiants,
        projId,
        taskId,
      };
      const result = await clientSendData('POST', '/clprt_save_induty', reqData); // запрос в БД
      if (result.success) {
        await alertFunction('save_settings', 'clientPost');
        getProjectStructure();
        setActiveTask({ ...activeTask, in_duty: participiants });
        setParticipiants([]);
        setIsAdding(false);
      }
    } catch (error) {
      catchHandler(error, 'clprtSaveInduty'); // обработка ошибок
    }
  }

  function cancelIndutyChoice() {
    setParticipiants([]);
    setIsAdding(false);
  }

  return (
    <div className="common-wrapper">
      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Статус:</p>
        <p className="active" onClick={() => setIsEditing(!isEditing)}>{activeTask?.status}</p>
      {isEditing && <div className="statuses">
          {statuses.map((el) => <p className="statuses-item" onClick={() => newStatus(el)}>{el.title}</p>)}
                    </div>}
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Дата начала:</p>
        {/* <p>20.02.2025</p> */}
        <p>{activeTask?.date_start}</p>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Дата окончания:</p>
        {/* <p style={{ color: 'red' }}>24.02.2025</p> */}
        <p style={{ color: 'red' }}>{activeTask?.date_end}</p>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header" style={{ alignSelf: 'start' }}>Кому назначена:</p>
        <div style={{ display: 'flex', flexFlow: 'column', gap: '5px' }}>
        {/* {'in_duty' in activeTask && activeTask?.in_duty.map((person) => <p>{person.title}</p>)} */}
        <p className="active" onClick={() => setIsAdding(!isAdding)}>{'in_duty' in activeTask ? activeTask.in_duty[0].title : 'Назначить ответственного'}</p>
        {isAdding && <span className="add-induty">
                   <InputSearch
                     array={users}
                     onSearch={(e) => searchWrapper(e)}
                   //  onChoose={(user) => addEmployees(user)}
                     onChoose={(e) => addParticipiants(e)}
                     defaultValue={selectUsers?.title}
                     placeholder="Введите пользователя Юникона"
                   />
                   <ul className="employees-list">
                    {participiants.map((user) => <li key={user.id}>
                     {user.title}
                      <p style={{ width: '10px', cursor: 'pointer' }} onClick={() => deleteInduty(user.id)}>x</p>
                                                 </li>)}
                   </ul>
                   <div
                     style={{
                       display: 'flex', flexDirection: 'row', gap: '15px', marginTop: 'auto', justifyContent: 'space-between',
                     }}
                   >
                    <p className="comment-line_btn" onClick={saveInduty}>Сохранить</p>
                    <p className="comment-line_btn" onClick={cancelIndutyChoice}>Отмена</p>
                   </div>
                     </span>}
        </div>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Дата создания:</p>
        {/* <p>20.02.2025</p> */}
        <p>{activeTask?.date_created}</p>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Кем создана:</p>
        <p>---</p>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Описание:</p>
        <p>{activeTask?.description}</p>
      </div>
    </div>
  );
}

function SubTasks({
  activeTask, setTemplate, template, subTemplate, newFolder, setNewFolder, getProjectStructure,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [action, setAction] = useState();
  // const [toDelete, setToDelete] = useState({});
  const projId = useSearchParams()[0].get('projId');

  function cancelCreation() {
    setNewFolder({});
    setIsEdit(false);
  }

  function editTask(item) {
    setNewFolder(item);
    setAction('edit');
    setIsEdit(true);
  }

  function createTask() {
    setAction('create');
    setIsEdit(true);
  }

  async function deleteItem(folder) {
    const toDelete = { ...folder, visibility: false };
    await saveNewItem(toDelete, 'edit');
    await getProjectStructure();
  }

  async function addNewItem() {
    await saveNewItem(newFolder, action);
    await getProjectStructure();
    setIsEdit(false);
    setNewFolder({});
  }

  async function saveNewItem(item, actionType) {
    try {
      const reqData = {
        type: 'clprtSaveNewItem',
        newItem: item,
        projId,
        action: actionType,
      };
      const result = await clientSendData('POST', '/clprt_save_new_item', reqData); // запрос в БД
      if (result) {
        await alertFunction('save_settings', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'clprtSaveNewItem'); // обработка ошибок
    }
  }

  return (
    <div className="subtasks">
      {subTemplate.map((item) => <div key={item.id}>
      {item.visibility !== false && <div className="subtask-wrapper">
        <div className="subtask-wrapper_options">
              <CheckBox><p className="folder-title">{item?.title}</p></CheckBox>
              <p className="folder-option" title="Редактировать" onClick={() => editTask(item)}>🖊</p>
              <p className="folder-option" title="Удалить элемент" onClick={() => deleteItem(item)}>⛌</p>
        </div>
              <p className="subtask-wrapper_in-duty">Манхаева И.С.</p>
                                    </div>}
                                 </div>)}

       <div className="add-task" onClick={createTask}>+ Добавить новую подзадачу</div>
       {isEdit && <NewTaskCard
         template={template}
         parentItem={activeTask}
         setNewFolder={setNewFolder}
         newFolder={newFolder}
         addNewItem={addNewItem}
         cancelCreation={cancelCreation}
       />}
    </div>
  );
}

function TaskDocs({ activeTask }) {
  const projId = useSearchParams()[0].get('projId'); // id ПО из url
  const taskId = useSearchParams()[0].get('taskId');
  const [documents, setDocuments] = useState([]);
  const [chosenDocs, setChosenDocs] = useState([]);
  const editExtensions = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'pdf'];

  useEffect(() => {
    getTaskDocuments();
  }, []);

  function collectDocuments(id) {
    if (chosenDocs.find((el) => el === id)) {
      setChosenDocs(chosenDocs.filter((el) => el !== id));
    } else setChosenDocs([...chosenDocs, id]);
  }

  async function uploadDocuments(fls) {
    try {
      const reqData = {
        type: 'clprtPutObjectsToBucket',
        projectId: projId,
        taskId,
        fl: fls,
      };
      const result = await clientSendData('POST', '/clprt_put_objects_to_bucket', reqData); // запрос в БД
      getTaskDocuments();
    } catch (error) {
      catchHandler(error, 'clprtPutObjectsToBucket'); // обработка ошибок
    }
  }

  function fileHandler(files) {
    for (const file of files) {
      const reader = new FileReader(); // API чтения файлов
      reader.onloadend = (e) => {
        uploadDocuments({ path: e.target.result, name: file.name, type: file.type });
      };
      reader.readAsDataURL(file); // Прочитать как DataURL ("data:image/png;base64,iVBORw...");
    }
  }

  async function getTaskDocuments() {
    try {
      const reqData = {
        type: 'clprtGetTasksDocuments',
        taskId,
        projId,
      };
      const result = await clientSendData('POST', '/clprt_get_tasks_documents', reqData); // запрос в БД
      setDocuments(result.data);
    } catch (error) {
      catchHandler(error, 'clprtGetTasksDocuments'); // обработка ошибок
    }
  }
  return (
    <>
      <div className="docs-panel">
        <div className="docs-panel-item">
          ➕
           <p>Новый файл</p>
        </div>
        <div className="docs-panel-item">
         <img src="../../icons/upload.png" alt="upload" />
            <BulkFileUpload
              handler={fileHandler}
            >
              <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" />
              <p>Загрузить файл</p>
            </BulkFileUpload>
        </div>
        <div className="docs-panel-item">
        🢃
          <p>Скачать</p>
        </div>
        <div className="docs-panel-item">
        🗑️
          <p>Удалить</p>
        </div>
      </div>
      <div className="docs-wrapper">
        {documents.map((doc) => (
          <div className="docs-wrapper_line" key={doc.id}>
            <CheckBox onChange={() => collectDocuments(doc.id)} />
            <img src="../../icons/document.png" alt="document" />
            <div className="doc-item">
              <p className="doc-item_title">
                {editExtensions.includes(doc.extension) ? <Link to={`/document_editor/${doc.id}`}>{doc.title}</Link> : doc.title}
              </p>
              <div className="doc-item_data">
                <p>{doc.author}</p>
                <span className="splitter" />
                <p>{doc.date_created}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function TaskComments({ activeTask }) {
  const projId = useSearchParams()[0].get('projId'); // id ПО из url
  const taskId = useSearchParams()[0].get('taskId');
  const [isCommenting, setIsCommenting] = useState(false);
  const [action, setAction] = useState(null);
  const [taskComments, setTaskComments] = useState([]);
  const [comment, setComment] = useState({});
  const current_datetime = ((new Date()).toLocaleString()).slice(0, 17).replace(',', '');
  const [chosenComment, setChosenComment] = useState(null);

  useEffect(() => {
    getComments();
  }, []);

  // Редактировать или создать комментарий
  function handleComment(actionType, commentId) {
    setIsCommenting(true);
    setAction(actionType);
    if (actionType === 'edit') {
      setComment({ ...taskComments.find((el) => el.id === commentId) });
      setChosenComment(commentId);
    } else {
      // eslint-disable-next-line no-unused-expressions
      // taskComments.length === 0 ? setComment({ id: 1 }) : setComment({ id: taskComments[taskComments.length - 1].id + 1 });
      setComment({ id: taskComments.length === 0 ? 1 : taskComments[taskComments.length - 1].id + 1 });
    }
  }

  // Отменить редактирование или создание комментария
  function cancelCommenting() {
    setIsCommenting(false);
    setComment({});
    if (action === 'edit') setChosenComment(null);
    setAction(null);
  }

  function deleteComment(id) {
    const allComments = taskComments;
    const filteredComments = allComments.filter((el) => el.id !== id);
    setTaskComments(filteredComments);
  }

  async function saveComment() {
    try {
      const reqData = {
        type: 'clprtSaveComment',
        comment,
        projId,
        taskId,
        action,
      };
      const result = await clientSendData('POST', '/clprt_save_comment', reqData); // запрос в БД
      if (result) {
        getComments();
        setIsCommenting(false);
        setComment({});
        if (action === 'edit') setChosenComment(null);
        setAction(null);
      }
    } catch (error) {
      catchHandler(error, 'clprtSaveComment'); // обработка ошибок
    }
  }

  async function getComments() {
    try {
      const reqData = {
        type: 'getTasksComments',
        projId,
        taskId,
      };
      const result = await clientSendData('POST', '/get_tasks_comments', reqData); // запрос в БД
      if (result) {
        setTaskComments(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getTasksComments'); // обработка ошибок
    }
  }

  return (
    <div id="comments-wrapper">
      {taskComments.length > 0 && taskComments.map((item) => <div className="comment-line" key={item.id}>
      <img
      //  className="user-page__user-avatar"
        src="../../icons/user_color.png"
        alt="avatar"
      />
        <tr className="comment-line_info">
          <td className="comment-line_header">
            <p className="comment-line_autor">{item.author}</p>
            <p className="comment-line_date">{item.timestamp}</p>
          </td>
          {action === 'edit' && chosenComment === item.id ? (<span className="comment-line_window">
          <textarea
            defaultValue={item.value}
            onChange={(e) => setComment({ ...comment, value: e.target.value })}
          />
          <div
            style={{
              display: 'flex', flexDirection: 'row', gap: '15px', marginTop: '5px',
            }}
          >
        <p className="comment-line_btn" onClick={saveComment}>Сохранить</p>
        <p className="comment-line_btn" onClick={cancelCommenting}>Отмена</p>
          </div>
                                                             </span>) : <p className="comment-line_comment">{item.value}</p>}

          <div className="comment-line_options">
            <p className="comment-line_add" onClick={() => handleComment('edit', item.id)}>редактировать</p>
            <p className="comment-line_add" onClick={() => deleteComment(item.id)}>удалить</p>
          </div>
        </tr>
                                                             </div>)}
      {taskComments.length === 0 && <p style={{ margin: '20px 0 0 5px', fontSize: '18px', fontWeight: '500' }}>Здесь пока нет комментариев</p>}

     {!isCommenting && <div className="comment-line_add" onClick={() => handleComment('create')}>Добавить комменатрий</div>}
     {(isCommenting && action === 'create') && <span className="comment-line_window">
        <textarea type="text" onChange={(e) => setComment({ ...comment, value: e.target.value })} />
        <div
          style={{
            display: 'flex', flexDirection: 'row', gap: '15px', marginTop: '5px',
          }}
        >
        <p className="comment-line_btn" onClick={saveComment}>Добавить</p>
        <p className="comment-line_btn" onClick={cancelCommenting}>Отмена</p>
        </div>
                                               </span>}

    </div>
  );
}

function TaskCard({
  activeTask, setActiveSection, setActiveTask, setTemplate, template, newFolder, setNewFolder, getProjectStructure,
}) {
  const [activeSubSection, setActiveSubSection] = useState('common');
  const Id = useSearchParams()[0].get('projId'); // id ПО из url;
  const taskId = useSearchParams()[0].get('taskId');
  const [searchParams, setSearchParams] = useSearchParams(''); // параметры поиска url

  useEffect(() => {
    setActiveSection('task');
  }, []);

  function onBackClick() {
    setSearchParams({ projId: Id });
    setActiveSection('tasksFolders');
  }

  const renderSection = () => {
    switch (activeSubSection) {
      case 'common':
        return <Common activeTask={activeTask} setActiveTask={setActiveTask} getProjectStructure={getProjectStructure} />;
      case 'subTasks':
        return <SubTasks
          template={template}
          subTemplate={template.filter((el) => el.parentId === Number(taskId))}
          activeTask={activeTask}
          setTemplate={setTemplate}
          setNewFolder={setNewFolder}
          newFolder={newFolder}
          getProjectStructure={getProjectStructure}
        />;
      case 'taskDocs':
        return <TaskDocs />;
      case 'taskComments':
        return <TaskComments activeTask={activeTask} />;
      // case 'task':
      //   return <TaskCard />;
      default:
        return <Common activeTask={activeTask} setActiveTask={setActiveTask} getProjectStructure={getProjectStructure} />;
    }
  };

  return (
<Service id="projectPage">
      <ServiceBody>
        <div className="proj-wrapper">
          <Button
            onClick={onBackClick}
            className="text-button"
            style={{ marginLeft: '-8px', fontWeight: 'bold' }}
          >
            <div>
              <span className="arrow">←</span>
              Вернуться на главную
            </div>
          </Button>

          <div className="proj-title">{activeTask.title}</div>
          <div className="project-panel">
            <p
              className={activeSubSection === 'common' ? 'tab_active' : 'tab'}
              onClick={() => setActiveSubSection('common')}
            >
              Общие сведения
            </p>
            <span className="splitter" />
            <p
              className={
                activeSubSection === 'subTasks' ? 'tab_active' : 'tab'
              }
              onClick={() => setActiveSubSection('subTasks')}
            >
              Подзадачи
            </p>
            <span className="splitter" />
            <p
              className={activeSubSection === 'taskDocs' ? 'tab_active' : 'tab'}
              onClick={() => setActiveSubSection('taskDocs')}
            >
              Документы
            </p>
            <span className="splitter" />
            <p
              className={activeSubSection === 'taskComments' ? 'tab_active' : 'tab'}
              onClick={() => setActiveSubSection('taskComments')}
            >
              Комментарии
            </p>
          </div>

          {/* ТЕКУЩИЙ РАЗДЕЛ ПРОЕКТА */}
          {renderSection()}

        </div>
      </ServiceBody>
</Service>
  );
}

export default TaskCard;
