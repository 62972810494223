import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Document, AlignmentType, Packer, Table, TableCell, TableRow, WidthType, Paragraph, TextRun, TableBorders, BorderStyle, convertInchesToTwip, Header,
} from 'docx';
import Button from '../../components/UI/Button/Button';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';

function CV({ cvData }) {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const orderedKeys = [
    'Ф.И.О.',
    'Занимаемая должность',
    'Стаж работы в компании',
    'Дата рождения',
    'Адрес электронной почты',
    'Образование и профессиональная квалификация/сертификаты',
    'Знание иностранных языков',
    'Номер квалификационного сертификата аудитора',
    'Номер квалификационного сертификата налогового консультанта',
    'Сертификаты',
    'Номер квалификационного сертификата оценщика',
    'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»',
    'Стаж финансово-экономической деятельности',
    'Стаж юридической деятельности',
    'Сведения о трудовой деятельности',
    'Ключевые компетенции',
    'Основные проекты',
    'В Юникон АО',
    'В других организациях',
    'Достоверность сведений, представленных в резюме, подтверждаю',
  ];

  useEffect(() => {
    if (cvData && cvData.cv) {
      setFormData(cvData.cv);
    }
  }, [cvData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleArrayChange = (key, index, field, value) => {
    const updatedArray = [...formData[key]];
    updatedArray[index] = {
      ...updatedArray[index],
      [field]: value,
    };
    setFormData((prevData) => ({
      ...prevData,
      [key]: updatedArray,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Отправленные данные:', formData);
    const userId = cvData?.id; // Замените на правильный путь к ID пользователя в cvData

    // Вызов функции для загрузки резюме
    await uploadResume(formData, userId);
  };

  const uploadResume = async (resumeData, userId) => {
    console.log(resumeData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editStaffCV',
        DataCV: resumeData,
        userId, // Добавляем ID пользователя
      };

      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_staff_cv', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('resume_edit_success', 'client'); // Уведомление об успешном обновлении
      } else {
        await alertFunction('resume_edit_error', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editStaffCV'); // Обработка ошибок
    }
  };

  const handleBackToResults = () => {
    navigate('/staff_cv');
    window.location.reload();
  };

  const generateWordDocument = () => {
    const rows = [];

    const defaultFontStyle = {
      font: 'Microsoft Sans Serif', // Название шрифта
      size: 18, // Размер шрифта (в пунктах)
    };

    // Создаем колонтитул
    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: 'РЕЗЮМЕ',
              bold: true,
              font: 'Microsoft Sans Serif', // Задаем шрифт для колонтитула
              size: 22, // Размер шрифта для колонтитула (можно настроить)
            }),
          ],
          alignment: AlignmentType.RIGHT, // Выравнивание по правому краю
        }),
      ],
    });

    orderedKeys.forEach((key) => {
      if (formData[key] !== undefined) {
        const value = formData[key];

        if (Array.isArray(value) && key === 'Образование и профессиональная квалификация/сертификаты') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Сведения о трудовой деятельности') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && (key === 'Ключевые компетенции')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (Array.isArray(value) && ((key === 'В Юникон АО') || (key === 'В других организациях'))) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Номер квалификационного сертификата аудитора' || key === 'Номер квалификационного сертификата налогового консультанта' || key === 'Сертификаты'
     || key === 'Номер квалификационного сертификата оценщика' || key === 'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»' || key === 'Стаж финансово-экономической деятельности' || key === 'Стаж юридической деятельности') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
          // Извлечение даты из value
          const dateRegex = /(\d{2}\.\d{2}\.\d{4})/;
          const dateMatch = value.match(dateRegex);
          const date = dateMatch ? dateMatch[0] : 'Введите дату';

          // Извлечение оставшегося текста
          const remainingText = value.replace(dateRegex, '').trim();
          // Разделяем текст на части
          const parts = remainingText.split('Игоревна');

          // Создание ячеек
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Ячейка с оставшимся текстом
          rows.push([
            new TableCell({
              children: [new Paragraph({
                children: [
                  new TextRun({
                    text: `${parts[0].trim()} Игоревна`,
                    ...defaultFontStyle,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: parts[1].trim(),
                    ...defaultFontStyle,
                  }),
                ],
              })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: date, ...defaultFontStyle })] })],
            }),
          ]);
        } else if (key === 'Ф.И.О.') {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: value, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: Array.isArray(value) ? value.join(', ') : value, ...defaultFontStyle })] })],
            }),
          ]);
        }
      }
    });

    const table = new Table({
      rows: rows.map((row) => new TableRow({ children: row })),
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: TableBorders.NONE,
      margins: {
        top: convertInchesToTwip(0.1),
        bottom: convertInchesToTwip(0.1),
        right: convertInchesToTwip(0),
        left: convertInchesToTwip(0),
      },
    });

    const doc = new Document({
      sections: [{
        properties: {},
        headers: {
          default: header, // Добавляем колонтитул в секцию
        },
        children: [table],
      }],
    });

    // Извлечение Ф.И.О. для имени файла
    const fullName = formData['Ф.И.О.'] || 'Резюме';
    const sanitizedFileName = fullName.replace(/[^a-zA-Z0-9а-яА-ЯёЁ\s]/g, '').replace(/\s+/g, '_').trim();

    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sanitizedFileName}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }).catch((error) => {
      console.error('Ошибка при создании документа:', error);
    });
  };

  const renderField = (key) => {
    if (key === 'Основные проекты') {
      return null;
    }

    const value = formData[key];

    if (typeof value === 'string') {
      return (
        <input
          type="text"
          id={key}
          name={key}
          value={value || ''}
          onChange={handleChange}
          style={{ width: '100%' }}
        />
      );
    }

    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <div key={index} style={{ marginBottom: '5px' }}>
          {typeof item === 'object' ? (
            <>
              <input
                type="text"
                value={item.year || ''}
                onChange={(e) => handleArrayChange(key, index, 'year', e.target.value)}
                placeholder="Год"
                style={{ marginRight: '0', width: '15%', fontWeight: 'bolder' }}
              />
              <input
                type="text"
                value={item.value || ''}
                onChange={(e) => handleArrayChange(key, index, 'value', e.target.value)}
                placeholder="Значение"
                style={{ width: '80%' }}
              />
            </>
          ) : (
            <input
              type="text"
              value={item || ''}
              onChange={(e) => {
                const updatedArray = [...value];
                updatedArray[index] = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  [key]: updatedArray,
                }));
              }}
              placeholder="Значение"
              style={{ width: '100%' }}
            />
          )}
        </div>
      ));
    }

    return null;
  };

  return (
    <div style={{ padding: '20px' }}>
      <Button onClick={handleBackToResults} className="text-button">
        <span className="arrow">←</span>
        Вернуться к резюме сотрудников
      </Button>
      <h1>Резюме</h1>
      <div style={{
        maxHeight: '70vh', overflowY: 'auto', marginBottom: '10px', paddingTop: '10px', borderTop: '1px solid black',
      }}
      >
        <form onSubmit={handleSubmit}>
          {orderedKeys.map((key) => {
            const value = formData[key];

            if (value === undefined || value === null || value === '') {
              return null; // Если значение отсутствует, ничего не рендерим
            }

            const isSpecialKey = [
              'Сведения о трудовой деятельности',
              'Образование и профессиональная квалификация/сертификаты',
              'В Юникон АО',
              'В других организациях',
              'Достоверность сведений, представленных в резюме, подтверждаю',
              'Ключевые компетенции',
            ].includes(key);

            const hasTopBorderKey = [
              'Образование и профессиональная квалификация/сертификаты',
              'Сведения о трудовой деятельности',
              'Ключевые компетенции',
              'Достоверность сведений, представленных в резюме, подтверждаю',
            ].includes(key);

            return (
              <div
                key={key}
                style={{
                  marginBottom: '15px',
                  borderTop: hasTopBorderKey ? '1px solid black' : 'none',
                  paddingTop: hasTopBorderKey ? '10px' : '0',
                }}
              >
                {isSpecialKey ? (
                  <>
                    <label htmlFor={key} style={{ display: 'block', fontWeight: 'bold', marginBottom: '10px' }}>
                      {key}
:
                    </label>
                    {renderField(key)}
                  </>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor={key} style={{ flex: '0 0 15%', fontWeight: 'bold' }}>
                      {key}
:
                    </label>
                    <div style={{ flex: '1 1 80%' }}>
                      {renderField(key)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </form>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button type="button" onClick={generateWordDocument} style={{ marginRight: '20px' }}>
          Скачать резюме
        </Button>
        <Button type="submit" onClick={handleSubmit}>
          Сохранить изменения
        </Button>
      </div>
    </div>
  );
}

export default CV;
