import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import { alertFunction } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import './adminrecrutment.scss';

function LevelLanguagesRecrutment() {
  const [levels, setLevels] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState(new Set()); // Состояние для хранения выбранных сертификатов
  const [newLevel, setNewLevel] = useState({ title: '' });

  useEffect(() => {
    awaitRequests();
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = { type: 'getAdminDataRecrutment' };
      const result = await clientSendData('POST', '/get_admin_data_recrutment', reqData);
      if (result) {
        console.log(result.data);
        const data = JSON.parse(result.data);
        setLevels(data.language_level);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataRecrutment');
    }
  }

  const handleTitle = (id, newLevel) => {
    setLevels(levels.map((l) => (l.id === id ? { ...l, title: newLevel } : l)));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  const toggleSelectCertificate = (id) => {
    setSelectedLevels((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  //  Функция отправки изменений на бэк
  const editStaffCVLevels = async (levelsData) => {
    console.log(levelsData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editRecrutmentLanguagesLevels',
        data: levelsData,
      };
      console.log(reqData);
      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_recrutment_languages_levels', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('save_settings', 'client'); // Уведомление об успешном обновлени
        // Обновляем состояние, чтобы перерендерить компонент
        await getPermissions();
      } else {
        await alertFunction('required_fields', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editRecrutmentLanguagesLevels'); // Обработка ошибок
    }
  };

  const deleteSelectedLevels = async () => {
    const newLevels = levels.filter((l) => !selectedLevels.has(l.id));
    setLevels(newLevels);
    setSelectedLevels(new Set()); // Сбросить выбранные сертификаты
    await editStaffCVLevels(newLevels);
  };

  const addSelectedLevel = async () => {
    const newLevels = levels.filter((l) => !selectedLevels.has(l.id));
    setLevels(newLevels);
    setSelectedLevels(new Set()); // Сбросить выбранные сертификаты
    await editStaffCVLevels(levels);
  };

  // Обработчик для добавления нового языка
  const addNewLevel = () => {
    if (newLevel.title) {
      const newLev = {
        id: (levels.length + 1).toString(), // Генерация ID (в реальном приложении лучше использовать уникальный идентификатор)
        title: newLevel.title,
      };
      setLevels([...levels, newLev]);
      setNewLevel({ title: '' }); // Сбросить форму
    } else {
      alertFunction('required_fields', 'client'); // Уведомление, если поля не заполнены
    }
  };

  if (isAuthorized) {
    return (
    // <div id="staff_cv" className="service">
                <ServiceBody>
                    <Table id="admin_staff_cv">
                        <THead title="Уровни владения языком" headers={headers.levels} className="table-header" />
                        <TBody>
                            {levels.map((el) => (
                                <TRow key={el.id}>
                                    <TData className="id-cell">
                                    {el.id}
                                    </TData>
                                    <TData className="full-name-cell">
    <Input
      type="text"
      value={el.title}
      onChange={(e) => handleTitle(el.id, e.target.value)}
      placeholder="Уровни языков"
    />
                                    </TData>
<TData className="checkbox-cell">
                                        <input
                                          type="checkbox"
                                          checked={selectedLevels.has(el.id)}
                                          onChange={() => toggleSelectCertificate(el.id)}
                                        />
</TData>
                                </TRow>
                            ))}

                             {/* Строка для добавления нового уровня */}
               <TRow>
                <TData className="id-cell" />
                <TData className="full-name-cell">
                  <Input
                    type="text"
                    value={newLevel.title}
                    onChange={(e) => setNewLevel({ ...newLevel, title: e.target.value })}
                    placeholder="уровень"
                  />
                </TData>
                <TData className="add-button-cell">
                  <Button onClick={addNewLevel} style={{ backgroundColor: '#28B47D', color: 'white' }}>
                    Добавить
                  </Button>
                </TData>
               </TRow>

                        </TBody>
                        <TFoot>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
  <Button
    onClick={addSelectedLevel}
    style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }} // Отступ справа
  >
    Сохранить изменения
  </Button>
  <Button
    onClick={deleteSelectedLevels}
    style={{ marginLeft: '10px' }} // Отступ слева
  >
    Удалить выбранные
  </Button>
                        </div>

                        </TFoot>
                    </Table>
                </ServiceBody>
    // </div>
    );
  } return <Cap />;
}

export default LevelLanguagesRecrutment;
