import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { setOperInfo } from '../../utils/functions/others';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button/Button';
import { createAction } from '../../utils/redux/store';
import { checkSession, finishAuthenticate } from '../../utils/functions/authenticate';
import { getCookie } from '../../utils/functions/cookies';
import ServiceBody from '../../components/Service/ServiceBody';
import classes from '../../components/UI/Button/button.module.scss';

/**
 * @component Страница-заглушка
 * @prop {type} string - Тип заглушки (NOAUTH/BLOCK/LOGOUT)
*/
function CapPage(props) {
  const { type } = props;
  const [authData, setAuthData] = useState({});
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [auth, setAuth] = useState(null);
  const [userIpAddress, setUserIpAddress] = useState(null);
  const navigate = useNavigate();
  const defaultPage = useSelector((state) => state.users.default_page);

  useEffect(() => {
    setOperInfo({ alias: type });
    if (type === 'BLOCK') getExpDate();
    getIp();
  }, []);

  useEffect(() => {
    if (defaultPage.includes('tv_panel') && userIpAddress) {
      localStorage.setItem('last_service', 'tv_panel');
      login(null, 'tv_panel');
    }
  }, [userIpAddress]);

  const [expDate, setExpDate] = useState(''); // дата истечения сессии (для BLOCK-заглушки)
  const smallButtonClass = `${classes.button} ${classes.small}`;

  // Получение даты окончания блокировки. (только для "BLOCK" страницы)
  async function getExpDate() {
    try {
      const reqData = { type: 'getSessionData' };
      const result = await clientSendData('POST', '/get_session_data', reqData);
      if (result) setExpDate(result?.date_experation);
    } catch (error) {
      catchHandler(error, 'getExpDate');
    }
  }

  async function getIp() {
    try {
      const reqData = { type: 'getIp' };
      const result = await clientSendData('POST', '/get_ip', reqData);
      if (result) await verifyOfficeIpAddress(result);
    } catch (error) {
      catchHandler(error, 'getExpDate');
    }
  }

  async function verifyOfficeIpAddress(ipAddress) {
    try {
      const reqData = { type: 'verifyOfficeIpAddress', ipAddress };
      const result = await clientSendData('POST', '/verify_office_ip_address', reqData);
      if (result) setUserIpAddress(result);
    } catch (error) {
      catchHandler(error, 'getIp');
    }
  }

  async function login(event, tmpLogin) {
    if (event) event.preventDefault();
    const userLogin = username || tmpLogin;
    createAction('SET_CURRENT_USER', userLogin);
    const reqData = {
      type: 'verifyAdCredentials',
      username: userLogin,
      password,
    };
    const result = await clientSendData('POST', '/verify_ad_credentials', reqData);
    if (result || userLogin === tmpLogin) {
      await checkSession(getCookie('ip_session'), userLogin);
      finishAuthenticate();
      setAuth(true);
      navigate(defaultPage, { replace: true });
      window.location.reload();
    } else {
      setAuth('fail');
      setAuthData({});
    }
  }

  const handleNextBtn = () => {
    setAuth(null);
    setAuthData((state) => ({ ...state, username }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    handleNextBtn();
  };

  switch (type) {
    case 'BLOCK': { // Заглушки при блокировке сессии
      return (
        <div className="main">
          <h1 className="main__title">Сессия заблокирована</h1>
          {/* Если присутствует expDate, показывается строка - "повторите попытку после <значение expDate>" */}
          {expDate && <p className="main__title">{`повторите попытку после ${expDate}`}</p>}
        </div>
      );
    }
    case 'NOAUTH': { // Заглушки при отсутствии аутентификации
      return (
        <ServiceBody>
        {/* <div className="circle_blue" /> //Синий полукруг фона */}
        <div className="main">
          {/* <h1 className="main__title">К сожалению, на данный момент, у вас нет доступа к порталу.</h1> */}
          <div>
          <div>
              <div className="light_blue">
                <h1 className="form__title">ВХОД</h1>
                <p className="form__p">Для доступа к порталу UNIPLACE введите логин и пароль</p>
              </div>
              <div className="grey">
            {!authData?.username && (
              <form onSubmit={handleLogin}>
                <Input type="text" autoFocus placeholder="Введите логин" onChange={(e) => setUsername(e.target.value)} required />
                <br />
                <br />
                <Button className={smallButtonClass} onClick={handleNextBtn}>Далее</Button>
                <br />
                <br />
                {defaultPage.includes('video_conferences/conf') && <Button className={`${smallButtonClass} buttonGreen`} onClick={(event) => login(event, 'conf_guest')}>Войти как гость</Button>}
                {/* {defaultPage.includes('tv_panel') && <Button className={`${smallButtonClass} buttonGreen`} onClick={(event) => login(event, 'tv_panel')}>Войти</Button>} */}
              </form>)}

            {authData?.username && (
              <form onSubmit={login}>
                <Input autoFocus type="password" placeholder="Введите пароль" onChange={(e) => setPassword(e.target.value)} required />
                <br />
                <br />
                <Button onClick={login}>Войти</Button>
              </form>)}
            {auth === 'fail' ? (<div>Не верный логин или пароль</div>) : null}
              </div>
          </div>
          </div>
        </div>
        </ServiceBody>

      );
    }
    case 'LOGOUT': { // Заглушки при выходе из системы (состаривании сессии)
      return (
        <div className="main">
          <h1 className="main__title">Вы вышли с портала</h1>
          <p className="main__title">
            Чтобы войти снова, пожалуйста,
            <span className="clickable" onClick={() => window.location.reload()}> обновите </span>
            страницу
          </p>
        </div>
      );
    }
    default: {
      return (
        <div className="main">
          <h1 className="main__title">Тип не идентифицирован</h1>
        </div>
      );
    }
  }
}

export default CapPage;
