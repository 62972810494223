import React, { useEffect } from 'react';

function WeatherWidget(id) {
  const { ids: ids1 } = id;
  useEffect(() => {
    const script = document.querySelector('script[src*="weatherwidget"]');
    if (script) {
      script.remove();
    }
    const newScript = document.createElement('script');
    newScript.src = 'https://app3.weatherwidget.org/js/?id=ww_b60581727e233';
    newScript.async = true;
    document.body.appendChild(newScript);
    return () => {
      document.body.removeChild(newScript);
    };
  }, [ids1]);

  return (
    <div key={ids1} id="ww_b60581727e233" v="1.3" loc="id" a={`{"t":"horizontal","lang":"ru","sl_lpl":1,"ids":["${ids1}"],"font":"Arial","sl_ics":"one_a","sl_sot":"celsius","cl_bkg":"#FFFFFF","cl_font":"#000000","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","el_whr":3,"el_nme":3}`}>
     <a href="https://weatherwidget.org/" id="ww_b60581727e233_u" target="_blank" rel="noreferrer" style={{ color: '#ffffff' }}>Free weather widget</a>
    </div>
  );
}

export default WeatherWidget;
