import React from 'react';
import AdminKB from '../../pages/AdminKB/AdminKB';
import AdminPage from '../../pages/AdminPage/AdminPage';
import AdminPanel from '../../pages/AdminPanel/AdminPanel';
import Counterparty from '../../pages/Counterparty/Counterparty';
import Evaluation from '../../pages/Evaluation/Evaluation';
import HelpDesk from '../../pages/HelpDesk/HelpDesk';
import Interface from '../../pages/KnowledgeBase/Interface/Interface';
import Interview from '../../pages/Questionnaire/Interview/Interview';
import Instructions from '../../pages/Instructions/Instructions';
import InstructionPage from '../../pages/Instructions/InstructionPage';
import Logs from '../../pages/AdminPanel/Logs/Logs';
import Main from '../../pages/Main/Main';
import Manager from '../../pages/KnowledgeBase/Manager/Manager';
import PortalState from '../../pages/PortalState/PortalState';
import Probation from '../../pages/ProbationControl/Probation';
import Questionnaire from '../../pages/Questionnaire/Questionnaire';
import Reports from '../../pages/Reports/Reports';
import ReportsFF from '../../pages/ReportsFF/ReportsFF';
import TestPage from '../../pages/TestPage';
import Tasks from '../../pages/Tasks/Tasks';
import UserPage from '../../pages/UserPage/UserPage';
import Workplaces from '../../pages/Workplaces/Workplaces';
import BookingPage from '../../pages/Workplaces/BookingPage/BookingPage';
import AdminHelpdesk from '../../pages/AdminHelpdesk/AdminHelpdesk';
import MobileConnection from '../../pages/MobileConnection/MobileConnection';
import Estimation from '../../pages/AdminHelpdesk/Estimation';
import AdminWorkplaces from '../../pages/AdminWorkplaces/AdminWorkplaces';
import NewWorkPlace from '../../pages/NewWorkPlace/NewWorkPlace';
import TempAccessControl from '../../pages/HelpDesk/TemporaryAccesses/TempAccessControl';
import TempAccessProlongation from '../../pages/HelpDesk/TemporaryAccesses/TempAccessProlongation';
import VideoConferences from '../../pages/VideoConferences/VideoConferences';
import Conference from '../../pages/VideoConferences/Conference';
import Test from '../../pages/Test';
import AdminQr from '../../pages/AdminQr/AdminQr';
import AdminPs from '../../pages/AdminPs/AdminPs';
import MaintenanceDepartment from '../../pages/MaintenanceDepartment/MaintenanceDepartment';
import LegalForm from '../../pages/LegalForm/LegalForm';
import AdminMd from '../../pages/AdminMd/AdminMd';
import ASAP from '../../pages/ASAP/ASAP';
import ClientsPortal from '../../pages/ClientsPortal/ClientsPortal';
import TVPanel from '../../pages/TVPanel/TVPanel';
import AdminTVPanel from '../../pages/AdminTVPanel/AdminTVPanel';
import StaffCV from '../../pages/StaffCV/StaffCV';
import CRM from '../../pages/CRM/CRM';
import PODFTmailservice from '../../pages/PODFTMailService/PODFTmailservice';

const routes = [
  { path: '/', component: <Main /> },
  { path: '/admin_kb', component: <AdminKB /> },
  { path: '/admin_panel', component: <AdminPanel /> },
  { path: '/admin_panel_log', component: <Logs /> },
  { path: '/admin_page', component: <AdminPage /> },
  { path: '/counterparty', component: <Counterparty /> },
  { path: '/evaluation', component: <Evaluation /> },
  { path: '/estimation/:id', component: <Estimation /> },
  { path: '/helpdesk', component: <HelpDesk /> },
  { path: '/interview/:link/*', component: <Interview /> },
  { path: '/instructions', component: <Instructions /> },
  { path: '/instructions/:link', component: <InstructionPage /> },
  { path: '/knowledge_base', component: <Interface /> },
  { path: '/knowledge_base_manager', component: <Manager /> },
  { path: '/portal_state', component: <PortalState /> },
  { path: '/probation', component: <Probation /> },
  { path: '/questionnaire', component: <Questionnaire /> },
  { path: '/reports', component: <Reports /> },
  { path: '/reports_ff', component: <ReportsFF /> },
  { path: '/tasks', component: <Tasks /> },
  { path: '/test_page', component: <TestPage /> },
  { path: '/user/:name', component: <UserPage /> },
  { path: '/workplaces', component: <Workplaces /> },
  { path: '/workplaces/:action/:id', component: <BookingPage /> },
  { path: '/admin_helpdesk', component: <AdminHelpdesk /> },
  { path: '/mobile_connection', component: <MobileConnection /> },
  { path: '/admin_workplaces', component: <AdminWorkplaces /> },
  { path: '/new_work_place', component: <NewWorkPlace /> },
  { path: '/temporary_accesses', component: <TempAccessControl /> },
  { path: '/temporary_accesses/prolongation/:id', component: <TempAccessProlongation /> },
  { path: '/video_conferences', component: <VideoConferences /> },
  { path: '/video_conferences/conf/:room', component: <Conference /> },
  { path: '/qr', component: <Test /> },
  { path: '/admin_qr', component: <AdminQr /> },
  { path: '/admin_ps', component: <AdminPs /> },
  { path: '/maintenance_department', component: <MaintenanceDepartment /> },
  { path: '/legal_form', component: <LegalForm /> },
  { path: '/admin_md', component: <AdminMd /> },
  { path: '/asap', component: <ASAP /> },
  { path: '/clients_portal', component: <ClientsPortal /> },
  { path: '/tv_panel', component: <TVPanel /> },
  { path: '/admin_tv_panel', component: <AdminTVPanel /> },
  { path: '/staff_cv', component: <StaffCV /> },
  { path: '/crm', component: <CRM /> },
  { path: '/PODFT_mail_service', component: <PODFTmailservice /> },
];

export default routes;
