import React from 'react';

/**
* @component Хедер карточки
*/

function CardPageHeader(props) {
  const { children } = props;
  return <div className="upu-card-on-page__header">{children}</div>;
}

export default CardPageHeader;
