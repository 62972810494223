import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import JSZip from 'jszip';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import TextArea from '../../components/UI/TextArea';
import CardPageSetting from '../../components/CardPage/CardPageSetting';
import classes from '../../components/UI/Button/button.module.scss';
import './recrutment.scss';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import CheckBox from '../../components/UI/CheckBox';
import ServiceBody from '../../components/Service/ServiceBody';

function RecrutmentCard(props) {
  const {
    setShowCard, closeHandler, statusID, ticketID, setIsLoadingData,
  } = props;

  console.log(ticketID, statusID);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки данных
  const smallCurtain = `${classes.curtain__button} ${classes.small}`;
  const smallButtonClass = `${classes.button} ${classes.small}`; // имя класса стилей кнопок
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [currentInitiator, setCurrentInitiator] = useState();

  // Инициализация formData при изменении ticketData
  // useEffect(() => {
  //   if (ticketData !== null) {
  //     setFormData(ticketData);
  //     setCurrentStatus(statusID || 1); // Установка статуса, если он есть
  //   } else if (dataTicket !== null) {
  //     setFormData(dataTicket);
  //     setCurrentStatus(statusID || 1);
  //   }
  // }, [ticketData, dataTicket]);

  useEffect(() => {
    // Если ticketID изменяется, можно добавить логику для загрузки данных
    if (ticketID) {
      getRecrutmentData(ticketID);
      setCurrentStatus(statusID || 1);
    }
  }, [ticketID]);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  useEffect(() => {
    getRecrutmentFormData();
  }, [searchParams]);

  // function closeHandler() {
  //   setShowCard(false); // закрытие карточки
  //   // setFormData([]);
  //   setSearchParams(''); // сброс параметром url
  //   // setCurrentStatus(1);
  // }

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  const [currentStatus, setCurrentStatus] = useState();
  const [formData, setFormData] = useState({
    company: '',
    head_mvz: { id: '', title: '' },
    performer: { id: '', title: '' },
    job_level: '',
    comments: '',
    location: '',
    justification: '',
    projects: [],
    certificates: [],
    programms: [],
    specialities: [],
    closing_date: '',
    needGd: false,
    user_id: '',
    orp: [],
    feo: [],
    zgd: [],
    gd: [],
    admin: [],
  });

  const [getData, setGetData] = useState({});
  const [userID, setUserID] = useState();
  const [performerId, setPerformerId] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [mvzNumber, setMvzNumber] = useState('');

  const isUserInOrp = getData.orp?.some((item) => String(item.id) === String(userID)) || false;
  const isUserInFeo = getData.feo?.some((item) => String(item.id) === String(userID)) || false;
  const isUserInZgd = getData.zgd?.some((item) => String(item.id) === String(userID)) || false;
  const isUserInGd = getData.gd?.some((item) => String(item.id) === String(userID)) || false;
  const isUserInAdmin = getData.admins?.some((item) => String(item.id) === String(userID)) || false;

  const [additionalInfo, setAdditionalInfo] = useState({ // дополнительная информация
    statuses: [], // типы статусов
    performers: [], // исполнители
    initiator: [], // инициаторы (при поиске)
  });

  async function getRecrutmentFormData() {
    try {
      setIsLoading(true);
      const reqData = { type: 'getRecrutmentFormData' };
      const result = await clientSendData('POST', '/get_recrutment_form_data', reqData);
      console.log(result);
      if (result?.success) {
        const dataString = result.data.regData[0].value;
        const safeDataString = dataString.replace(/'/g, '"');
        const dataHeadMvz = result.data.head_mvz;
        const dataMvz = result.data.mvzs;
        const dataDepartment = result.data.departments;
        const data = JSON.parse(safeDataString);
        data.head_mvz = dataHeadMvz;
        data.mvz = dataMvz;
        data.department = dataDepartment;
        setUserID(result.data.user_id);
        setPerformerId(result.data?.performer?.id || null);
        setAdditionalInfo((state) => ({ ...state, ...data, statuses: result.data.statuses }));
        setGetData(data);
        console.log(data);
      }
    } catch (error) {
      catchHandler(error, 'getRecrutmentFormData');
    } finally {
      setIsLoading(false);
    }
  }

  // console.log(performerId, userID);

  async function getRecrutmentData(ticketID) {
    try {
      setIsLoading(true);
      const reqData = { type: 'getOneRTicket', ticketID };
      const result = await clientSendData('POST', '/get_one_r_ticket', reqData);
      if (result.success) {
        setMvzNumber(result.data[0].mvz_number);
        setFormData(result.data[0].data);
        setCurrentStatus(result.data[0].status_id);
        setCurrentInitiator(result.data[0].initiator_id);
        setIsLoadingData(true);
        setDataLoaded(true);
      } else {
        await alertFunction('no_access', 'clientPost');
        setIsLoadingData(false);
        setDataLoaded(false);
      }
      // console.log(result.data[0].initiator_id);
    } catch (error) {
      catchHandler(error, 'getOneRTicket');
      setDataLoaded(false);
      setIsLoadingData(false);
    } finally {
      setIsLoading(false);
    }
  }

  const handleChooseSpeciality = (speciality) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.specialities.find((p) => p.id === speciality.id)) {
        return {
          ...prevData,
          specialities: [...prevData.specialities, speciality],
        };
      }
      return prevData;
    });
  };

  const handleRemoveSpeciality = (specialityId) => {
    setFormData((prevData) => ({
      ...prevData,
      specialities: prevData.specialities.filter((speciality) => speciality.id !== specialityId),
    }));
  };

  const handleChoose = (project) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.projects.find((p) => p.id === project.id)) {
        return {
          ...prevData,
          projects: [...prevData.projects, project],
        };
      }
      return prevData;
    });
  };

  const handleRemove = (projectId) => {
    setFormData((prevData) => ({
      ...prevData,
      projects: prevData.projects.filter((project) => project.id !== projectId),
    }));
  };

  const handleChooseProgramm = (programm) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.programms.find((p) => p.id === programm.id)) {
        return {
          ...prevData,
          programms: [...prevData.programms, programm],
        };
      }
      return prevData;
    });
  };

  const handleRemoveProgramm = (programmId) => {
    setFormData((prevData) => ({
      ...prevData,
      programms: prevData.programms.filter((programm) => programm.id !== programmId),
    }));
  };

  const handleChooseCertificate = (certificate) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.certificates.find((p) => p.id === certificate.id)) {
        return {
          ...prevData,
          certificates: [...prevData.certificates, certificate],
        };
      }
      return prevData;
    });
  };

  const handleRemoveCertificate = (certificateId) => {
    setFormData((prevData) => ({
      ...prevData,
      certificates: prevData.certificates.filter((certificate) => certificate.id !== certificateId),
    }));
  };

  async function createRecrutmentTicket() {
    try {
      // const validateRecrutmentTicket = ['company', 'head_mvz', 'job_level', 'number_of_vacancies', 'education', 'location', 'certificates', 'specialities', 'contracts', 'types', 'qualifications'].every((key) => formData[key]);
      // console.log(formData);
      // console.log(validateRecrutmentTicket);
      // if (validateRecrutmentTicket) {
      setIsLoading(true);
      const status_id = 2;
      const reqData = { type: 'createRecrutmentTicket', formData, status_id };
      const result = await clientSendData('POST', '/create_recrutment_ticket', reqData);
      if (result?.success) {
        // await refreshTable();
        await alertFunction('ticket_created', 'clientPost');
        // goToUrl(result.data);
        closeHandler();
      }
      // } else {
      //   await alertFunction('required_fields', 'clientPost');
      // }
    } catch (error) {
      catchHandler(error, 'createRecrutmentTicket');
    } finally {
      setIsLoading(false);
    }
  }

  const validateRecrutmentTicket = (currentStatus) => {
    // Проверяем обязательность полей в зависимости от currentStatus
    const isPerformerRequired = currentStatus === 10 ? !!formData.performer : true;
    const isNewEmployeeRequired = currentStatus === 11 ? !!formData.fio_new_employee : true;
    const isFEORequired = currentStatus === 4 ? !!formData.feo_comments : true;
    const isZGDRequired = currentStatus === 12 ? !!formData.zgd_comments : true;
    const isORPRequired = currentStatus === 14 ? !!formData.rp_comments : true;
    const isMvzValid = !!formData.mvz; // Проверяем mvz
    const isDepartmentValid = !!formData.department; // Проверяем department

    // Проверяем необходимость r_mvz_comments в зависимости от статуса
    const isCommentsRequired = (currentStatus === 13 || currentStatus === 5) ? !!formData.r_mvz_comments : true;

    console.log(currentStatus, isPerformerRequired, isNewEmployeeRequired, isMvzValid, isDepartmentValid, isCommentsRequired);

    // Возвращаем результат проверки
    return isPerformerRequired && isNewEmployeeRequired && isMvzValid && isDepartmentValid && isCommentsRequired && isFEORequired && isZGDRequired && isORPRequired;
  };

  // функци обновления статусов и данных при изменении
  async function sendStatus(statusId, additionalData = {}) {
    try {
      if (!validateRecrutmentTicket(statusId)) {
        await alertFunction('required_fields', 'clientPost');
        return; // Прекращаем выполнение функции, если валидация не прошла
      }

      const reqData = {
        type: 'UpdateStatus',
        status_id: statusId,
        formData: { ...formData, ...additionalData },
        ticket_id: ticketID,
      };

      const result = await clientSendData('POST', '/update_recrutment_status', reqData);
      if (result?.success) {
        await alertFunction('save_settings', 'clientPost');
        await getRecrutmentFormData();
        closeHandler();
      } else {
        await alertFunction('something_went_wrong', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'UpdateStatus');
    }
  }

  // создание файла распоряжения
  async function createHRForm() {
    try {
      const reqData = {
        type: 'createHRForm',
        fio: formData.fio_new_employee || '',
        mvz: formData.mvz || '',
        reason: formData.reason || '',
        department: formData.department || '',
        mvzNumber: mvzNumber || '',
      };

      const result = await clientSendData('POST', '/create_hr_form', reqData);

      if (!result.files && !result.file) {
        alertFunction('no_data', 'clientPost');
        return;
      }

      // Если есть массив файлов (несколько документов)
      if (result.files && result.files.length > 0) {
        const zip = new JSZip(); // Используем библиотеку JSZip для создания архива
        result.files.forEach((fileData, index) => {
          const uInt8Array = new Uint8Array(fileData.data);
          zip.file(`Распоряжение_${formData.fio_new_employee.split(',')[index]}_${formData.mvz}.docx`, uInt8Array);
        });

        // Генерируем ZIP-архив и предлагаем скачать
        zip.generateAsync({ type: 'blob' }).then((blob) => {
          const tempLink = document.createElement('a');
          tempLink.href = window.URL.createObjectURL(blob);
          tempLink.setAttribute('download', `Распоряжения_${formData.fio_new_employee}_${formData.mvz}.zip`);
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
        });
      } else if (result.file) {
        const uInt8Array = new Uint8Array(result.file.data);
        const blob = new Blob([uInt8Array], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', `Распоряжение_${formData.fio_new_employee}_${formData.mvz}.docx`);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    } catch (error) {
      catchHandler(error, 'createHRForm');
    }
  }
  // console.log(userID, formData.performer.id); // true
  // console.log(formData.head_mvz.title);

  if (isAuthorized && dataLoaded) {
    return (
        <ServiceBody>
            <div style={{ width: '100%' }}>
        <h3 style={{ color: '#0F1955' }}>Общая информация</h3>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '5px',
          margin: '7px 0 20px 0',
          padding: '7px',
          backgroundColor: 'rgba(228, 241, 255, 0.5)', // Цвет фона (можно изменить на любой другой)
          borderRadius: '10px', // Скругление углов
        }}
        >
  {/* Первый столбец */}
  <div>

    <CardPageSetting title="Компания:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="company"
          array={getData.company}
          onChoose={(e) => setFormData({ ...formData, company: e.title })}
          defaultValue={formData.company || ''}
        />
       ) : (
        <span>{formData.company}</span>
       )}
    </CardPageSetting>
    <CardPageSetting title="Уровень должности:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) || (isUserInAdmin && (currentStatus === 9 || currentStatus === 10)) ? (
        <Select
          id="job_level"
          array={getData.job_level}
          onChoose={(e) => setFormData({ ...formData, job_level: e.title, qualifications: e.description })}
          defaultValue={formData.job_level || ''}
        />
       ) : (
        <span>{formData.job_level}</span>
       )}
    </CardPageSetting>

    <CardPageSetting title="Подразделение:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="department"
          array={getData.department}
          onChoose={(e) => setFormData({ ...formData, department: e.title })}
          defaultValue={formData.department || ''}
        />
       ) : (
        <span>{formData.department}</span>
       )}
    </CardPageSetting>

    <CardPageSetting title="МВЗ:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="mvz"
          array={getData.mvz}
          onChoose={(e) => setFormData({ ...formData, mvz: e.title })}
          defaultValue={formData.mvz || ''}
        />
       ) : (
        <span>{formData.mvz}</span>
       )}
    </CardPageSetting>
  </div>
    <div>
    <CardPageSetting title="Руководитель МВЗ:">
   {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
    <Select
      id="head_mvz"
      array={getData.head_mvz} // Убедитесь, что это массив объектов с id и title
      onChoose={(e) => setFormData({ ...formData, head_mvz: { id: e.id, title: e.title } })}
      defaultValue={formData.head_mvz.title || ''}
    />
   ) : (
    <span>{formData.head_mvz.title}</span>
   )}
    </CardPageSetting>
    <CardPageSetting title="Количество вакансий:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) || (isUserInAdmin && (currentStatus === 9 || currentStatus === 10)) ? (
        <Input
          id="number_of_vacancies"
          onChange={(e) => setFormData({ ...formData, number_of_vacancies: e.target.value })}
          defaultValue={formData.number_of_vacancies || ''}
        />
       ) : (
        <span>{formData.number_of_vacancies}</span>
       )}
    </CardPageSetting>

    <CardPageSetting title="Территориальная локация поиска:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="location"
          array={getData.location}
          onChoose={(e) => setFormData({ ...formData, location: e.title })}
          defaultValue={formData.location || ''}
        />
       ) : (
        <span>{formData.location}</span>
       )}
    </CardPageSetting>
    <CardPageSetting title="Срок закрытия вакансии:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="closing_date"
          array={getData.closing_date}
          onChoose={(e) => setFormData({ ...formData, closing_date: e.title })}
          defaultValue={formData.closing_date || ''}
        />
       ) : (
        <span>{formData.closing_date}</span>
       )}
    </CardPageSetting>
    </div>

  {/* Комментарий и Обоснование - растягиваем на всю ширину */}
  <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Комментарии по замене вакансии:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <TextArea
          placeholder=""
          value={formData.comments || ''}
          onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
        />
       ) : (
        <span>{formData.comments}</span>
       )}
    </CardPageSetting>
  </div>

  <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Обоснование открытия вакансии:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <TextArea
          placeholder=""
          value={formData.justification || ''}
          onChange={(e) => setFormData({ ...formData, justification: e.target.value })}
        />
       ) : (
        <span>{formData.justification}</span>
       )}
    </CardPageSetting>
  </div>

  {/* Проекты */}
  <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Проекты:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <TextArea
          placeholder=""
          value={formData.projects || ''}
          onChange={(e) => setFormData({ ...formData, projects: e.target.value })}
        />
       ) : (
        <span>{formData.comments}</span>
       )}
    </CardPageSetting>
  </div>
  {/* <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Проекты:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
        <Select
          id="projects"
          array={getData.projects}
          onChoose={handleChoose}
          defaultValue=""
        />
      )}
      {![1, 2, 4, 12, 13, 14].includes(currentStatus) && (
        <div>
          {formData.projects.length > 0 ? (
            <span>
              {formData.projects.map((project) => project.title).join(', ')}
            </span>
          ) : (
            <span> - </span>
          )}
        </div>
      )}
    </CardPageSetting>
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
      <CardPageSetting>
        <div style={{
          display: 'flex', flexWrap: 'wrap', gap: '8px', minHeight: '40px',
        }}
        >
          {formData.projects.map((project) => (
            <div key={project.id} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{project.title}</span>
              <Button
                className={smallCurtain}
                onClick={() => handleRemove(project.id)}
                style={{ marginLeft: '8px' }}
              >
                ✖
              </Button>
            </div>
          ))}
        </div>
      </CardPageSetting>
    )}
  </div> */}
        </div>
        <h3 style={{ color: '#0F1955' }}>Требования к кандидату</h3>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '5px',
          margin: '7px 0 20px 0',
          padding: '7px',
          backgroundColor: 'rgba(228, 241, 255, 0.5)', // Цвет фона (можно изменить на любой другой)
          borderRadius: '10px', // Скругление углов
        }}
        >
  {/* Первый столбец */}
  <div>

    <CardPageSetting title="Образование:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="education"
          array={getData.education}
          onChoose={(e) => setFormData({ ...formData, education: e.title })}
          defaultValue={formData.education || ''}
        />
       ) : (
        <span>{formData.education}</span>
       )}
    </CardPageSetting>

    <CardPageSetting title="Профиль образования:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="profile"
          array={getData.profile}
          onChoose={(e) => setFormData({ ...formData, profile: e.title })}
          defaultValue={formData.profile || ''}
        />
       ) : (
        <span>{formData.profile}</span>
       )}
    </CardPageSetting>

    <CardPageSetting title="Готовность к командировкам:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="business_trip"
          array={getData.business_trip}
          onChoose={(e) => setFormData({ ...formData, business_trip: e.title })}
          defaultValue={formData.business_trip || ''}
        />
      ) : (
        <span>{formData.business_trip}</span>
      )}
    </CardPageSetting>
  </div>

{/* Второй столбец */}
<div>
    <CardPageSetting title="Владение английским языком:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="language_level"
          array={getData.language_level}
          onChoose={(e) => setFormData({ ...formData, language_level: e.title })}
          defaultValue={formData.language_level || ''}
        />
       ) : (
        <span>{formData.language_level}</span>
       )}
    </CardPageSetting>

    <CardPageSetting title="Владение иным языком:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Input
          type="text"
          id="language_2"
          value={formData.language_2 || ''}
          onChange={(e) => setFormData({ ...formData, language_2: e.target.value })}
        />
       ) : (
        <span>{formData.language_2}</span>
       )}
    </CardPageSetting>

    <CardPageSetting title="Уровень владения:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <Select
          id="language_level_2"
          array={getData.language_level}
          onChoose={(e) => setFormData({ ...formData, language_level_2: e.title })}
          defaultValue={formData.language_level_2 || ''}
        />
       ) : (
        <span>{formData.language_level_2}</span>
       )}
    </CardPageSetting>
</div>

    <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Аттестаты и сертификаты:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
        <Select
          id="certificate"
          array={getData.certificates}
          onChoose={handleChooseCertificate}
          defaultValue=""
        />
      )}
      {![1, 2, 4, 12, 13, 14].includes(currentStatus) && (
        <div>
          {formData.certificates.length > 0 ? (
            <span>
              {formData.certificates.map((certificate) => certificate.title).join(', ')}
            </span>
          ) : (
            <span> - </span>
          )}
        </div>
      )}
    </CardPageSetting>

    {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
    <div style={{ gridColumn: '1 / -1' }}>
      <CardPageSetting>
        <div style={{
          display: 'flex', flexWrap: 'wrap', gap: '8px', minHeight: '40px',
        }}
        >
          {formData.certificates.map((certificate) => (
            <div key={certificate.id} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{certificate.title}</span>
              <Button
                className={smallCurtain}
                onClick={() => handleRemoveProgramm(certificate.id)}
                style={{ marginLeft: '8px' }}
              >
                ✖
              </Button>
            </div>
          ))}
        </div>
      </CardPageSetting>
    </div>
    )}

    <CardPageSetting title="ПК и спец. программы:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
        <Select
          id="programms"
          array={getData.programms}
          onChoose={handleChooseProgramm}
          defaultValue=""
        />
      )}
      {!([1, 2, 4, 12, 13, 14].includes(currentStatus)) && (
        <div>
          {formData.programms.length > 0 ? (
            <span>
              {formData.programms.map((programm) => programm.title).join(', ')}
            </span>
          ) : (
            <span> - </span>
          )}
        </div>
      )}
    </CardPageSetting>
    </div>

  {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
    <div style={{ gridColumn: '1 / -1' }}>
      <CardPageSetting>
        <div style={{
          display: 'flex', flexWrap: 'wrap', gap: '8px', minHeight: '40px',
        }}
        >
          {formData.programms.map((programm) => (
            <div key={programm.id} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{programm.title}</span>
              <Button
                className={smallCurtain}
                onClick={() => handleRemoveProgramm(programm.id)}
                style={{ marginLeft: '8px' }}
              >
                ✖
              </Button>
            </div>
          ))}
        </div>
      </CardPageSetting>
    </div>
  )}

  <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Проф. навыки, специализация:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
        <Select
          id="specialities"
          array={getData.specialities}
          onChoose={handleChooseSpeciality}
          defaultValue=""
        />
      )}
      {![1, 2, 4, 12, 13, 14].includes(currentStatus) && (
        <div>
          {formData.specialities.length > 0 ? (
            <span>
              {formData.specialities.map((speciality) => speciality.title).join(', ')}
            </span>
          ) : (
            <span> - </span>
          )}
        </div>
      )}
    </CardPageSetting>
  </div>

  {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
    <div style={{ gridColumn: '1 / -1' }}>
      <CardPageSetting>
        <div style={{
          display: 'flex', flexWrap: 'wrap', gap: '8px', minHeight: '40px',
        }}
        >
          {formData.specialities.map((speciality) => (
            <div key={speciality.id} style={{ display: 'flex', alignItems: 'center' }}>
              <span>{speciality.title}</span>
              <Button
                className={smallCurtain}
                onClick={() => handleRemoveSpeciality(speciality.id)}
                style={{ marginLeft: '8px' }}
              >
                ✖
              </Button>
            </div>
          ))}
        </div>
      </CardPageSetting>
    </div>
  )}

  {/* Элементы, которые занимают всю ширину */}
  <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Должностные обязанности сотрудника:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <TextArea
          placeholder=""
          value={formData.job_respons || ''}
          onChange={(e) => setFormData({ ...formData, job_respons: e.target.value })}
        />
       ) : (
        <span>{formData.job_respons}</span>
       )}
    </CardPageSetting>
  </div>

  <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Компетенции:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <TextArea
          placeholder=""
          value={formData.qualifications || ''}
          onChange={(e) => setFormData({ ...formData, qualifications: e.target.value })}
        />
       ) : (
        <span>{formData.qualifications}</span>
       )}
    </CardPageSetting>
  </div>

  <div style={{ gridColumn: '1 / -1' }}>
    <CardPageSetting title="Дополнительные квалификационные требования:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <TextArea
          placeholder=""
          value={formData.dop_qualifications || ''}
          onChange={(e) => setFormData({ ...formData, dop_qualifications: e.target.value })}
        />
       ) : (
        <span>{formData.dop_qualifications}</span>
       )}
    </CardPageSetting>
  </div>
        </div>

    <h3 style={{ color: '#0F1955' }}>Условия труда</h3>
    <div style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '5px',
      margin: '7px 0 20px 0',
      padding: '7px',
      backgroundColor: 'rgba(228, 241, 255, 0.5)', // Цвет фона (можно изменить на любой другой)
      borderRadius: '10px', // Скругление углов
    }}
    >
        <div>
      <CardPageSetting title="Планируемая продолжительность ТД:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="contracts"
        array={getData.contracts}
        onChoose={(e) => setFormData({ ...formData, contracts: e.title })}
        defaultValue={formData.contracts || ''}
      />
       ) : (
      <span>{formData.contracts}</span>
       )}
      </CardPageSetting>

      <CardPageSetting title="Вид отношений с работником:">
       {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="types"
        array={getData.types}
        onChoose={(e) => setFormData({ ...formData, types: e.title })}
        defaultValue={formData.types || ''}
      />
       ) : (
      <span>{formData.types}</span>
       )}
      </CardPageSetting>
        </div>
      <div>
<CardPageSetting title="Режим работы:">
 {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
<Select
  id="mode"
  array={getData.mode}
  onChoose={(e) => setFormData({ ...formData, mode: e.title })}
  defaultValue={formData.mode || ''}
/>
 ) : (
    <span>{formData.mode}</span>
 )}
</CardPageSetting>

<CardPageSetting title="Регион постоянного проживания кандидата:">
 {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
<Input
  id="types"
  onChange={(e) => setFormData({ ...formData, region: e.target.value })}
  defaultValue={formData.region || ''}
/>
 ) : (
  <span>{formData.region}</span>
 )}
</CardPageSetting>
      </div>

      <div style={{ gridColumn: '1 / -1' }}>
      {formData.contracts === 'срочный договор (СТД)' && ( // Проверяем, выбран ли "срочный договор"
        <CardPageSetting title="Основание срочности:">
            {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
          <TextArea
            placeholder="Укажите основание срочности"
            value={formData.reason}
            onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
          />
            ) : (
          <span>{formData.reason}</span>
            )}
        </CardPageSetting>
      )}

      </div>

    </div>
    <h3 style={{ color: 'red' }}>Согласование</h3>
<div style={{
  margin: '7px 0 20px 0',
  padding: '7px',
  backgroundColor: 'rgba(228, 241, 255, 0.5)',
  borderRadius: '10px',
}}
>
{currentStatus === 2 && userID === formData.head_mvz.id && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий руководителя МВЗ:" invalid={!formData.r_mvz_comments}>
<TextArea
  placeholder=""
  value={formData.r_mvz_comments || ''}
  onChange={(e) => setFormData({ ...formData, r_mvz_comments: e.target.value })}
/>
</CardPageSetting>
                                                            </div>)}
                         {(currentStatus > 2) && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий руководителя МВЗ:">
{formData.r_mvz_comments || ''}
</CardPageSetting>
                                                  </div>)}

{currentStatus === 3 && isUserInOrp && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Данные о загрузке ФАКТ:">
<Input
  placeholder=""
  value={formData.data_FACT || ''}
  onChange={(e) => setFormData({ ...formData, data_FACT: e.target.value })}
/>
</CardPageSetting>

<CardPageSetting title="Данные о загрузке ПЛАН:">
<Input
  placeholder=""
  value={formData.data_PLAN || ''}
  onChange={(e) => setFormData({ ...formData, data_PLAN: e.target.value })}
/>
</CardPageSetting>

<CardPageSetting title="Комментарий ОРП:" invalid={!formData.rp_comments}>
<TextArea
  placeholder=""
  value={formData.rp_comments || ''}
  onChange={(e) => setFormData({ ...formData, rp_comments: e.target.value })}
/>
</CardPageSetting>
                                        </div>)}
{currentStatus > 3 && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Данные о загрузке ФАКТ:">
{formData.data_FACT || ''}
</CardPageSetting>

<CardPageSetting title="Данные о загрузке ПЛАН:">
{formData.data_PLAN || ''}
</CardPageSetting>
<CardPageSetting title="Комментарий ОРП:">
{formData.rp_comments || ''}
</CardPageSetting>
                       </div>)}

{currentStatus === 6 && isUserInFeo && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий ФЭО:" invalid={!formData.feo_comments}>
<TextArea
  placeholder=""
  value={formData.feo_comments || ''}
  onChange={(e) => setFormData({ ...formData, feo_comments: e.target.value })}
/>
</CardPageSetting>
                                        </div>)}
                         {(currentStatus > 6 || currentStatus === 4) && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий ФЭО:">
{formData.feo_comments || ''}
</CardPageSetting>
                                                                         </div>)}
{currentStatus === 7 && isUserInZgd && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий ЗГД:" invalid={!formData.zgd_comments}>
<TextArea
  placeholder=""
  value={formData.zgd_comments || ''}
  onChange={(e) => setFormData({ ...formData, zgd_comments: e.target.value })}
/>
</CardPageSetting>

                                        </div>)}
{currentStatus === 7 && isUserInZgd && ['А0', 'А1/С1', 'А2/С2', 'А3/С3'].includes(formData.job_level) && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Необходимо согласование ГД:">
<CheckBox
  checked={formData.needGd}
  onClick={() => setFormData((prevFormData) => ({
    ...prevFormData,
    needGd: !prevFormData.needGd, // Переключаем значение
  }))}
/>

</CardPageSetting>
                                                                                                          </div>)}
{currentStatus > 7 && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий ЗГД:">
<span>{formData.zgd_comments}</span>
</CardPageSetting>

                       </div>)}
{currentStatus === 8 && isUserInGd && (['А4', 'А5', 'А6', 'А7'].includes(formData.job_level) || formData.needGd === true) && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий ГД:">
<TextArea
  placeholder=""
  value={formData.gd_comments || ''}
  onChange={(e) => setFormData({ ...formData, gd_comments: e.target.value })}
/>
</CardPageSetting>
                                                                                                                              </div>)}
{currentStatus > 8 && (<div style={{ gridColumn: 'span 2' }}>
<CardPageSetting title="Комментарий ГД:">
<span>{formData.gd_comments}</span>
</CardPageSetting>

                       </div>)}
{currentStatus === 9 && isUserInAdmin && (<div style={{ gridColumn: 'span 2' }}>
    <CardPageSetting title="Выберите исполнителя:" invalid={!formData.performer}>
  <Select
    id="performers"
    array={getData.performers}
    onChoose={(e) => {
      setFormData({ ...formData, performer: { title: e.title, id: e.id } });
    }}
    defaultValue={formData.performer ? formData.performer.title : ''} // Проверка на наличие performer
  />
    </CardPageSetting>

                                          </div>)}

{currentStatus < 12 && currentStatus > 9 && (<div style={{ gridColumn: 'span 2' }}>
 <CardPageSetting title="Исполнитель:">
                      {formData.performer.title || ''}
 </CardPageSetting>
                                             </div>)}

                                             {currentStatus === 10 && (<div style={{ gridColumn: 'span 2' }}>
 <CardPageSetting title="ФИО нового сотрудника(ов):" invalid={!formData.fio_new_employee}>
                         <Input
                           id="mode"
                           onChange={(e) => setFormData({ ...formData, fio_new_employee: e.target.value })}
                           defaultValue={formData.fio_new_employee || ''}
                           style={{ maxWidth: '600px', minWidth: '300px' }}
                         />

 </CardPageSetting>
                                                                       </div>)}

{currentStatus < 12 && currentStatus > 10 && (<div style={{ gridColumn: 'span 2' }}>
 <CardPageSetting title="ФИО нового сотрудника:">
                      {formData.fio_new_employee || ''}
 </CardPageSetting>
                                              </div>)}

</div>

        <div className="ticket__footer">

          {/* {(isUserInAdmin && currentStatus !== 11) && (
            <Button onClick={() => sendStatus(currentStatus)}>
              Внести изменения
            </Button>
          )} */}
          {currentStatus === 2 && userID === formData.head_mvz.id && (
          <>
            <Button onClick={() => sendStatus(3)} className={`${smallButtonClass} buttonGreen`}>
              Согласовать
            </Button>
             <Button onClick={() => sendStatus(13)}>
             Вернуть
             </Button>
            <Button onClick={() => sendStatus(5)} className={`${smallButtonClass} buttonOrange`}>
           Отказать
            </Button>
          </>
          )}

{currentStatus === 3 && isUserInOrp && (
     <>
            <Button onClick={() => sendStatus(6)}>
              Сохранить
            </Button>

<Button onClick={() => sendStatus(14)}>
Вернуть
</Button>
     </>
)}

{currentStatus === 6 && isUserInFeo && (
          <>
            <Button onClick={() => sendStatus(7)} className={`${smallButtonClass} buttonGreen`}>
              Согласовать
            </Button>
             <Button onClick={() => sendStatus(4)}>
             Вернуть
             </Button>
          </>
)}

{currentStatus === 7 && isUserInZgd && (
  <>
    <Button
      onClick={() => sendStatus((['А4', 'А5', 'А6', 'А7'].includes(formData.job_level) || formData.needGd === true) ? 8 : 9)}
      className={`${smallButtonClass} buttonGreen`}
    >
      Согласовать
    </Button>
    <Button onClick={() => sendStatus(12)}>
      Вернуть
    </Button>
  </>
)}

{/* {currentStatus === 4 && isUserInFeo && (
            <Button onClick={() => sendStatus(6)}>
              Сохранить
            </Button>
)}

{currentStatus === 12 && isUserInZgd && (
            <Button onClick={() => sendStatus(7)}>
              Сохранить
            </Button>
)} */}

{/* {currentStatus === 13 && userID === formData.head_mvz.id && (
            <Button onClick={() => sendStatus(2)}>
              Сохранить
            </Button>
)} */}

{(currentStatus === 14 || currentStatus === 13 || currentStatus === 12 || currentStatus === 4) && userID === currentInitiator && (
            <Button onClick={() => sendStatus(2)}>
              Сохранить
            </Button>
)}

{currentStatus === 8 && isUserInGd && (
          <>
           <Button onClick={() => sendStatus(9)} className={`${smallButtonClass} buttonGreen`}>
              Утвердить
           </Button>
            <Button onClick={() => sendStatus(5)} className={`${smallButtonClass} buttonOrange`}>
             Отказать
            </Button>
          </>
)}
{currentStatus === 9 && isUserInAdmin && (
           <Button onClick={() => sendStatus(10)}>
              Сохранить
           </Button>
)}

{currentStatus === 10 && (isUserInAdmin || formData.performer.id === userID) && (
           <Button onClick={() => sendStatus(11)}>
             Выполнено
           </Button>
)}

{currentStatus === 11 && (isUserInAdmin || formData.performer.id === userID) && (
            <Button onClick={() => createHRForm()} className="button_long">
             Сформировать распоряжение
            </Button>
)}
        </div>

            </div>
        </ServiceBody>
    );
  }
  return <Cap />;
}

export default RecrutmentCard;
