const testTemplate = [
  {
    parentId: 0,
    id: 1,
    section: 'А',
    title: 'Планирование',
    type: 'folder',
  },
  {
    parentId: 0,
    id: 2,
    section: 'Б',
    title: 'Аудиторские процедуры на уровне предпосылок',
    type: 'folder',
  },
  {
    parentId: 0,
    id: 3,
    section: 'В',
    title: 'Общие аудиторские процедуры',
    type: 'folder',
  },
  {
    parentId: 0,
    id: 4,
    section: 'Г',
    title: 'Завершение аудита',
    type: 'folder',
  },
  {
    parentId: 1,
    id: 5,
    title: 'Начальная стадия проекта',
    type: 'subfolder',
    section: 'А.1',
  },
  {
    parentId: 5,
    id: 6,
    title: 'Задача 1',
    type: 'task',
    section: 'А.1.1',
    visibility: true,
    in_duty: 'Без ответственного',
    status: 'Закрыта',
  },
  {
    parentId: 2,
    id: 7,
    title: 'Подпапка 1',
    type: 'subfolder',
    section: 'Б.1',
    visibility: true,
  },
  {
    parentId: 7,
    id: 8,
    title: 'Задача Б1',
    type: 'task',
    section: 'Б.2',
    visibility: true,
    in_duty: 'Без ответственного',
    status: 'Закрыта',
  },
  {
    parentId: 6,
    id: 9,
    title: 'Подзадача 1.1',
    type: 'task',
    section: 'А.1.1.1',
    visibility: true,
    in_duty: 'Без ответственного',
    status: 'Закрыта',
  },
];
