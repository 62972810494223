import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import TBody from '../../components/Table/TBody';
import TFoot from '../../components/Table/TFoot';
import { clearHtmlString } from '../../utils/functions/stringHandling';
import VacationCard from './VacationCard';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Select from '../../components/UI/Select';

function AdminPage() {
  const [openedVacation, setOpenedVacation] = useState({}); // уведомление открытое в карточке
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const headers = [
    { id: 1, title: 'ФИО', field: 'name' },
    { id: 2, title: 'Должность', field: 'position' },
    { id: 3, title: 'Доступные дни', field: 'days' },
  ];
  const [vacations, setVacations] = useState([]);
  const [action, setAction] = useState();
  const [filterYears, setFilterYears] = useState([]);
  const current_year = new Date().getFullYear();
  const [currentYear, setCurrentYear] = useState(current_year);

  useEffect(() => {
    getVacations();
    getFilterYears();
  }, [currentYear]);

  // Получить года отпусков для фильтра
  async function getFilterYears() {
    try {
      const reqData = {
        type: 'getFilterYears',
      };
      const result = await clientSendData('POST', '/get_filter_years', reqData); // запрос в БД
      if (result.data) {
        setFilterYears(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getFilterYears'); // обработка ошибок
    }
  }

  function openCard(object, type) {
    setOpenedVacation(object);
    setAction(() => type);
    setShowCard(true);
  }
  async function getVacations() {
    try {
      const reqData = {
        type: 'getVacationsAdmin',
        currentYear,
      };
      const result = await clientSendData('POST', '/get_vacations_admin', reqData); // запрос в БД
      if (result.data) {
        setVacations(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getVacationsAdmin'); // обработка ошибок
    }
  }

  if (showCard) {
    return (
      <VacationCard
        openedVacation={openedVacation}
        setOpenedVacation={setOpenedVacation}
        setShow={setShowCard}
        setVacations={setVacations}
        vacations={vacations}
        getVacations={getVacations}
        actionType={action}
      />
    );
  }
  return (
    <ServiceBody id="admin__page">
      {/* <div id="filter">
      <Select array={filterYears} defaultValue={currentYear} onChoose={(e) => setCurrentYear(e.title)} />
      </div> */}
      <div className="page_header">
      <Select array={filterYears} defaultValue={currentYear} onChoose={(e) => setCurrentYear(e.title)} id="filter" />
      <div className="spare-days">
        {' '}
        <p className="count" />
      </div>
      </div>
    <Table>
    <THead>
        <TRow>
          {headers.map((item) => <TData key={item.id}>{item.title}</TData>)}
          <TData />
        </TRow>
    </THead>
    <TBody>
    {vacations.map((row) => (
          <TRow key={row.user_id}>
            {headers.map((column) => {
              const { id, field } = column; // дектруктуризация объекта колонки
              const value = row?.[field];
              return (
                <TData key={id}>
                  {field !== 'content' ? value : clearHtmlString(value)}
                </TData>
              );
            })}
            <TData onClick={() => openCard(row, 'edit')}>
              {/* <img src="../../icons/file-text.svg" alt="file" /> */}
              Редактировать
            </TData>
          </TRow>
    ))}
    </TBody>
      <TFoot>
        <TRow>
          <TData onClick={() => openCard({ user_id: 'new' }, 'create')}>Создать</TData>
        </TRow>
      </TFoot>
    </Table>
    </ServiceBody>
  );
}

export default AdminPage;
