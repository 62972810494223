// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Позиция подсказки */
  left: 50%;
  margin-left: 1px; /* Центрирование подсказки */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; /* Показываем подсказку при наведении */
}`, "",{"version":3,"sources":["webpack://./src/pages/StaffCV/staffcv.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,kBAAA;EACA,qBAAA;AAEF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA,EAAA,sBAAA;EACA,SAAA;EACA,gBAAA,EAAA,4BAAA;EACA,UAAA;EACA,wBAAA;AAEF;;AACA;EACE,mBAAA;EACA,UAAA,EAAA,uCAAA;AAEF","sourcesContent":[".tooltip {\n  position: relative;\n  display: inline-block;\n}\n\n.tooltip .tooltiptext {\n  visibility: hidden;\n  width: 200px;\n  background-color: black;\n  color: #fff;\n  text-align: center;\n  border-radius: 5px;\n  padding: 5px;\n  position: absolute;\n  z-index: 1;\n  bottom: 125%; /* Позиция подсказки */\n  left: 50%;\n  margin-left: 1px; /* Центрирование подсказки */\n  opacity: 0;\n  transition: opacity 0.3s;\n}\n\n.tooltip:hover .tooltiptext {\n  visibility: visible;\n  opacity: 1; /* Показываем подсказку при наведении */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
