// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#adminTVPanel {
  max-height: 88%;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminTVPanel/admintvpanel.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,cAAA;AAAJ","sourcesContent":["\n#adminTVPanel {\n    max-height: 88%;\n    overflow: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
