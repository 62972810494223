/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/ru';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import CardBody from '../../components/Card/CardBody';
import Loader from '../../components/UI/Loader';
import CardFooter from '../../components/Card/CardFooter';
import CardSetting from '../../components/Card/CardSetting';
import IconButton from '../../components/UI/Button/IconButton';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import Toggle from '../../components/UI/Toggle';

/**
 * @component База данных подписчиков сайта Юникон - карточка подписчика - вкладка "Основная"
 * @prop {emailId} object - email подписчика
 * @prop {refreshTable} function - обновление данных в таблице
 * @prop {closeHandler} function - закрытие карточки
 */

function CRMCardMain(props) {
  const {
    emailId, handler, closeHandler, refreshTable,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const isNewSubscriber = emailId === 'new';
  const [dataSubscriber, setDataSubscriber] = useState({
    data: {
      msfo: 0,
      news: 0,
      rsbu: 0,
      obzor: 0,
      unsubscribed: 0,
      blocked: 0,
      name: '',
      position: '',
      company: '',
      partner: '',
      where: '',
      comments: '',
    },
    email: '',
  });
  useEffect(() => {
    if (!isNewSubscriber) {
      getSubscriberData();
    }
  }, [isNewSubscriber]);

  async function getSubscriberData() {
    try {
      const reqData = {
        type: 'getDataCRMSubscriber',
        email: emailId,
      };
      const result = await clientSendData('POST', '/get_data_crm_subscriber', reqData);
      if (result) {
        setDataSubscriber(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getDataCRM');
    }
  }

  // Сохранение данных
  async function saveSubscriber(editDataSubscriber) {
    // Подготовка данных для сохранения
    const preparedData = {
      ...editDataSubscriber,
      data: {
        ...editDataSubscriber.data,
        msfo: editDataSubscriber.data.msfo === 1 ? 1 : 0,
        news: editDataSubscriber.data.news === 1 ? 1 : 0,
        rsbu: editDataSubscriber.data.rsbu === 1 ? 1 : 0,
        obzor: editDataSubscriber.data.obzor === 1 ? 1 : 0,
        unsubscribed: editDataSubscriber.data.unsubscribed === 1 ? 1 : 0,
        blocked: editDataSubscriber.data.blocked === 1 ? 1 : 0,
      },
    };
    try {
      setIsLoading(true);
      const reqData = {
        type: 'editDataCRMSubscriber', editDataSubscriber: preparedData,
      };
      const result = await clientSendData('POST', '/edit_data_crm_subscriber', reqData);
      if (result?.success) {
        await alertFunction('resume_edit_success', 'clientPost');
        // handler();
        await refreshTable();
      }
    } catch (error) {
      catchHandler(error, 'editDataCRMSubscriber');
    } finally {
      setIsLoading(false);
    }
  }

  const switchOnUnsibscribe = () => {
    setDataSubscriber((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        unsubscribed: prevState.data.unsubscribed === 1 ? 0 : 1, // Переключаем значение отписки
      },
    }));
  };

  const switchOnBlocked = () => {
    setDataSubscriber((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        blocked: prevState.data.blocked === 1 ? 0 : 1, // Переключаем значение блокировки
      },
    }));
  };

  const toggleSetting = (key) => {
    setDataSubscriber((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: prevState.data[key] === 1 ? 0 : 1, // Переключаем значение
      },
    }));
  };

  async function deleteSubscriber(deleteDataSubscriber) {
    try {
      setIsLoading(true);
      const reqData = {
        type: 'deleteDataCRMSubscriber',
        deleteDataSubscriber,
      };
      const result = await clientSendData('POST', '/delete_data_crm_subscriber', reqData);
      if (result) {
        await alertFunction('resume_edit_success', 'clientPost');
        closeHandler(); // Закрываем карточку после успешного удаления
        await refreshTable();
      }
    } catch (error) {
      catchHandler(error, 'deleteDataCRMSubscriber');
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <Loader />;

  return (
    <>
    <CardBody>

  <CardSetting title="ФИО">
    <Input
      type="text"
      onChange={(e) => setDataSubscriber((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          name: e.target.value,
        },
      }))}
      value={dataSubscriber?.data?.name || ''}
    />
  </CardSetting>

  <CardSetting title="Должность">
    <Input
      type="text"
      onChange={(e) => setDataSubscriber((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          position: e.target.value,
        },
      }))}
      value={dataSubscriber?.data?.position || ''}
    />
  </CardSetting>

  <CardSetting title="Компания">
    <Input
      type="text"
      onChange={(e) => setDataSubscriber((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          company: e.target.value,
        },
      }))}
      value={dataSubscriber?.data?.company || ''}
    />
  </CardSetting>
  <CardSetting title="Адрес электронной почты" invalid={!dataSubscriber?.email}>
    <Input
      type="text"
      onChange={(e) => setDataSubscriber((prevState) => ({ ...prevState, email: e.target.value }))}
      value={dataSubscriber?.email || ''}
    />
  </CardSetting>
  <CardSetting title="МСФО">
        <Toggle
          state={String(Boolean(dataSubscriber?.data?.msfo === 1))} // Приводим к строке
          onClick={() => toggleSetting('msfo')}
        />
  </CardSetting>

      <CardSetting title="Новости">
        <Toggle
          state={String(Boolean(dataSubscriber?.data?.news === 1))} // Приводим к строке
          onClick={() => toggleSetting('news')}
        />
      </CardSetting>

      <CardSetting title="РСБУ">
        <Toggle
          state={String(Boolean(dataSubscriber?.data?.rsbu === 1))} // Приводим к строке
          onClick={() => toggleSetting('rsbu')}
        />
      </CardSetting>

      <CardSetting title="Обзор изменений законодательства">
        <Toggle
          state={String(Boolean(dataSubscriber?.data?.obzor === 1))} // Приводим к строке
          onClick={() => toggleSetting('obzor')}
        />
      </CardSetting>

  <CardSetting title="Партнер">
    <Input
      type="text"
      onChange={(e) => setDataSubscriber((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          partner: e.target.value,
        },
      }))}
      value={dataSubscriber?.data?.partner || ''}
    />
  </CardSetting>

  <CardSetting title="Откуда пришел">
    <Input
      type="text"
      onChange={(e) => setDataSubscriber((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          where: e.target.value,
        },
      }))}
      value={dataSubscriber?.data?.where || ''}
    />
  </CardSetting>

  <CardSetting title="Комментарий">
    <Input
      type="text"
      onChange={(e) => setDataSubscriber((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          comments: e.target.value,
        },
      }))}
      value={dataSubscriber?.data?.comments || ''}
    />
  </CardSetting>

  <CardSetting title="Отписать">
          <Toggle
            state={String(dataSubscriber?.data?.unsubscribed === 1)} // Приводим к строке
            onClick={switchOnUnsibscribe}
          />
  </CardSetting>

        <CardSetting title="Заблокировать">
          <Toggle
            state={String(dataSubscriber?.data?.blocked === 1)} // Приводим к строке
            onClick={switchOnBlocked}
          />
        </CardSetting>

  {/* Удаление подписчика */}

    <CardSetting title="Удалить подписчика">
      <IconButton onClick={() => deleteSubscriber(dataSubscriber)} icon="delete" theme="red" />
    </CardSetting>

    </CardBody>
      <CardFooter>
        <Button onClick={() => saveSubscriber(dataSubscriber)}>Сохранить</Button>
        <Button onClick={closeHandler}>Закрыть</Button>
      </CardFooter>
    </>
  );
}

export default CRMCardMain;
