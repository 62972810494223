import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AdminPage from './AdminPage';
import VacationPage from './VacationPage';
import { authorization } from '../../utils/functions/authenticate';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import './vacation.scss';

function VacationService() {
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [pages, setPages] = useState([]); // страницы доступные пользователю
  const components = { // Компоненты для отображения в сервисе
    admin_page: <AdminPage />, // Интерфейс администратора
    vacation_page: <VacationPage />, // Интерфейс отпусков
  };

  useEffect(() => {
    awaitAuthorize();
  }, []);

  async function awaitAuthorize() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    if (checkAuthorization) await getVacationInterfaces(); // получить интерфейсы пользователя
    setIsLoading(false);
  }

  // Получить интерфейсы доступные пользователю
  async function getVacationInterfaces() {
    try {
      const reqData = {
        type: 'getVacationInterfaces',
        location: window.location.pathname,
      };
      const result = await clientSendData('POST', '/get_vacation_interfaces', reqData);
      if (result.success) {
        const userPages = []; // Пустой массив для страниц
        result.data.forEach((item) => {
          const component = item?.component; // Имя компонента
          let active = false; // параметр
          // Проверка параметра url
          // Это нужно для того, чтобы при переходе по прямой ссылке на карточку заявки/актива/пользователя
          // автоматически выбирался компонент, которых рендерит эту карточку
          // Параметр active обрабатывается внутри компонента Service

          //  if (searchParams?.get('userticket') && item.component === 'user') active = true;
          if (item.component === 'admin_page') active = true;
          if (item.component === 'vacation_page') active = false;
          if (components[component]) { // Проверяем, есть ли такой компонент в объекте 'components'
            // Если компонент существует, добавляем страницу с этим компонентом
            userPages.push({
              id: component, // идентификатор страницы
              title: item.title, // заголовок страницы
              component: components[component], // соответствующий компонент
              component_name: component, // имя компонента
              active, // выбор этой страницы по умолчанию
            });
          }
        });
        setPages(userPages); // Результат записывается в состояние
        // Если получены параметры, записать их в Redux
      //  if (result.parameters) createAction('SET_SD_PARAMETERS', result.parameters);
      }
    } catch (error) {
      catchHandler(error, 'getVacationInterfaces');
    }
  }

  if (isAuthorized) { // если пользователь авторизован
    // если пользователю не доступен ни один интерфейс - заглушка
    if (pages.length === 0) {
      return (
        <div className="upu-service_padding-top">
          <h2 className="upu-service__title">Доступные интерфейсы отсутствуют</h2>
        </div>
      );
    }
    // иначе показываем то, что доступно пользователю
    return <Service id="vacation_service" pages={pages} horizontal />;
  } return <Cap />; // если пользователь не авторизован - показать заглушку
}

export default VacationService;
