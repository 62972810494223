import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import Service from '../../../components/Service/Service';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ServiceBody from '../../../components/Service/ServiceBody';
import Button from '../../../components/UI/Button/Button';
import CardSetting from '../../../components/Card/CardSetting';
import Select from '../../../components/UI/Select';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import ServicePanel from '../../../components/Service/ServicePanel';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import Input from '../../../components/UI/Input';

export default function FinalAppCard(props) {
  const { employee } = props;
  const [userInfo, setUserInfo] = useState();
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [employeeEvals, setEmployeeEvals] = useState([]);
  const [total, setTotal] = useState(0);
  const [extraInfo, setExtraInfo] = useState(false);
  const [cascadeChar, setCascadeChar] = useState('Оценка');
  const [cascadeSolution, setCascadeSolution] = useState(''); // решение по каскаду выбранное из списка
  const [cascadeComment, setCascadeComment] = useState(''); // комментарий к решению по каскаду
  const [recommendation, setRecommendation] = useState(''); // рекоммендация

  const headers = [ // заголовки
    { id: 1, title: 'Проект', field: 'project_title' },
    { id: 2, title: 'Роль', field: 'user_role' },
    { id: 3, title: 'Средняя оценка', field: 'avg_mark' },
    { id: 4, title: 'Итоговая оценка', field: 'final_mark' },
  ];
  const defaultTableState = {
    title: 'Финальные оценки',
    page: 1,
    limit: 5, // количество строк в таблице
    // switch_data: {
    //   current: 'active',
    //   titles: {
    //     all: 'Все',
    //     active: 'Активные',
    //   },
    // },
    button_place: 'header',
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const cascadeSolutions = [
    { id: 1, title: 'Повысить в грейде/должности' },
    { id: 2, title: 'Оставить в занимаемой должности ' },
    { id: 3, title: 'Рассмотреть вопрос о расставании ' },
    { id: 4, title: 'Рассмотреть в промежуточную аттестацию' },
    { id: 5, title: 'Условный промоушен' },
    { id: 6, title: 'Не оценивается' },
    { id: 7, title: 'Понизить в грейде/должности' },
  ];

  const cascadeChars = [
    { id: 1, title: 'A' },
    { id: 2, title: 'B' },
    { id: 3, title: 'C' },
    { id: 4, title: 'D' },
  ];

  useEffect(() => {
    getFinalEvalInfo();
    getEmployeeEvals();
  }, []);

  // Определить отображаемое значение
  const defineValue = (key) => {
    if (userInfo[key]) {
      const data = userInfo[key];
      if (key === 'experience') {
        return `${data?.years || 0}лет ${data?.months || 0}мес.`;
      } return data;
    }
    return '-';
  };

  // Получить инф. о сотруднике
  async function getFinalEvalInfo() {
    try {
      const reqData = {
        type: 'getFinalEvalInfo',
        employee,
      };
      const result = await clientSendData('POST', '/get_finalEval_info', reqData); // запрос в БД
      if (result) {
        setEmployeeEvals(result);
        setTotal(result.length);
      }
    } catch (error) {
      catchHandler(error, 'getProjectMasters'); // обработка ошибок
    }
  }

  // Получить инф. об оценках сотрудника
  async function getEmployeeEvals() {
    try {
      const reqData = {
        type: 'getEmployeeEvals',
        employee,
      };
      const result = await clientSendData('POST', '/get_employee_evals', reqData); // запрос в БД
      if (result) {
        setUserInfo(result[0]);
      }
    } catch (error) {
      catchHandler(error, 'getEmployeeEvals'); // обработка ошибок
    }
  }

  function onBackClick() {
    setSearchParams('');
  }

  // Открыть заявление
  function openApplication(id) {
    const search = { app: id, finalApp: true };
    setSearchParams(search);
  }

  return (
    <Service id="final_evaluation">
        <ServiceBody horizontal>

        <div
          className="button-container"
          style={{
            display: 'flex', width: '100%', flexFlow: 'column', marginTop: '15px',
          }}
        >
 <Button onClick={onBackClick} className="text-button" style={{ marginLeft: '10px' }}>
 <div className="evl">
          <span className="arrow">←</span>
          Вернуться к оценкам
 </div>
 </Button>
 <ServicePanel>
            <Button style={{ marginBottom: '10px' }}>Сохранить</Button>
            <Button>Редактировать</Button>
 </ServicePanel>
        </div>

  <div className="container">
    <div className="period">
       <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Период:</p>
       <Select id="period" />
    </div>

    <div className="user_info">
        <div>
        <CardSetting title="ФИО:">
          <span className="userName">
{userInfo?.employee_title}
<span className="reference_info" onPointerEnter={() => setExtraInfo(true)} onPointerLeave={() => setExtraInfo(false)}>
        <div className="question_mark">
?
{extraInfo && <div className="reference">

<p className="help-title">Подразделение: </p>
<span>
{userInfo?.dept}
</span>
<p className="help-title">Стаж: </p>
<span>
{defineValue('experience')}
</span>
<p className="help-title">Опыт в текущей должности: </p>
<span>
1 год 2 месяца
</span>
<p className="help-title">ФИО ментора: </p>
<span>
{userInfo.mentor_title}
</span>
<p className="help-title">Грейд ментора: </p>
<span>
{userInfo.mentor_grade}
</span>
<p className="help-title">Утилизация (ч): </p>
<span>
{userInfo.total}
</span>

              </div>}
        </div>
</span>
          </span>
        </CardSetting>
        <CardSetting title="Должность:">{userInfo?.job_title}</CardSetting>
        <CardSetting title="Грейд:">{userInfo?.grade}</CardSetting>
       {/* <CardSetting title="Подразделение:">{userInfo?.dept}</CardSetting> */}
        <CardSetting title="МВЗ:">{userInfo?.mvz}</CardSetting>
        </div>
        <div>
        <CardSetting title="Средняя утилизация:">77%</CardSetting>
        <CardSetting title="Оценка 'снизу-вверх' ментор:">B</CardSetting>
        <CardSetting title="Оценка 'снизу-вверх' руководитель:">B</CardSetting>
        <CardSetting title="Усредненная оценка по компетенциям:">7,64</CardSetting>
        </div>
    </div>

<Table id="employee_final-evals">
<THead
  headers={headers}
  array={employeeEvals}
  setArray={setEmployeeEvals}
/>
<TBody>
          {employeeEvals.length === 0

            ? <TRow>
                    <TData>Результаты отсутствуют</TData>
                    <TData />
                    <TData />
                    <TData />
                    <TData />
              </TRow>
            // Если результаты есть
            : employeeEvals.map((row) => {
              const {
                id, finalApp,
              } = row;
              return (
                        <TRow key={id}>
                            {headers.map((item) => {
                              if (item.field !== 'project_title') {
                                return (
                                  <TData key={item.id}>
                                  {row[item.field] ? row[item.field] : '-'}
                                  </TData>
                                );
                              }
                              return (
                                    <TData key={item.id} onClick={() => openApplication(id, finalApp)}>
                                     <p style={{ fontWeight: '500' }}>{row[item.field] ? row[item.field] : '-'}</p>
                                    </TData>
                              );
                            })}
                        </TRow>
              );
            })}
</TBody>
</Table>
<span className="decision-rec">
        <div className="decision">
        {/* <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Решение по результатам каскада:</p> */}
        <Select
          array={cascadeSolutions}
          id="decision"
          defaultValue="Решение по результатам каскада"
          onChoose={(e) => setCascadeSolution({ id: e.id, title: e.title })}
        />
        {[5, 6].includes(cascadeSolution.id)
        && <textarea
          className="comment"
          placeholder="Обоснование решения"
          onChange={(e) => setCascadeComment(e.target.value)}
        /> }
        <div className="cascade-mark">
       <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Оценка по итогам каскада:</p>
        <Select id="cascade" array={cascadeChars} onChoose={(e) => setCascadeChar(e.title)} defaultValue={cascadeChar} />
        </div>
        </div>

        <div className="recommendation">
        {/* <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Рекомендация:</p> */}
        <textarea className="comment" placeholder="Рекомендация" onChange={(e) => setRecommendation(e.target.value)} />
        </div>
</span>
{/* <div className="cascade-mark">
       <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Оценка по итогам каскада:</p>
       <Select id="cascade" array={cascadeChars} onChoose={(e) => setCascadeChar(e.title)} defaultValue={cascadeChar} />
</div> */}

  </div>
        </ServiceBody>
    </Service>
  );
}
