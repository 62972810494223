import React, { useEffect, useState } from 'react';
import {
  Document, AlignmentType, Packer, Table, TableCell, TableRow, WidthType, Paragraph, TextRun, TableBorders, BorderStyle, convertInchesToTwip, Header,
} from 'docx';
import { useNavigate } from 'react-router-dom';
import ServiceBody from '../../components/Service/ServiceBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction'; // Импортируем alertFunction
import CV from './CV'; // Import the CV component
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import Button from '../../components/UI/Button/Button';

const defaultTableState = {
  title: 'Резюме сотрудников',
  page: 1,
  limit: 50, // количество строк в таблице
};

function StaffCV() {
  const tableId = 'staff-cv';
  const [isLoading, setIsLoading] = useState(false);
  const [dataCV, setDataCV] = useState([]);
  const [fileInputVisible, setFileInputVisible] = useState(false);
  const [selectedCV, setSelectedCV] = useState({});
  const navigate = useNavigate();
  const [showCard, setShowCard] = useState(false);
  const [total, setTotal] = useState(0);

  const orderedKeys = [
    'Ф.И.О.',
    'Занимаемая должность',
    'Стаж работы в компании',
    'Дата рождения',
    'Адрес электронной почты',
    'Образование и профессиональная квалификация/сертификаты',
    'Знание иностранных языков',
    'Номер квалификационного сертификата аудитора',
    'Номер квалификационного сертификата налогового консультанта',
    'Сертификаты',
    'Номер квалификационного сертификата оценщика',
    'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»',
    'Стаж финансово-экономической деятельности',
    'Стаж юридической деятельности',
    'Сведения о трудовой деятельности',
    'Ключевые компетенции',
    'Основные проекты',
    'В Юникон АО',
    'В других организациях',
    'Достоверность сведений, представленных в резюме, подтверждаю',
  ];

  const headers = [ // Заголовки таблицы
    { id: 1, title: 'ФИО', field: 'fullName' },
    { id: 2, title: 'Дата создания', field: 'date_created' },
    { id: 3, title: 'Дата изменения', field: 'date_modifed' },
    { id: 4, title: 'Редактировать резюме', field: 'resume' },
  ];
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );

  useEffect(() => {
    getStaffCV();
  }, []);

  async function getStaffCV() {
    try {
      const reqData = { type: 'getStaffCV' };
      const result = await clientSendData('POST', '/get_staff_cv', reqData);
      if (result && result.data) {
        setSelectedCV(result.data);
        const formattedData = result.data.map((item) => ({
          id: item.id,
          fullName: item.cv['Ф.И.О.'],
          date_created: new Date(item.date_created).toLocaleDateString(),
          date_modifed: new Date(item.date_modifed).toLocaleDateString(),
          resume: 'Просмотреть резюме',
        }));
        setDataCV(formattedData);
      }
    } catch (error) {
      catchHandler(error, 'getStaffCV');
    }
  }

  const downloadCV = () => {
    setFileInputVisible(true);
  };

  let uploadedFiles = [];
  let totalFilesCount = 0;

  const uploadFile = async (file) => {
    try {
      const reader = new FileReader();
      reader.onloadend = async (e) => {
        const binaryArray = new Uint8Array(e.target.result);
        uploadedFiles.push(binaryArray);

        if (uploadedFiles.length === totalFilesCount) {
          const reqData = {
            type: 'updateStaffCV',
            DataCV: uploadedFiles,
          };

          const result = await clientSendData('POST', '/update_staff_cv', reqData);
          if (result.success) {
            await alertFunction('file_upload_success', 'client'); // Уведомление об успешной загрузке
            setFileInputVisible(false);
            setTableState({ ...tableState });
          } else if (result.message === 'Пользователь не найден.') {
            await alertFunction('not_user', 'client'); // Уведомление об ошибке
          } else {
            await alertFunction('file_upload_error', 'client'); // Уведомление об ошибке
          }
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      catchHandler(error, 'uploadFile');
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    totalFilesCount = files.length;

    if (files.length > 0) {
      uploadedFiles = [];
      for (const file of files) {
        // eslint-disable-next-line no-await-in-loop
        await uploadFile(file);
      }
    }
  };

  const generateWordDocument = (id) => {
    console.log(id);
    console.log(selectedCV);
    const selectedResume = selectedCV.find((cv) => cv.id === id);
    console.log(selectedResume);
    if (!selectedResume) {
      console.error('Резюме не найдено');
      return;
    }

    const rows = [];

    const defaultFontStyle = {
      font: 'Microsoft Sans Serif', // Название шрифта
      size: 18, // Размер шрифта (в пунктах)
    };

    // Создаем колонтитул
    const header = new Header({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: 'РЕЗЮМЕ',
              bold: true,
              font: 'Microsoft Sans Serif', // Задаем шрифт для колонтитула
              size: 22, // Размер шрифта для колонтитула (можно настроить)
            }),
          ],
          alignment: AlignmentType.RIGHT, // Выравнивание по правому краю
        }),
      ],
    });

    orderedKeys.forEach((key) => {
      if (selectedResume.cv[key] !== undefined) {
        const value = selectedResume.cv[key];

        if (Array.isArray(value) && key === 'Образование и профессиональная квалификация/сертификаты') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && key === 'Сведения о трудовой деятельности') {
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
          value.forEach((item) => {
            rows.push([
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.year, bold: true, ...defaultFontStyle })] })],
              }),
              new TableCell({
                children: [new Paragraph({ children: [new TextRun({ text: item.value, ...defaultFontStyle })] })],
              }),
            ]);
          });
        } else if (Array.isArray(value) && (key === 'Ключевые компетенции')) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (Array.isArray(value) && ((key === 'В Юникон АО') || (key === 'В других организациях'))) {
          // Если ключ совпадает, добавляем заголовок
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              columnSpan: 2,
            }),
          ]);

          // Добавляем массив значений в виде списка с буллитами
          const bulletList = value.map((item) => new Paragraph({
            children: [new TextRun({ text: `• ${item}`, ...defaultFontStyle })],
          }));

          // Добавляем список в новую ячейку, занимающую две колонки
          rows.push([
            new TableCell({
              children: bulletList,
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Номер квалификационного сертификата аудитора' || key === 'Номер квалификационного сертификата налогового консультанта' || key === 'Сертификаты'
     || key === 'Номер квалификационного сертификата оценщика' || key === 'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»' || key === 'Стаж финансово-экономической деятельности' || key === 'Стаж юридической деятельности') {
          rows.push([
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({ text: `${key}`, bold: true, ...defaultFontStyle }),
                    new TextRun({ text: `: ${value}`, ...defaultFontStyle }),
                  ],
                }),
              ],
              columnSpan: 2,
            }),
          ]);
        } else if (key === 'Достоверность сведений, представленных в резюме, подтверждаю') {
          // Извлечение даты из value
          const dateRegex = /(\d{2}\.\d{2}\.\d{4})/;
          const dateMatch = value.match(dateRegex);
          const date = dateMatch ? dateMatch[0] : 'Введите дату';

          // Извлечение оставшегося текста
          const remainingText = value.replace(dateRegex, '').trim();
          // Разделяем текст на части
          const parts = remainingText.split('Игоревна');

          // Создание ячеек
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, ...defaultFontStyle })] })],
              columnSpan: 2,
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);

          // Ячейка с оставшимся текстом
          rows.push([
            new TableCell({
              children: [new Paragraph({
                children: [
                  new TextRun({
                    text: `${parts[0].trim()} Игоревна`,
                    ...defaultFontStyle,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: parts[1].trim(),
                    ...defaultFontStyle,
                  }),
                ],
              })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: date, ...defaultFontStyle })] })],
            }),
          ]);
        } else if (key === 'Ф.И.О.') {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: value, bold: true, ...defaultFontStyle })] })],
              borders: {
                top: {
                  style: BorderStyle.SINGLE,
                  size: 1,
                  color: 'auto',
                },
              },
            }),
          ]);
        } else {
          // Для обычных ключей
          rows.push([
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: `${key}:`, bold: true, ...defaultFontStyle })] })],
            }),
            new TableCell({
              children: [new Paragraph({ children: [new TextRun({ text: Array.isArray(value) ? value.join(', ') : value, ...defaultFontStyle })] })],
            }),
          ]);
        }
      }
    });

    const table = new Table({
      rows: rows.map((row) => new TableRow({ children: row })),
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: TableBorders.NONE,
      margins: {
        top: convertInchesToTwip(0.1),
        bottom: convertInchesToTwip(0.1),
        right: convertInchesToTwip(0),
        left: convertInchesToTwip(0),
      },
    });

    const doc = new Document({
      sections: [{
        properties: {},
        headers: {
          default: header, // Добавляем колонтитул в секцию
        },
        children: [table],
      }],
    });

    // Извлечение Ф.И.О. для имени файла
    const fullName = selectedResume.cv['Ф.И.О.'] || 'Резюме';
    const sanitizedFileName = fullName.replace(/[^a-zA-Z0-9а-яА-ЯёЁ\s]/g, '').replace(/\s+/g, '_').trim();

    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${sanitizedFileName}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }).catch((error) => {
      console.error('Ошибка при создании документа:', error);
    });
  };

  function openCard(id) {
    const cv = selectedCV.find((item) => item.id === id);
    setSelectedCV(cv);
    setShowCard(true);
    navigate(`/staff_cv?user=${id}`);
  }

  if (showCard) {
    return (
      <CV
        meetingId={selectedCV?.id}
        cvData={selectedCV}
        handler={getStaffCV}
        setShow={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  return (
    <ServiceBody>
<PaginatedTable
  id="cv_results"
  headers={headers}
  state={tableState}
  setState={setTableState}
  total={total}
>

{dataCV.map((cv) => (
      <TRow key={cv.id}>
        {headers.map((header) => {
          const { id, field } = header; // Деструктуризация объекта колонки
          const value = cv?.[field]; // Значение текущего ключа в объекте строки

          return (
            <TData key={id} loading={isLoading}>
              {header.field === 'resume' ? (
                <div onClick={() => openCard(cv.id)} style={{ cursor: 'pointer' }}>
                  <img src="../../icons/button/edit.svg" alt="file" />
                </div>
              ) : (
                // Обработка значения, если оно является массивом
                typeof value === 'object' ? value.join(', ') : value ?? '-'
              )}
            </TData>
          );
        })}

        {/* Последняя ячейка для поиска */}
        <TData loading={isLoading}>
      <div onClick={() => generateWordDocument(cv.id)} style={{ cursor: 'pointer', alt: 'скачать резюме' }}>
                  <img src="../../icons/file.png" alt="скачать резюме" />
      </div>
        </TData>
      </TRow>
))}

    <TRow>
      <TData onClick={downloadCV} style={{ cursor: 'pointer' }}>
        Загрузить резюме (формат только .docx)
      </TData>
    </TRow>

</PaginatedTable>
      {fileInputVisible && (
        <input type="file" accept=".docx" multiple onChange={handleFileChange} />
      )}
    </ServiceBody>
  );
}

export default StaffCV;
