import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../../utils/functions/requests';
import { alertFunction } from '../../../../../utils/functions/alertFunction';
import { convertTicketId } from '../../../common';
import SingleFileUpload from '../../../../../components/UI/FileUpload/SingleFileUpload';
import TextArea from '../../../../../components/UI/TextArea';
import Modal from '../../../../../components/UI/Modal';
import Table from '../../../../../components/Table/Table';
import TBody from '../../../../../components/Table/TBody';
import TRow from '../../../../../components/Table/TRow';
import TData from '../../../../../components/Table/TData';

/**
  * @component ХелпДеск - заявки - карточка заявки - вкладка "Работа по заявке" - форма отправки email
  * @prop {ticketID} number - id заявкиы
  * @prop {ticketInfo} object - данные процесса по заявке
  * @prop {getTicketProcess} function - получение данных по переписке
*/
function SendForm(props) {
  const {
    ticketID, ticketInfo, getTicketProcess, falsePerformer, PODFT, currentStatus, sdlUser, dir,
  } = props;

  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [newMail, setNewMail] = useState({}); // новое письмо
  const [displayModal, setDisplayModal] = useState(false); // состояние отображения модалки
  const [standartSolutions, setStandartSolutions] = useState([]); // стандартные решения
  const [acceptFileExtension, setAcceptFileExtension] = useState([]); // разрешения файлов
  const ticketNumber = ticketInfo?.ticket_number;

  useEffect(() => {
    getStandartSolutions();
  }, []);

  function emailTicketStatus(ticketInfo, type) {
    let emailStatus;
    if (type === 'solve') {
      emailStatus = 'Решен';
    } else if ([1, 2, 4, 5, 6, 8].includes(ticketInfo.status_id)) {
      emailStatus = ticketInfo.status;
    } else if (ticketInfo.status_id === 3) {
      emailStatus = 'В ожидании';
    }
    return emailStatus;
  }

  // Обработка прикрепленного вложения и запись в объект нового email
  function attachmentHandler(file) {
    const reader = new FileReader(); // API чтения файлов
    reader.onloadend = (e) => {
      setNewMail((prev) => ({
        ...prev, // развернуть существующее состояние
        attachment: {
          file: e.target.result, // строка в формате DataURL для записи в БД
          name: file.name, // имя файла
        },
      }));
    };
    reader.readAsDataURL(file); // Прочитать как DataURL ("data:image/png;base64,iVBORw...")
  }

  // Отправить сообщение в чате ПОДФТ
  async function sendPODFTEmail(type) {
    try {
      if (!isLoading) { // предотвратить повторную отправку во время загрузки
        if (newMail.text) { // если есть содержание письма
          const reqData = {
            type: 'sendPODFTEmail',
            message_id: ticketID,
            initiator_id: ticketInfo.initiator_id,
            taskSupervisor_id: ticketInfo.tasksupervisor_id,
            message_type: type,
            subject: `[Работа по сообщению #${ticketID}]`,
            text: newMail.text,
            file: newMail.attachment?.file,
            file_name: newMail.attachment?.name,
            source: operInfo.alias,
            messageInfo: ticketInfo,
            currentStatus,
          };
          setIsLoading(true);
          const result = await clientSendData('POST', '/send_podft_email', reqData);
          if (result?.success) {
            setNewMail({});
            await getTicketProcess();
          }
        } else if (type === 'accept' || type === 'signed') {
          const mailText = type === 'accept' ? 'Внутреннее сообщение принято ✔️' : 'Внутреннее сообщение подписано ✔️';
          const reqData = {
            type: 'sendPODFTEmail',
            message_id: ticketID,
            initiator_id: ticketInfo.initiator_id,
            taskSupervisor_id: ticketInfo.tasksupervisor_id,
            message_type: type,
            subject: `[Работа по сообщению #${ticketID}]`,
            text: mailText,
            file: newMail.attachment?.file,
            file_name: newMail.attachment?.name,
            source: operInfo.alias,
            messageInfo: ticketInfo,
            currentStatus,
          };
          setIsLoading(true);
          const result = await clientSendData('POST', '/send_podft_email', reqData);
          if (result?.success) {
            setNewMail({});
            await getTicketProcess();
          }
        } else alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'sendPODFTEmail');
    } finally {
      setIsLoading(false);
    }
  }

  // Отправить email
  async function sendEmail(type) {
    try {
      if (!isLoading) { // предотвратить повторную отправку во время загрузки
        if (newMail.text) { // если есть содержание письма
          const reqData = {
            type: 'sendEmailToInitiator',
            file: newMail.attachment?.file,
            file_name: newMail.attachment?.name,
            ticket_id: ticketID,
            status_id: ticketInfo.status_id,
            initiator_id: ticketInfo.initiator_id,
            performer_id: ticketInfo.performer_id,
            message_type: type,
            subject: `[Запрос #${ticketInfo.ticket_number} - ${emailTicketStatus(ticketInfo, type)}] ${ticketInfo.title}`,
            text: newMail.text,
            source: operInfo.alias,
          };
          setIsLoading(true);
          const result = await clientSendData('POST', '/send_email_to_initiator', reqData);
          if (result?.success) { // если обработка запроса выполнена успешно
            if (result?.tasks_completed) { // и если отсутствую незавершенные наряды (обработка для isItSolution)
              setNewMail({});
              await getTicketProcess();
            } else alertFunction('uncompleted_tasks', 'clientPost'); // иначе уведомление
          }
        } else alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'sendEmail');
    } finally {
      setIsLoading(false);
    }
  }

  async function getStandartSolutions() {
    try {
      const reqData = {
        type: 'getStandartSolutions',
        group_id: ticketInfo.group_id,
      };
      const result = await clientSendData('POST', '/get_ticket_process/standart_solutions', reqData);
      if (result?.success) {
        setStandartSolutions(result.data);
        setAcceptFileExtension(result.fileExtension);
      }
    } catch (error) {
      catchHandler(error, 'getStandartSolutions');
    }
  }

  function chooseSolution(content) {
    setNewMail((prev) => ({ ...prev, text: content }));
    setDisplayModal(false);
  }

  async function solveTicket() {
    const confirm = window.confirm('Вы уверены?');
    if (confirm) {
      await sendEmail('solve');
    }
  }

  function generateConferenceLink(ticketN) {
    const baseUrl = 'https://uniplace.unicon.ru/video_conferences/conf/';
    const conferenceLink = `${baseUrl}${ticketN}l`;
    return `Приглашаем Вас обсудить запрос в Юниколе. Присоединяйтесь по ссылке: ${conferenceLink}`;
  }

  const handleImageClick = async () => {
    const text = generateConferenceLink(ticketNumber);
    // Устанавливаем текст в newMail
    setNewMail((prev) => ({ ...prev, text }));
  };

  return (
    <div className="ticket-send__wrapper">
      <div className="ticket-send">
        <TextArea
          placeholder="Введите сообщение..."
          value={newMail?.text || ''}
          onChange={(e) => setNewMail((prev) => ({ ...prev, text: e.target.value }))}
          disabled={isLoading}
        />
        <SingleFileUpload options={{ handler: attachmentHandler, size: 5000000, accept: acceptFileExtension.length > 0 ? acceptFileExtension[0].value : '' }}>
          <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" />
        </SingleFileUpload>
        <img onClick={() => setDisplayModal(true)} className="ticket-send__template" src="../../icons/file-text_blue.svg" alt="file" title="Выбрать стандартное решение" />
        <img onClick={handleImageClick} className="ticket-send__unicall" src="../../icons/video.png" alt="unicall" title="Создать встречу в Unicall" />
       {PODFT !== true && falsePerformer && <div className="ticket-send__button ticket-send__send" onClick={() => sendEmail('send')} title="Отправить комментарий инициатору">
          <img className="ticket-send__mail" src="../../icons/button/mail.svg" alt="mail" />
                                            </div>}

       {falsePerformer && <div className="ticket-send__button ticket-send__solve" onClick={() => solveTicket()} title="Отправить решение инициатору">
          <img className="ticket-send__solve" src="../../icons/check.svg" alt="checkmark" />
                          </div>}

      {PODFT !== true && (<div className="ticket-send__button ticket-send__comment" onClick={() => sendEmail('comment')} title="Написать личный комментарий">
          <img className="ticket-send__comment" src="../../icons/button/comment.svg" alt="comment" />
                          </div>)}

                           {/* Отправка сообщений для ПОДФТ */}
        {(PODFT && [2, 3, 4, 8].includes(ticketInfo.status_id) && operInfo.user_id !== ticketInfo.tasksupervisor_id) && <div className="ticket-send__button ticket-send__send" onClick={() => sendPODFTEmail('send')} title="Отправить сообщение">
          <img className="ticket-send__mail" src="../../icons/button/mail.svg" alt="mail" />
                                                                                                                        </div>}
       {(PODFT && sdlUser && [2, 3, 8].includes(ticketInfo.status_id)) && <div className="ticket-send__button ticket-send__solve" onClick={() => sendPODFTEmail('accept')} title="Внутреннее сообщение принято">
          <img className="ticket-send__solve" src="../../icons/check.svg" alt="checkmark" />
                                                                          </div>}

       {/* {!PODFT && <div className="ticket-send__button ticket-send__comment" onClick={() => sendEmail('comment')} title="Написать личный комментарий">
          <img className="ticket-send__comment" src="../../icons/button/comment.svg" alt="comment" />
                  </div>} */}

        {(PODFT && operInfo.user_id === ticketInfo.tasksupervisor_id && [4].includes(ticketInfo.status_id)) && <div className="ticket-send__button ticket-send__solve" title="Подписать сообщение" onClick={() => sendPODFTEmail('signed')}>
          <img className="ticket-send__solve" src="../../icons/button/edit.svg" alt="sign" />
                                                                                                               </div>}

          {(PODFT === true && operInfo.user_id === ticketInfo.tasksupervisor_id && [2, 3, 4, 8].includes(ticketInfo.status_id))
            && <div className="ticket-send__button ticket-send__send" onClick={() => sendPODFTEmail('comment')} title="Написать личный комментарий">
          <img className="ticket-send__comment" src="../../icons/button/comment.svg" alt="comment" />
               </div> }

      </div>
      {newMail.attachment && (
      <div className="ticket-send__attachment">
        <p className="ticket-send__attachment-name">{newMail.attachment?.name}</p>
        <div className="ticket-send__attachment-img">
          <img onClick={() => setNewMail((prev) => ({ ...prev, attachment: null }))} src="../../icons/cancel.svg" alt="cancel" />
        </div>
      </div>)}

      <Modal active={displayModal} setActive={setDisplayModal}>
        <Table id="helpdesk__standart-solutions">
          <TBody>
            {standartSolutions.map((solution) => {
              const { id, title, content } = solution;
              return (
                <TRow key={id} onClick={() => chooseSolution(content)} button>
                  <TData>
                    <p>{title}</p>
                  </TData>
                </TRow>
              );
            })}
          </TBody>
        </Table>
      </Modal>
    </div>
  );
}

export default SendForm;
