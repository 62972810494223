import React, { useState, useEffect } from 'react';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Loader from '../../components/UI/Loader';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/UI/Button/Button';

/**
  * @component Сервис подбора персонала - история изменений
  * @prop {subject_id} function - обновление состояния отображения карточки (открыть/закрыть)
*/

function RecrutmentHistory(props) {
  const { subject_id } = props;
  const [history, setHistory] = useState([]); // история субъекта
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const columns = [
    { title: 'Дата', field: 'date', align: 'right' },
    { title: 'Кто изменил', field: 'creator', align: 'left' },
    { title: 'Изменения', field: 'message', align: 'left' },
  ];

  useEffect(() => {
    if (subject_id) getRHistory();
  }, [subject_id]);

  // Получить историю задач
  async function getRHistory() {
    try {
      const reqData = {
        type: 'getRHistory',
        subject_id,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_r_history', reqData);
      if (result?.success) setHistory(result.data); // Результат записывается в состояние
    } catch (error) {
      catchHandler(error, 'getRHistory');
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <Loader />;
  if (history.length === 0) return <h2 className="upu-service__title">История отсутствует</h2>;
  return (
            <Table id="history">
                <THead headers={columns} array={history} setArray={setHistory} />
                <TBody>
                    {history.map((row) => (
                        <TRow key={row.id}>
                            {columns.map((column) => {
                              const { field, align } = column;
                              if (field === 'message') {
                                return (
                                        <td
                                          key={field}
                                          className={`upu-table__table-data${align ? ` upu-table__table-data_${align}` : ''}`}
                                          dangerouslySetInnerHTML={{ __html: row[field] }}
                                        />
                                );
                              }
                              return <TData key={field} align={align}>{row[field]}</TData>;
                            })}
                        </TRow>
                    ))}
                </TBody>
            </Table>
  );
}

export default RecrutmentHistory;
