import React, { useState } from 'react';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import CheckBox from '../../components/UI/CheckBox';
import RadioButton from '../../components/UI/RadioButton';
import Button from '../../components/UI/Button/Button';

function Decision() {
  const decisions = [
    { id: 1, title: 'Признать выявленную необычную операцию (сделку) подозрительной операцией (сделкой), осуществление которой может быть направлено на легализацию (отмывание) доходов, полученных преступным путем, или финансирование терроризма, или распространение оружия массового уничтожения;' },
    { id: 2, title: 'Принять дополнительные меры по изучению необычной операции (сделки) клиента;' },
    { id: 3, title: 'Представить информацию об операции, предусмотренной подпунктом "а" настоящего пункта, в Федеральную службу по финансовому мониторингу' },
  ];
  const [decisionsList, setDecisions] = useState(false);
  const [userRole, setUserRole] = useState('sdl');
  const [isSigned, setIsSigned] = useState(false);
  const [showDecisions, setShowDecisions] = useState(false);

  return (
    <div id="decision">
    <div className="decision-wrapper">
      {/* Дата и время составления внутреннего сообщения об операции (сделке) */}
      <CardSetting title="Дата и время составления внутреннего сообщения об операции">
        <Input type="date" />
      </CardSetting>

      {/* Дата и время получения сообщения СДЛ */}
      <CardSetting title="Дата и время получения сообщения СДЛ">
        <Input type="date" />
      </CardSetting>

      {/* Запись (отметка) о решении СДЛ, принятом в отношении внутреннего сообщения об операции (сделке) */}
      <CardSetting title="Запись (отметка) о решении СДЛ, принятом в отношении внутреннего сообщения об операции (сделке)">
        <Input />
      </CardSetting>

      {/* Мотивированное обоснование принятого СДЛ решения (в случае принятия решения о не направлении сведений об операции в Уполномоченный орган) */}
      <CardSetting title="Мотивированное обоснование принятого СДЛ решения*">
        <Input />
      </CardSetting>

      {/* Дата и время принятия решения СДЛ */}
      <CardSetting title="Дата и время принятия решения СДЛ">
        <Input type="date" />
      </CardSetting>

      {/* Запись (отметка) о решении Руководителя Компании либо уполномоченного им должностного лица */}
      <CardSetting title="Запись (отметка) о решении Руководителя Компании/уполномоченного должностного лица">
       {/* <Select array={decisions} /> */}
      <p onClick={() => setDecisions(!decisionsList)}>
      <img
        onClick={() => setShowDecisions(!showDecisions)}
        className={`nav-button__chevron ${showDecisions ? 'nav-button__chevron_active' : ''}`}
        src="../../icons/chevron.svg"
        alt="chevron-down"
      />
      </p>
      {decisionsList && (
      <div className="decisions-container">
        <div className="decisionsList">

        <div className="decision-point">
Признать выявленную необычную операцию (сделку) подозрительной операцией (сделкой), осуществление которой может быть направлено на легализацию (отмывание) доходов, полученных преступным путем, или финансирование терроризма, или распространение оружия массового уничтожения
<div className="checkbox-container">
  <p>
Да
<CheckBox />
  </p>
  <p>
Нет
<CheckBox />
  </p>
</div>
        </div>

        <div className="decision-point">
Принять дополнительные меры по изучению необычной операции (сделки) клиента
<div className="checkbox-container">
  <p>
Да
<CheckBox />
  </p>
  <p>
Нет
<CheckBox />
  </p>
</div>
        </div>

        <div className="decision-point">
Представить информацию об операции, предусмотренной подпунктом `&quot;`а`&quot;` настоящего пункта, в Федеральную службу по финансовому мониторингу
<div className="checkbox-container">
  <p>
Да
<CheckBox />
  </p>
  <p>
Нет
<CheckBox />
  </p>
</div>
        </div>
        </div>
      </div>
      )}
      </CardSetting>

       {/* Запись (отметка) о дополнительных мерах (иных действиях) */}
       <CardSetting title="Запись (отметка) о дополнительных мерах (иных действиях)*">
        <Input />
       </CardSetting>
    </div>

    <div className="buttons-container">
      {userRole === 'sdl' && <Button>Направить сообщение руководителю Компании</Button>}
      {(userRole === 'dir' && !isSigned) && (<div className="dir-buttons">
      <Button onClick={() => setIsSigned(true)}>Подписать сообщение</Button>
      <Button>Отправить на доработку</Button>
                                             </div>)}

   {isSigned && <Button>Сформировать внутреннее сообщение</Button>}
    </div>
    </div>
  );
}

export default Decision;
