import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { authorization } from '../../utils/functions/authenticate';
import { alertFunction } from '../../utils/functions/alertFunction';
import { headers } from './common';
import Button from '../../components/UI/Button/Button';
import TFoot from '../../components/Table/TFoot';
import InputSearch from '../../components/UI/InputSearch';
import Cap from '../Cap/Cap';
import Input from '../../components/UI/Input';

function AllEducationRecrutment() {
  const [educations, setEducations] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [programms, setProgramms] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [newAccess, setNewAccess] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [selectedEducations, setSelectedEducations] = useState(new Set());
  const [selectedProfiles, setSelectedProfiles] = useState(new Set());
  const [selectedProgramms, setSelectedProgramms] = useState(new Set());
  const [selectedSpecialities, setSelectedSpecialities] = useState(new Set());
  const [newEducation, setNewEducation] = useState({ title: '' }); // Для образования
  const [newProfile, setNewProfile] = useState({ title: '' }); // Для профиля
  const [newProgramm, setNewProgramm] = useState({ title: '' }); // Для программ
  const [newSpeciality, setNewSpeciality] = useState({ title: '' }); // Для специализаций

  useEffect(() => {
    awaitRequests();
    getAccessData();
  }, []);

  async function getAccessData() {
    try {
      const reqData = { type: 'getAdminDataRecrutment' };
      const result = await clientSendData('POST', '/get_admin_data_recrutment', reqData);
      if (result) {
        const data = JSON.parse(result.data);
        setEducations(data.education);
        setSpecialities(data.specialities);
        setProfiles(data.profile);
        setProgramms(data.programms);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataRecrutment');
    }
  }

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  const addNewAccess = async (array, setArray, newItem) => {
    if (newItem.title) {
      setArray((prevArray) => [
        ...prevArray,
        { id: (prevArray.length + 1).toString(), title: newItem.title },
      ]);
      // Сбрасываем состояние после добавления
      if (newItem === newEducation) setNewEducation({ title: '' });
      if (newItem === newProfile) setNewProfile({ title: '' });
      if (newItem === newProgramm) setNewProgramm({ title: '' });
      if (newItem === newSpeciality) setNewSpeciality({ title: '' });
    } else {
      await alertFunction('sd_user_group_exist', 'client');
    }
  };

  const deleteSelectedAccess = async (array, setArray, selectedUsers, setSelectedUsers, editFunction) => {
    const newArray = array.filter((item) => !selectedUsers.has(item.id));
    setArray(newArray);

    // Получаем массив объектов с id и title
    const accessData = newArray.map((item) => ({
      id: item.id,
      title: item.title,
    }));

    setSelectedUsers(new Set());
    await editFunction(accessData);
  };

  const editRecrutmentAllEducations = async (accessData, endpoint, type) => {
    try {
      const reqData = {
        type,
        data: accessData, // Здесь передаем массив объектов с id и title
      };
      const result = await clientSendData('POST', endpoint, reqData);
      console.log(reqData);
      if (result.success) {
        await alertFunction('save_settings', 'client');
        await getAccessData();
      } else {
        await alertFunction('required_fields', 'client');
      }
    } catch (error) {
      catchHandler(error, type);
    }
  };

  async function searchWrapper(e) {
    try {
      setIsSearching(true);
      const result = await searchUserData(e.target.value);
      setAdditionalInfo(result);
    } catch (error) {
      catchHandler(error, 'searchWrapper');
    } finally {
      setIsSearching(false);
    }
  }

  async function searchUserData(query) {
    try {
      const reqData = { type: 'cvGetUserData' };
      const result = await clientSendData('POST', `/cv_get_user_data/?query=${query}`, reqData);
      if (result?.success) {
        return result.data || [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'cvGetUserData');
      return [];
    }
  }

  const chooseData = (id, selectedUsers, setSelectedUsers) => {
    const newSelected = new Set(selectedUsers);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedUsers(newSelected);
  };

  if (isAuthorized) {
    return (
      <ServiceBody>
        {/* Таблица для администраторов */}
        <Table id="admin_staff_cv">
          <THead title="Образование" headers={headers.education} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="education"
                  type="text"
                  value={newEducation.title}
                  onChange={(e) => setNewEducation({ ...newEducation, title: e.target.value })}
                  placeholder="Образование"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(educations, setEducations, newEducation)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {educations.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedEducations.has(el.id)}
                    onChange={() => chooseData(el.id, selectedEducations, setSelectedEducations)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                educations,
                setEducations,
                selectedEducations,
                setSelectedEducations,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_education', 'editRecrutmentEducation'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    educations,
    setEducations,
    selectedEducations,
    setSelectedEducations,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_education', 'editRecrutmentEducation'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
        <br />

        {/* Таблица для заместителей генерального директора */}
        <Table id="admin_staff_cv">
          <THead title="Профиль образования" headers={headers.education} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="profile"
                  type="text"
                  value={newProfile.title}
                  onChange={(e) => setNewProfile({ ...newProfile, title: e.target.value })}
                  placeholder="Профиль"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(profiles, setProfiles, newProfile)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {profiles.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedProfiles.has(el.id)}
                    onChange={() => chooseData(el.id, selectedProfiles, setSelectedProfiles)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                profiles,
                setProfiles,
                selectedProfiles,
                setSelectedProfiles,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_profile', 'editRecrutmentProfile'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    profiles,
    setProfiles,
    selectedProfiles,
    setSelectedProfiles,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_profile', 'editRecrutmentProfile'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
<br />
        {/* Таблица для генерального директора */}
        <Table id="admin_staff_cv">
          <THead title="ПК и спец. программы" headers={headers.education} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="programm"
                  type="text"
                  value={newProgramm.title}
                  onChange={(e) => setNewProgramm({ ...newProgramm, title: e.target.value })}
                  placeholder="Программа"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(programms, setProgramms, newProgramm)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {programms.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedProgramms.has(el.id)}
                    onChange={() => chooseData(el.id, selectedProgramms, setSelectedProgramms)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                programms,
                setProgramms,
                selectedProgramms,
                setSelectedProgramms,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_programms', 'editRecrutmentProgramms'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    programms,
    setProgramms,
    selectedProgramms,
    setSelectedProgramms,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_programms', 'editRecrutmentProgramms'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
<br />
        {/* Таблица для отдела ресурсного планирования */}
        <Table id="admin_staff_cv">
          <THead title="Проф. навыки, специализация" headers={headers.education} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="speciality"
                  type="text"
                  value={newSpeciality.title}
                  onChange={(e) => setNewSpeciality({ ...newSpeciality, title: e.target.value })}
                  placeholder="Специализация"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(specialities, setSpecialities, newSpeciality)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {specialities.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedSpecialities.has(el.id)}
                    onChange={() => chooseData(el.id, selectedSpecialities, setSelectedSpecialities)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                specialities,
                setSpecialities,
                selectedSpecialities,
                setSelectedSpecialities,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_specialities', 'editRecrutmentSpecialities'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    specialities,
    setSpecialities,
    selectedSpecialities,
    setSelectedSpecialities,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_specialities', 'editRecrutmentSpecialities'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
      </ServiceBody>
    // </div>
    );
  }
  return <Cap />;
}

export default AllEducationRecrutment;
