import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';

// ID статусов заявки
export const ticketStatus = {
  new: 1, // новая
  queue: 2, // в очереди
  work: 3, // в работе
  await: 4, // в ожидании
  solved: 5, // решена
  refused: 6, // отклонена
  closed: 7, // закрыта
  associated: 8, // связана
};

// Статусы заявки находящейся в обработке
export const activeTicketStatuses = [
  ticketStatus.new,
  ticketStatus.queue,
  ticketStatus.work,
  ticketStatus.await,
  ticketStatus.solved,
];

// ID статусов наряда
export const taskStatus = {
  new: 1, // новый
  done: 2, // выполнен
};

// Настройки отображения заявок по умолчанию
export const defaultTableState = {
  support: {
    main: {
      title: 'Запросы',
      page: 1,
      limit: 50,
      switch_data: {
        current: 'active',
        titles: {
          all: 'Все',
          new: 'Новые',
          active: 'Активные',
          my_groups: 'В моих группах',
        },
      },
    },
  },
  assets: {
    main: { // Общая таблица активов
      title: 'Общее количество',
      page: 1,
      limit: 50,
      switch_data: {
        current: 'asset',
        titles: {
          user: 'По сотрудникам',
          asset: 'По активам',
        },
      },
      button_place: 'header',
    },
    user_tickets: { // Карточка пользователя - Заявки
      title: 'Запросы',
      page: 1,
      limit: 50,
      button_place: 'header',
    },
    user_assets: { // Карточка пользователя - Активы
      title: 'Активы',
      page: 1,
      limit: 50,
      switch_data: {
        current: 'active',
        titles: {
          active: 'Активные',
          all: 'История',
        },
      },
      button_place: 'header',
    },
    asset_tickets: { // Карточка актива - Заявки
      title: 'Связанные запросы',
      page: 1,
      limit: 50,
      button_place: 'header',
    },
  },
  user: {
    main: {
      title: 'Запросы',
      page: 1,
      limit: 50,
      switch_data: {
        current: 'active',
        titles: {
          all: 'Все',
          active: 'Активные',
        },
      },
      button_place: 'header',
    },
  },
  tempAccesses: {
    title: 'Временные доступы',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'my_groups',
      titles: {
        all: 'Все',
        active: 'Активные',
        my_groups: 'В моих группах',
      },
    },
  },
};

// Наименования колонок таблиц
export const headers = {
  support: {
    main: [
      { title: '№', field: 'id', align: 'left' },
      { title: 'Приоритет', field: 'priority', align: 'left' },
      { title: 'Заголовок', field: 'title', align: 'left' },
      { title: 'Статус', field: 'status', align: 'left' },
      { title: 'Инициатор', field: 'initiator', align: 'left' },
      { title: 'Исполнитель', field: 'performer', align: 'left' },
      { title: 'Группа', field: 'group', align: 'left' },
      {
        title: 'Обновлена', field: 'date_modified', align: 'left', mobile_hide: true,
      },
      {
        title: 'Создана', field: 'date_created', align: 'left', mobile_hide: true,
      },
    ],
  },
  assets: {
    main: {
      user: [
        { title: 'ID', field: 'id', align: 'right' },
        { title: 'ФИО', field: 'name', align: 'left' },
      ],
      asset: [
        { title: 'Инв.№', field: 'inventory', align: 'right' },
        { title: 'Наименование', field: 'title', align: 'left' },
        { title: 'Тип', field: 'type', align: 'left' },
        { title: 'Статус', field: 'status', align: 'left' },
        { title: 'Компания', field: 'company', align: 'left' },
        { title: 'Сотрудник', field: 'user', align: 'left' },
        { title: 'Дата создания', field: 'date_created', align: 'left' },
        { title: 'Дата изменения', field: 'date_modified', align: 'left' },
        { title: 'Серийный №', field: 'serial', align: 'right' },
      ],
    },
    user_tickets: [
      { title: '№', field: 'id', align: 'right' },
      { title: 'Заголовок', field: 'title', align: 'left' },
      { title: 'Статус', field: 'status', align: 'left' },
      { title: 'Исполнитель', field: 'performer', align: 'left' },
      {
        title: 'Создана', field: 'date_created', align: 'left', mobile_hide: true,
      },
      {
        title: 'Обновлена', field: 'date_modified', align: 'left', mobile_hide: true,
      },
    ],
    user_assets: [
      { title: 'Инв.№', field: 'inventory', align: 'right' },
      { title: 'Наименование', field: 'title', align: 'left' },
      { title: 'Тип', field: 'type', align: 'left' },
      { title: 'Статус', field: 'status', align: 'left' },
      { title: 'Компания', field: 'company', align: 'left' },
    ],
    asset_tickets: [
      { title: '№', field: 'id', align: 'right' },
      { title: 'Заголовок', field: 'title', align: 'left' },
      { title: 'Статус', field: 'status', align: 'left' },
      { title: 'Исполнитель', field: 'performer', align: 'left' },
    ],
  },
  user: {
    main: [
      { title: '№', field: 'id', align: 'right' },
      { title: 'Заголовок', field: 'title', align: 'left' },
      { title: 'Статус', field: 'status', align: 'left' },
      { title: 'Исполнитель', field: 'performer', align: 'left' },
      {
        title: 'Создана', field: 'date_created', align: 'left', mobile_hide: true,
      },
      {
        title: 'Обновлена', field: 'date_modified', align: 'left', mobile_hide: true,
      },
    ],
  },
  tempAccesses: [
    { title: '№ задачи', field: 'id', align: 'center' }, // title- то что видит пользователь, field - ключ объекта из базы данных align-выравнивание
    { title: '№ запроса', field: 'ticket_number', align: 'left' },
    { title: 'Статус', field: 'title', align: 'left' },
    { title: 'Инициатор', field: 'initiator', align: 'left' },
    { title: 'Тип задачи', field: 'task_type', align: 'left' },
    { title: 'Создана', field: 'creator', align: 'left' },
    {
      title: 'Дата начала', field: 'date_created', align: 'left', mobile_hide: true,
    },
    {
      title: 'Дата исполнения', field: 'date_fulfilled', align: 'left', mobile_hide: true,
    },
  ],
};

/**
 * @function
* Поиск данных для карточки (категории/группы/исполнители)
* @param {string} string (Обязательный) искомая строка
* @param {data_type} string (Обязательный) что ищем - category/group/performer
*/
export async function searchTicketData(query, type) {
  try {
    const reqData = { type: 'searchTicketData' };
    const result = await clientSendData('POST', `/search_ticket_data/${type}?query=${query}`, reqData);
    if (result?.success) return result.data;
    return [];
  } catch (error) {
    catchHandler(error, 'searchTicketData');
    return [];
  }
}

/**
* @function
* Конвертация id заявки в формат строки "№0000123"
* @param {id} string|number идентификатор заявки
*/
export function convertTicketId(id) {
  if (id) {
    // if (typeof id === 'string') return id.replaceAll(/^0+/g, '')
    if (typeof id === 'string') return parseInt(id, 10); // если id - строка - убрать нули, привести к числу
    if (typeof id === 'number') return String(id).padStart(7, '0'); // если id - число - добавить нули, привести к строке
    return id;
  }
  return id;
}
