export const orderedKeys = [
  'Ф.И.О.',
  'Занимаемая должность',
  'Стаж работы в компании',
  'Дата рождения',
  'Адрес электронной почты',
  'Образование и профессиональная квалификация/сертификаты',
  'Знание иностранных языков',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного сертификата налогового консультанта',
  'Сертификаты',
  'Номер квалификационного сертификата оценщика',
  'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»',
  'Стаж финансово-экономической деятельности',
  'Стаж юридической деятельности',
  'Сведения о трудовой деятельности',
  'Ключевые компетенции',
  'Основные проекты',
  'В Юникон АО',
  'В других организациях',
  'Достоверность сведений, представленных в резюме, подтверждаю',
];

// Определите массив ключей, для которых редактирование запрещено
export const disabledFields = [
  'Ф.И.О.',
  'Занимаемая должность',
  'Стаж работы в компании',
  'Дата рождения',
  'Адрес электронной почты',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного сертификата налогового консультанта',
  'Номер квалификационного сертификата оценщика',
  'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»',
  'Стаж финансово-экономической деятельности',
  'Достоверность сведений, представленных в резюме, подтверждаю',
  'Стаж юридической деятельности',
];

export const specialKey = [
  'Сведения о трудовой деятельности',
  'Образование и профессиональная квалификация/сертификаты',
  'В Юникон АО',
  'В других организациях',
  'Достоверность сведений, представленных в резюме, подтверждаю',
  'Ключевые компетенции',
  'Знание иностранных языков',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного сертификата налогового консультанта',
  'Номер квалификационного сертификата оценщика',
  'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»',
  'Стаж финансово-экономической деятельности',
  'Стаж юридической деятельности',
  'Сведения о трудовой деятельности',
  'Основные проекты',
];

export const topBorderKey = [
  'Образование и профессиональная квалификация/сертификаты',
  'Сведения о трудовой деятельности',
  'Ключевые компетенции',
  'Достоверность сведений, представленных в резюме, подтверждаю',
  'Знание иностранных языков',
  'В других организациях',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного сертификата налогового консультанта',
  'Номер квалификационного сертификата оценщика',
  'Номер членства в НП «Сообщество специалистов-оценщиков «СМАО»',
  'Стаж финансово-экономической деятельности',
  'Стаж юридической деятельности',
  'Сведения о трудовой деятельности',
  'Основные проекты',
];
