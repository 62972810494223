import React, { useState, useEffect } from 'react';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import TFoot from '../../components/Table/TFoot';
import VideoConferencesCard from './VideoConferenceCard';
import ServiceBody from '../../components/Service/ServiceBody';
import './videoConference.scss';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';

/**
* @component Видеоконференция - интерфейс администратора - управление видеоконференциями
* @prop {meeting} object - встреча, открытая в карточке
*/

function VideoConferences() {
  const tableId = 'video-conference__meetings';
  const [meetings, setMeetings] = useState([]); // все встречи
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [openedMeeting, setOpenedMeeting] = useState(); // открытая в карточке видеоконференции
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const defaultTableState = {
    title: 'Видео встречи',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  };

  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState,
  );
  const headers = [ // Заголовки таблицы
    { id: 1, title: 'Тема', field: 'subject' },
    { id: 2, title: 'Время начала', field: 'timestamp_start' },
    { id: 3, title: 'Время завершения', field: 'timestamp_end' },
  ];
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации

  useEffect(() => {
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  useEffect(() => {
    GetVCMeetings();
  }, [tableState]);

  // Обработка действий со видеоконференциях
  // Получение данных о видеоконференциях
  async function GetVCMeetings() {
    try {
      const reqData = {
        type: 'getVCMeetings',
        tableState,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_vc_meetings', reqData);
      if (result.success) {
        setMeetings(result.data);
        setTotal(result.total);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'GetVСMeetings');
    }
  }

  function openCard(id) {
    setOpenedMeeting(id);
    setShowCard(true);
  }
  // Если параметр отображения карточки - true, показываем карточку
  if (showCard) {
    return (
      <VideoConferencesCard
        meetingId={openedMeeting}
        handler={GetVCMeetings}
        setShow={setShowCard}
        // refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }
  // Иначе показываем таблицу

  if (isAuthorized) {
    return (
    <div id="video-conference_table">
    <ServiceBody>
    <PaginatedTable
      id="video-conference__meetings"
      headers={headers}
      state={tableState}
      setState={setTableState}
      total={total}
      optButton={{
        title: 'Создать',
        handler: () => openCard('new'),
      }}
    >
        {meetings.map((row) => (
          <TRow key={row.id}>
            {headers.map((column) => {
              const { id, field } = column; // дектруктуризация объекта колонки
              const value = row?.[field]; // значение текущего ключа в объекте строки
              return (<TData key={id} loading={isLoading}>
                {typeof (value) === 'object' ? value.join(', ') : value ?? '-'}
                      </TData>);
            })}

<TData onClick={() => openCard(row.id)} loading={isLoading}>
              <img src="../../icons/file-text.svg" alt="file" />
</TData>
          </TRow>
        ))}
    </PaginatedTable>
    </ServiceBody>
    </div>
    );
  } return <Cap />;
}

export default VideoConferences;
