/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { log } from '../../utils/functions/others';
import ServiceBody from '../../components/Service/ServiceBody';
import InputFit from '../../components/UI/InputFit/InputFit';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import TFoot from '../../components/Table/TFoot';
import Loader from '../../components/UI/Loader';

/**
 * @component Таблица настроек сервиса
 * @prop {table} string - название таблицы с настройками в БД
 * @prop {title} string - название таблицы
*/
function ParamTable(props) {
  const { table, title } = props;

  useEffect(() => {
    getTableParam(table);
  }, [table]);

  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [params, setParams] = useState([]); // параметры сервиса
  const [changedParams, setChangedParams] = useState({}); // измененные параметры сервиса
  const headers = [
    {
      id: 1, title: 'Название', field: 'name', align: 'left',
    },
    {
      id: 2, title: 'Описание', field: 'description', align: 'left',
    },
    {
      id: 3, title: 'Значение', field: 'value', align: 'right',
    },
  ];

  // Получить параметры из БД
  async function getTableParam(tableName) {
    try {
      const reqData = {
        id: 1,
        type: 'getTableParam',
        table: tableName,
      };

      setIsLoading(true);
      const result = await clientSendData('POST', '/get_table_param', reqData); // запрос в БД
      if (result) {
        setParams(result); // иначе данные записываются в состояние
        setChangedParams({}); // сбросить измененные параметры (при изменении таблицы или сохранении изменений)
      } else return;
    } catch (error) {
      catchHandler(error, 'getTableParam');
    } finally {
      setIsLoading(false);
    }
  }

  async function saveParams(paramsObj, prevParams, tableName) {
    try {
      const reqData = {
        type: 'saveParam',
        params: paramsObj,
        prevParams,
        table: tableName,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/save_param', reqData); // запрос в БД
      if (result) {
        let message = 'Изменения: ';
        const msgParams = [];
        for (const key in paramsObj) {
          if (Object.hasOwn(paramsObj, key)) {
            const prevParam = prevParams.find((param) => param.name === key);
            msgParams.push(`параметр ${key} изменен с ${prevParam.value} на ${paramsObj[key]}`);
            // Добавление или удаление учетки для hp slice
            if (tableName === 'vc_param') {
              findHPsToManipulate(paramsObj, prevParam);
            }
          }
        }
        message += msgParams.join(', ');
        log({ message });
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'saveParam');
      setIsLoading(false);
    }
  }

  async function findHPsToManipulate(newObj, oldObj) {
    const new_obj = JSON.parse(newObj.hp_slice_IPs);
    const old_obj = JSON.parse(oldObj.value);
    if (JSON.stringify(new_obj) !== JSON.stringify(old_obj)) {
      const toDisable = [];
      const toEnable = [];
      for (const key in new_obj) {
        if (new_obj[key].login !== old_obj[key]?.login) {
          toEnable.push(new_obj[key].login);
        }
      }

      for (const key in old_obj) {
        if (old_obj[key].login !== new_obj[key]?.login) {
          toDisable.push(old_obj[key].login);
        }
      }

      await manipulateAccount(toEnable, toDisable);
    }
  }

  // async function findDiff(newObj, oldObj) {
  //   if (newObj.hp_slice_IPs !== oldObj.value) {
  //     const new_obj = JSON.parse(newObj.hp_slice_IPs);
  //     const old_obj = JSON.parse(oldObj.value);
  //     const newValues = Object.keys(new_obj);
  //     const oldValues = Object.keys(old_obj);
  //     let diff;
  //     const logins = [];

  //     const moreOrLess = newValues.length > oldValues.length;
  //     switch (moreOrLess) {
  //       case true:
  //         for (const oldVal of oldValues) {
  //           diff = newValues.filter((newValue) => newValue !== oldVal);
  //         }
  //         for (const key of diff) {
  //           logins.push(JSON.parse(newObj.hp_slice_IPs)[key].login);
  //         }
  //         await manipulateAccount(logins, 'enable');
  //         break;

  //       case false:
  //         for (const newVal of newValues) {
  //           diff = oldValues.filter((oldVal) => oldVal !== newVal);
  //         }
  //         for (const key of diff) {
  //           logins.push(JSON.parse(oldObj.value)[key].login);
  //         }
  //         await manipulateAccount(logins, 'disable');
  //         break;
  //       default: console.log('no_changes');
  //     }
  //   }
  // }

  async function manipulateAccount(toEnable, toDisable) {
    try {
      const reqData = {
        type: 'manipulateHpsliceAccount',
        toEnable,
        toDisable,
      };
      await clientSendData('POST', '/manipulate_hpslice_account', reqData);
    } catch (error) {
      catchHandler(error, 'manipulateHpsliceAccount');
    }
  }

  function changeValue(key, value) {
    let checkedValue = value;
    if (typeof value === 'boolean') checkedValue = String(+checkedValue);
    setChangedParams(((prevState) => ({ ...prevState, [key]: checkedValue })));
  }

  async function saveCurrentParams() {
    try {
      await saveParams(changedParams, params, table);
      getTableParam(table);
    } catch (error) {
      catchHandler(error, 'saveCurrentParams');
    }
  }

  // Обработка кнопки отмена
  function cancel() {
    getTableParam(table);
  }

  function defineValue(type, value) {
    switch (type) {
      case 'bool': return Boolean(+value);
      default: return value;
    }
  }

  const isMarked = (key) => {
    const oldValue = params.find((row) => row.name === key)?.value;
    const newValue = changedParams?.[key];
    return (Object.hasOwn(changedParams, key) && newValue !== oldValue);
  };

  if (isLoading) return <Loader />;
  return (
    <ServiceBody id="admin-panel">
      <Table id="admin-panel__table">
        <THead
          title={title}
          headers={headers}
          array={params}
          setArray={setParams}
        />
        <TBody>
          {params.map((param) => {
            const {
              name, value, type,
            } = param;
            const checkedValue = defineValue(type, value);
            return (
              <TRow key={param.id} mark={isMarked(name)}>
                {headers.map((row) => {
                  const { field, align } = row;
                  if (field !== 'value') {
                    return (
                      <TData key={row.id} loading={isLoading} align={align}>
                        {param[field]}
                      </TData>
                    );
                  }
                  return (
                    <TData key={row.id} loading={isLoading} align={align}>
                      {isLoading ? <Loader /> : (
                      <InputFit
                        value={checkedValue}
                        onChange={(val) => changeValue(name, val)}
                        type={type}
                      />)}
                    </TData>
                  );
                })}
              </TRow>
            );
          })}
        </TBody>
        {Object.keys(changedParams).length > 0 && (
        <TFoot>
          <TRow>
            <TData onClick={cancel} loading={isLoading}>Отменить</TData>
            <TData onClick={saveCurrentParams} loading={isLoading}>Сохранить</TData>
          </TRow>
        </TFoot>)}
      </Table>
    </ServiceBody>
  );
}

export default ParamTable;
