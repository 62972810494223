import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StaffCV from './StaffCV';
import CV from './CV';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { setOperInfo } from '../../utils/functions/others';
import { clientSendData } from '../../utils/functions/requests';
import { authorization } from '../../utils/functions/authenticate';

function MainCVPage() {
  // const isAdmin = false;
  const [isLoading, setIsLoading] = useState(false);

  const [currentAdmins, setCurrentAdmins] = useState([]);
  const [currentHr, setCurrentHr] = useState([]);
  const [currentCertificates, setCurrentCertificates] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const location = useSearchParams()[0].get('location'); // интерфейс откуда заходит пользователь
  const [currentUser, setCurrentUser] = useState();
  const isAdmin = currentAdmins.includes(String(currentUser));
  const isHr = currentHr.includes(String(currentUser));
  const isCertificates = currentCertificates.includes(String(currentUser));

  useEffect(() => {
    authorizationCheck();
    firstRender();
  }, []);

  async function authorizationCheck() {
    setIsLoading(true);
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
    // if (checkAuthorization) await getSdInterfaces(); // получить интерфейсы пользователя
    setIsLoading(false);
  }

  async function firstRender() {
    try {
      const reqData = {
        type: 'cvAuthorization',
      };
      const result = await clientSendData('POST', '/cv_authorization', reqData); // запрос в БД
      if (result) {
        setCurrentUser(result.current_user);
        setCurrentAdmins(result.data);
        setCurrentHr(result.admins_hr);
        setCurrentCertificates(result.admins_certificates);
      }
    } catch (error) {
      catchHandler(error, 'firstRender');
    }
  }

  console.log(isAdmin);
  console.log(isHr);
  console.log(isCertificates);
  console.log(currentAdmins);
  console.log(currentHr);
  console.log(currentCertificates);

  if (isAuthorized) {
    if (isAdmin || isHr || isCertificates) return <StaffCV isAdmin={isAdmin} isHr={isHr} isCertificates={isCertificates} />;
    // if (isAdmin && location === 'admin') return <CV isAdmin />;
    if (!isAdmin && currentUser) return <CV userId={currentUser} isAdmin={false} isHr={false} isCertificates={false} />;
  }
}

export default MainCVPage;
