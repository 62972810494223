import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { alertFunction } from '../../../../../utils/functions/alertFunction';
import {
  firstHeaders, secondHeaders, mainInfoHeaders, referenceInfo,
} from '../options';
import Table from '../../../../../components/Table/Table';
import THead from '../../../../../components/Table/THead';
import TBody from '../../../../../components/Table/TBody';
import TRow from '../../../../../components/Table/TRow';
import TData from '../../../../../components/Table/TData';
import RowUnchangeable from './RowUnchangeable';
import RowChangeable from './RowChangeable';
import Select from '../../../../../components/UI/Select';
import { clientSendData } from '../../../../../utils/functions/requests';
import { catchHandler } from '../../../../../utils/error_handling/error_handling';
import Button from '../../../../../components/UI/Button/Button';
import { setOperInfo } from '../../../../../utils/functions/others';
import Instruction from '../Instruction';

// Сервис оценок - страница заявления - заявление на оценку - таблица оценки
function TableEvaluation(props) {
  const {
    applicationData, extTasks, setExtTasks, appraisal, setAppraisal, loading, isEditable, projectMasters,
    getNewApplication, isTableVisible, status, isAdmin, isFinalApp, location,
  } = props;
  const {
    general, categories, tasks,
  } = applicationData;
  const grade = useSearchParams()[0].get('grade');
  const period = useSearchParams()[0].get('period');
  const operInfo = useSelector((state) => state.oper_info); // id пользователя из сессии
  const user_id = useSelector((state) => state?.oper_info?.user_id); // id текущего пользователя
  const application = general?.application; // объект заявления
  const isUser = application?.user_id === user_id; // является ли текущий пользователь заявителем
  const isApprover = application?.approver_id === user_id; // является ли текущий пользователь согласующим
  const isMainApprover = application?.main_approver_id === user_id; // является ли текущий пользователь подтверждающим
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [image, setImage] = useState(null); // источник изображения
  const [chosenMaster, setChosenMaster] = useState({});
  const [extInfo, setExtInfo] = useState(false);
  const [reference, setReference] = useState(false);
  const [reference1, setReference1] = useState(false);
  const isMonitoringUser = applicationData.monitoringUsers.includes(user_id); // является ли текущий пользователь исполнителем мониторинга
  // ID новой оценки сразу после ее создания
  let newAppraisalId;
  const approver_id = general.application ? general.application.approver_id : '';
  const employee_id = general.application ? general.application.user_id : '';
  const avatar_getId = (isApprover || isMainApprover || isAdmin) ? employee_id : approver_id;
  const [role, setRole] = useState('');
  const performers = ['НГ', 'А0', 'А1'];
  const projectRoles1 = [
    { id: 1, title: 'Исполнитель' },
  ];
  const projectRoles2 = [
    { id: 1, title: 'Исполнитель' },
    { id: 2, title: 'Руководитель команды' },
    { id: 3, title: 'Руководитель проекта' },
    { id: 4, title: 'Руководитель проверки' },
  ];

  useEffect(() => {
    getAvatar();
    if (status === 1) {
      setValues();
    }
  }, []);
  // function defineRole(project_id) {
  //   return 'Исполнитель';
  // }
  // Сортировка по ключу order в объекте массива
  function sortByOrder(array) {
    if (!array) return [];
    // eslint-disable-next-line no-unsafe-optional-chaining
    return array.sort((a, b) => a?.object?.order - b?.object?.order);
  }

  function createAppPeriodDate(periodDates) {
    const periodSplit = periodDates.split(' - ');
    const dateSplit = periodSplit[0].split('-');
    dateSplit[0] = '10';
    return dateSplit.reverse().join('-');
  }

  async function getAvatar() {
    try {
      const reqData = {
        type: 'getEvAvatar',
        avatar_getId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_ev_avatar', reqData);
      if (result) {
        const reader = new FileReader(); // API чтения файлов
        const resBlob = new Blob([new Uint8Array(result[0].avatar.data)]);
        reader.onloadend = ((e) => setImage(e.target.result)); // установка аватара из БД
        reader.readAsDataURL(resBlob);
      } else setImage('');
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getEvAvatar');
      setIsLoading(false);
    }
  }

  // Сохранить новую оценку
  async function saveGeneralInfo() {
    await createNewApplication();
    setSearchParams({ app: newAppraisalId });
    getNewApplication(newAppraisalId);
    setValues();
  }

  // function addNewTask() {
  //   if (!extTasks) {
  //     const newTask = {
  //       id: 'ext_task_0',
  //       object: {
  //         mark: [],
  //         order: 0,
  //         title: 'Дополнительная задача 1',
  //         category_id: 'ext',
  //       },
  //     };
  //     setExtTasks([newTask]);
  //   } else if (extTasks?.length <= 20) {
  //     const newTask = {
  //       id: `ext_task_${extTasks.length}`,
  //       object: {
  //         mark: [],
  //         order: extTasks?.length,
  //         title: `Дополнительная задача ${extTasks.length + 1}`,
  //         category_id: 'ext',
  //       },
  //     };
  //     setExtTasks([...extTasks, newTask]);
  //   } else alertFunction('max_tasks', 'clientPost');
  // }

  // Выбор сотрудника
  async function chooseMaster(master) {
    setChosenMaster((state) => ({
      ...state, master_id: master.id, master_title: master.title, master_grade: master.grade,
    }));
  }

  // Дефолтное заполнение оценки
  function setValues() {
    for (const task of tasks) {
      const category_id = task.object.category_id;
      const task_id = task.id;
      const task_title = task.object.title;
      // const changes = { value_int: 7, value_char: 'B' };
      if (category_id !== 11) {
        setAppraisal((appraisal) => ({
          ...appraisal,
          [category_id]: {
            ...appraisal?.[category_id],
            [task_id]: {
              value_int: 7, value_char: 'B', category_id, task_title, task_id,
            },
          },
        }));
      }
    }
  }
  // Проверка на наличие роли и руководителя перед созданием новой оценки
  const isCreateDisabled = Boolean(
    !role || !chosenMaster?.master_id,
  );

  // Создать новое заявление
  async function createNewApplication() {
    try {
      const now = new Date();
      const timestamp = new Date(now.setHours(now.getHours() + 3)).toISOString();
      const userId = user_id;
      const managerGrades = ['А5', 'А6', 'А7', 'НГ'];
      let isMonitoring;
      if (managerGrades.includes(general.grade)) {
        isMonitoring = true;
      } else {
        isMonitoring = false;
      }
      const application_object = {
        project_id: general.project_id,
        contract_id: general.contract_id,
        client_id: general.client_id,
        approver_id: chosenMaster.master_id,
        user_role: role,
        employee_grade: grade,
        date_start: general.date_start,
        date_end: general.date_end,
        fin_year: createAppPeriodDate(period),
        user_id: userId,
        changes_id: userId,
        status_id: 1,
        monitoring: isMonitoring,
        draft: true,
        version: 1,
        created_at: timestamp,
      };
      const reqData = {
        type: 'createNewApplication',
        url: operInfo?.url,
        alias: operInfo?.alias,
        application: application_object,
        grade,
        period,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/create_new_application', reqData); // запрос в БД
      // Если не удалось найти утверждающего для 2 этапа согласования - уведомление
      // if (result?.main_approver === false) {
      //   await alertFunction('no_main_approver', 'clientPost');
      // } else {
      newAppraisalId = result.id;
      // }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'createNewApplication'); // обработка ошибок
    }
  }

  function onBackClick() {
    if (isFinalApp) {
      setSearchParams({ employee: application?.user_id });
    } else { setSearchParams(''); }
  }

  return (
    <>
    <div className="button-container" style={{ display: 'flex', width: '100%' }}>
 <Button onClick={onBackClick} className="text-button">
  <div className="evl">
          <span className="arrow">←</span>
          Вернуться к оценкам
  </div>
 </Button>
    </div>

{/* --------Информация о проекте--------*/}
<div className="panel_container">
  <div className="user-page__avatar-wrapper">
      <img
        className="user-page__user-avatar"
        src={image || '../../icons/user_color.png'}
        alt="avatar"
      />
  </div>

  <div className="div-panel_ev">
  { (isApprover || isMainApprover || isAdmin)
    ? <span className="panel_string">
<p className="title">Сотрудник:</p>
<p className="data">
{general?.worker_title}
<span className="task_info" onPointerEnter={() => setExtInfo(true)} onPointerLeave={() => setExtInfo(false)}>
<div className="question_mark">
?
{extInfo && <div className="help-card">
   <span>
<p className="help-title">Грейд: </p>
{general?.grade}
   </span>
   <span>
   <p className="help-title">Должность: </p>
{general?.title}
   </span>
   <span>
   <p className="help-title">МВЗ: </p>
{general?.mvz}
   </span>
   <span>
   <p className="help-title">Подразделение: </p>
{general?.department}
   </span>
            </div>}

</div>
</span>
</p>
      </span>
    : <span className="panel_string">
<p className="title">Руководитель:</p>
<p className="data">{general.approver_title ? general.approver_title : chosenMaster.master_title}</p>
      </span>}
<span className="panel_string">
<p className="title">Клиент:</p>
<p className="data">{applicationData.general.client_title}</p>
</span>
<span className="panel_string">
<p className="title">Проект:</p>
<p className="data">{applicationData.general.project_title}</p>
</span>
<span className="panel_string">
<p className="title">Объем проекта (час.):</p>
<p className="data">-</p>
</span>
<span className="panel_string">
<p className="title">Участие сотрудника в проекте (час.):</p>
<p className="data">{applicationData.general.workload}</p>
</span>
<span className="panel_string">
<p className="title">Роль в проекте:</p>
<p className="data">{general.user_role ? general.user_role : role}</p>
</span>
  </div>
</div>

{/* --------Выбор роли и руководителя--------*/}
{!isTableVisible && !general.approver_title
&& <div className="choice_options">
      <div className="optionsContainer">
      <div className="option">
        <span className="choice-title">Выберите роль:</span>
        <Select id="role" array={performers.includes(general.grade) ? projectRoles2 : projectRoles1} onChoose={(e) => setRole(e.title)} defaultValue={role} />
      </div>
      <div className="option">
        <span className="choice-title">Выберите руководителя:</span>
        <Select id="master" array={projectMasters} onChoose={chooseMaster} defaultValue={chosenMaster.master_title} search />
      </div>
      </div>
      <Button onClick={saveGeneralInfo} disabled={isCreateDisabled}>Сохранить выбор</Button>
   </div>}

{(isTableVisible || general.approver_title)
        && <Table id="evaluation__evaluation-table" loading={loading} drop_down>

            {/* --------Общая информация--------*/}

            <THead>
                <TRow>
                    {/* Заголовки */}
                    {/* {firstHeaders.map((item) => <TData key={item.id}>{item.title}</TData>)} */}
                    {/* <TData>Роль в проекте</TData> */}
                </TRow>
            </THead>
            <TBody>
                <TRow>
                    {/* Информация */}
                    {/* {firstHeaders.map((item) => (
                        <TData key={item.id}>
                            {general?.[item.field]}
                        </TData>
                    ))} */}
                    {/* <TData>{defineRole(general?.id)}</TData> */}
                </TRow>
            </TBody>

            {/* --------Проектная оценка--------*/}

            <THead>
                <TRow>
                    {/* Заголовки */}
                    {secondHeaders.map((item) => <TData key={item.id}>
                      {item.title}
                      {item.id === 3 ? <span className="reference_info" onPointerEnter={() => setReference(true)} onPointerLeave={() => setReference(false)}>
<div className="question_mark">
?
{reference && <div className="reference">

   <p className="help-title">A (10-9): </p>
<span>
  {referenceInfo.A}
</span>
   <p className="help-title">B (8-6): </p>
   <span>
   {referenceInfo.B}
   </span>
   <p className="help-title">C (5-3): </p>
   <span>
   {referenceInfo.C}
   </span>
   <p className="help-title">D (2-1): </p>
   <span>
   {referenceInfo.D}
   </span>
              </div>}
</div>
                                       </span> : ''}
{item.id === 2 ? <span className="reference_info" onPointerEnter={() => setReference1(true)} onPointerLeave={() => setReference1(false)}>
<div className="question_mark">
?
{reference1 && <div className="reference">

   <p className="help-title">A (10-9): </p>
<span>
  {referenceInfo.A}
</span>
   <p className="help-title">B (8-6): </p>
   <span>
   {referenceInfo.B}
   </span>
   <p className="help-title">C (5-3): </p>
   <span>
   {referenceInfo.C}
   </span>
   <p className="help-title">D (2-1): </p>
   <span>
   {referenceInfo.D}
   </span>
               </div>}
</div>
                 </span> : ''}

                                                 </TData>)}
                </TRow>
            </THead>
            <TBody>
                {/* Информация */}
                {sortByOrder(categories).map((category) => {
                  const { title } = category.object;
                  return (
                    // Категория с задачами
                    <React.Fragment key={category.id}>

                        {/* Наименование категории */}
                        {/* <TRow active>
                            <TData>{title}</TData>
                        </TRow> */}

                        {/* Задачи */}
                        {sortByOrder(tasks).map((task) => {
                          const { id, object } = task; // задача
                          const rowData = { // Данные для строки
                            category_id: object.category_id, // id категории
                            task_id: id, // id задачи
                            task_title: ` ${object.title}`, // название задачи
                            //    mark: object?.mark.includes(general?.grade), // Параметр выделения строки цветом
                            mark: object?.type === 'additional',
                            order: object.order,
                          };
                          if (object.category_id === category.id && category.id !== 11) {
                            if (isEditable && !isMonitoringUser) {
                              return (
                                    <RowChangeable
                                      key={id}
                                      data={rowData}
                                      isUser={isUser}
                                      isApprover={isApprover}
                                      isMainApprover={isMainApprover}
                                      appraisal={appraisal}
                                      setAppraisal={setAppraisal}
                                      isMonitoringUser={isMonitoringUser}
                                      isAdmin={isAdmin}
                                      location={location}
                                      role={general.user_role}
                                    />);
                            }
                            return (
                                    <RowUnchangeable
                                      key={id}
                                      data={rowData}
                                      appraisal={appraisal}
                                      role={general.user_role}
                                    />);
                          } if (object.category_id === category.id && isMonitoringUser) {
                            return (
                              <RowChangeable
                                key={id}
                                order
                                data={rowData}
                                isUser={isUser}
                                isApprover={isApprover}
                                isMainApprover={isMainApprover}
                                appraisal={appraisal}
                                setAppraisal={setAppraisal}
                                isMonitoringUser={isMonitoringUser}
                                isAdmin={isAdmin}
                                location={location}
                              />
                            );
                          } return null;
                        })}
                    </React.Fragment>
                  );
                })}

                {/* Дополнительные задачи */}
                {/* {extTasks?.length > 0 && <TRow active><TData>Дополнительные задачи</TData></TRow>}
                {extTasks?.length > 0 && extTasks.map((task) => {
                  const { id, object } = task; // задача
                  const rowData = { // Данные для строки
                    category_id: 'ext', // id категории
                    task_id: id, // id задачи
                    task_title: object.title, // название задачи
                    mark: object?.mark.includes(general?.grade), // Параметр выделения строки цветом
                  };
                  if (isEditable) {
                    return (
                      <RowChangeable
                        key={id}
                        data={rowData}
                        isUser={isUser}
                        isApprover={isApprover}
                        isMainApprover={isMainApprover}
                        appraisal={appraisal}
                        setAppraisal={setAppraisal}
                        extTasks={extTasks}
                        setExtTasks={setExtTasks}
                      />
                    );
                  }
                  return (
                    <RowUnchangeable
                      key={id}
                      data={rowData}
                      appraisal={appraisal}
                    />
                  );
                })} */}
            </TBody>

            {/* Управление доп. задачами (для руководителя) */}
            {/* {(isApprover || isMainApprover) && ( */}
            {/* <TFoot>
                <TRow>
                    <TData onClick={addNewTask}> Добавить задачу </TData>
                </TRow>
            </TFoot> */}
            {/* )} */}
           </Table>
}
    </>
  );
}

export default TableEvaluation;
