import React, { useEffect, useState } from 'react';
import Card from '../../components/Card/Card';
import CardFooter from '../../components/Card/CardFooter';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import InputSearch from '../../components/UI/InputSearch';
import { alertFunction } from '../../utils/functions/alertFunction';

function VacationCard(props) {
  const {
    openedVacation, setOpenedVacation, setShow, vacations, setVacations, getVacations, actionType,
  } = props;

  const [users, setUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState({});
  const [current_year, setCurrentYear] = useState(new Date().getFullYear());
  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      const reqData = {
        type: 'getUsersPortal',
      };
      const result = await clientSendData('POST', '/get_users_portal', reqData); // запрос в БД
      if (result) {
        setUsers(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getUsersPortal'); // обработка ошибок
    }
  }

  async function saveNewVacation() {
    try {
      setOpenedVacation((prev) => prev, openedVacation.year = current_year);
      const reqData = {
        type: 'saveNewVacationAdmin',
        newVctnData: openedVacation,
        location: 'admin',
        interface_page: 'team',
        action: actionType,
      };
      const result = await clientSendData('POST', '/save_new_vacation_admin', reqData); // запрос в БД
      if (result) {
        await alertFunction('save_settings', 'clientPost');
        await getVacations();
        setShow(false);
      }
    } catch (error) {
      catchHandler(error, 'saveNewVacationAdmin'); // обработка ошибок
    }
  }

  async function searchWrapper(e) {
    if (e.target.value.length === 0) setSelectUsers({});
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'vacationSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData(
          'POST',
          '/vacation_search_user',
          reqData,
        );
        setSelectUsers({
          id: e.target.value,
          title: e.target.value,
          mail: e.target.value,
        });
        if (result?.success) setUsers(result.data);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'vacationSearchUser');
      return [];
    }
  }

  function saveVacation() {
    setOpenedVacation((prev) => prev, openedVacation.year = current_year);
    if (vacations.filter((el) => el.user_id === openedVacation.user_id).length !== 0) {
      setOpenedVacation({ ...openedVacation, days: openedVacation.days });
      const updatedTasks = vacations.map((task, i) => {
        if (task.user_id === openedVacation.user_id) {
          // Если задача редактировалась
          return openedVacation;
        }
        // Если задача не редактировалась
        return task;
      });
      setVacations(updatedTasks);
    } else {
      setVacations([...vacations, openedVacation]);
    }
    setShow(false);
  }

  return (
    <Card setShow={setShow} id="admin-vacation-card">
      <CardBody>
        {/* <span className="wrapper"> */}
        <p className="title">ФИО:</p>
      <InputSearch
        array={users}
        onChoose={(user) => setOpenedVacation({
          user_id: user.id, name: user.title, position: user.position, group: user.group_id,
        })}
        onSearch={(e) => searchWrapper(e)}
        defaultValue={openedVacation?.name}
        placeholder="Введите пользователя"
      />
      <p className="title">Доступные дни:</p>
      <Input
        placeholder="Кол-во дней"
        onChange={(e) => setOpenedVacation({ ...openedVacation, days_available: e.target.value })}
        defaultValue={openedVacation?.days}
      />
      <p className="title">Год:</p>
      <Input type="number" min="1900" max="2099" step="1" value={current_year} onChange={(e) => setCurrentYear(e.target.value)} />
        {/* </span> */}
      </CardBody>
      <CardFooter>
        <Button onClick={saveNewVacation}>Сохранить</Button>
      </CardFooter>
    </Card>
  );
}

export default VacationCard;
