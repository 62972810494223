import React, { useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import CardSetting from '../../components/Card/CardSetting';
import Select from '../../components/UI/Select';
import Input from '../../components/UI/Input';
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import TextArea from '../../components/UI/TextArea';

function Message() {
  const [message, setMessage] = useState({
    client: 'Клиент',
    contractNum: '12345678-9-10',
    releaseDate: '26.11.2024',
    taskSupervisor: 'Руководителев Начальник Начальникович',
    operationType: 'Разовая необычная операция',
    operTypeCriteria: 'Критерий 1, критерий 2',
    operationContent: 'Обычная ничем не примечательная операция, такая же как и все остальные',
    additionalData: '№ Договора: 12345678-9-10, Номер операции: 1',
    operationComment: 'Комментарий к операции',
    attachment: 'посмотреть вложение',
    executionDate: '01.12.2024',
    operationSumm: ' 5000',
    currency: 'Евро',
    foreignExecutor: 'Джон Доу, партнер по сделке, очень надежный человек',
    employee: 'Митина Катя, простой непритязательный сотрудник',
  });
  const [editable, setEditable] = useState(null);
  console.log(message);
  return (

    <ServiceBody id="PODFT-message">
      <div className="msg_container">
       {/* Наименование клиента */}
       <CardSetting title="Наименование клиента:">
        {editable === 'client' ? <TextArea
          onChange={(e) => setMessage({ ...message, client: e.target.value })}
          onBlur={() => setEditable(null)}
          onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
          defaultValue={message.client}
        /> : <p className="clickable" onClick={() => setEditable('client')}>{message.client}</p>}
       </CardSetting>

         {/* Номер договора */}
         <CardSetting title="Номер договора:">
         {editable === 'contractNum' ? <TextArea
           onChange={(e) => setMessage({ ...message, contractNum: e.target.value })}
           onBlur={() => setEditable(null)}
           onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
           defaultValue={message.contractNum}
         /> : <p className="clickable" onClick={() => setEditable('contractNum')}>{message.contractNum}</p>}
         </CardSetting>

          {/* Дата выпуска отчетного документа  */}
          <CardSetting title="Дата выпуска отчетного документа :">
          {editable === 'releaseDate' ? <Input
            type="date"
            onChange={(e) => setMessage({ ...message, releaseDate: e.target.value })}
            onBlur={() => setEditable(null)}
            onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
            defaultValue={message.releaseDate}
          /> : <p className="clickable" onClick={() => setEditable('releaseDate')}>{message.releaseDate}</p>}
          </CardSetting>

          {/* Руководитель задания */}
         <CardSetting title="Руководитель задания:">
         {editable === 'taskSupervisor' ? <Select
           search
           onChoose={(e) => setMessage({ ...message, taskSupervisor: e.target.value })}
           onBlur={() => setEditable(null)}
           onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
           defaultValue={message.taskSupervisor}
         /> : <p className="clickable" onClick={() => setEditable('taskSupervisor')}>{message.taskSupervisor}</p>}
         </CardSetting>

         {/* Вид операции */}
         <CardSetting title="Вид операции:">
         {editable === 'operationType' ? <Select
           onChoose={(e) => setMessage({ ...message, operationType: e.target.value })}
           onBlur={() => setEditable(null)}
           onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
           defaultValue={message.operationType}
         /> : <p className="clickable" onClick={() => setEditable('operationType')}>{message.operationType}</p>}
         </CardSetting>

         {/* Критерии выбора вида операции */}
         <CardSetting title="Критерии выбора вида операции:">
         {editable === 'operTypeCriteria' ? <Select
           onChoose={(e) => setMessage({ ...message, operTypeCriteria: e.target.value })}
           onBlur={() => setEditable(null)}
           onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
           defaultValue={message.operTypeCriteria}
         /> : <p className="clickable" onClick={() => setEditable('operTypeCriteria')}>{message.operTypeCriteria}</p>}
         </CardSetting>

         {/* Содержание (характер) операции (сделки) */}
         <CardSetting title="Содержание (характер) операции:">
         {editable === 'operationContent' ? <TextArea
           onChange={(e) => setMessage({ ...message, operationContent: e.target.value })}
           onBlur={() => setEditable(null)}
           onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
           defaultValue={message.operationContent}
         /> : <p className="clickable" onClick={() => setEditable('operationContent')}>{message.operationContent}</p>}
         </CardSetting>

          {/* Иные данные по операции (№ договора, и т.п.) */}
          <CardSetting title="Иные данные по операции:">
          {editable === 'additionalData' ? <TextArea
            onChange={(e) => setMessage({ ...message, additionalData: e.target.value })}
            onBlur={() => setEditable(null)}
            onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
            defaultValue={message.additionalData}
          /> : <p className="clickable" onClick={() => setEditable('additionalData')}>{message.additionalData}</p>}
          </CardSetting>

          {/* Комментарии */}
          <CardSetting title="Комментарии:">
          {editable === 'operationComment' ? <TextArea
            onChange={(e) => setMessage({ ...message, operationComment: e.target.value })}
            onBlur={() => setEditable(null)}
            onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
            defaultValue={message.operationComment}
          /> : <p className="clickable" onClick={() => setEditable('operationComment')}>{message.operationComment}</p>}
          </CardSetting>

           {/* Документы, подтверждающие сделку */}
           <CardSetting title="Документы, подтверждающие сделку:">
             {/* <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" /> */}
           <p className="clickable">Посмотреть вложение 📑</p>
           </CardSetting>

            {/* Дата совершения операции (сделки */}
          <CardSetting title="Дата совершения операции (сделки):">
          {editable === 'executionDate' ? <Input
            type="date"
            onChange={(e) => setMessage({ ...message, executionDate: e.target.value })}
            onBlur={() => setEditable(null)}
            onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
            defaultValue={message.executionDate}
          /> : <p className="clickable" onClick={() => setEditable('executionDate')}>{message.executionDate}</p>}
          </CardSetting>

          {/* Сумма операции (сделки) */}
          <CardSetting title="Сумма операции (сделки):">
          {editable === 'operationSumm' ? <TextArea
            onChange={(e) => setMessage({ ...message, operationSumm: e.target.value })}
            onBlur={() => setEditable(null)}
            onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
            defaultValue={message.operationSumm}
          /> : <p className="clickable" onClick={() => setEditable('operationSumm')}>{message.operationSumm}</p>}
          </CardSetting>

           {/* Валюта проведения операции (сделки) */}
           <CardSetting title="Валюта проведения операции (сделки):">
           {editable === 'currency' ? <TextArea
             onChange={(e) => setMessage({ ...message, currency: e.target.value })}
             onBlur={() => setEditable(null)}
             onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
             defaultValue={message.currency}
           /> : <p className="clickable" onClick={() => setEditable('currency')}>{message.currency}</p>}
           </CardSetting>

           {/* Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку) */}
           <CardSetting title="Сведения о лице (лицах), иностранной структуры без образования юридического лица, проводящих операцию (сделку):">
           {editable === 'foreignExecutor' ? <TextArea
             onChange={(e) => setMessage({ ...message, foreignExecutor: e.target.value })}
             onBlur={() => setEditable(null)}
             onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
             defaultValue={message.foreignExecutor}
           /> : <p className="clickable" onClick={() => setEditable('foreignExecutor')}>{message.foreignExecutor}</p>}
           </CardSetting>

            {/* Сведения о работнике, составившем внутреннее сообщение об операции (сделке)  */}
            <CardSetting title="Сведения о сотруднике, составившем внутреннее сообщение об операции (сделке):">
            {editable === 'employee' ? <Select
              search
              onChoose={(e) => setMessage({ ...message, employee: e.target.value })}
              onBlur={() => setEditable(null)}
              onKeyUp={(e) => { if (e.key === 'Enter') setEditable(null); }}
              defaultValue={message.employee}
            /> : <p className="clickable" onClick={() => setEditable('employee')}>{message.employee}</p> }
            </CardSetting>
      </div>
    </ServiceBody>
  );
}

export default Message;
