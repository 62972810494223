import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import Select from '../../components/UI/Select';
import TextArea from '../../components/UI/TextArea';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import CardSetting from '../../components/Card/CardSetting';
import classes from '../../components/UI/Button/button.module.scss';
import './recrutment.scss';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { alertFunction } from '../../utils/functions/alertFunction';
import CheckBox from '../../components/UI/CheckBox';

// 1 экран формы
function Step1({
  formData,
  setFormData,
  getData,
  currentStatus,
}) {
  return (
<div
  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px' }}
>
  {/* Первый столбец */}
  <div>
    <CardSetting title="Компания:" invalid={!formData.company}>
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="company"
        array={getData.company}
        onChoose={(e) => setFormData({ ...formData, company: e.title })}
        defaultValue={formData.company || ''}
      />
    ) : (
      <span>{formData.company}</span>
    )}
    </CardSetting>
    <CardSetting title="Уровень должности:" invalid={!formData.job_level}>
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="job_level"
        array={getData.job_level}
        onChoose={(e) => setFormData({ ...formData, job_level: e.title, qualifications: e.description })}
        defaultValue={formData.job_level || ''}
      />
    ) : (
      <span>{formData.job_level}</span>
    )}
    </CardSetting>

      <CardSetting title="Подразделение:" invalid={!formData.department}>

      <Select
        id="department"
        array={getData.department}
        onChoose={(e) => setFormData({ ...formData, department: e.title })}
        defaultValue={formData.department || ''}
        search
      />
      </CardSetting>
  </div>

<div>
      <CardSetting title="МВЗ:" invalid={!formData.mvz}>
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
       <Select
         id="mvz"
         array={getData.mvz}
         onChoose={(e) => setFormData({ ...formData, mvz: e.title })}
         defaultValue={formData.mvz || ''}
         search
       />
      ) : (
      <span>{formData.mvz}</span>
      )}
      </CardSetting>

      <CardSetting title="Руководитель МВЗ:" invalid={!formData.head_mvz}>
  {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
    <Select
      id="head_mvz"
      array={getData.head_mvz} // Убедитесь, что это массив объектов с id и title
      onChoose={(e) => setFormData({ ...formData, head_mvz: { id: e.id, title: e.title } })}
      defaultValue={formData.head_mvz.title || ''}
      search
    />
  ) : (
    <span>{formData.head_mvz.title}</span>
  )}
      </CardSetting>

    <CardSetting title="Количество вакансий:" invalid={!formData.number_of_vacancies}>
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Input
        id="number_of_vacancies"
        onChange={(e) => setFormData({ ...formData, number_of_vacancies: e.target.value })}
        defaultValue={formData.number_of_vacancies || ''}
      />
    ) : (
      <span>{formData.number_of_vacancies}</span>
    )}
    </CardSetting>
</div>

  {/* Первый textarea в первом столбце */}
  <div>
    <CardSetting title="Комментарии по замене вакансии:">
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <TextArea
        placeholder=""
        value={formData.comments || ''}
        onChange={(e) => setFormData({ ...formData, comments: e.target.value })}
      />
    ) : (
      <span>{formData.comments}</span>
    )}
    </CardSetting>
  </div>

  {/* Второй textarea во втором столбце */}
  <div>
    <CardSetting title="Обоснование открытия вакансии:">
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <TextArea
        placeholder=""
        value={formData.justification || ''}
        onChange={(e) => setFormData({ ...formData, justification: e.target.value })}
      />
    ) : (
      <span>{formData.justification}</span>
    )}
    </CardSetting>
  </div>

  {/* Другие элементы */}
  <div>
  <div style={{ gridColumn: '1 / -1' }}>
    <CardSetting title="Проекты:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
        <TextArea
          placeholder=""
          value={formData.projects || ''}
          onChange={(e) => setFormData({ ...formData, projects: e.target.value })}
        />
      ) : (
        <span>{formData.comments}</span>
      )}
    </CardSetting>
  </div>
  {/* <CardSetting title="Проекты:">
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
      <Select
        id="projects"
        array={getData.projects}
        onChoose={handleChoose}
        defaultValue=""
        search
      />
    )}
     {currentStatus !== 1 && (
    <div>
  {formData.projects.length > 0 ? (
    <span>
      {formData.projects.map((project) => project.title).join(', ')}
    </span>
  ) : (
    <span> - </span> // Сообщение, если проектов нет
  )}
    </div>
     )}
  </CardSetting>
  {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
  <CardSetting>
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        minHeight: '40px',
      }}
    >
      {formData.projects.map((project) => (
        <div
          key={project.id}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <span>{project.title}</span>

            <Button
              className={smallCurtain}
              onClick={() => handleRemove(project.id)}
              style={{ marginLeft: '8px' }}
            >
              ✖
            </Button>

        </div>
      ))}
    </div>
  </CardSetting>
  )} */}
  </div>

  <div>
    <CardSetting title="Территориальная локация поиска:" invalid={!formData.location}>
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="location"
        array={getData.location}
        onChoose={(e) => setFormData({ ...formData, location: e.title })}
        defaultValue={formData.location || ''}
        search
      />
    ) : (
      <span>{formData.location}</span>
    )}
    </CardSetting>
    <CardSetting title="Срок закрытия вакансии:">
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
    <Select
      id="closing_date"
      array={getData.closing_date}
      onChoose={(e) => setFormData({ ...formData, closing_date: e.title })}
      defaultValue={formData.closing_date || ''}
    />
    ) : (
    <span>{formData.closing_date}</span>
    )}
    </CardSetting>
  </div>
</div>

  );
}

// 2 экран формы
function Step2({
  formData,
  setFormData,
  getData,
  handleChooseCertificate,
  handleRemoveCertificate,
  handleChooseProgramm,
  handleRemoveProgramm,
  handleChooseSpeciality,
  handleRemoveSpeciality,
  smallCurtain,
  currentStatus,
}) {
  return (
    <div
      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px' }}
    >

<div>
      <CardSetting title="Образование:" invalid={!formData.education}>
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="education"
        array={getData.education}
        onChoose={(e) => setFormData({ ...formData, education: e.title })}
        defaultValue={formData.education || ''}
      />
      ) : (
      <span>{formData.education}</span>
      )}
      </CardSetting>

      <CardSetting title="Профиль образования:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="profile"
        array={getData.profile}
        onChoose={(e) => setFormData({ ...formData, profile: e.title })}
        defaultValue={formData.profile || ''}
      />
      ) : (
      <span>{formData.profile}</span>
      )}
      </CardSetting>

      <CardSetting title="Готовность к командировкам:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="business_trip"
        array={getData.business_trip}
        onChoose={(e) => setFormData({ ...formData, business_trip: e.title })}
        defaultValue={formData.business_trip || ''}
      />
      ) : (
      <span>{formData.business_trip}</span>
      )}
      </CardSetting>
</div>
      <div>
      <CardSetting title="Владение английским языком:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="language_level"
        array={getData.language_level}
        onChoose={(e) => setFormData({ ...formData, language_level: e.title })}
        defaultValue={formData.language_level || ''}
      />
      ) : (
      <span>{formData.language_level}</span>
      )}
      </CardSetting>

      <CardSetting title="Владение иным языком:">
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Input
        type="text"
        id="language_2"
        value={formData.language_2 || ''}
        onChange={(e) => setFormData({ ...formData, language_2: e.target.value })}
      />
      ) : (
      <span>{formData.language_2}</span>
      )}
      </CardSetting>
<CardSetting title="Уровень владения:">
{[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="language_level_2"
        array={getData.language_level}
        onChoose={(e) => setFormData({ ...formData, language_level_2: e.title })}
        defaultValue={formData.language_level_2 || ''}
      />
) : (
      <span>{formData.language_level_2}</span>
)}
</CardSetting>
      </div>
<div>
    <CardSetting title="Аттестаты и сертификаты:" invalid={!formData.certificates}>
    {[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
      <Select
        id="certificate"
        array={getData.certificates}
        onChoose={handleChooseCertificate}
        defaultValue=""
      />
    )}
         {currentStatus !== 1 && (
    <div>
  {formData.certificates.length > 0 ? (
    <span>
      {formData.certificates.map((certificate) => certificate.title).join(', ')}
    </span>
  ) : (
    <span> - </span> // Сообщение, если проектов нет
  )}
    </div>
         )}
    </CardSetting>
</div>
{[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
<div>
    <CardSetting>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          minHeight: '40px',
        }}
      >
        {formData.certificates.map((certificate) => (
          <div
            key={certificate.id}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{certificate.title}</span>

            <Button
              className={smallCurtain}
              onClick={() => handleRemoveCertificate(certificate.id)}
              style={{ marginLeft: '8px' }}
            >
              ✖
            </Button>

          </div>
        ))}
      </div>
    </CardSetting>
</div>
)}
<div>
<CardSetting title="ПК и спец. программы:">
{[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
      <Select
        id="programms"
        array={getData.programms}
        onChoose={handleChooseProgramm}
        defaultValue=""
      />
)}
{!([1, 2, 4, 12, 13, 14].includes(currentStatus)) && (
    <div>
  {formData.programms.length > 0 ? (
    <span>
      {formData.programms.map((programm) => programm.title).join(', ')}
    </span>
  ) : (
    <span> - </span> // Сообщение, если проектов нет
  )}
    </div>
)}
</CardSetting>
</div>
{[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
<div>
<CardSetting>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          minHeight: '40px',
        }}
      >
        {formData.programms.map((programm) => (
          <div
            key={programm.id}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{programm.title}</span>

            <Button
              className={smallCurtain}
              onClick={() => handleRemoveProgramm(programm.id)}
              style={{ marginLeft: '8px' }}
            >
              ✖
            </Button>

          </div>
        ))}
      </div>
</CardSetting>
</div>
)}
<div>
<CardSetting title="Проф. навыки, специализация:" invalid={!formData.specialities}>
{[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
      <Select
        id="specialities"
        array={getData.specialities}
        onChoose={handleChooseSpeciality}
        defaultValue=""
      />
)}
{currentStatus !== 1 && (
    <div>
  {formData.specialities.length > 0 ? (
    <span>
      {formData.specialities.map((speciality) => speciality.title).join(', ')}
    </span>
  ) : (
    <span> - </span> // Сообщение, если проектов нет
  )}
    </div>
)}
</CardSetting>
</div>
{[1, 2, 4, 12, 13, 14].includes(currentStatus) && (
<div>
<CardSetting>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          minHeight: '40px',
        }}
      >
        {formData.specialities.map((speciality) => (
          <div
            key={speciality.id}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span>{speciality.title}</span>

            <Button
              className={smallCurtain}
              onClick={() => handleRemoveSpeciality(speciality.id)}
              style={{ marginLeft: '8px' }}
            >
              ✖
            </Button>

          </div>
        ))}
      </div>
</CardSetting>
</div>
)}
    </div>
  );
}

function Step3({
  formData,
  setFormData,
  currentStatus,
}) {
  return (
    <div
      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px' }}
    >
<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Должностные обязанности сотрудника:">
{[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
<TextArea
  placeholder=""
  value={formData.job_respons || ''}
  onChange={(e) => setFormData({ ...formData, job_respons: e.target.value })}
/>
) : (
  <span>{formData.job_respons}</span>
)}
</CardSetting>
</div>
<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Компетенции:">
{[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
<TextArea
  placeholder=""
  value={formData.qualifications || ''}
  onChange={(e) => setFormData({ ...formData, qualifications: e.target.value })}
/>
) : (
  <span>{formData.qualifications}</span>
)}
</CardSetting>
</div>
<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Дополнительные квалификационные требования:">
{[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
<TextArea
  placeholder=""
  value={formData.dop_qualifications || ''}
  onChange={(e) => setFormData({ ...formData, dop_qualifications: e.target.value })}
/>
) : (
  <span>{formData.dop_qualifications}</span>
)}
</CardSetting>
</div>
    </div>
  );
}
// 4 экран формы
function Step4({
  formData, setFormData, getData, currentStatus,
}) {
  return (
    <div>
      <CardSetting title="Планируемая продолжительность ТД:" invalid={!formData.contracts}>
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="contracts"
        array={getData.contracts}
        onChoose={(e) => setFormData({ ...formData, contracts: e.title })}
        defaultValue={formData.contracts || ''}
      />
      ) : (
      <span>{formData.contracts}</span>
      )}
      </CardSetting>

      {formData.contracts === 'срочный договор (СТД)' && ( // Проверяем, выбран ли "срочный договор"
        <CardSetting title="Основание срочности:">
           {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
          <TextArea
            placeholder="Укажите основание срочности"
            value={formData.reason}
            onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
          />
           ) : (
          <span>{formData.reason}</span>
           )}
        </CardSetting>
      )}

      <CardSetting title="Вид отношений с работником:" invalid={!formData.types}>
      {[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
      <Select
        id="types"
        array={getData.types}
        onChoose={(e) => setFormData({ ...formData, types: e.title })}
        defaultValue={formData.types || ''}
      />
      ) : (
      <span>{formData.types}</span>
      )}
      </CardSetting>
<CardSetting title="Режим работы:">
{[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
<Select
  id="mode"
  array={getData.mode}
  onChoose={(e) => setFormData({ ...formData, mode: e.title })}
  defaultValue={formData.mode || ''}
/>
) : (
    <span>{formData.mode}</span>
)}
</CardSetting>

<CardSetting title="Регион постоянного проживания кандидата:">
{[1, 2, 4, 12, 13, 14].includes(currentStatus) ? (
<Input
  id="types"
  onChange={(e) => setFormData({ ...formData, region: e.target.value })}
  defaultValue={formData.region || ''}
/>
) : (
  <span>{formData.region}</span>
)}
</CardSetting>

{currentStatus === 2 && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий руководителя МВЗ:">
<TextArea
  placeholder=""
  value={formData.r_mvz_comments || ''}
  onChange={(e) => setFormData({ ...formData, feo_comments: e.target.value })}
/>
</CardSetting>
                         </div>)}
                         {(currentStatus > 2 || currentStatus === 2) && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий руководителя МВЗ:">
{formData.r_mvz_comments || ''}
</CardSetting>
                                                                         </div>)}

{currentStatus === 3 && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Данные о загрузке ФАКТ:">
<Input
  placeholder=""
  value={formData.data_FACT || ''}
  onChange={(e) => setFormData({ ...formData, data_FACT: e.target.value })}
/>
</CardSetting>

<CardSetting title="Данные о загрузке ПЛАН:">
<Input
  placeholder=""
  value={formData.data_PLAN || ''}
  onChange={(e) => setFormData({ ...formData, data_PLAN: e.target.value })}
/>
</CardSetting>

<CardSetting title="Комментарий РП:">
<TextArea
  placeholder=""
  value={formData.rp_comments || ''}
  onChange={(e) => setFormData({ ...formData, rp_comments: e.target.value })}
/>
</CardSetting>
                         </div>)}
{currentStatus > 3 && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Данные о загрузке ФАКТ:">
{formData.data_FACT || ''}
</CardSetting>

<CardSetting title="Данные о загрузке ПЛАН:">
{formData.data_PLAN || ''}
</CardSetting>
<CardSetting title="Комментарий РП:">
{formData.rp_comments || ''}
</CardSetting>
                       </div>)}

{currentStatus === 6 && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий ФЭО:">
<TextArea
  placeholder=""
  value={formData.feo_comments || ''}
  onChange={(e) => setFormData({ ...formData, feo_comments: e.target.value })}
/>
</CardSetting>
                         </div>)}
                         {(currentStatus > 6 || currentStatus === 4) && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий ФЭО:">
{formData.feo_comments || ''}
</CardSetting>
                                                                         </div>)}
{currentStatus === 7 && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий ЗГД:">
<TextArea
  placeholder=""
  value={formData.zgd_comments || ''}
  onChange={(e) => setFormData({ ...formData, zgd_comments: e.target.value })}
/>
</CardSetting>

                         </div>)}
{currentStatus === 7 && ['А0', 'А1/С1', 'А2/С2', 'А3/С3'].includes(formData.job_level) && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Необходимо согласование ЗГД:">
<CheckBox
  checked={formData.needGd}
  onClick={() => setFormData((prevFormData) => ({
    ...prevFormData,
    needGd: !prevFormData.needGd, // Переключаем значение
  }))}
/>

</CardSetting>
                                                                                           </div>)}
{currentStatus > 7 && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий ЗГД:">
<span>{formData.zgd_comments}</span>
</CardSetting>

                       </div>)}
{currentStatus === 8 && (['A4', 'A5', 'A6', 'A7'].includes(formData.job_level) || formData.needGd === true) && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий ГД:">
<TextArea
  placeholder=""
  value={formData.gd_comments || ''}
  onChange={(e) => setFormData({ ...formData, gd_comments: e.target.value })}
/>
</CardSetting>
                                                                                                                </div>)}
{currentStatus > 8 && (<div style={{ gridColumn: 'span 2' }}>
<CardSetting title="Комментарий ГД:">
<span>{formData.gd_comments}</span>
</CardSetting>

                       </div>)}
{currentStatus === 9 && (<div style={{ gridColumn: 'span 2' }}>
 <CardSetting title="Выберите исполнителя:">
                         <Select
                           id="mode"
                           array={getData.performers}
                           onChoose={(e) => setFormData({ ...formData, performer: e.title })}
                           defaultValue={formData.performer || ''}
                         />

 </CardSetting>
                         </div>)}

{currentStatus < 12 && currentStatus > 9 && (<div style={{ gridColumn: 'span 2' }}>
 <CardSetting title="Исполнитель:">
                      {formData.performer || ''}
 </CardSetting>
                                             </div>)}

                                             {currentStatus === 10 && (<div style={{ gridColumn: 'span 2' }}>
 <CardSetting title="ФИО нового сотрудника:">
                         <Input
                           id="mode"
                           onChange={(e) => setFormData({ ...formData, fio_new_employee: e.target.value })}
                           defaultValue={formData.fio_new_employee || ''}
                         />

 </CardSetting>
                                                                       </div>)}

{currentStatus < 12 && currentStatus > 10 && (<div style={{ gridColumn: 'span 2' }}>
 <CardSetting title="ФИО нового сотрудника:">
                      {formData.fio_new_employee || ''}
 </CardSetting>
                                              </div>)}

    </div>

  );
}

function RecrutmentForm(props) {
  const {
    setShowCreateCard, ticketData, statusID, ticketID,
  } = props;
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки данных
  const smallCurtain = `${classes.curtain__button} ${classes.small}`;
  const smallButtonClass = `${classes.button} ${classes.small}`; // имя класса стилей кнопок

  useEffect(() => {
    if (ticketData !== null) {
      setFormData(ticketData);
      const initialStatus = statusID || 1; // Установка статуса, если он есть
      setCurrentStatus(initialStatus);

      // Проверка статуса и установка текущего шага
      if (initialStatus > 1) {
        setCurrentStep(4); // Переход к 4-му шагу, если статус больше 1
      } else {
        setCurrentStep(1); // Иначе остаемся на первом шаге
      }
    } else {
      setCurrentStep(1);
    }
  }, [ticketData, statusID]);

  // Инициализация formData при изменении ticketData
  useEffect(() => {
    if (ticketData !== null) {
      setFormData(ticketData);
      setCurrentStatus(statusID || 1); // Установка статуса, если он есть
    } else {
      setCurrentStatus(1);
    }
  }, [ticketData]);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  useEffect(() => {
    getRecrutmentFormData();
  }, []);

  function closeHandler() {
    setShowCreateCard(false); // закрытие карточки
    setFormData([]);
    setCurrentStatus(1);
  }

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStatus, setCurrentStatus] = useState();
  const [formData, setFormData] = useState({
    company: '',
    head_mvz: '',
    job_level: '',
    comments: '',
    location: '',
    justification: '',
    projects: [],
    certificates: [],
    programms: [],
    specialities: [],
    closing_date: '',
    needGd: false,
  });

  const [getData, setGetData] = useState({});
  const isPrevDisabled = currentStep <= 1; // Определяем, должна ли кнопка "Назад" быть неактивной
  const isNextDisabled = currentStep >= 4; // Определяем, должна ли кнопка "Далее" быть неактивной

  const [additionalInfo, setAdditionalInfo] = useState({ // дополнительная информация
    statuses: [], // типы статусов
    performers: [], // исполнители
    initiator: [], // инициаторы (при поиске)
  });

  async function getRecrutmentFormData() {
    try {
      setIsLoading(true);
      const reqData = { type: 'getRecrutmentFormData' };
      const result = await clientSendData('POST', '/get_recrutment_form_data', reqData);
      console.log(result);
      if (result?.success) {
        const dataString = result.data.regData[0].value;
        console.log(dataString);
        const safeDataString = dataString.replace(/'/g, '"');
        const dataHeadMvz = result.data.head_mvz;
        const dataMvz = result.data.mvzs;
        const dataDepartment = result.data.departments;
        const data = JSON.parse(safeDataString);
        data.head_mvz = dataHeadMvz;
        data.mvz = dataMvz;
        data.department = dataDepartment;

        setAdditionalInfo((state) => ({ ...state, ...data, statuses: result.data.statuses }));
        setGetData(data);
      }
    } catch (error) {
      catchHandler(error, 'getRecrutmentFormData');
    } finally {
      setIsLoading(false);
    }
  }

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep1) => prevStep1 - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            formData={formData}
            setFormData={setFormData}
            getData={getData}
            handleChoose={handleChoose}
            handleRemove={handleRemove}
            smallCurtain={smallCurtain}
            currentStatus={currentStatus}
          />
        );
      case 2:
        return <Step2
          formData={formData}
          setFormData={setFormData}
          getData={getData}
          handleChooseCertificate={handleChooseCertificate}
          handleRemoveCertificate={handleRemoveCertificate}
          handleChooseSpeciality={handleChooseSpeciality}
          handleRemoveSpeciality={handleRemoveSpeciality}
          handleChooseProgramm={handleChooseProgramm}
          handleRemoveProgramm={handleRemoveProgramm}
          smallCurtain={smallCurtain}
          currentStatus={currentStatus}
        />;
      case 3:
        return <Step3
          formData={formData}
          setFormData={setFormData}
          getData={getData}
          handleChoose={handleChoose}
          handleRemove={handleRemove}
          smallCurtain={smallCurtain}
          currentStatus={currentStatus}
        />;
      case 4:
        return <Step4
          formData={formData}
          setFormData={setFormData}
          getData={getData}
          handleChoose={handleChoose}
          handleRemove={handleRemove}
          smallCurtain={smallCurtain}
          currentStatus={currentStatus}
        />;
      default:
        return <div>Шаг не найден</div>;
    }
  };

  const handleChooseSpeciality = (speciality) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.specialities.find((p) => p.id === speciality.id)) {
        return {
          ...prevData,
          specialities: [...prevData.specialities, speciality],
        };
      }
      return prevData;
    });
  };

  const handleRemoveSpeciality = (specialityId) => {
    setFormData((prevData) => ({
      ...prevData,
      specialities: prevData.specialities.filter((speciality) => speciality.id !== specialityId),
    }));
  };

  const handleChoose = (project) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.projects.find((p) => p.id === project.id)) {
        return {
          ...prevData,
          projects: [...prevData.projects, project],
        };
      }
      return prevData;
    });
  };

  const handleRemove = (projectId) => {
    setFormData((prevData) => ({
      ...prevData,
      projects: prevData.projects.filter((project) => project.id !== projectId),
    }));
  };

  const handleChooseProgramm = (programm) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.programms.find((p) => p.id === programm.id)) {
        return {
          ...prevData,
          programms: [...prevData.programms, programm],
        };
      }
      return prevData;
    });
  };

  const handleRemoveProgramm = (programmId) => {
    setFormData((prevData) => ({
      ...prevData,
      programms: prevData.programms.filter((programm) => programm.id !== programmId),
    }));
  };

  const handleChooseCertificate = (certificate) => {
    setFormData((prevData) => {
      // Проверяем, есть ли проект уже в списке
      if (!prevData.certificates.find((p) => p.id === certificate.id)) {
        return {
          ...prevData,
          certificates: [...prevData.certificates, certificate],
        };
      }
      return prevData;
    });
  };

  const handleRemoveCertificate = (certificateId) => {
    setFormData((prevData) => ({
      ...prevData,
      certificates: prevData.certificates.filter((certificate) => certificate.id !== certificateId),
    }));
  };

  const getStepTitle = () => {
    const totalSteps = 4; // Общее количество шагов
    const stepTitles = {
      1: 'Общая информация',
      2: 'Требования к кандидату',
      3: 'Требования к кандидату',
      4: 'Условие труда',
    };

    if (currentStep >= 1 && currentStep <= totalSteps) {
      return `${currentStep} из ${totalSteps}: ${stepTitles[currentStep]}`;
    }
    return '';
  };

  async function createRecrutmentTicket() {
    try {
      const validateRecrutmentTicket = ['company', 'head_mvz', 'mvz', 'department', 'job_level', 'number_of_vacancies', 'education', 'location', 'certificates', 'specialities', 'contracts', 'types', 'qualifications'].every((key) => formData[key]);
      // console.log(formData);
      console.log(validateRecrutmentTicket);
      if (validateRecrutmentTicket) {
        setIsLoading(true);
        console.log(formData);
        const status_id = 2;
        const reqData = { type: 'createRecrutmentTicket', formData, status_id };
        const result = await clientSendData('POST', '/create_recrutment_ticket', reqData);
        if (result?.success) {
        // await refreshTable();
          await alertFunction('ticket_created', 'clientPost');
          // goToUrl(result.data);
          closeHandler();
        }
      } else {
        await alertFunction('required_fields', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'createRecrutmentTicket');
    } finally {
      setIsLoading(false);
    }
  }

  if (isAuthorized) {
    return (
      <Card setShow={closeHandler} id="new_recrutment_card">
        <CardHeader>
          {/* <h3>Заявка на подбор персонала</h3> */}
          <h4>{getStepTitle()}</h4>
        </CardHeader>
        <CardBody>
{renderStep()}

        </CardBody>
        <CardFooter>
        { currentStep === 4 && (
            <Button onClick={createRecrutmentTicket}>
              Сохранить
            </Button>
        )}

        </CardFooter>
        <div className="container_button">
        <Button
          onClick={prevStep}
          className={`button_arrow ${isPrevDisabled ? 'disabled' : ''}`}
          disabled={isPrevDisabled}
        >
          ◄
        </Button>
        <Button
          onClick={nextStep}
          className={`button_arrow ${isNextDisabled ? 'disabled' : ''}`}
          disabled={isNextDisabled}
        >
          ►
        </Button>
        </div>
      </Card>
    );
  }
  return <Cap />;
}

export default RecrutmentForm;
