import React, { useEffect, useState, useRef } from 'react';
// eslint-disable-next-line
import * as XLSX from 'xlsx'; // Импортируем библиотеку xlsx
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import CRMCard from './CRMCard';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Cap from '../Cap/Cap';
import { authorization } from '../../utils/functions/authenticate';

const defaultTableState = {
  title: 'База данных подписчиков сайта Юникона',
  page: 1,
  limit: 500, // количество строк в таблице
};

function CRM() {
  const tableId = 'crm';
  const [subscribers, setSubscribers] = useState([]); // все подписчики
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [openedMeeting, setOpenedMeeting] = useState(); // открытая в карточке подписчика
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки

  const headers = [ // Заголовки таблицы
    { id: 1, title: 'ФИО', field: 'name' },
    { id: 2, title: 'Должность', field: 'position' },
    { id: 3, title: 'Компания', field: 'company' },
    { id: 4, title: 'Адрес электронной почты', field: 'email' },
    { id: 5, title: 'Обзор изменений законодательства', field: 'obzor' },
    { id: 6, title: 'Новости МСФО', field: 'msfo' },
    { id: 7, title: 'Новости РСБУ', field: 'rsbu' },
    { id: 8, title: 'Новости компании', field: 'news' },
    { id: 9, title: 'Год', field: 'year' },
    { id: 10, title: 'Партнер', field: 'partner' },
    { id: 11, title: 'Источник', field: 'where' },
    { id: 12, title: 'Комментарий', field: 'comments' },
    { id: 13, title: 'Отписался', field: 'unsubscribed' },
    { id: 14, title: 'Заблокирован', field: 'blocked' },
    { id: 15, title: 'Дата создания', field: 'date_created' },
  ];

  const [tableState, setTableState] = useState(JSON.parse(localStorage.getItem(tableId)) || defaultTableState);
  const fileInputRef = useRef(null);

  function openCard(id) {
    setOpenedMeeting(id);
    setShowCard(true);
  }

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    getDataCRM();
  }, [tableState]);

  // Получение данныиз БД
  useEffect(() => {
    getDataCRM();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  async function getDataCRM() {
    try {
      const reqData = {
        type: 'getDataCRM',
        tableState,
        total,
      };
      const result = await clientSendData('POST', '/get_data_crm', reqData);
      if (result && result.data) {
        console.log(result.data);
        setSubscribers(result.data);
        setTotal(result.total); // Обновляем общее количество записей
      }
    } catch (error) {
      catchHandler(error, 'getDataCRM');
    }
  }

  // Функция для экспорта данных в XLSX
  const exportToXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(subscribers.map((subscriber) => ({
      ФИО: subscriber.name,
      Должность: subscriber.position,
      Компания: subscriber.company,
      'Адрес электронной почты': subscriber.email,
      'Обзор изменений законодательства': subscriber.obzor,
      'Новости МСФО': subscriber.msfo,
      'Новости РСБУ': subscriber.rsbu,
      'Новости компании': subscriber.news,
      Год: subscriber.year,
      Партнер: subscriber.partner,
      Источник: subscriber.where,
      Комментарий: subscriber.comments,
      Отписался: subscriber.unsubscribed,
      Заблокирован: subscriber.blocked,
      'Дата создания': subscriber.date_created,
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Подписчики');

    // Генерация файла и скачивание
    XLSX.writeFile(workbook, 'subscribers.xlsx');
  };

  // Сохранение данных и отправка на маршрут
  async function editDataCRM(dataCRM) {
    try {
      setIsLoading(true);
      const reqData = {
        type: 'ediDataCRM', dataCRM,
      };
      const result = await clientSendData('POST', '/edit_data_crm', reqData);
      if (result?.success) {
        await alertFunction('resume_edit_success', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'ediDataCRM');
    } finally {
      setIsLoading(false);
    }
  }

  // Функция для обработки загрузки XLSX файла
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Берем первый лист
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet); // Преобразуем в JSON

      // Фильтруем данные для соответствия заголовкам
      const formattedData = jsonData.map((item) => ({
        name: item['ФИО'] || '',
        position: item['Должность'] || '',
        company: item['Компания'] || '',
        email: item['Адрес электронной почты'] || '',
        obzor: item['Обзор изменений законодательства'] || '0',
        msfo: item['Новости МСФО'] || '0',
        rsbu: item['Новости РСБУ'] || '0',
        news: item['Новости компании'] || '0',
        year: item['Год'] || '',
        partner: item['Партнер'] || '',
        where: item['Источник'] || '',
        comments: item['Комментарий'] || '',
        unsubscribed: item['Отписался'] || '0',
        blocked: item['Заблокирован'] || '0',
      }));

      // Добавление поля date_created к каждому объекту
      const updatedObjects = formattedData.map((obj) => ({
        ...obj,
        date_created: new Date().toISOString().split('T')[0], // Добавляем текущую дату
      }));

      // Обновляем состояние с новыми данными
      await editDataCRM(updatedObjects);
    };

    reader.readAsArrayBuffer(file); // Читаем файл как массив байтов
  };

  const handleImageClick = () => {
    fileInputRef.current.click(); // Программно вызываем клик на input
  };

  // Если параметр отображения карточки - true, показываем карточку
  if (showCard) {
    return (
      <CRMCard
        emailId={openedMeeting}
        setShow={setShowCard}
        refreshTable={() => setTableState({ ...tableState })}
      />
    );
  }

  if (isAuthorized) { // если пользователь авторизован
    return (
    <div id="crm" className="service">
      <div className="service__body">
        {/* <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload} // Обработчик загрузки файла
        /> */}
        <PaginatedTable
          id={tableId}
          headers={headers}
          state={tableState}
          setState={setTableState}
          total={total}
          data={subscribers} // Передаем данные в таблицу
          footerButton={{
            title: 'Скачать данные подписчиков в xlsx',
            handler: () => exportToXLSX(),
          }}
          footerButtonUpload={{
            title: 'Загрузить данные подписчиков в xlsx',
            handler: () => handleImageClick(),
          }}
          optButton={{
            title: 'Добавить',
            handler: () => openCard('new'),
          }}

        >
          {/* Динамическое создание строк таблицы на основе данных */}
          {subscribers.map((row) => (
            <TRow key={row.id}>
              {headers.map((column) => {
                const { id, field } = column; // Destructure column object
                const value = row?.[field]; // Get the current value

                let displayValue;

                if (value !== null && value !== undefined && value !== '') {
                  displayValue = typeof value === 'string' ? value.trim() : value;
                } else {
                  displayValue = '-';
                }

                return (
                    <TData key={id} loading={isLoading}>
                    {displayValue}
                    </TData>
                );
              })}

              <TData onClick={() => openCard(row.email)} loading={isLoading}>
                <img src="../../icons/file-text.svg" alt="file" />
              </TData>
            </TRow>
          ))}
                      <TRow>
            <TData loading={isLoading}>
            <div>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload} // Обработчик загрузки файла
        ref={fileInputRef} // Привязываем реф к input
        style={{ display: 'none' }} // Скрываем input
      />
            </div>
            </TData>
                      </TRow>
        </PaginatedTable>
      </div>
    </div>
    );
  } return <Cap />;
}

export default CRM;
