import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import Card from '../../../components/Card/Card';
import Service from '../../../components/Service/Service';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ServiceBody from '../../../components/Service/ServiceBody';
import Button from '../../../components/UI/Button/Button';
import CardSetting from '../../../components/Card/CardSetting';
import Select from '../../../components/UI/Select';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import ServicePanel from '../../../components/Service/ServicePanel';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead';
import TBody from '../../../components/Table/TBody';
import Input from '../../../components/UI/Input';
import FinalEvalToPrint from './FinalEvalToPrint';
import { alertFunction } from '../../../utils/functions/alertFunction';
import Loader from '../../../components/UI/Loader';

const ComponentToPrint = React.forwardRef((props, ref) => (
  <div
    id="final-evl"
    ref={ref}
    style={{
      width: '200mm',
      minHeight: '295mm',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
  <FinalEvalToPrint
    employee={props.employee}
    finalEvaluation={props.finalEvaluation}
    employeeEvals={props.employeeEvals}
    userInfo={props.userInfo}
  />
  </div>
));

export default function FinalAppCard(props) {
  const { employee, isAdmin } = props;
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [userInfo, setUserInfo] = useState();
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [employeeEvals, setEmployeeEvals] = useState([]);
  const [total, setTotal] = useState(0);
  const [extraInfo, setExtraInfo] = useState('');
  let finalProjMark = 0;
  let finalProjChar;
  let totalProjMark;
  const [finYears, setFinYears] = useState([]);
  const [period, setPeriod] = useState(useSelector((state) => state.evaluation.period)); // период времени
  const [finalEvaluation, setFinalEvaluation] = useState({
    cascadeChar: 'Оценка',
    cascadeSolution: { id: 0, title: 'Решение по результатам каскада' },
    recommendation: '',
  });

  const headers = [ // заголовки
    { id: 0, title: 'Проект', field: 'project_title' },
    { id: 1, title: 'Клиент', field: 'client_title' },
    { id: 2, title: 'Роль', field: 'user_role' },
    { id: 3, title: 'Количество часов', field: 'hours_amount' },
    { id: 4, title: 'Проектный балл', field: 'proj_score' },
    { id: 5, title: 'Проектная оценка', field: 'final_mark' },
    // { id: 6, title: 'Результаты мониторинга', field: 'monitoring_res' },
  ];
  const defaultTableState = {
    title: 'Финальные оценки',
    page: 1,
    limit: 5, // количество строк в таблице
    // switch_data: {
    //   current: 'active',
    //   titles: {
    //     all: 'Все',
    //     active: 'Активные',
    //   },
    // },
    button_place: 'header',
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const cascadeSolutions = [
    { id: 1, title: 'Повысить в грейде/должности' },
    { id: 2, title: 'Оставить в занимаемой должности ' },
    { id: 3, title: 'Рассмотреть вопрос о расставании ' },
    { id: 4, title: 'Рассмотреть в промежуточную аттестацию' },
    { id: 5, title: 'Условный промоушен' },
    { id: 6, title: 'Не оценивается' },
    { id: 7, title: 'Понизить в грейде/должности' },
  ];

  const cascadeChars = [
    { id: 1, title: 'A' },
    { id: 2, title: 'B' },
    { id: 3, title: 'C' },
    { id: 4, title: 'D' },
  ];

  useEffect(() => {
    getFinalEvalInfo();
    getEmployeeEvals();
  }, [period]);

  useEffect(() => {
    getFinYears();
  }, []);
  // Итоговая проектная оценка
  function finalProjScore() {
    if (employeeEvals.length) {
      let projHoursTotal = 0;
      let markMultiplyHoursTotal = 0;
      for (const evl of employeeEvals) {
        const markMultiplyHours = evl.proj_score * evl.hours_amount;
        markMultiplyHoursTotal += markMultiplyHours;
        projHoursTotal += Number(evl.hours_amount);
      }
      if (markMultiplyHoursTotal) {
        finalProjMark = markMultiplyHoursTotal / projHoursTotal;
      }
      if (finalProjMark >= 9) {
        finalProjChar = 'A';
      } else if (finalProjMark < 9 && finalProjMark >= 6) {
        finalProjChar = 'B';
      } else if (finalProjMark < 6 && finalProjMark >= 3) {
        finalProjChar = 'C';
      } else {
        finalProjChar = 'D';
      }
      totalProjMark = `${finalProjMark.toFixed(2)}/${finalProjChar}`;
      finalEvaluation.finalProjInt = finalProjMark.toFixed(2);
      finalEvaluation.finalProjChar = finalProjChar;
    }
  }
  finalProjScore();

  // Добавление опционального столбца "Результаты мониторинга"
  if (employeeEvals.length > 0) {
    for (const evl of employeeEvals) {
      if (evl.monitoring_res && headers.length < 6) {
        headers.push({ id: 6, title: 'Результаты мониторинга', field: 'monitoring_res' });
      }
    }
  }

  async function getFinYears() {
    try {
      const reqData = {
        type: 'getAllFinYears',
        employee,
        interfaceType: 'final',
      };
      const result = await clientSendData('POST', '/get_all_fin_years', reqData); // запрос в БД
      if (result) {
        setFinYears(result);
        if (!period) setPeriod(result[0].title);
      }
    } catch (error) {
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  // Определить отображаемое значение общего стажа ИЛИ стажа в грейде
  const defineValue = (key) => {
    if (userInfo[key]) {
      const data = userInfo[key];
      if (key === 'experience' || key === 'grade_experience') {
        return `${data?.years || 0}лет ${data?.months || 0}мес.`;
      } return data;
    }
    return '-';
  };

  // Получить инф. об оценках сотрудника
  async function getFinalEvalInfo() {
    try {
      const reqData = {
        type: 'getFinalEvalInfo',
        employee,
        period,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_finalEval_info', reqData); // запрос в БД
      if (result) {
        setEmployeeEvals(result);
        setTotal(result.length);
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getProjectMasters'); // обработка ошибок
    }
  }

  if (employeeEvals.length > 0) {
    for (const empEval of employeeEvals) {
      if (Number(empEval.proj_score) >= 9) {
        empEval.final_mark = 'A';
      } else if (Number(empEval.proj_score) < 9 && Number(empEval.proj_score) >= 6) {
        empEval.final_mark = 'B';
      } else if (Number(empEval.proj_score) < 6 && Number(empEval.proj_score) >= 3) {
        empEval.final_mark = 'C';
      } else {
        empEval.final_mark = 'D';
      }
    }
  }

  // Получить инф. о сотруднике
  async function getEmployeeEvals() {
    try {
      const reqData = {
        type: 'getEmployeeEvals',
        employee,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_employee_evals', reqData); // запрос в БД
      if (result) {
        setUserInfo(result.employeeInfo[0]);
        setIsLoading(false);
        if (result.finalEval) {
          setFinalEvaluation(result.finalEval);
        }
      }
    } catch (error) {
      catchHandler(error, 'getEmployeeEvals'); // обработка ошибок
    }
  }

  function onBackClick() {
    setSearchParams('');
  }

  // Открыть заявление
  function openApplication(id) {
    const search = { app: id, finalApp: true };
    setSearchParams(search);
  }

  function defineNormative() {
    if (['АИ0', 'АИ1', 'АИ2', 'АИ3', 'А0', 'А1', 'А2', 'А3', 'С1', 'С2', 'С3', 'К0', 'К1', 'К2', 'К3', 'Ф0', 'Ф1', 'Ф2', 'Ф3', 'Р0', 'Р1', 'Р2', 'Р3'].includes(userInfo?.grade)) {
      return '75%';
    } if (['АИ4', 'АИ5', 'А4', 'А5', 'К4', 'К5', 'Ф4', 'Ф5', 'Р', 'Р5'].includes(userInfo?.grade)) {
      return '65%';
    } if (['АИ', 'АИ7', 'А6', 'А7', 'К6', 'К7', 'Ф6', 'Ф7', 'Р', 'Р7', 'НГ'].includes(userInfo?.grade)) {
      return '55%';
    } if (['АИ8', 'А8', 'К8', 'Ф8', 'Р8'].includes(userInfo?.grade)) {
      return '30%';
    } if (['П1', 'П2'].includes(userInfo?.grade)) {
      return '15%';
    }
    return '100%';
  }

  function generateImg() {
    const input = document.getElementById('final-evl');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0, 205, 297);
      // pdf.output('dataurlnewwindow');
      pdf.save(`Финальная оценка ${userInfo?.employee_title}.pdf`);
    });
  }

  // Сохранить результаты ИО по сотруднику
  async function saveFinalEval() {
    try {
      const eval_fin_year = employeeEvals[0].eval_fin_year;
      const reqData = {
        type: 'saveFinalEval',
        finalEvaluation,
        employee,
        grade: userInfo?.grade,
        eval_fin_year,
        period,
      };
      const result = await clientSendData('POST', '/save_final_eval', reqData); // запрос в БД
      if (result) {
        await alertFunction('final_ev_saved', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'saveFinalEval'); // обработка ошибок
    }
  }
  if (isLoading) return <Loader />;
  return (
    <Service id="final_evaluation">
        <ServiceBody horizontal>
          <div
            className="button-container"
            style={{
              display: 'flex', width: '100%', flexFlow: 'column', marginTop: '15px',
            }}
          >
            <Button onClick={onBackClick} className="text-button" style={{ marginLeft: '10px' }}>
              <div className="evl">
                      <span className="arrow">←</span>
                      Вернуться к оценкам
              </div>
            </Button>
            <ServicePanel>
                      {isAdmin === 'true' && <Button onClick={saveFinalEval} style={{ marginBottom: '10px' }}>Сохранить</Button>}
                      <Button onClick={generateImg}>Скачать оценку</Button>
            </ServicePanel>
          </div>

          <div className="container">
            <div className="period">
              <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Период:</p>
              {/* <Select id="period" /> */}
              <Select
                id="fin_year"
                array={finYears}
                onChoose={(finYear) => setPeriod(finYear.title)}
                defaultValue={period}
              />
            </div>

            <div className="user_info">
                <div>
                  <CardSetting title="ФИО:">
                    <span className="userName">
                      {userInfo?.employee_title}
                      <span className="reference_info" onPointerEnter={() => setExtraInfo('user')} onPointerLeave={() => setExtraInfo('')}>
                        <div className="question_mark">
                          ?
                          {extraInfo === 'user' && <div className="reference">
                                                      <p className="help-title">Подразделение: </p>
                                                      <span>
                                                        {userInfo?.dept}
                                                      </span>
                                                      <p className="help-title">Стаж: </p>
                                                      <span>
                                                        {defineValue('experience')}
                                                      </span>
                                                      <p className="help-title">Опыт в текущей должности: </p>
                                                      <span>
                                                      {defineValue('grade_experience')}
                                                      </span>
                                                      {/* <p className="help-title">ФИО руководителя: </p>
                                                      <span>
                                                        {userInfo.mentor_title !== ' ' ? userInfo.mentor_title : '-'}
                                                      </span>
                                                      <p className="help-title">Грейд руководителя: </p>
                                                      <span>
                                                        {userInfo.mentor_grade ? userInfo.mentor_grade : '-'}
                                                      </span> */}
                                                   </div>}
                        </div>
                      </span>
                    </span>
                  </CardSetting>
                  <CardSetting title="Должность:">{userInfo?.job_title}</CardSetting>
                  <CardSetting title="Грейд:">{userInfo?.grade}</CardSetting>
                  <CardSetting title="Подразделение:">{userInfo?.dept}</CardSetting>
                </div>
                <div>
                  <CardSetting title="МВЗ:">{userInfo?.mvz}</CardSetting>
                  <CardSetting title="Средняя утилизация:">
                    {userInfo?.utilization ? `${String(userInfo?.utilization)} %` : '-'}
                  </CardSetting>
                  <CardSetting title="Норматив:">{defineNormative()}</CardSetting>
                    {/* <CardSetting title="Оценка 'снизу-вверх' ментор:">B</CardSetting>
                    <CardSetting title="Оценка 'снизу-вверх' руководитель:">B</CardSetting> */}
                  <CardSetting title="Итоговая проектная оценка:">
                    <div className="finalMark">
                      <p>
                        {totalProjMark}
                      </p>
                      <span className="reference_info" onPointerEnter={() => setExtraInfo('finalMark')} onPointerLeave={() => setExtraInfo('')}>
                      <div className="question_mark">
                        ?
                        {extraInfo === 'finalMark' && <div className="reference">
                                                        <span>
                                                        Средневзвешенная оценка в зависимости от количества часов по проектам
                                                        </span>
                                                      </div> }
                      </div>
                      </span>
                    </div>
                  </CardSetting>
                </div>
            </div>

            <Table id="employee_final-evals">
              <THead
                headers={headers}
                array={employeeEvals}
                setArray={setEmployeeEvals}
              >
                <TRow>
                  {headers.map((item) => <TData key={item.id}>
                                          {item.title}
                                          { item.id === 4 ? <span className="reference_info" onPointerEnter={() => setExtraInfo('projScore')} onPointerLeave={() => setExtraInfo('')}>
                                                              <div className="question_mark">
                                                              ?
                                                                {extraInfo === 'projScore' && <div className="reference">Балл с учетом результатов мониторинга, если применимо</div>}
                                                              </div>
                                                            </span> : ''}
                                         </TData>)}

                </TRow>
              </THead>
              <TBody>
                {employeeEvals.length === 0
                  ? <TRow>
                          <TData>Результаты отсутствуют</TData>
                          <TData />
                          <TData />
                          <TData />
                          <TData />
                          <TData />
                    </TRow>
                    // Если результаты есть
                  : employeeEvals.map((row) => {
                    const {
                      id, finalApp,
                    } = row;
                    return (
                      <TRow key={id}>
                          {headers.map((item) => {
                            if (item.field !== 'project_title') {
                              return (
                                <TData key={item.id}>
                                {row[item.field] ? row[item.field] : '-'}
                                </TData>
                              );
                            }
                            if (item.field === 'proj_score') {
                              return (
                                <TData key={item.id}>
                                {row[item.field] ? row[item.field].toFixed(2) : '-'}
                                </TData>
                              );
                            }

                            return (
                              <TData key={item.id} onClick={() => openApplication(id, finalApp)}>
                                <p style={{ fontWeight: '500' }}>{row[item.field] ? row[item.field] : '-'}</p>
                              </TData>
                            );
                          })}
                      </TRow>
                    );
                  })}
              </TBody>
            </Table>
{isAdmin === 'true' && (<span className="decision-rec">
              <div className="decision">
                {/* <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Решение по результатам каскада:</p> */}
                <Select
                  array={cascadeSolutions}
                  id="decision"
                  defaultValue={finalEvaluation?.cascadeSolution?.title}
                //  onChoose={(e) => setCascadeSolution({ id: e.id, title: e.title })}
                  onChoose={(e) => setFinalEvaluation({ ...finalEvaluation, cascadeSolution: { id: e.id, title: e.title } })}
                />
                {[5, 6].includes(finalEvaluation.cascadeSolution?.id)
                && <textarea
                  className="comment"
                  placeholder="Обоснование решения"
                  defaultValue={finalEvaluation.comment}
                  onChange={(e) => setFinalEvaluation({ ...finalEvaluation, comment: e.target.value })}
                /> }
                <div className="cascade-mark">
                  <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Оценка по итогам каскада:</p>
                    <Select
                      id="cascade"
                      array={cascadeChars}
                      onChoose={(e) => setFinalEvaluation({ ...finalEvaluation, cascadeChar: e.title })}
                      defaultValue={finalEvaluation.cascadeChar}
                    />
                </div>
              </div>

              <div className="recommendation">
                {/* <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Рекомендация:</p> */}
                <textarea
                  className="comment"
                  placeholder="Рекомендация"
                  defaultValue={finalEvaluation.recommendation}
                  onChange={(e) => setFinalEvaluation({ ...finalEvaluation, recommendation: e.target.value })}
                />
              </div>
                        </span>)}
            {/* <div className="cascade-mark">
                  <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Оценка по итогам каскада:</p>
                  <Select id="cascade" array={cascadeChars} onChoose={(e) => setCascadeChar(e.title)} defaultValue={cascadeChar} />
            </div> */}
          </div>
        <div>
          <ComponentToPrint employee={employee} finalEvaluation={finalEvaluation} employeeEvals={employeeEvals} userInfo={userInfo} />
        </div>
        </ServiceBody>
    </Service>
  );
}
