import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';
import Button from '../../components/UI/Button/Button';
import ServicePanel from '../../components/Service/ServicePanel';
import Chat from './Chat';
import Message from './Message';
import Decision from './Decision';
import MessageHistory from './MessageHistory';
import classes from '../../components/UI/Button/button.module.scss';

function PODFTpage() {
  const pages = ['Сообщение', 'Работа с СДЛ', 'Принятие решения', 'История']; // вкладки
  const [choice, setChoice] = useState(pages[0]); // Состояние выбора сервиса
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const content = {
    Сообщение: <Message />,
    'Работа с СДЛ': <Chat />,
    'Принятие решения': <Decision />,
    История: <MessageHistory />,
  };

  function onBackClick() {
    setSearchParams('');
  }

  return (
    <Service>
        <ServiceBody horizontal>
        <ServicePanel>
            <Button onClick={onBackClick} className="text-button" style={{ marginLeft: '10px' }}>
            <div className="evl">
                    <span className="arrow">←</span>
                    Вернуться на главную
            </div>
            </Button>
        {pages.map((page) => {
          const { button, button_ghost } = classes;
          const buttonClass = choice === page ? button : button_ghost;
          return (
            <Button key={page} className={buttonClass} onClick={() => setChoice(page)}>
              {page}
            </Button>
          );
        })}
        </ServicePanel>
        {content[choice]}
        </ServiceBody>
    </Service>
  );
}

export default PODFTpage;
