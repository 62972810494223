import { React, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ServiceBody from '../../../components/Service/ServiceBody';
import Table from '../../../components/Table/Table';
import Loader from '../../../components/UI/Loader';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import Select from '../../../components/UI/Select';
import Wrapper from '../../../components/Basic/Wrapper';
import { createAction } from '../../../utils/redux/store';

export default function FinalEvaluation() {
  const [reportIsLoading, setReportIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [finalEvals, setFinalEvals] = useState([]);
  const [total, setTotal] = useState(0);
  const [isEvAdmin, setIsEvAdmin] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [finYears, setFinYears] = useState([]);
  const [period, setPeriod] = useState(''); // период времени
  const defaultTableState = {
    title: 'Финальные оценки',
    page: 1,
    limit: 50, // количество строк в таблице
    button_place: 'header',
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const headers = [ // заголовки
    { id: 1, title: 'ФИО', field: 'employee_title' },
    { id: 2, title: 'Подразделение', field: 'dept' },
    // { id: 3, title: 'Должность', field: 'job_title' },
    { id: 3, title: 'МВЗ', field: 'mvz_title' },
    { id: 4, title: 'Грейд', field: 'grade' },
    // { id: 5, title: 'Действие', field: 'action' },
  ];

  useEffect(() => {
    findApplications();
  }, [tableState, period]);

  useEffect(() => {
    getFinYears();
  }, []);

  // Поиск оценок
  async function findApplications() {
    try {
      const reqData = {
        type: 'findFinalEvals',
        tableState,
        period,
      };
      const result = await clientSendData('POST', '/find_final_evals', reqData); // запрос в БД
      if (result) {
        setFinalEvals(result.data);
        setIsEvAdmin(result.isAdmin);
        setTotal(result.data.length);
      }
    //  setTotal(result.length);
    } catch (error) {
      catchHandler(error, 'findFinalEvals'); // обработка ошибок
    }
  }

  function openApplicationCard(id) {
  //  setShowCard(true);
    const search = { employee: id, isAdmin: isEvAdmin };
    setSearchParams(search);
    // setCurrentApplicationId(id);
  }

  async function getFinYears() {
    try {
      const reqData = {
        type: 'getAllFinYears',
      };
      const result = await clientSendData('POST', '/get_all_fin_years', reqData); // запрос в БД
      if (result) {
        setFinYears(result);
        setPeriod(result[0].title);
        createAction('SET_PERIOD', result[0].title);
      }
    } catch (error) {
      catchHandler(error, 'getAllFinYears'); // обработка ошибок
    }
  }

  function chooseFinYear(finYear) {
    setPeriod(finYear.title);
    setTableState((state) => ({ ...state, page: 1 }));
    createAction('SET_PERIOD', finYear.title);
  }

  async function getReport() {
    try {
      const reqData = {
        type: 'getFinalEvalReport',
        period,
      };
      setReportIsLoading(true);
      const result = await clientSendData('POST', '/get_final_eval_report', reqData); // запрос в БД
      if (result?.success) { // если запрос выполнен успешно
        const uInt8Array = new Uint8Array(result.file.data);
        const blob = new Blob([uInt8Array], { type: 'application/vnd.ms-excel' });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', 'Отчет_ИО.xlsx');
        document.body.appendChild(tempLink);
        tempLink.click();
        setReportIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'getFinalEvalReport'); // обработка ошибок
    }
  }

  if (isLoading) return <Loader />;
  return (
    <ServiceBody>
      <Wrapper flexFlow="row wrap">
        Финансовый год
        <Select
          id="fin_year"
          array={finYears}
          onChoose={(finYear) => chooseFinYear(finYear)}
          defaultValue={period}
        />
      </Wrapper>
      <PaginatedTable
        id="final-evaluation__table"
        headers={headers}
        array={finalEvals}
        setState={setTableState}
        state={tableState}
        total={total}
      >
            {finalEvals.length === 0
              ? <TRow>
                    <TData>Результаты отсутствуют</TData>
                    <TData />
                    <TData />
                    <TData />
                </TRow>
            // Если результаты есть
              : finalEvals.map((row) => {
                const {
                  id, employee_id,
                } = row;
                return (
                        <TRow key={employee_id}>
                            {headers.map((item) => {
                              if (item.field !== 'employee_title') {
                                return (
                                    <TData key={item.id}>{row[item.field] ? row[item.field] : '-'}</TData>
                                );
                              }
                              return (
                                    <TData key={item.id} onClick={() => openApplicationCard(employee_id)}>
                                       {/* {draft ? 'Посмотреть' : 'Заполнить'} */}
                                       {row[item.field] ? row[item.field] : '-'}
                                    </TData>
                              );
                            })}
                            {/* <TData onClick={() => openApplicationCard(id)}>
                                <img src="../../icons/file-text.svg" alt="edit" />
                            </TData> */}
                        </TRow>
                );
              })}
      </PaginatedTable>
      <div className="excel-button">
    <div className="upu-table__table-button" onClick={getReport}>Скачать в Excel</div>
      </div>
      {reportIsLoading && <div className="loadalert">
        <p>ПОЖАЛУЙСТА, ПОДОЖДИТЕ!</p>
        <p>ДОКУМЕНТ СКАЧИВАЕТСЯ</p>
                          </div>}
    </ServiceBody>
  );
}
