import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import NewMessageCard from './NewMessageCard';
import ServiceBody from '../../components/Service/ServiceBody';
import PODFTpage from './PODFTpage';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import ServicePanel from '../../components/Service/ServicePanel';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import Service from '../../components/Service/Service';
import CheckBox from '../../components/UI/CheckBox';

export default function PODFTmailservice() {
  const [sdlUsers, setSdlUsers] = useState([]); // СДЛ-сотрудники
  const [reportDates, setReportDates] = useState({});
  const [dir, setDir] = useState([]); // генеральный директор
  // const [tasks, setTasks] = useState([]); // задания из 1формы
  const user_id = useSelector((state) => state?.oper_info?.user_id); // id текущего пользователя
  const sdlUser = sdlUsers.includes(String(user_id));
  const genDir = dir.includes(String(user_id));
  const [messages, setMessages] = useState([]);
  const [total, setTotal] = useState('');
  const [currentApplicationId, setCurrentApplicationId] = useState(null);
  const messageId = useSearchParams()[0].get('PODFTmessage'); // id ПО из url
  const [showCreateCard, setShowCreateCard] = useState(false); // состояние отображения карточки создания
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url

  const headers = sdlUser ? [ // заголовки
    { id: 1, title: sdlUser || genDir ? 'Номер' : 'Сообщение', field: 'id' },
    { id: 2, title: 'Клиент', field: 'client_title' },
    { id: 3, title: 'Номер договора', field: 'contract_num' },
    { id: 4, title: 'Статус', field: 'status_title' },
    { id: 5, title: 'Инициатор', field: 'initiator_title' },
    { id: 6, title: 'Отправлено в Росфинмониторинг', field: 'monitoring' },
  ] : [ // заголовки
    { id: 1, title: sdlUser || genDir ? 'Номер' : 'Сообщение', field: 'id' },
    { id: 2, title: 'Клиент', field: 'client_title' },
    { id: 3, title: 'Номер договора', field: 'contract_num' },
    { id: 4, title: 'Статус', field: 'status_title' },
    { id: 5, title: 'Инициатор', field: 'initiator_title' },
    { id: 6, title: 'Руководитель задания', field: 'task_supervisor' },
    { id: 7, title: 'Характер операции', field: 'oper_type' },
  ];

  const defaultTableState = {
    title: 'Сообщения',
    page: 1,
    limit: 20, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
    button_place: 'header',
  };
  const [tableState, setTableState] = useState(defaultTableState);

  function openApplicationCard(id, date) {
    setSearchParams({ PODFTmessage: btoa(`${id}@${date}`) });
  }

  useEffect(() => {
    getMessages();
    // get1FormTasks();
  }, [tableState]);

  async function getMessages() {
    try {
      const reqData = {
        type: 'podftGetMessages',
        tableState,
      };
      const result = await clientSendData('POST', '/podft_get_messages', reqData); // запрос в БД
      if (result) {
        setMessages(result.data);
        setTotal(result.data.length);
        setSdlUsers(result.sdl_users);
        setDir(result.dir);
      }
    } catch (error) {
      catchHandler(error, 'podftGetMessages'); // обработка ошибок
    }
  }

  // async function get1FormTasks() {
  //   try {
  //     const reqData = {
  //       type: 'getPODFTtasks',
  //       tableState,
  //     };
  //     const result = await clientSendData('POST', '/get_podft_tasks', reqData); // запрос в БД
  //     if (result) {
  //       setTasks(result.data);
  //     }
  //   } catch (error) {
  //     catchHandler(error, 'getPODFTtasks'); // обработка ошибок
  //   }
  // }

  async function getReport() {
    try {
      const reqData = {
        type: 'getPodftReport',
        reportDates,
      };
      const result = await clientSendData('POST', '/get_podft_report', reqData); // запрос в БД
      if (result?.success) { // если запрос выполнен успешно
        const uInt8Array = new Uint8Array(result.file.data);
        const blob = new Blob([uInt8Array], { type: 'application/vnd.ms-excel' });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', 'Report.xlsx');
        document.body.appendChild(tempLink);
        tempLink.click();
      }
    } catch (error) {
      catchHandler(error, 'getPodftReport'); // обработка ошибок
    }
  }

  async function markAsSent(message_id) {
    try {
      const reqData = {
        type: 'markAsSent',
        message_id,
      };
      await clientSendData('POST', '/mark_as_sent', reqData); // запрос в БД
    } catch (error) {
      catchHandler(error, 'getPodftReport'); // обработка ошибок
    }
  }

  if (messageId) return <PODFTpage messageId={messageId} sdlUser={sdlUser} dir={genDir} getMessages={getMessages} />;
  // if (currentApplicationId) return <PODFTpage messageId={currentApplicationId} sdlUser={sdlUser} dir={genDir} getMessages={getMessages} setCurrentApplicationId={setCurrentApplicationId} />;
  return (
    <Service id="PODFT-service">
    <ServiceBody horizontal>
      <ServicePanel>

        <div className="report">
          <div className="report-header">Отчетный период</div>
       <div className="report-date">
С:
<Input type="date" onChange={(e) => setReportDates({ ...reportDates, dateStart: e.target.value })} />
       </div>
<div className="report-date">
По:
<Input type="date" onChange={(e) => setReportDates({ ...reportDates, dateEnd: e.target.value })} />
</div>
        </div>
        <Button onClick={getReport}>Сформировать отчет</Button>
      </ServicePanel>
    <PaginatedTable
      id="PODFT_table"
      headers={headers}
      setState={setTableState}
      state={tableState}
      button_place="row"
      total={total}
      optButton={(!sdlUser && !genDir) && {
        title: 'Создать сообщение',
        handler: () => setShowCreateCard(true),
      }}
    >
      {messages.length === 0 ? (
        <TRow>
          <TData>Результаты отсутствуют</TData>
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
          <TData />
        </TRow>) : (
        // Если результаты есть
        messages.map((row, index) => (
          // eslint-disable-next-line no-nested-ternary
          genDir || sdlUser ? (
            row.decision?.oddParameter === 'minOneYes' ? (
             <TRow mark="odd" key={row.id}>
              {headers.map((item) => {
                if (item.field !== 'id') {
                  return item.field !== 'monitoring' ? <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>
                    : <TData key={item.field}>
                        <CheckBox
                          id={row.id}
                          onChange={() => markAsSent(row.id)}
                          defaultChecked={row.is_monitoring === true}
                        />
                      </TData>;
                }
                return <TData key={item.field} onClick={() => openApplicationCard(row.id, row.date_created)}><p className="clickable">{row[item.field]}</p></TData>;
              // return <TData key={item.field} onClick={() => openApplicationCard(row.id)}>Открыть карточку</TData>;
              })}
             </TRow>
            ) : (
           <TRow key={row.id}>
              {headers.map((item) => {
                if (item.field !== 'id') {
                  return item.field !== 'monitoring' ? <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>
                    : <TData key={item.field}>
                      <CheckBox
                        id={row.id}
                        onChange={() => markAsSent(row.id)}
                        defaultChecked={row.is_monitoring === true}
                      />
                      </TData>;
                }
                return <TData key={item.field} onClick={() => openApplicationCard(row.id, row.date_created)}><p className="clickable">{row[item.field]}</p></TData>;
              // return <TData key={item.field} onClick={() => openApplicationCard(row.id)}>Открыть карточку</TData>;
              })}
           </TRow>
            )
          ) : (
            [3, 5, 6, 7].includes(row.status_id) ? (
            <TRow key={row.id}>
              {headers.map((item) => {
                if (item.field !== 'id') return <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>;
                return <TData key={item.field}>{btoa(`${row.id}@${row.date_created}`)}</TData>;
              // return <TData key={item.field} onClick={() => openApplicationCard(row.id)}>Открыть карточку</TData>;
              })}
            </TRow>
            ) : (
              <TRow key={row.id}>
              {headers.map((item) => {
                if (item.field !== 'id') return <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>;
                return <TData key={item.field} onClick={() => openApplicationCard(row.id, row.date_created)}>
                       <p className="clickable">{btoa(`${row.id}@${row.date_created}`)}</p>
                       </TData>;
              // return <TData key={item.field} onClick={() => openApplicationCard(row.id)}>Открыть карточку</TData>;
              })}
              </TRow>
            )
          )
        )))}
    </PaginatedTable>

    { showCreateCard && (<NewMessageCard
      setShowCreateCard={setShowCreateCard}
      getMessages={getMessages}
    //  tasks1form={tasks}
    />)}
    </ServiceBody>
    </Service>
  );
}
