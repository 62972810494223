import React, { useEffect, useState } from 'react';
import './clientsportal.scss';
import { useSearchParams } from 'react-router-dom';
import ServiceBody from '../../components/Service/ServiceBody';
import ServicePanel from '../../components/Service/ServicePanel';
import Menu from '../ClientsPortal2/Menu';
import Button from '../../components/UI/Button/Button';
import NewProjectCard from './NewProjectCard';
import ProjectPage from './ProjectPage';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import NewProjectCard1 from './NewProjectCard1';

function ClientsPortalMain() {
  const [showCreateCard, setShowCreateCard] = useState(false);
  const [showProject, setShowProject] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации
  const Id = useSearchParams()[0].get('projId'); // id ПО из url
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [projects, setProjects] = useState([]);
  const [openedProj, setOpenedProj] = useState({});
  const [isSort, setIsSort] = useState(false);
  // const sortlist = ['Крайний срок', 'Дата создания', 'Дата начала', 'Приоритет', 'Название'];
  const sortlist = [
  //  { title: 'Крайний срок', field: 'deadline' },
    { title: 'Дата создания', field: 'date_created' },
    //  { title: 'Дата начала', field: 'date_start' },
    //  { title: 'Приоритет', field: 'priority' },
    { title: 'Название', field: 'project_name' },
  ];
  const defaultTableState = {
    page: 1,
    limit: 50, // количество строк в таблице
    sort: { field: 'project_name', direction: 'asc', title: 'Название' },
    searchData: [],
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const [search, setSearch] = useState('');

  useEffect(() => {
    awaitRequests();
    getProjects();
  }, [tableState]);

  async function getProjects() {
    try {
      const reqData = {
        type: 'clprtGetProjects',
        tableState,
      };
      const result = await clientSendData('POST', '/clprt_get_projects', reqData); // запрос в БД
      if (result) {
        setProjects(result.data);
      }
    } catch (error) {
      catchHandler(error, 'clprtGetProjects'); // обработка ошибок
    }
  }

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  function openProject(id, projectItem) {
    setShowProject(true);
    setOpenedProj({ ...projectItem.project, date_created: projectItem.date_created });
    setSearchParams({ projId: id });
  //  setProjectId(id);
  }

  function fieldSorting(opt) {
    setTableState({ ...tableState, sort: { field: opt.field, title: opt.title, direction: 'desc' } });
    setIsSort(!isSort);
  }

  function addSearch() {
    setTableState({ ...tableState, searchData: [...tableState.searchData, search] });
    setSearch('');
    document.getElementById('clprt_filter').value = '';
  }

  if (Id) return (<ProjectPage projectId={Id} getProjects={getProjects} />);

  if (isAuthorized) {
    return (
      <ServiceBody id="clients-portal_main">
        {!Id && <div className="portal-wrapper">
                  <div className="creation-button" onClick={() => setShowCreateCard(true)}>
                    <p>Создать проект</p>
                    <p style={{ fontSize: '20px' }}>+</p>
                  </div>
                  {/* Фильтр */}
                                  <div className="clprt_filter">
                <p style={{ fontSize: '13px' }}>Фильтр</p>
                <div className="clprt_filter-input">

                  {tableState.searchData.map((el) => <p style={{
                    border: '1px solid #d4e4ec',
                    background: '#edf6fd',
                    padding: '0px 2px',
                    borderRadius: '3px',
                    color: '#1a6db3',
                  }}
                  >
{el}
                                                     </p>)}
                                                     <p className="input-add" onClick={addSearch}>+</p>
                  <input
                    placeholder="введите запрос"
                    id="clprt_filter"
                    name="filter"
                   // onChange={(e) => setTableState({ ...tableState, data: e.target.value })}
                    onChange={(e) => setSearch(e.target.value)}
                    defaultValue={search}
                  />
                  <img
                    src="../../icons/button/search.svg"
                    alt="search"
                    width="16px"
                    height="18px"
                    style={{ marginLeft: 'auto' }}
                  />
                </div>
                <div className="sort">
                  Сортировать по:
                {' '}
                <p className="sort-option" onClick={() => setIsSort(!isSort)}>
                    {tableState.sort.title}
                    {' '}
                    ↴
                </p>
                {/* <p style={{ marginLeft: '10px', cursor: 'pointer' }}>🢁</p> */}
                <p
                  style={{
                    marginLeft: '10px', cursor: 'pointer', transform: tableState.sort?.direction === 'desc' ? 'rotate(180deg)' : 'none', transition: '0.2s',
                  }}
                  onClick={() => setTableState({ ...tableState, sort: { ...tableState.sort, direction: tableState.sort.direction === 'desc' ? 'asc' : 'desc' } })}
                >
                    ▲
                </p>
                </div>
                    {isSort && <div className="sort-list">
                    {sortlist.map((opt) => <p
                      key={opt.id}
                      className="sort-list-item"
                      onClick={() => fieldSorting(opt)}
                    >
                      {opt.title}
                                           </p>)}
                               </div> }
                                  </div>
                  {projects.map((item) => <div key={item.id} className="project-item" onClick={() => openProject(item.id, item)}>
                    <p className="project-title">{item.project.project_name}</p>
                    <p style={{
                      marginLeft: 'auto', marginRight: '10px', paddingTop: '3px', fontSize: '11px', color: '#C80018',
                    }}
                    >
                    {item.project.date_end}
                    </p>
                    <p className="manager-title">
                    {item.project.project_manager.title}
                    </p>
                                          </div>)}
                </div>}
        {/* {showCreateCard && <NewProjectCard setShowCreateCard={setShowCreateCard} getProjects={getProjects} />} */}
        {showCreateCard && <NewProjectCard1 setShowCreateCard={setShowCreateCard} getProjects={getProjects} />}
      </ServiceBody>
    );
  } return <Cap />;
}

export default ClientsPortalMain;
