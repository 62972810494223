import React, { useEffect, useState, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { setOperInfo } from '../../utils/functions/others';
import { alertFunction, alertFunctionLocal } from '../../utils/functions/alertFunction';
import { clientSendData, clientSendFile } from '../../utils/functions/requests';
import SingleFileUpload from '../../components/UI/FileUpload/SingleFileUpload';
import EditUserForm from '../../components/Forms/EditUserForm/EditUserForm';
import AccessForm from '../../components/Forms/AccessForm/AccessForm';
import IconButton from '../../components/UI/Button/IconButton';
import Loader from '../../components/UI/Loader';
import './userPage.scss';
import Cap from '../Cap/Cap';
import Toggle from '../../components/UI/Toggle';
import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button/Button';
import VCardPicture from './VCardPicture';

/**
* @component Страница пользователя
* Общая информация о пользователе и настройка доступа
*/

const ComponentToPrint = React.forwardRef((props, ref) => (
  <div className="vcard_block" id="vcard_block" ref={ref}>
    <VCardPicture
      position={props.position}
      surname={props.surname}
      givenname={props.givenname}
      dept={props.dept}
      mobile={props.mobile}
      email={props.email}
      avatar={props.avatar}
      link={props.link}
    />
  </div>
));

function UserPage() {
  const history = useNavigate(); // переменная для навигации
  const location = useLocation(); // информация о текущем url
  const user = useParams()?.name; // параметр url
  const [isAuthorized, setIsAuthorized] = useState(false); // состояние авторизации
  const [showEditForm, setShowEditForm] = useState(false); // состояние отображения формы редактирования
  const [isLoading, setIsLoading] = useState(true); // состояние загрузки
  const [imgHover, setImgHover] = useState(false); // состояние наведения на аватар
  const [showMore, setShowMore] = useState(false); // состояние отображения доп кнопок
  const [userInfo, setUserInfo] = useState({}); // Информация о пользователе
  const [image, setImage] = useState(null); // источник изображения
  const currentUser = useSelector((state) => state.users.current_user); // логин текущего пользователя
  const isAdmin = location.state?.source === '/admin_page'; // источник перехода - страница администрирования?
  const [qrShow, setQrShow] = useState(false); // отображение QR кода
  const [userQr, setUserQr] = useState(null); // ссылка для отображения в QR
  // const [posAndDept, setPosAndDept] = useState('');
  const [posAndDept, setPosAndDept] = useState(localStorage.getItem('posAndDept') ? localStorage.getItem('posAndDept') : '');

  useEffect(() => {
    setOperInfo({ alias: 'USRPC' });
    // Если смотрит админ или пользователь смотрит свою страницу
    if (isAdmin || (currentUser === user)) {
      setIsAuthorized(true); // обновляем состояние авторизации
      getUserData(user); // запрашиваем данные по пользователю
    }
  }, [currentUser, isAdmin, user]);

  async function getUserData(name) {
    try {
      const reqData = {
        type: 'getUserData',
        fields: [ // необходимые поля
          'id', 'dn', 'cn', 'sn', 'initials', 'samaccountname', 'givenname',
          'description', 'objectsid', 'fired', 'enabled', 'company', 'mail',
          'displayname', 'userprincipalname', 'extensionattribute11', 'avatar', 'telephonenumber',
          'mobile', 'whencreated', 'title', 'department', 'extensionattribute13',
        ],
        name,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_user_data', reqData);
      if (result) {
        setUserInfo(result);
        const url = `https://uniplace.unicon.ru:1443/qr/${result.token}`;
        setUserQr(url);
        if (result?.avatar) {
          const reader = new FileReader(); // API чтения файлов
          const resBlob = new Blob([new Uint8Array(result.avatar.data)]);
          reader.onloadend = ((e) => setImage(e.target.result)); // установка аватара из БД
          reader.readAsDataURL(resBlob);
        } else setImage('');
      } else {
        await alertFunction('incorrect_url_param', 'clientPost');
        setUserInfo(result);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getUserData');
      setIsLoading(false);
    }
  }
  // Обработчик загрузки аватара
  async function fileHandler(file) {
    try {
      setIsLoading(true);
      const reader = new FileReader(); // API чтения файлов
      reader.onloadend = uploadFile; // При успешном завершении операции чтения вызывается функция отправки данных в БД
      reader.readAsArrayBuffer(file); // Чтение данных

      async function uploadFile(e) { // функция отправки данных
        const binaryArray = new Uint8Array(e.target.result); // создание бинарного массива из прочтенного файла
        const blob = new Blob([binaryArray]); // создание Blob из бинарного массива

        const formData = new FormData(); // FormData для отправки blob
        // добавление данных к запросу
        formData.append('type', 'uploadAvatar');
        formData.append('content', blob);
        formData.append('objectsid', userInfo?.objectsid);

        const result = await clientSendFile('POST', '/upload_avatar', formData);
        if (result === 'bad_request') alertFunction('bad_request');
        else if (result.error) {
          alertFunctionLocal(`Ошибка при загрузке!${JSON.stringify(result.error)}`);
          setIsLoading(false);
        } else if (result.avatar) {
          const resBinaryArray = new Uint8Array(e.target.result); // создание бинарного массива из полученного результата
          const resBlob = new Blob([resBinaryArray]); // // создание Blob из бинарного массива
          reader.onloadend = ((event) => {
            setImage(event.target.result);
            setIsLoading(false);
          }); // При успешном чтении устанавливается источник изображения
          reader.readAsDataURL(resBlob); // Чтение данных
        }
      }
    } catch (error) {
      catchHandler(error, 'fileHandler');
    }
  }

  async function deleteUser() {
    try {
      const reqData = {
        type: 'deleteUser',
        objectsid: userInfo?.objectsid,
      };
      const confirm = window.confirm('Удалить пользователя?');
      if (confirm) {
        setIsLoading(true);
        const result = await clientSendData('POST', '/delete_user', reqData);
        if (result === 'success') {
          alertFunction('user_deleted', 'clientPost', (`${userInfo?.sn} ${userInfo?.initials}!`)); // Уведомление "Удалён пользователь <Фамилия Имя>"
          history(-1); // перенаправление на страницу источника и передача состояния
        } else alertFunction('something_went_wrong', 'clientPost'); // Уведомление "Что-то пошло не так!"
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'deleteUser');
      setIsLoading(false);
    }
  }

  function goBackPage() {
    setIsLoading(false);
    history(-1);
  }

  function generateImg() {
    html2canvas(document.body.querySelector('#vcard_block'), { allowTaint: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const arr = imgData.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const newFile = new File([u8arr], 'qr.jpeg', {
        type: mime,
      });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(newFile);
      a.download = 'qr.jpeg';
      a.click();
    });
  }

  function vcardHandler(e) {
    localStorage.setItem('posAndDept', posAndDept);
    exportComponentAsPNG(componentRef, { fileName: 'vCardPresenter', html2CanvasOptions: { backgroundColor: null } });
  }
  const componentRef = useRef();

  // Определить отображаемое значение
  const defineValue = (key) => {
    if (userInfo[key]) {
      const data = userInfo[key];
      if (key === 'experience') {
        let year;
        if ([0, 5, 6, 7, 8, 9].includes(data.years % 10) || [11, 12, 13, 14].includes(data.years) || data.years === undefined) {
          year = 'лет';
        } else if ([2, 3, 4].includes(data.years % 10)) {
          year = 'года';
        } else {
          year = 'год';
        }
        return `${data?.years || 0}${year} ${data?.months || 0}мес.`;
      } return data;
    }
    return '-';
  };

  function changeEightToPlusSeven(mobile) {
    if (String(mobile).charAt(0) === '8') { return `+7${mobile.slice(1)}`; }
    return mobile;
  }

  if (isAuthorized) {
    if (userInfo) {
      return (
        <div id="userPage" className="user-page">
            <div className="user-page__stripe">
                <img className="user-page__back" onClick={goBackPage} src="../../icons/arrow.svg" alt="arrow-left" />
                <p className="user-page__name">
                    {isLoading ? <Loader theme="light" /> : `Добро пожаловать, ${userInfo?.displayname}!`}
                </p>
            </div>
            <div className="user-page__user">
                <div className="user-page__edit-block">
                    {isAdmin && (
                    <div className={showMore ? 'user-page__button-wrapper user-page__button-wrapper_active' : 'user-page__button-wrapper'}>
                        <IconButton onClick={deleteUser} icon="delete" />
                        <IconButton onClick={() => setShowEditForm(true)} icon="edit" />
                    </div>)}
                    {isAdmin && (
                    <img
                      className={showMore ? 'user-page__chevron user-page__chevron_active' : 'user-page__chevron'}
                      onClick={() => setShowMore(!showMore)}
                      src="../../icons/chevron.svg"
                      alt="chevron"
                    />)}
                </div>
                <div className={isAdmin ? 'user-page__body' : 'personal'}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="user-page__info-block">
                        <p className="user-page__personal-data"><b>ФИО</b></p>
                        <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.displayname}</p>
                        <p className="user-page__personal-data"><b>Логин</b></p>

                        <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.samaccountname}</p>
                        <p className="user-page__personal-data"><b>Организация</b></p>
                        <p className="user-page__personal-data-w">{isLoading ? <Loader /> : 'Юникон АО'}</p>
                        {userInfo.mobile !== '' && <p className="user-page__personal-data"><b>Мобильный телефон</b></p> }
                        {userInfo.mobile !== '' && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : changeEightToPlusSeven(userInfo?.mobile)}</p> }
                        {userInfo.telephonenumber !== '' && <p className="user-page__personal-data"><b>Телефон</b></p> }

                        {userInfo.telephonenumber !== '' && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : `8-495-797-56-65 доб. ${userInfo?.telephonenumber}`}</p>}
                        <p className="user-page__personal-data"><b>Сайт</b></p>
                        <p className="user-page__personal-data-w">{isLoading ? <Loader /> : <a href="https://www.unicon.ru">https://www.unicon.ru</a>}</p>
                        <p className="user-page__personal-data"><b>Email</b></p>
                        <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.mail}</p>
                        <p className="user-page__personal-data"><b>Адрес компании</b></p>
                        <p className="user-page__personal-data-w">{isLoading ? <Loader /> : 'Москва, 107061, Преображенская площадь д.8, БЦ "ПРЕО-8", 14 этаж'}</p>
                        {isAdmin && <p className="user-page__personal-data"><b>Последняя сессия</b></p>}

                        {isAdmin && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.last_session || ' '}</p>}
                        {isAdmin && <p className="user-page__personal-data"><b>Описание</b></p>}
                        {isAdmin && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.description || ' '}</p>}

                        {isAdmin && <p className="user-page__personal-data"><b>Грейд</b></p>}
                        {isAdmin && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.extensionattribute11}</p>}

                        {(isAdmin && userInfo?.mentor_name) && <p className="user-page__personal-data"><b>Ментор</b></p>}
                        {(isAdmin && userInfo?.mentor_name) && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.mentor_name}</p>}

                        {isAdmin && <p className="user-page__personal-data"><b>МВЗ</b></p>}
                        {isAdmin && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.extensionattribute13}</p>}

                        {isAdmin && <p className="user-page__personal-data"><b>Подразделение</b></p>}
                        {isAdmin && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.department}</p>}

                        {isAdmin && <p className="user-page__personal-data"><b>Стаж</b></p>}
                        {isAdmin && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : defineValue('experience')}</p>}

                        {isAdmin && <p className="user-page__personal-data"><b>Опыт в текущей должности</b></p>}
                        {isAdmin && <p className="user-page__personal-data-w">{isLoading ? <Loader /> : '-'}</p>}

                        {userInfo.token !== null
                          && <>
                          <p className="user-page__personal-data"><b>QR-визитка</b></p>
                        <p className="user-page__personal-data" />
                          <p className="user-page__personal-data-b">
                              <input
                                defaultValue={posAndDept}
                                name="posAndDept"
                                className="input"
                                placeholder="Должность|направление, отрасль"
                                onInput={(e) => setPosAndDept(e.target.value)}
                              />
                          </p>
                          <p className="user-page__personal-data-b">
                              {isLoading ? <Loader /> : <Button onClick={generateImg}>Сформировать визитку</Button>}
                          </p>
                             </>}

                        {/*  <p className="user-page__personal-data" />
                          <p className="user-page__personal-data">{isLoading ? <Loader /> : <Button>Сформировать визитку</Button> }</p> */}

                        { /*  {(userQr !== null && userInfo.token !== null)
                            && <>
                                  <p className="user-page__personal-data"><b>QR</b></p>
                                    <span className="user-page__personal-data qr-toggle">
                                      <Toggle onClick={() => setQrShow(!qrShow)} state={qrShow.toString()} />
                                    </span>
                                    {qrShow
                                      && <span className="access-form__sub-form_enabled">
                                            <div className="qr-box" id="qrCode">
                                              <div className="vCard__info">
                                                <p><b>ФИО:</b></p>
                                                <p className="sub-info">{userInfo?.displayname}</p>
                                                <p><b>Организация:</b></p>
                                                <p className="sub-info">{userInfo?.company}</p>
                                                {userInfo.telephonenumber !== '' && (
                                                <>
                                                  <p><b>Телефон:</b></p>
                                                  <p className="sub-info">{`8-495-797-56-65 доб. ${userInfo?.telephonenumber}`}</p>
                                                </>)}
                                                {userInfo.mobile !== '' && (
                                                <>
                                                  <p><b>Мобильный телефон:</b></p>
                                                  <p className="sub-info">{userInfo?.mobile}</p>
                                                </>)}
                                                <p><b>Сайт:</b></p>
                                                <p className="sub-info"><a href="https://www.unicon.ru">https://www.unicon.ru</a></p>
                                                <p><b>Личная почта:</b></p>
                                                <p className="sub-info">{userInfo?.mail}</p>
                                                <p><b>Адрес компании:</b></p>
                                                <p className="sub-info">Москва, 107061, Преображенская площадь д.8, БЦ `&quot;`ПРЕО-8`&quot;`, 14 этаж</p>
                                              </div>
                                              <div className="qr-wrapper">
                                                <QRCodeCanvas
                                                  className="qr"
                                                  onClick={() => generateImg()}
                                                  value={userQr}
                                                  size={276}
                                                  bgColor="#ffffff"
                                                  fgColor="#000000"
                                                  level="H"
                                                  includeMargin={false}
                                                  imageSettings={{
                                                    src: '../../../icons/unicon.jpg',
                                                    x: undefined,
                                                    y: undefined,
                                                    height: 60,
                                                    width: 110,
                                                    excavate: false,
                                                  }}
                                                />
                                              </div>
                                            </div>
                                        </span> }
                                </> } */}
                    </div>
                   {!isAdmin && <div className="user-page__info-block">
                    <p className="user-page__personal-data"><b>Грейд</b></p>
                    <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.extensionattribute11}</p>
                    {userInfo?.mentor_name && (<>
                    <p className="user-page__personal-data"><b>Ментор</b></p>
                    <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.mentor_name}</p>
                                               </>)}
                    <p className="user-page__personal-data"><b>МВЗ</b></p>
                    <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.extensionattribute13}</p>
                    <p className="user-page__personal-data"><b>Подразделение</b></p>
                    <p className="user-page__personal-data-w">{isLoading ? <Loader /> : userInfo?.department}</p>
                    <p className="user-page__personal-data"><b>Стаж</b></p>
                    <p className="user-page__personal-data-w">{isLoading ? <Loader /> : defineValue('experience') }</p>
                    <p className="user-page__personal-data"><b>Опыт в текущей должности</b></p>
                    <p className="user-page__personal-data-w">{isLoading ? <Loader /> : '-' }</p>
                                </div>}
                  </div>

                  {/* Настройка доступа */}
                  {isAdmin
&& <div className={`user-page__info-block ${isLoading ? ' loading' : ''}`}>
                      <p className="user-page__personal-data"><b>Настройка доступов сотрудника</b></p>
                          {isLoading ? <Loader /> : (
                          <AccessForm
                            user_id={userInfo?.id}
                            objectsid={userInfo?.objectsid}
                            user={userInfo?.samaccountname}
                            isAdmin={isAdmin}
                          />)}
   </div>}

{(userQr !== null && userInfo.token !== null) && <ComponentToPrint
  ref={componentRef}
            //  position={userInfo?.title}
  position={posAndDept}
  surname={userInfo?.sn}
  givenname={userInfo?.givenname}
  dept={userInfo?.department}
  mobile={changeEightToPlusSeven(userInfo?.mobile)}
  email={userInfo?.mail}
  avatar={image}
  link={userQr}
/>}

                </div>
            </div>

            {showEditForm && (
              <EditUserForm
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                setShow={setShowEditForm}
              />)}
            <div className="user-page__avatar-wrapper">
              <SingleFileUpload
                options={{
                  handler: fileHandler,
                  accept: '.jpg, .jpeg, .png, .gif',
                  size: 1024000,
                }}
              >
                <img
                  onMouseEnter={() => setImgHover(true)}
                  onMouseLeave={() => setImgHover(false)}
                  className="user-page__user-avatar"
                  src={image || '../../icons/user_color.png'}
                  alt="avatar"
                />
              </SingleFileUpload>
              <img
                className={imgHover ? 'user-page__avatar-hint user-page__avatar-hint_active' : 'user-page__avatar-hint'}
                src="../../icons/button/edit.svg"
                alt="edit_foto"
              />
            </div>
        </div>

      );
    }
    return (
        <div className="user-page">
            <div className="user-page__stripe">
                <img className="user-page__back" onClick={goBackPage} src="../../icons/arrow.svg" alt="arrow-left" />
                <p className="user-page__name">
                    {isLoading ? <Loader theme="light" /> : 'Пользователь не найден'}
                </p>
            </div>
            <div className="user-page__avatar-wrapper">
                <img className="user-page__user-avatar" src="../../icons/user_color.png" alt="avatar" />
            </div>
        </div>
    );
  } return <Cap />;
}

export default UserPage;
