import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceBody from '../../components/Service/ServiceBody';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TData from '../../components/Table/TData';
import TRow from '../../components/Table/TRow';
import TBody from '../../components/Table/TBody';
import { clearHtmlString } from '../../utils/functions/stringHandling';
import TFoot from '../../components/Table/TFoot';
import EmployeeVacationCard from './EmployeeVacationCard';
import Select from '../../components/UI/Select';
import { alertFunction } from '../../utils/functions/alertFunction';

function VacationPage() {
  const headers = [
    { id: 1, title: 'ФИО', field: 'name' },
    { id: 2, title: 'Дата начала', field: 'date_start' },
    { id: 3, title: 'Дата окончания', field: 'date_end' },
    { id: 3, title: 'Количество дней', field: 'days' },
    { id: 3, title: 'Статус', field: 'status' },
  ];
  const user_id = useSelector((state) => state?.oper_info?.user_id); // id текущего пользователя
  // const isAdmin = user_id === 183401;
  const isAdmin = [1298, 1287].includes(user_id);
  const [employeeName, setEmployeeName] = useState([]);
  const [openedVacation, setOpenedVacation] = useState({}); // отпуск открытый в карточке
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const [filterYears, setFilterYears] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [groupAmount, setGroupAmount] = useState();
  const [action, setAction] = useState();
  const [allDays, setAlldays] = useState();
  const [spareDays, setSpareDays] = useState(0);
  const current_year = new Date().getFullYear();
  const [currentYear, setCurrentYear] = useState(current_year);
  const [calendar, setCalendar] = useState();

  useEffect(() => {
    getVacations();
    getFilterYears();
  }, [currentYear]);

  function openCard(object, type) {
    setAction(() => type);
    setOpenedVacation(object);
    setShowCard(true);
  }

  function countDays(userVacations, days_available) {
    let sumDays = 0;
    for (const v of userVacations.filter((el) => Number(el.user_id) === user_id)) {
      sumDays += Number(v.days);
    }
    setSpareDays(() => days_available - sumDays);
  }

  function closeCard() {
    setShowCard(false);
    countDays(vacations, allDays);
  }

  async function getVacations() {
    try {
      const reqData = {
        type: 'getVacationsEmployee',
        currentYear,
        isAdmin,
      };
      const result = await clientSendData('POST', '/get_vacations_employee', reqData); // запрос в БД
      if (result.data) {
        if (!isAdmin) {
          setGroupAmount(result.groupAmount);
        }
        setAlldays(Number(result.userData.days_available));
        setEmployeeName(result.userData.name);
        countDays(result.data, Number(result.userData.days_available));
        // setSpareDays(Number(result.userData.days_available));
        setVacations(result.data);
        setCalendar(result.calendar.months);
      }
    } catch (error) {
      catchHandler(error, 'getVacationsEmployee'); // обработка ошибок
    }
  }

  // Получить года отпусков для фильтра
  async function getFilterYears() {
    try {
      const reqData = {
        type: 'getFilterYears',
      };
      const result = await clientSendData('POST', '/get_filter_years', reqData); // запрос в БД
      if (result.data) {
        setFilterYears(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getFilterYears'); // обработка ошибок
    }
  }

  // Сохранение заявления
  async function saveApplication(kind, vacation) {
    try {
      const reqData = {
        type: 'downloadDocVacation',
        kind,
        vacation,
      };
      const result = await clientSendData('POST', '/download_doc_vacation', reqData);
      if (result) { // если запрос выполнен успешно
        const uInt8Array = new Uint8Array(result.data);
        const blob = new Blob([uInt8Array], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const tempLink = document.createElement('a');
        tempLink.href = window.URL.createObjectURL(blob);
        tempLink.setAttribute('download', kind === 'shift' ? 'Заявление_на_перенос_отпуска.docx' : 'Заявление_о_предоставлении_отпуска.docx');
        document.body.appendChild(tempLink);
        tempLink.click();
      }
      if (kind === 'shift') await changeVacationStatus(vacation.id);
    } catch (error) {
      catchHandler(error, 'downloadDocVacation');
    }
  }

  // Сохранение ведомости
  async function changeVacationStatus(vctnId) {
    try {
      const reqData = {
        type: 'changeVacationStatus',
        vctnId,
      };
      const result = await clientSendData('POST', '/change_vacation_status', reqData);
      if (result.success) {
        getVacations();
      }
    } catch (error) {
      catchHandler(error, 'changeVacationStatus');
    }
  }

  async function confirmVacation(vctnId) {
    try {
      const reqData = {
        type: 'confirmVacation',
        vctnId,
        currentYear,
      };
      const result = await clientSendData('POST', '/confirm_vacation', reqData);
      if (result.success) {
        await alertFunction('vacation_confirmed', 'clientPost');
        getVacations();
      }
    } catch (error) {
      catchHandler(error, 'confirmVacation');
    }
  }

  return (
    <ServiceBody id="vacation__page">
      <div className="page_header">
      <Select array={filterYears} defaultValue={currentYear} onChoose={(e) => setCurrentYear(e.title)} id="filter" />
      <div className="spare-days">
        Осталось дней отпуска для распределения:
        {' '}
        <p className="count">{spareDays}</p>
      </div>
      </div>
      {/* <div id="filter">
      <Select array={filterYears} defaultValue={currentYear} onChoose={(e) => setCurrentYear(e.title)} />
      </div> */}
    <Table>
    <THead>
        <TRow>
          {headers.map((item) => <TData key={item.title}>{item.title}</TData>)}
          <TData />
        </TRow>
    </THead>
    <TBody>
    {vacations.map((row) => (
      row.status === 'На согласовании'
        ? <TRow key={row.id} mark="odd">
            {headers.map((column) => {
              const { id, field } = column; // дектруктуризация объекта колонки
              const value = row?.[field];
              return (
                <TData key={field}>
                  {field !== 'content' ? value : clearHtmlString(value)}
                </TData>
              );
            })}
            {(row.status === 'Создано') && <TData>
              {(Number(row.user_id) === user_id || isAdmin) && (
                <>
                <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} title="Редактировать" />
                <img src="../../icons/editor/check.svg" alt="file" onClick={() => confirmVacation(row.id)} title="Подтвердить даты" />
                </>
              )}
                                           </TData>}

           {(row.status === 'Ознакомлен' || row.status === 'На согласовании') && <TData>
            {(Number(row.user_id) === user_id || isAdmin) && (
              <>
               <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} title="Редактировать" />
               <img src="../../icons/file-text.svg" alt="file" onClick={() => saveApplication('vacation', row)} title="Скачать заявление" />
              </>
            )}
                                                                                 </TData>}

          {(row.status === 'Не согласовано' || row.status === 'Перенесено') && <TData>
            {(Number(row.user_id) === user_id || isAdmin) && (
              <>
              <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} />
              <img src="../../icons/file-text.svg" alt="file" onClick={() => saveApplication('vacation', row)} />
              </>
            )}
                                                                               </TData>}

          {row.status === 'Согласовано' && <TData>
            {(Number(row.user_id) === user_id || isAdmin) && (
              <>
               <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} />
               <img src="../../icons/file-text.svg" alt="file" onClick={() => saveApplication('shift', row)} />
              </>
            )}
                                           </TData>}
          </TRow> : <TRow key={row.id}>
            {headers.map((column) => {
              const { id, field } = column; // дектруктуризация объекта колонки
              const value = row?.[field];
              return (
                <TData key={field}>
                  {field !== 'content' ? value : clearHtmlString(value)}
                </TData>
              );
            })}
            {(row.status === 'Создано') && <TData>
              {(Number(row.user_id) === user_id || isAdmin) && (
                <>
                <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} title="Редактировать" />
                <img src="../../icons/editor/check.svg" alt="file" onClick={() => confirmVacation(row.id)} title="Подтвердить даты" />
                </>
              )}
                                           </TData>}

          {(row.status === 'Ознакомлен' || row.status === 'На согласовании') && <TData>
            {(Number(row.user_id) === user_id || isAdmin) && (
              <>
               <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} title="Редактировать" />
               <img src="../../icons/file-text.svg" alt="file" onClick={() => saveApplication('vacation', row)} title="Скачать заявление" />
              </>
            )}
                                                                                </TData>}

          {(row.status === 'Не согласовано' || row.status === 'Перенесено') && <TData>
            {(Number(row.user_id) === user_id || isAdmin) && (
              <>
              <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} />
              <img src="../../icons/file-text.svg" alt="file" onClick={() => saveApplication('vacation', row)} />
              </>
            )}
                                                                               </TData>}
          {row.status === 'Согласовано' && <TData>
            {(Number(row.user_id) === user_id || isAdmin) && (
              <>
               <img src="../../icons/button/edit.svg" alt="edit" onClick={() => openCard(row, 'edit')} />
               <img src="../../icons/file-text.svg" alt="file" onClick={() => saveApplication('shift', row)} />
              </>
            )}
                                           </TData>}
                    </TRow>
    ))}
    </TBody>
      <TFoot>
        <TRow>
          <TData onClick={() => openCard({ id: 'new' }, 'create')}>Создать</TData>
        </TRow>
      </TFoot>
    </Table>
    {showCard && (
      <EmployeeVacationCard
        getVacations={getVacations}
        openedVacation={openedVacation}
        setOpenedVacation={setOpenedVacation}
        spareDays={spareDays}
        setSpareDays={setSpareDays}
        setShow={closeCard}
        setVacations={setVacations}
        vacations={vacations}
        allDays={allDays}
        type={action}
        employeeName={employeeName}
        currentYear={currentYear}
        groupAmount={groupAmount}
        calendar={calendar}
      />
    )
  }
    </ServiceBody>
  );
}

export default VacationPage;
