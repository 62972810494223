import React, { useState } from 'react';

function Chat() {
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки

  return (
    <div>Chat</div>
  );
}

export default Chat;
