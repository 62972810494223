import React, { useEffect, useState } from 'react';
import Button from '../../components/UI/Button/Button';
import InputSearch from '../../components/UI/InputSearch';
import Input from '../../components/UI/Input';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';

/**
* @component Карточка создания задачи/подзадачи
  @prop {newFolder} object - объект новой задачи
  @prop {setNewFolder} function - функция установки параметров в объект задачи newFolder
  @prop {addFolder} function -  Функция сохранения задачи
  @prop {parentItem} object - текущая папка, в которую добавляется новая задача
  @prop {cancelCreation} function - функция отмены создания (закрытия карточки)
*/

function NewTaskCard({
  setNewFolder, cancelCreation, newFolder, addNewItem, parentItem, template,
}) {
  useEffect(() => {
    getUsers();
    if (newFolder?.id) {
      setNewFolder({ ...newFolder });
    } else {
      setNewFolder({
        id: template.length + 1,
        type: 'task',
        section: `${parentItem.section}.${template.filter((el) => el.parentId === parentItem.id).length + 1}`,
        parentId: parentItem.id,
        status: 'Открыта',
      });
    }
  }, []);

  const [employees, setEmployees] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [selectUsers, setSelectUsers] = useState({});
  const [participiants, setParticipiants] = useState([]); // участники команды

  async function getUsers() {
    try {
      const reqData = {
        type: 'getUsersPortal',
      };
      const result = await clientSendData('POST', '/get_users_portal', reqData); // запрос в БД
      if (result) {
        setEmployees(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getUsersPortal'); // обработка ошибок
    }
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'clientsPortalSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData(
          'POST',
          '/clients_portal_search_user',
          reqData,
        );
        setSelectUsers({
          id: e.target.value,
          title: e.target.value,
          mail: e.target.value,
        });
        if (result?.success) setEmployees(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'clientsPortalSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  // Добавление пользователей
  async function addEmployees(participiant) {
    if (Object.keys(participiant).length > 0) {
      if (
        participiants.filter((el) => el.id === participiant.id).length === 0
      ) {
        setParticipiants((state) => [...state, participiant]);
        //   const newUsers = newProjectData.project_team;
        // newUsers.push({
        //   teamMember_id: participiant.id,
        //   teamMember_title: participiant.title,
        //   mail: participiant.mail,
        // });
        //  setNewProjectData((state) => ({ ...state, project_team: newUsers }));
      }
    }
  }

  function saveInduties() {
    setNewFolder({ ...newFolder, in_duty: participiants });
    setIsAdding(false);
  }

  function deleteInduty(id) {
    const allUsers = participiants;
    const filteredUsers = allUsers.filter((el) => el.id !== id);
    setParticipiants(filteredUsers);
  }

  return (
    <div className="create-folder">
            <div className="task-card_background" />
              <div className="task-card">
                <p className="task-card_header">
                  Создать новую задачу
                  <p className="close" onClick={cancelCreation}>x</p>
                </p>

                <div className="task-card_container">
                  {/* Название */}
                  <span className="task-card_inputs">
                    <p className="title">Название*</p>
                    <textarea
                      className="create-folder-line"
                      onChange={(e) => setNewFolder({ ...newFolder, title: e.target.value })}
                      defaultValue={newFolder?.title}
                    />
                  </span>
                  {/* Описание */}
                  <span className="task-card_inputs">
                    <p className="title">Описание</p>
                    <textarea className="create-folder-line" style={{ height: '60px' }} onChange={(e) => setNewFolder({ ...newFolder, description: e.target.value })} />
                  </span>
                  <span className="data-container">
                    <p className="data-container_title">Ответственный:</p>
                    <div><p className="action-btn" onClick={() => setIsAdding(true)}>добавить</p></div>
                   {isAdding
                   && <span className="add-induty">
                   <InputSearch
                     array={employees}
                     onSearch={(e) => searchWrapper(e)}
                     onChoose={(user) => addEmployees(user)}
                     defaultValue={selectUsers?.title}
                   />
                   <ul className="employees-list">
                    {participiants.map((user) => <li>
                     {user.title}
                      <p style={{ width: '10px', cursor: 'pointer' }} onClick={() => deleteInduty(user.id)}>x</p>
                                                 </li>)}
                   </ul>
                   <div
                     style={{
                       display: 'flex', flexDirection: 'row', gap: '15px', marginTop: 'auto', justifyContent: 'space-between',
                     }}
                   >
                    <p className="comment-line_btn" onClick={saveInduties}>Сохранить</p>
                    <p className="comment-line_btn" onClick={() => setIsAdding(false)}>Отмена</p>
                   </div>
                      </span>
                   }
                  </span>
                  <span className="data-container">
                    <p className="data-container_title">Дата начала:</p>
                    <input type="date" onChange={(e) => setNewFolder({ ...newFolder, date_start: e.target.value })} />
                  </span>
                  <span className="data-container">
                    <p className="data-container_title">Дата окончания:</p>
                    <input type="date" onChange={(e) => setNewFolder({ ...newFolder, date_end: e.target.value })} />
                  </span>
                  <span className="data-container">
                    <p className="data-container_title">Приоритет:</p>
                    <div><p className="action-btn" onClick={() => setNewFolder({ ...newFolder, priority: 'low' })}>Низкий</p></div>
                    <div><p className="action-btn" onClick={() => setNewFolder({ ...newFolder, priority: 'medium' })}> Средний</p></div>
                    <div><p className="action-btn" onClick={() => setNewFolder({ ...newFolder, priority: 'high' })}>Высокий</p></div>
                  </span>
                </div>
                <div className="footer">
                  <Button onClick={addNewItem}>Сохранить</Button>
                </div>
              </div>
    </div>
  );
}

export default NewTaskCard;
