/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import {
  activeTicketStatuses,
  convertTicketId,
  searchTicketData,
  ticketStatus,
} from '../../common';
import CardSetting from '../../../../components/Card/CardSetting';
import InputSearch from '../../../../components/UI/InputSearch';
import Button from '../../../../components/UI/Button/Button';
import Loader from '../../../../components/UI/Loader';
import TextArea from '../../../../components/UI/TextArea';
import Modal from '../../../../components/UI/Modal';
import CheckBox from '../../../../components/UI/CheckBox';
import Select from '../../../../components/UI/Select';
import classes from '../../../../components/UI/Button/button.module.scss';
import Input from '../../../../components/UI/Input';
import TimeIndicatorSLA from './TimeIndicatorSLA';

/**
 * @component ХелпДеск - заявки - карточка заявки - вкладка "Заявка"
 * @prop {ticketID} object - id заявки
 * @prop {refreshTable} function - обновление заявок в таблице
 * @prop {closeHandler} function - закрытие карточки
 */
function Ticket(props) {
  const { ticketID, refreshTable, closeHandler } = props;
  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const currentUser = operInfo.user_id; // id текущего пользователя
  const sdParametres = useSelector((state) => state.helpdesk.parameters); // настройки хелпдеска из sd_parameters
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [ticket, setTicket] = useState({}); // объект с данными заявки
  const [timeDifference, setTimeDifference] = useState('');
  const [preliminaryResolutionTime, setPreliminaryResolutionTime] = useState('');
  const [modalState, setModalState] = useState({
    active: false,
    action: '',
    placeholder: '',
    message: '',
  });
  const [ticketDefault, setTicketDefault] = useState({}); // объект с данными заявки до внесения изменений
  const isSuperUser = sdParametres?.admins?.includes(String(currentUser));
  const isCoordinator = sdParametres?.coordinators?.includes(String(currentUser));
  const currentStatus = ticket?.status_id; // текущий статус заявки
  const [calendarData, setCalendarData] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState({ // дополнительная информация
    // statuses: [], // типы статусов
    priorities: [], // приоритеты заявок
    category: [], // категории (при поиске)
    group: [], // группы исполнителей (при поиске)
    performer: [], // исполнители (при поиске)
    initiator: [], // исполнители (при поиске)
    asset: [], // активы (при поиске)
    treatments: [], // типы обращений
  });
  const [dataInfo, setDataInfo] = useState({});

  // проверка изменения данных заявки
  const isTicketChanged = [
    'category_id',
    'group_id',
    'performer_id',
    'date_solved',
    'date_closed',
    'asset_connection',
    'asset_id',
    'priority_id',
    'treatment_id',
    'initiator_id',
    'dismissal_date',
  ].some((key) => ticketDefault?.[key] !== ticket?.[key]);
  const smallButtonClass = `${classes.button} ${classes.small}`; // имя класса стилей кнопок
  useEffect(() => {
    if (ticketID) getTicketData('main');
    // запросить информацию по заявке
  }, [ticketID]);

  async function getTicketData(data_type, defaultGroupId = null) {
    try {
      const reqData = { type: 'getTicketData' };
      setIsLoading(true);
      const result = await clientSendData('POST', `/get_ticket_data/${ticketID}?type=${data_type}`, reqData);
      if (result?.success) { // если запрос выполнен успешно
        if (data_type === 'main') { // при получении основной информации
          const data = result.data[0];
          console.log(data);
          document.title = `Запрос #${data?.ticket_number}`;
          if (data) { // если заявка найдена
            setTicket(data); // записываем данные в состояние заявки
            setTicketDefault(data); // записываем данные в состояние заявки по умолчанию
            await getTicketData('categories'); // запрашиваем категории
            await getTicketData('priorities'); // запрашиваем приоритеты
            await getTicketData('treatments'); // запрашиваем типы обращений
            await getTicketData('groups'); // запрашиваем группы
            await getTicketData('performer', data.group_id); // запрашиваем исполнителя
            await getTicketData('initiator'); // запрашиваем инициатора
            // await getTicketData('statuses'); // запрашиваем статусы
          } else closeHandler(); // закрыть карточку
        }
        setDataInfo((state) => ({ ...state, ...result.data }));
        if (data_type === 'categories') { // при получении статусов
          setAdditionalInfo((state) => ({ ...state, category: result.data })); // обновляем состояние категорий
        }
        if (data_type === 'priorities') { // при получении приоритетов
          setAdditionalInfo((state) => ({ ...state, priorities: result.data })); // обновляем состояние приоритетов
        }
        if (data_type === 'treatments') { // при получении типов обращений
          setAdditionalInfo((state) => ({ ...state, treatments: result.data })); // обновляем состояние типов обращений
        }
        if (data_type === 'initiator') { // при получении инициатора
          setAdditionalInfo((state) => ({ ...state, treatments: result.data })); // обновляем состояние инициатора
        }

        if (data_type === 'groups') { // при получении типов обращений
          setAdditionalInfo((state) => ({ ...state, group: result.data })); // обновляем состояние групп
        }
        if (data_type === 'performer') { // при получении типов обращений
          setAdditionalInfo((state) => ({ ...state, performer: result.data })); // обновляем состояние исполнителей
          setDataInfo((state) => ({
            ...state,
            performer: result.data.filter((el) => el.group_id.includes(defaultGroupId)),
          }));
        }
      }
    } catch (error) {
      catchHandler(error, 'getTicketData');
    } finally {
      setIsLoading(false);
    }
  }

  // Сохранение изменений заявки
  async function saveTicket() {
    try {
      if (validateTicket()) {
        const confirm = window.confirm('Сохранить изменения?');
        if (confirm) {
          // Поля необходимые для записи изменений
          const necessaryFields = [
            'id',
            'title',
            'status',
            'status_id',
            'category',
            'category_id',
            'performer',
            'performer_id',
            'group',
            'group_id',
            'date_solved',
            'date_closed',
            'asset_connection',
            'asset_id',
            'asset',
            'priority_id',
            'treatment_id',
            'initiator',
            'initiator_id',
            'dismissal_date',
          ];
          // Создать объект из необходимы полей
          const createObject = (source) => necessaryFields.reduce((acc, field) => ({ ...acc, [field]: source[field] }), {});
          const reqData = {
            type: 'editTicket',
            ticket: createObject(ticket),
            ticketDefault: createObject(ticketDefault),
            source: operInfo.alias,
          };
          setIsLoading(true);
          const result = await clientSendData('POST', '/edit_ticket', reqData);
          if (result.session_expired) window.location.reload();
          else if (result?.success) {
            await getTicketData('main');
            await refreshTable();
            await alertFunction('save_settings', 'clientPost');
          } else await alertFunction('something_went_wrong', 'clientPost');
        }
      } else await alertFunction('required_fields', 'clientPost');
    } catch (error) {
      catchHandler(error, 'ticketHandler');
    } finally {
      setIsLoading(false);
    }
  }

  async function ticketButtonHandler(button) {
    try {
      const message = () => {
        switch (button) {
          case 'take_ticket': return 'Взять запрос?';
          case 'back_to_work': return 'Вернуть запрос в работу?';
          case 'unsolve': return 'Отменить решение и вернуть запрос в работу?';
          case 'await': return 'Отправить запрос в ожидание?';
          case 'close': return 'Закрыть запрос?';
          default: return 'Вы уверены?';
        }
      };

      const confirm = window.confirm(message());
      if (confirm) {
        // Инициализация reqData
        const reqData = {
          type: 'ticketButtonHandler',
          ticket_id: ticketID,
          ticketConvertId: convertTicketId(ticketID),
          modal_message: modalState.message,
          source: operInfo.alias,
          initiator_id: ticket.initiator_id,
          associated_tickets_id: ticket.associated_tickets_id,
          date_expectation: ticket.date_expectation || [], // Инициализация, если массив еще не существует
          date_start_work: ticket.date_start_work,
        };

        console.log(reqData);

        // Обработка нажатия кнопки 'take_ticket'
        if (button === 'take_ticket') {
          const currentTime = new Date();
          const startTime = `${currentTime.getFullYear()}-${String(currentTime.getMonth() + 1).padStart(2, '0')}-${String(currentTime.getDate()).padStart(2, '0')} ${String(currentTime.getHours()).padStart(2, '0')}:${String(currentTime.getMinutes()).padStart(2, '0')}`;

          reqData.date_start_work = startTime; // Устанавливаем текущее время
          setIsLoading(true);
          const result = await clientSendData('POST', `/ticket_button_handler/${button}`, reqData);
          if (result?.success) {
            await getTicketData('main');
            await refreshTable();
          } else {
            await alertFunction('something_went_wrong', 'clientPost');
          }
          return; // Выходим из функции после обработки 'take_ticket'
        }

        // Обработка нажатия кнопки 'await'
        if (button === 'await') {
          const currentTime = new Date();
          const startTime = `${currentTime.getFullYear()}-${String(currentTime.getMonth() + 1).padStart(2, '0')}-${String(currentTime.getDate()).padStart(2, '0')} ${String(currentTime.getHours()).padStart(2, '0')}:${String(currentTime.getMinutes()).padStart(2, '0')}`;

          // Инициализация, если массив еще не существует
          if (!ticket.date_expectation) {
            ticket.date_expectation = [];
          }

          ticket.date_expectation.push({ start: startTime });
          console.log(ticket.date_expectation);

          // Обновляем reqData с новыми данными
          reqData.date_expectation = ticket.date_expectation;
        }

        // Обработка нажатия кнопки 'back_to_work'
        if (button === 'back_to_work') {
          const currentTime = new Date();
          const endTime = `${currentTime.getFullYear()}-${String(currentTime.getMonth() + 1).padStart(2, '0')}-${String(currentTime.getDate()).padStart(2, '0')} ${String(currentTime.getHours()).padStart(2, '0')}:${String(currentTime.getMinutes()).padStart(2, '0')}`;

          // Проверяем, есть ли предыдущая дата ожидания
          if (ticket.date_expectation && ticket.date_expectation.length > 0) {
            const lastExpectation = ticket.date_expectation[ticket.date_expectation.length - 1];
            if (!lastExpectation.end) {
              lastExpectation.end = endTime; // Устанавливаем конечное время
            }
          }

          // Обновляем reqData с новыми данными
          reqData.date_expectation = ticket.date_expectation;
        }

        // Отправляем данные на сервер
        setIsLoading(true);
        const result = await clientSendData('POST', `/ticket_button_handler/${button}`, reqData);
        if (result?.success) {
          await getTicketData('main');
          await refreshTable();
        } else {
          await alertFunction('something_went_wrong', 'clientPost');
        }
      }
    } catch (error) {
      catchHandler(error, 'ticketHandler'); // Обработка ошибки с помощью catchHandler
    } finally {
      setIsLoading(false); // Устанавливаем состояние загрузки в false в любом случае
    }
  }

  // Валидация заявки перед сохранением
  // Если каждый из ключей в массиве прошел валидацию
  const validateTicket = () => (['category_id', 'group_id', 'performer_id', 'asset_id', 'treatment_id', 'dismissal_date'].every((key) => validateField(key)));
  // Валидация отдельного ключа
  const validateField = (field) => {
    // Если заявка новая или в очереди, выбирать исполнителя не обязательно, поэтому он валидируется
    if (field === 'performer_id' && [ticketStatus.new, ticketStatus.queue, ticketStatus.work, ticketStatus.await].includes(currentStatus)) return true;
    // Если это актив и привязка актива к заявке выключена - поле актива валидируется
    if (field === 'asset_id' && !ticket?.asset_connection) return true;
    // Если заявка не по сервису увольнения - поле даты увольнения валидируется
    if (field === 'dismissal_date' && ticket?.category_id !== 177) return true;
    return Boolean(ticket[field]);
  };

  // Поиск данных и запись в состояние
  // e - event
  // field - какие данные ищем
  async function searchWrapper(e, field) {
    try {
      setIsSearching(true); // поиск выполняется
      if (e.target.value.length === 0) {
        setTicket((prev) => ({
          ...prev,
          [`${field}_id`]: null,
          [field]: null,
        }));
      }
      const result = await searchTicketData(e.target.value, field); // получение данных
      setAdditionalInfo((state) => ({ ...state, [field]: result })); // обновление состояния по переданному ключу
    } catch (error) {
      catchHandler(error, 'searchWrapper');
    } finally {
      setIsSearching(false);
    }
  }

  // Выбор категории
  function chooseCategory(category) {
    const {
      id, title, group_id, group_title,
    } = category;

    setTicket((state) => ({
      ...state,
      category: title,
      category_id: id,
      group: group_title,
      group_id,
      //
      performer: additionalInfo.performer.title || null, // сброс исполнителя при новом выборе категории
      performer_id: additionalInfo.performer.id || null,
      //
    }));
    setDataInfo((state) => ({
      ...state,
      performer: additionalInfo.performer.filter((el) => el.group_id.includes(category.group_id)),
    }));
  }

  // Выбор группы
  function chooseGroup(group) {
    const { id, title } = group;
    // let cTitle;
    // for (let i = 0; i <= additionalInfo.category.length - 1; i++) {
    //   cTitle = title === additionalInfo.category[i].group_title ? additionalInfo.category[i].title : null;
    // }

    setTicket((state) => ({
      ...state,
      category: additionalInfo.category.title || null, // сброс категории при новом выборе группы
      category_id: additionalInfo.category.id || null,
      //
      performer: additionalInfo.performer.title || null, // сброс исполнителя при новом выборе группы
      performer_id: additionalInfo.performer.id || null,
      //
      group: title,
      group_id: id,
    }));
    setDataInfo((state) => ({
      ...state,
      performer: additionalInfo.performer.filter((el) => el.group_id.includes(group.id)),
    }));
  }

  // Выбор исполнителя
  function choosePerformer(performer) {
    const {
      id,
      title,
      // group_id,
      // group_title,
    } = performer;

    setTicket((state) => ({
      ...state,
      performer: title,
      performer_id: id,
      //  group: group_title.length > 0 ? group_title[0] : group_title,
      // group_id: group_id.length > 0 ? group_id[0] : group_id,
    }));
  }

  // Выбор актива
  function chooseAsset(asset) {
    const { id, title } = asset;

    setTicket((state) => ({
      ...state,
      asset: title,
      asset_id: id,
    }));
  }

  // Вкл/выкл привязку актива
  function toggleAsset(event) {
    setTicket((state) => ({
      ...state,
      asset_connection: event.target.checked,
      asset_id: null,
      asset: null,
    }));
  }

  async function chooseDate(date) {
    const prevDate = `(${ticket.dismissal_date})`;
    let title = ticket.title;
    if (ticket.title.includes(prevDate)) {
      const index = ticket.title.indexOf(prevDate);
      title = title.slice(0, index - 1);
    }
    setTicket((state) => ({
      ...state,
      dismissal_date: date,
      title: `${title} (${date})`,
    }));
  }

  // SLA
  useEffect(() => {
    const fetchTime = async () => {
      if (ticket) {
        const timeDiff = await calculateTimeDifference(ticket);
        setTimeDifference(timeDiff);
      }
    };

    fetchTime();

    const intervalId = setInterval(fetchTime, 60000); // обновление каждые 60 секунд (60000 мс)

    return () => clearInterval(intervalId);
  }, [ticket]);

  useEffect(() => {
    const fetchTimes = async () => {
      if (ticket && (!ticket.preliminaryresolutiontime || ticket.preliminaryresolutiontime === null)) { // Проверяем, было ли уже вычислено время
        const resolutionTime = await increaseDate(ticket);
        setPreliminaryResolutionTime(resolutionTime);
      }
    };

    fetchTimes();
  }, [ticket.date_start_work]);

  async function savePreliminaryResolutionTime(preliminaryTime) {
    try {
      const reqData = {
        type: 'updateResolutionTime',
        ticketId: ticket.id, // ID заявки
        preliminaryResolutionTime: preliminaryTime,
      };
      console.log(reqData);

      const result = await clientSendData('POST', '/update_resolution_time', reqData);
      if (!result.success) {
        console.error('Ошибка при обновлении времени решения');
      }
    } catch (error) {
      catchHandler(error, 'savePreliminaryResolutionTime');
    }
  }

  // время, когда заявку взяли в работу
  ticket.date_start = ticket?.date_start_work;

  const checkIsDayOff = (dateStr) => {
    // Разбор входной строки даты на год, месяц и день
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1; // месяцы нумеруются с 0
    const day = parseInt(dateStr.substring(6, 8), 10);

    // Создание объекта Date с использованием разобранных значений
    const date = new Date(year, month, day);

    // Проверка, является ли объект даты действительным
    if (Number.isNaN(date.getTime())) {
      throw new Error('Invalid date object');
    }

    // Проверка наличия данных календаря
    if (!calendarData) {
      console.warn('Calendar data not loaded. Please wait until the data is fetched.');
      return false; // Если данные не загружены, считаем рабочим днем
    }

    // Получение индекса месяца и значения дня из объекта даты
    const monthIndex = date.getMonth() + 1; // месяцы нумеруются с 1
    const dayValue = date.getDate();

    // Поиск данных месяца в календаре
    const monthData = calendarData.months.find((m) => m.month === monthIndex);
    if (!monthData) {
      console.warn(`Month data not found for month: ${monthIndex}`);
      return false; // Если месяц недействителен, считаем, что день не выходной
    }

    // Разделение строки дней на массив и проверка, является ли день выходным
    const days = monthData.days.split(',');

    // Проверка, является ли день выходным, учитывая только дни без * и +
    const isDayOff = days.includes(`${dayValue}`) || days.includes(`${dayValue}+`);

    return isDayOff; // Возвращаем true, если выходной, иначе false
  };

  const CheckisPartialDay = (dateStr) => {
    // Разбор входной строки даты на год, месяц и день
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1; // месяцы нумеруются с 0
    const day = parseInt(dateStr.substring(6, 8), 10);

    // Создание объекта Date с использованием разобранных значений
    const date = new Date(year, month, day);

    // Проверка, является ли объект даты действительным
    if (Number.isNaN(date.getTime())) {
      throw new Error('Invalid date object');
    }

    // Проверка наличия данных календаря
    if (!calendarData) {
      console.warn('Calendar data not loaded. Please wait until the data is fetched.');
      return false; // Если данные не загружены, считаем, что день не сокращенный
    }

    // Получение индекса месяца и значения дня из объекта даты
    const monthIndex = date.getMonth() + 1; // месяцы нумеруются с 1
    const dayValue = date.getDate();

    // Поиск данных месяца в календаре
    const monthData = calendarData.months.find((m) => m.month === monthIndex);
    if (!monthData) {
      return false; // Если месяц недействителен, считаем, что день не сокращенный
    }

    // Разделение строки дней на массив и проверка на сокращенный день
    const days = monthData.days.split(',');
    const isPartialDay = days.includes(`${dayValue}*`);

    return isPartialDay; // Возвращаем true, если сокращенный, иначе false
  };

  useEffect(() => {
    getDataCalendar();
  }, []);

  async function getDataCalendar() {
    try {
      const reqData = { type: 'getDataCalendar' };
      const result = await clientSendData('POST', '/get_data_sd_calendar', reqData);
      console.log('Результат запроса:', result);

      if (result?.success && result.data && result.data.length > 0) {
        try {
          const parsedValue = JSON.parse(result.data[0].value);
          setCalendarData(parsedValue);
        } catch (parseError) {
          console.error('Ошибка при парсинге данных:', parseError);
        }
      } else {
        console.error('Данные отсутствуют или неуспешный ответ:', result);
      }
    } catch (error) {
      catchHandler(error, 'getDataCalendar');
    }
  }

  async function calculateTimeDifference(ticket) {
    // Проверка наличия date_start
    if (!ticket?.date_start) {
      return '00:00'; // Если date_start нет, возвращаем "00:00"
    }

    const WORK_START_HOUR = 9; // Начало рабочего дня
    const WORK_END_HOUR = 19; // Конец рабочего дня
    const SHORT_WORK_END_HOUR = 18; // Конец сокращенного рабочего дня

    const currentDate = new Date(ticket.date_start);
    const dateSolved = ticket.date_solved ? new Date(ticket.date_solved) : new Date();
    let totalMinutes = 0;

    // Корректировка рабочего времени в первый день
    if (currentDate.getHours() < WORK_START_HOUR) {
      currentDate.setHours(WORK_START_HOUR, 0, 0, 0); // Устанавливаем на начало рабочего дня
    } else if (currentDate.getHours() >= WORK_END_HOUR) {
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(WORK_START_HOUR, 0, 0, 0);
    }

    // Основной цикл
    while (currentDate <= dateSolved) {
      const dateString = currentDate.toISOString().split('T')[0].replace(/-/g, '');
      // eslint-disable-next-line no-await-in-loop
      const isDayOff = await checkIsDayOff(dateString);
      // eslint-disable-next-line no-await-in-loop
      const isPartialDay = await CheckisPartialDay(dateString);

      let dayMinutes = 0;

      if (!isDayOff) {
        const endOfDayHour = isPartialDay ? SHORT_WORK_END_HOUR : WORK_END_HOUR;

        // Динамическое вычисление рабочего времени для первого и последнего дня
        if (currentDate.getTime() === new Date(ticket.date_start).getTime()) {
          // Первый день: считаем от времени создания заявки до конца рабочего дня
          const endOfDay = new Date(currentDate);
          endOfDay.setHours(endOfDayHour, 0, 0, 0);
          dayMinutes = (dateSolved < endOfDay ? dateSolved : endOfDay) - currentDate;
          dayMinutes /= (1000 * 60);
        } else if (
          currentDate.getFullYear() === dateSolved.getFullYear()
                && currentDate.getMonth() === dateSolved.getMonth()
                && currentDate.getDate() === dateSolved.getDate()
        ) {
          // Последний день: считаем от начала рабочего дня до времени решения заявки
          const startOfDay = new Date(currentDate);
          startOfDay.setHours(WORK_START_HOUR, 0, 0, 0);
          const endOfDay = new Date(currentDate);
          endOfDay.setHours(endOfDayHour, 0, 0, 0);

          dayMinutes = (dateSolved < endOfDay ? dateSolved : endOfDay) - startOfDay;
          dayMinutes /= (1000 * 60); // Разница в минутах
        } else {
          // Полный рабочий день
          dayMinutes = (endOfDayHour - WORK_START_HOUR) * 60;
        }

        // Проверяем наличие данных в ticket.date_expectation
        if (ticket.date_expectation && Array.isArray(ticket.date_expectation) && ticket.date_expectation.length > 0) {
          // Учитываем время, когда работа не выполнялась
          for (let i = 0; i < ticket.date_expectation.length; i++) {
            const periodStart = new Date(ticket.date_expectation[i].start);
            const periodEnd = new Date(ticket.date_expectation[i].end);

            if (periodStart.getDate() === currentDate.getDate()
                        && periodStart.getMonth() === currentDate.getMonth()
                        && periodStart.getFullYear() === currentDate.getFullYear()) {
              const overlapStart = Math.max(WORK_START_HOUR * 60, periodStart.getHours() * 60 + periodStart.getMinutes());
              const overlapEnd = Math.min(endOfDayHour * 60, periodEnd.getHours() * 60 + periodEnd.getMinutes());

              if (overlapEnd > overlapStart) {
                dayMinutes -= (overlapEnd - overlapStart); // Уменьшаем на пересекающиеся минуты
              }
            }
          }
        }

        totalMinutes += dayMinutes; // Добавляем рассчитанные минуты за день
      }

      // Переходим к следующему дню
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Рассчитываем окончательный результат в часах и минутах
    const finalHours = Math.floor(totalMinutes / 60); // Полные часы
    const finalMinutes = Math.floor(totalMinutes % 60); // Остаток в минутах, округленный до целого числа

    // Форматируем вывод с двумя знаками для минут
    const formattedTime = `${finalHours.toString().padStart(2, '0')}:${finalMinutes.toString().padStart(2, '0')}`;

    console.log(`Final Total Time: ${formattedTime}`);
    return formattedTime;
  }

  // функция для увеличения даты на определенное количество минут
  async function increaseDate(ticket) {
    // Проверяем, определены ли ticket и date_start
    if (!ticket || !ticket.date_start) {
      console.error('ticket или ticket.date_start не определены');
      return null; // или обработка по необходимости
    }

    // Проверяем, было ли уже вычислено время для данного тикета
    if (ticket.preliminaryResolutionTime && (ticket.preliminaryResolutionTime !== 0)) {
      console.log('Время уже было вычислено для этого тикета.');
      return ticket.preliminaryResolutionTime; // Возвращаем ранее вычисленное время
    }

    // Безопасно извлекаем компоненты даты
    const year = parseInt(ticket.date_start.substring(0, 4), 10);
    const month = parseInt(ticket.date_start.substring(5, 7), 10) - 1; // Месяцы нумеруются с 0
    const day = parseInt(ticket.date_start.substring(8, 10), 10);
    const hours = parseInt(ticket.date_start.substring(11, 13), 10);
    const minutes = parseInt(ticket.date_start.substring(14, 16), 10);

    // Создаем объект Date
    const startDate = new Date(Date.UTC(year, month, day, hours, minutes));

    // Проверяем, валидный ли созданный объект Date
    if (Number.isNaN(startDate.getTime())) {
      console.error('Недопустимое значение даты из ticket.date_start:', ticket.date_start);
      return null; // или обработка по необходимости
    }

    let minutesToAdd = getPriorityMinutes(ticket.priority);

    // Проверяем, является ли начальная дата рабочим часом
    if (!await isWorkingHour(startDate)) {
      await moveToNextWorkingDay(startDate);
    }

    // Цикл для добавления минут
    while (minutesToAdd > 0) {
      const dateString = startDate.toISOString().split('T')[0].replace(/-/g, '');
      // eslint-disable-next-line no-await-in-loop
      const isDayOff = await checkIsDayOff(dateString);

      if (!isDayOff) {
        const currentMinutes = startDate.getUTCHours() * 60 + startDate.getUTCMinutes();
        // eslint-disable-next-line no-await-in-loop
        const isPartialDay = await CheckisPartialDay(dateString);
        const endOfDayHour = isPartialDay ? 18 : 19; // Устанавливаем время окончания рабочего дня
        const remainingMinutesInDay = (endOfDayHour * 60) - currentMinutes;

        const minutesToAddToday = Math.min(minutesToAdd, remainingMinutesInDay);
        startDate.setUTCMinutes(startDate.getUTCMinutes() + minutesToAddToday);
        minutesToAdd -= minutesToAddToday;

        // Если добавлено полных суток, переходим на следующий день
        if (minutesToAddToday >= 24 * 60) {
          startDate.setUTCDate(startDate.getUTCDate() + 1);
          startDate.setUTCHours(9, 0, 0, 0); // Сбрасываем на начало следующего рабочего дня
        }
      }

      if (minutesToAdd > 0) {
        // eslint-disable-next-line no-await-in-loop
        await moveToNextWorkingDay(startDate);
      }
    }

    // Форматируем конечную дату
    const formattedDate = `${String(startDate.getUTCDate()).padStart(2, '0')}-${String(startDate.getUTCMonth() + 1).padStart(2, '0')}-${String(startDate.getUTCFullYear()).slice(-2)} ${String(startDate.getUTCHours()).padStart(2, '0')}:${String(startDate.getUTCMinutes()).padStart(2, '0')}`;

    // Обновляем состояние ticket с новым значением preliminaryResolutionTime
    setTicket((prevTicket) => ({
      ...prevTicket,
      preliminaryResolutionTime: formattedDate, // Сохраняем вычисленное время
    }));

    // Сохраняем вычисленное время на бэкэнд
    await savePreliminaryResolutionTime(formattedDate);

    return formattedDate; // Возвращаем отформатированную дату
  }

  async function moveToNextWorkingDay(date) {
    let isNextDayOff;
    do {
      date.setUTCDate(date.getUTCDate() + 1);
      date.setUTCHours(9, 0, 0, 0);
      const nextDayString = date.toISOString().split('T')[0].replace(/-/g, '');
      // eslint-disable-next-line no-await-in-loop
      isNextDayOff = await checkIsDayOff(nextDayString);
    } while (isNextDayOff);

    return date;
  }

  async function isWorkingHour(date) {
    const hours = date.getUTCHours();
    const dateString = date.toISOString().split('T')[0].replace(/-/g, '');

    // Получаем информацию о выходном и неполном дне
    const isPartialDay = await CheckisPartialDay(dateString);

    // Устанавливаем время окончания рабочего дня в зависимости от неполного дня
    const endOfDayHour = isPartialDay ? 18 : 19;

    // Проверяем, находится ли время в пределах рабочего дня
    return hours >= 9 && hours < endOfDayHour;
  }

  function getPriorityMinutes(priority) {
    switch (priority) {
      case 'Низкий':
        return 2400;
      case 'Средний':
        return 960;
      case 'Высокий':
        return 480;
      case 'Наивысший':
        return 120;
      default:
        return 0;
    }
  }

  if (isLoading) return <Loader />;
  return (

    <div className="ticket__wrapper">
       <div className="ticket__date">
      <CardSetting title="Cоздана:">{ticket?.date_created}</CardSetting>
      <CardSetting title="Изменена:">{ticket?.date_modified}</CardSetting>
      <CardSetting title="Выполнена:">{ticket?.date_solved}</CardSetting>
      <CardSetting title="Закрыта:">{ticket?.date_closed}</CardSetting>
       </div>

      <div className="ticket__info">

        <CardSetting title="№:">
{`#${ticket?.ticket_number}`}

        </CardSetting>
        <CardSetting title="Статус:">{ticket?.status}</CardSetting>
        <CardSetting title="Тема запроса:">{ticket?.title}</CardSetting>

        {(currentStatus === ticketStatus.new && (isSuperUser || isCoordinator))
          ? (<CardSetting title="Инициатор:" invalid={!ticket.initiator_id}>
        <InputSearch
          id="helpdesk__new-ticket-search-initiator"
          array={additionalInfo.initiator}
          onSearch={(e) => searchWrapper(e, 'initiator')}
          onChoose={(initiator) => setTicket((state) => ({ ...state, initiator_id: initiator.id, initiator: initiator.title }))}
          placeholder="ФИ инициатора запроса"
          defaultValue={ticket.initiator}
          loading={isSearching}
        />
             </CardSetting>)

          : (<CardSetting title="Инициатор:">
          <Link className="clickable" to={`?user=${ticket.initiator_id}`}>
            {ticket?.initiator}
          </Link>
             </CardSetting>)}

        {ticket.associated_initiators && (
          <CardSetting
            title="Связанные инициаторы:"
            active={ticket.associated_initiators.length > 1}
          >
            {ticket.associated_initiators.map((initiator) => (
              <Link className="clickable" to={`?user=${initiator.id}`}>
                {initiator.name}
              </Link>
            ))}
          </CardSetting>
        )}
        {ticket?.observers && (
          <CardSetting title="Наблюдатели:" active>
            {ticket?.observers?.map((user) => (
              <Link className="clickable" to={`?user=${user.id}`}>
                {user.name}
              </Link>
            ))}
          </CardSetting>
        )}
        {ticket.associated_tickets_id && (
          <CardSetting
            title="Связанные запросы:"
            active={ticket.associated_tickets_id.length > 1}
          >
            {ticket.associated_tickets_id.map((associated_ticket_id) => (
              <Link
                className="clickable"
                to={`?ticket=${convertTicketId(associated_ticket_id)}`}
              >
                {`#${convertTicketId(associated_ticket_id)}`}
              </Link>
            ))}
          </CardSetting>
        )}
        {ticket.main_ticket_id && (
          <CardSetting title="Главный запрос:">
            <Link
              className="clickable"
              to={`?ticket=${convertTicketId(ticket.main_ticket_id)}`}
            >
              {`#${convertTicketId(ticket.main_ticket_id)}`}
            </Link>
          </CardSetting>
        )}
      </div>
        <div className="ticket__content">
          <div className="ticket__content_wrapper">{ticket?.content}</div>
        </div>

      <div className="ticket__inputs">
        {/* <Select
          id="helpdesk__ticket-status"
          array={additionalInfo.statuses}
          onChoose={(status) => setTicket((state) => ({ ...state, status_id: status.id, status: status.title }))}
          defaultValue={ticket?.status || 'Статус'}
          disabled={isSearching}
        /> */}
        <CardSetting title="Тип обращения:" invalid={!validateField('treatment_id')}>
          <Select
            id="helpdesk__ticket-choose-treatment"
            array={additionalInfo.treatments}
            onChoose={(treatment) => setTicket((state) => ({
              ...state,
              treatment_id: treatment.id,
              treatment: treatment.title,
            }))
            }
            defaultValue={ticket?.treatment}
            loading={isSearching}
            disabled={!activeTicketStatuses.includes(ticket.status_id)}
          />
        </CardSetting>
        <CardSetting title="Приоритет:">
          <Select
            id="helpdesk__ticket-choose-priority"
            array={additionalInfo.priorities}
            onChoose={(priority) => setTicket((state) => ({
              ...state,
              priority_id: priority.id,
              priority: priority.title,
            }))
            }
            defaultValue={ticket?.priority}
            loading={isSearching}
            disabled={!activeTicketStatuses.includes(ticket.status_id)}
          />
        </CardSetting>

        <CardSetting title="Сервис:" invalid={!validateField('category_id')}>
          <Select
            id="helpdesk__ticket-choose-category"
            array={additionalInfo.category}
            onChoose={chooseCategory}
            defaultValue={ticket?.category}
            loading={isSearching}
            disabled={!activeTicketStatuses.includes(ticket.status_id)}
            search
          />
        </CardSetting>

        <CardSetting
          title="Группы:"
          invalid={!validateField('group_id')}
        >
            <Select
              id="helpdesk__ticket-choose-group"
              array={additionalInfo.group}
              onChoose={chooseGroup}
              defaultValue={ticket?.group}
              loading={isSearching}
              disabled={!activeTicketStatuses.includes(ticket.status_id)}
              search
            />
        </CardSetting>

      <CardSetting title="Исполнитель">
        <Select
          id="helpdesk__new-ticket-choose-performer"
          array={dataInfo.performer}
          onChoose={choosePerformer}
          defaultValue={ticket?.performer}
          loading={isSearching}
        />
      </CardSetting>

       {/* {searchInputs.map((input) => {
          const { title, key, onChoose } = input;
          return (
            <CardSetting
              key={key}
              title={`${title}:`}
              invalid={!validateField(`${key}_id`)}
            >
              <InputSearch
                id={`helpdesk__ticket-search-${key}`}
                array={additionalInfo[key]}
                onSearch={(e) => searchWrapper(e, key)}
                onChoose={onChoose}
                placeholder="/ - показать все"
                defaultValue={ticket?.[key]}
                loading={isSearching}
                disabled={!activeTicketStatuses.includes(ticket.status_id)}
              />
            </CardSetting>
          );
        })} */}

        <CardSetting title="Актив" invalid={!validateField('asset_id')}>
          <div className="questions-card__setting-wrapper">
            <CheckBox
              checked={Boolean(ticket?.asset_connection)}
              onChange={toggleAsset}
              disabled={!activeTicketStatuses.includes(ticket.status_id)}
            />
            <InputSearch
              id="helpdesk__ticket-search-asset"
              array={additionalInfo.asset}
              onSearch={(e) => searchWrapper(e, 'asset')}
              onChoose={chooseAsset}
              placeholder="Введите наименование"
              defaultValue={ticket?.asset}
              loading={isSearching}
              disabled={!ticket?.asset_connection}
            />
          </div>
        </CardSetting>
        <CardSetting title="Потраченное время:">{timeDifference}</CardSetting>
        {ticket.priority && (
        <>
        <CardSetting title="Время решения:"><TimeIndicatorSLA time={timeDifference} additionalInfo={ticket?.priority} /></CardSetting>
        <CardSetting title="Предварительный срок решения:">{ticket.preliminaryresolutiontime}</CardSetting>
        </>
        )}
        {ticket.category_id === 177 && (isSuperUser || isCoordinator) && (<CardSetting title="Дата увольнения:" invalid={!validateField('dismissal_date')}>
          <Input
            type="date"
            defaultValue={ticket?.dismissal_date}
            onChange={(event) => chooseDate(event.target.value)}
          />
                                                                          </CardSetting>
        )}

        <Modal
          active={modalState.active}
          setActive={(active) => setModalState({ ...modalState, active })}
          callback={() => ticketButtonHandler(modalState.action)}
        >
          <TextArea
            placeholder={modalState.placeholder}
            defaultValue={modalState.message}
            onChange={(e) => setModalState((prev) => ({ ...prev, message: e.target.value }))
            }
          />
        </Modal>
      </div>

    <div className="ticket__footer">
{isTicketChanged && (
<Button className={`${smallButtonClass} buttonGreen`} onClick={saveTicket}>Сохранить</Button>)}

{currentStatus === ticketStatus.new && (
  <Button
    className={smallButtonClass}
    onClick={() => setModalState({
      active: true,
      action: 'refuse',
      placeholder: 'Сообщение для пользователя',
      message: '',
    })
    }
  >
    Отклонить
  </Button>
)}

{currentStatus === ticketStatus.solved && (isSuperUser || isCoordinator) && (
  <Button
    className={smallButtonClass}
    onClick={() => setModalState({
      active: true,
      action: 'unsolve',
      placeholder: 'Приватный комментарий',
      message: '',
    })
    }
  >
    Отменить решение
  </Button>
)}

{currentStatus === ticketStatus.work && (
  <Button
    className={`${smallButtonClass} buttonGreen`}
    // onClick={() => ticketButtonHandler('await')}
    onClick={() => setModalState({
      active: true,
      action: 'await',
      placeholder: 'Приватный комментарий',
      message: '',
    })
    }
  >
    В ожидание
  </Button>
)}

{currentStatus === ticketStatus.await && (
  <Button
    className={smallButtonClass}
    onClick={() => ticketButtonHandler('back_to_work')}
  >
    Вернуть в работу
  </Button>
)}

{currentStatus === ticketStatus.queue && (
  <Button
    className={`${smallButtonClass} buttonGreen`}
    style={{ backgroundColor: '#28B47D' }}
    onClick={() => ticketButtonHandler('take_ticket')}
  >
    Взять в работу
  </Button>
)}

{currentStatus !== ticketStatus.closed && isSuperUser && (
  <Button
    className={smallButtonClass}
    onClick={() => ticketButtonHandler('close')}
  >
    Закрыть запрос
  </Button>
)}
    </div>
    </div>

  // </div>

  );
}

export default Ticket;
