/* eslint-disable no-console */
import { useSelector } from 'react-redux';
import { catchHandler } from '../error_handling/error_handling';
import { createAction } from '../redux/store';
import { alertFunction } from './alertFunction';
import { deleteCookie, getCookie, setCookie } from './cookies';
import { decode_sting_by_string, encode_sting_by_string } from './crypt';
import { getUserAgent } from './others';
import { clientSendData, sendData } from './requests';

/**
 * @function Обработчик сессии
*/

// функция для получения count сессий пользователя
let sessions_count;
export async function getStartPageInfo() {
  const login = useSelector((state) => state.users.current_user);
  try {
    const reqData = {
      type: 'getStartPageInfo',
      userLogin: login,
    };
    const result = await clientSendData('POST', '/get_startpage_info', reqData);
    if (result) {
      sessions_count = result;
      console.log(sessions_count);
    }
  } catch (error) {
    catchHandler(error, 'getStartPageInfo');
  }
}

export async function sessionHandler() {
  createAction('SET_IS_AUTHENTICATING', true); // запустить процесс аутентификации
  const last_service_opened = localStorage.getItem('last_service');
  try {
  //  const response = await fetch(document.location); // GET запрос на текущую страницу
    const default_service = window.location.pathname === '/' ? last_service_opened || '/helpdesk' : `${window.location.pathname}${window.location.search}`;
    createAction('SET_DEFAULT_PAGE', default_service);
    const isAuthHeader = getCookie('session'); // заголовок с именем X-Authenticated-User
    // если заголовок присутствует, то начинается аутентификация пользователя
    // если нет - показывается заглушка для неизвестных пользователей
    // если приложение запущено на локальном хосте, то заголовок не проверяется
    const reqData = {
      type: 'getIp',
    };

    const result = await sendData('POST', '/get_ip', reqData); // Получение ip-адреса
    if (result === 'bad_request') alertFunction('bad_request');
    else {
      // Запись в куки ip-адреса, время жизни куки - 10мин
      setCookie('ip_session', result, { 'max-age': 600 });
      if (isAuthHeader) {
        await checkSession(result); // проверка сессии
      } else {
        finishAuthenticate(); // Остановить аутентификацию
        createAction('SET_AUTH', false);
      }
    }
  } catch (error) {
    catchHandler(error, 'sessionHandler');
  }
}

/**
 * @function Проверка сессии
 * @prop {sessionIp} string - Ip адрес сессии
*/
export async function checkSession(sessionIp, username) {
  try {
    const reqData = {
      type: 'checkSession',
      session_ip: sessionIp,
    };
    const sessionId = getCookie('session');
    const result = await sendData('POST', '/check_session', reqData); // Проверка наличия активных и блокированных сессий по данному ip в БД
    if (result === 'bad_request') {
      alertFunction('bad_request');
      finishAuthenticate(); // Остановить аутентификацию
    } else {
      // Если таких сессий нет и в куках есть параметр session
      if (+result.count === 0 && sessionId) {
        // Получение secret и передача в authenticateCheck
        const reqData2 = {
          type: 'getSessionData',
          session_id: sessionId,
        };
          // Получаем данные сессии
        const result2 = await sendData('POST', '/get_session_data', reqData2);
        if (result2 === 'no matches') { // если сессия с таким id не найдена
          deleteCookie(); // чистятся куки
          window.location.reload(); // перезагружается страница
        }
        if (result2.clear_cookie) { // параметр очистки куки - true
          deleteCookie(); // чистятся куки
          window.location.reload(); // перезагружается страница
        } else await authenticateCheck(result2.secret); // Иначе вызывается функция проверки аутентификации
      }
      // Если таких сессий нет и в куках нет параметра session - создаётся новая сессия
      if (+result.count === 0 && !sessionId) await addNewSession(sessionIp, username);
      if (+result.count !== 0) { // Если такая сессия есть
        finishAuthenticate(); // Остановить аутентификацию
        alertFunction('session_is_blocked'); // Выводится сообщение о том, что сессия заблокирована
        createAction('SET_BLOCK_ROUTING', true); // маршрутизация изменяется на блокированную
      }
    }
  } catch (error) {
    catchHandler(error, 'checkSession');
  }
}

/**
 * @function Создание новой сессии
 * @prop {ip} string - Ip адрес сессии
*/
async function addNewSession(ip, username) {
  try {
    const reqData = {
      type: 'addNewSession',
      session_ip: ip,
      user_agent: getUserAgent(),
      username,
    };

    const result = await sendData('POST', '/add_new_session', reqData);
    if (result === 'bad_request') alertFunction('bad_request');
    else {
      const { id_session, secret } = result;
      setCookie('session', id_session, {}); // Запись в куки id сессии
      setCookie('auth', 0, {}); // Запись в куки auth = 0
      await userAuthenticate(secret); // В качестве аргумента функции userAuthenticate передается secret, для шифрования логина пользователя
    }
  } catch (error) {
    catchHandler(error, 'addNewSession');
  }
}

/**
 * @function Получение имени текущего пользователя
*/
export async function getCurrentUserName(secret) {
  try {
    const reqData = {
      type: 'getLoginBySecret',
      secret,
    };
    const user = await sendData('POST', '/get_login_by_secret', reqData);

    createAction('SET_CURRENT_USER', user.login);
    return user.login;
  } catch (error) {
    catchHandler(error, 'getCurrentUserName');
    return null;
  }
}

/**
 * @function Аутентификация пользователя
 * @prop {secret} string - ключ для шифрования логина
*/
async function userAuthenticate(secret) {
  try {
    const user = await getCurrentUserName(secret); // user - значение заголовка с именем X-Authenticated-User
    const encodedLogin = encode_sting_by_string(user, secret); // Шифрование user
    setCookie('login', encodedLogin, {}); // запись в куки зашифрованного логина

    const reqData = {
      type: 'userAuthenticate',
      login: encodedLogin,
      id_session: getCookie('session'),
      page: window.location.pathname,
    };
    const result = await sendData('POST', '/user_authenticate', reqData);
    if (result === 'bad_request') {
      finishAuthenticate(); // Остановить аутентификацию
      alertFunction('bad_request');
    } else if (result.auth === 1) { // Если результат аутентификации = 1
      setCookie('auth', result.auth, {}); // Запись в куки auth=1
      authenticateCheck(secret); // Вызывается функция проверки аутентификации
    } else {
      finishAuthenticate(); // Остановить аутентификацию
      alertFunction('ntlm_discrepancy'); // Иначе выводится сообщение "Не удаётся войти в систему!"
      createAction('SET_AUTH', false);
    }
  } catch (error) {
    catchHandler(error, 'userAuthenticate');
  }
}

/**
 * @function Функция проверки аутентификации
 * @prop {secret} string - ключ для шифрования логина
*/
export async function authenticateCheck(secret) {
  try {
    const sessionId = getCookie('session'); // sessionId - id сессии из куки

    if (Number(getCookie('auth')) === 0) { // Если в куки auth = 0
    //   addIntoLog('user is not authenticated', 'warning', 'authenticate'); // в лог записывается сообщение "user is not authenticated"
      await userAuthenticate(secret);
    }

    if (Number(getCookie('auth')) === 1) { // Если в куки auth = 1
      const cookieLogin = decode_sting_by_string(getCookie('login'), secret); // расшифрованный логин из куки
      const headerLogin = await getCurrentUserName(secret); // kerberos логин из заголовка
      // если логины совпадают - выполняется валидация сессии
      // если нет - показывается заглушка для неизвестных пользователей
      if (cookieLogin === headerLogin) {
        const reqData = { // объект для передачи в запросе
          type: 'sessionValidate',
          session_id: sessionId,
        };

        const result = await sendData('POST', '/session_validate', reqData); // Выполняется валидация сессии
        if (result === 'validated') { // Если сессия валидна
          finishAuthenticate(); // Остановить аутентификацию
          await getUsersServices(); // вызывается функция получения доступных пользователю сервисов
        } else if (result === 'expired' || result === "session doesn't exist") { // Если сессия не заблокирована, но её срок истёк
          deleteCookie(); // чистятся куки
          window.location.reload(); // перезагружается страница
        }
        //   else { // в ином случае
        //   deleteCookie(); // чистятся куки
        //   window.location.reload(); // перезагружается страница
        // }
      } else {
        finishAuthenticate(); // Остановить аутентификацию
        createAction('SET_AUTH', false);
      }
    }
  } catch (error) {
    catchHandler(error, 'authenticateCheck');
  }
}

/**
 * @function Завершение процесса аутентификации
*/
export function finishAuthenticate() {
  createAction('SET_IS_AUTHENTICATING', false); // остановить процесс аутентификации
}

/**
 * @function Функция авторизации
 * @prop {page} string - адрес страницы к которой проверяется доступ
*/
export async function authorization(page) {
  try {
    const reqData = {
      type: 'authorization',
      page: page || window.location.pathname,
    };
    // Получение информации о возможности доступа к запрашиваемой странице
    const result = await clientSendData('POST', '/authorization', reqData);
    // Если результат в формате [{access: 1}] функция вернет true в иных случаях false
    if (!result || result.length === 0 || result[0].access === 0) return false;
    if (result && result[0].access === 1) return true;
    return false;
  } catch (error) {
    catchHandler(error, 'authorization');
    return false;
  }
}

/**
 * @function Функция получения сервисов, доступных пользователю
*/
async function getUsersServices() {
  try {
    const reqData = { // Данные о пользователе из куки
      type: 'getUserServices',
      login: getCookie('login'),
      session_id: getCookie('session'),
      auth: getCookie('auth'),
    };
    const result = await clientSendData('POST', '/get_user_services', reqData);
    if (result) createAction('SET_USER_SERVICES', result);
  } catch (error) {
    catchHandler(error, 'getUsersServices');
  }
}
