import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { setOperInfo } from '../../utils/functions/others';
import CardPage from '../../components/CardPage/CardPage';
import CardPageHeader from '../../components/CardPage/CardPageHeader';
import CardPageBody from '../../components/CardPage/CardPageBody';
import RecrutmentCard from './RecrutmentCard';
import RecrutmentHistory from './RecrutmentHistory';
import TabPanel from '../../components/UI/TabPanel';
import Cap from '../Cap/Cap';

/**
  * @component Сервис подбора персонала - заявка - карточка заявки
  * @prop {setShowCard} function - обновление состояния отображения карточки (открыть/закрыть)
  * @prop {refreshTable} function - обновление данных таблицы
*/
function RecrutmentMainCard(props) {
  const {
    setShowCard, refreshTable, statusID,
  } = props;

  const tabs = ['Запрос', 'История']; // вкладки
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска из url
  const [isLoadingData, setIsLoadingData] = useState(false); // состояние выбора вкладки
  const [choice, setChoice] = useState(); // состояние выбора вкладки
  const [ticketID, setTicketID] = useState(searchParams.get('rTicket')); // извлечение rTicket из параметров
  const content = {
    Запрос: <RecrutmentCard ticketID={ticketID} refreshTable={refreshTable} statusID={statusID} closeHandler={closeHandler} setIsLoadingData={setIsLoadingData} />,
    История: isLoadingData ? <RecrutmentHistory subject_id={ticketID} type="1" /> : <Cap />,
  };
  console.log(ticketID);
  // закрыть карточку
  function closeHandler() {
    setShowCard(false); // закрытие карточки
    setSearchParams('');
  }
  // обработка выбора вкладки
  function choiceHandler(tab) {
    setChoice(tab); // установить выбор
  }

  return (
    <CardPage setShow={closeHandler} id="recrutment_card">
    <CardPageHeader>
   <TabPanel tabs={tabs} choice={choice} choiceHandler={choiceHandler} />
    </CardPageHeader>
      <CardPageBody>
        {content[choice]}
      </CardPageBody>
    </CardPage>
  );
}

export default RecrutmentMainCard;
