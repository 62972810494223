/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Service from '../../../../components/Service/Service';
import ServiceBody from '../../../../components/Service/ServiceBody';
import ServicePanel from '../../../../components/Service/ServicePanel';
import Button from '../../../../components/UI/Button/Button';
import Loader from '../../../../components/UI/Loader';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../../utils/functions/requests';
import EvaluationCap from '../EvaluationCap';
import Instruction from './Instruction';
import TableEvaluation from './TableEvaluation/TableEvaluation';
import { deepObjectsComparing } from '../../../../utils/functions/others';

// Сервис оценок - страница заявления - заявление на оценку
function Application(props) {
  const {
    id, projectId, isAdmin, finalApp, location,
  } = props;

  const period = useSearchParams()[0].get('period');
  const operInfo = useSelector((state) => state.oper_info); // оперативная информация
  const [searchParams, setSearchParams] = useSearchParams(); // параметры url
  const [applicationData, setApplicationData] = useState(null); // данные для таблицы заявления
  const [isLoading, setIsLoading] = useState(false); // параметры поиска url
  const application = applicationData?.general?.application; // объект заявления
  const defaultAppraisal = application?.appraisal; // исходное состояние заявки
  const [appraisal, setAppraisal] = useState(defaultAppraisal); // состояние заявки
  const [extTasks, setExtTasks] = useState(application?.ext_tasks); // дополнительные задачи
  const [projectMasters, setProjectMasters] = useState([]); // список возможных руководителей проекта
  const [isTableVisible, setIsTableVisible] = useState(false);// отображение таблицы с вопросами для заполнения
  const [alertType, setAlertType] = useState(null);

  const [functionNumberState, setfunctionNumberState] = useState(null);
  // null for leaving while loop
  const [isYes, setIsYes] = useState(false);
  // setWithComment - если  true, то надо показывать окно "Добавить комментарий"
  const [isNewMaster, setIsNewMaster] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Пожалуйста заполните все необходимые комменатраии');
  const [confirmMessage, setConfirmMessage] = useState('');

  const admin = isAdmin === 'true';
  const user_id = operInfo?.user_id; // id текущего пользователя
  const isMainApprover = application?.main_approver_id === user_id; // является ли текущий пользователь утверждающим
  const isApprover = application?.approver_id === user_id; // является ли текущий пользователь согласующим
  const isUser = application?.user_id === user_id; // является ли текущий пользователь
  // const isMonitoringUser = application?.monitoring; // является ли текущий пользователь
  const isChanged = !deepObjectsComparing(defaultAppraisal, appraisal); // показатель изменения исходного объекта
  const isFinalApp = finalApp === 'true';
  const status = application?.status_id; // статус ПО
  const familirizationStatuses = [6, 7]; // статусы для кнопки "Ознакомлен"
  const isMonitoringUser = applicationData?.monitoringUsers?.includes(user_id);
  useEffect(() => {
    getApplicationData();
    getProjectMasters();
  }, []);

  useEffect(() => {
    mainHandler();
  }, [isYes]);
  let statusId = '';
  // Определение возможнсти редактирования данных
  const defineIsEditable = () => {
    // Cотрудник может редактировать на статусах 1,8,9 (на этих статусах draft = true)
    if (isUser && [1, 8, 9].includes(status)) return true;
    // Согласующий может редактировать на статусах 2,3
    if (isApprover && [2, 3].includes(status)) return true;
    // Утверждающий может редактировать на статусах 4,5
    if (isMainApprover && [4, 5].includes(status)) return true;
    // Сотрудник может редактировать, если он администратор
    if (admin) return true;

    // Иначе нельзя
    return false;
  };

  // Получение руководителей проекта для выбора
  async function getProjectMasters() {
    try {
      const reqData = {
        type: 'getProjectMasters',
      };
      const result = await clientSendData('POST', '/get_project_masters', reqData); // запрос в БД
      if (result) {
        setProjectMasters(result);
      }
    } catch (error) {
      catchHandler(error, 'getProjectMasters'); // обработка ошибок
    }
  }

  // Проверка доступа к заявлению
  async function getApplicationData() {
    try {
      const reqData = {
        type: 'getApplicationData',
        application_id: id,
        projectId,
        admin,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_application_data', reqData);
      if (result) {
        // оценка
        const appraisalData = result?.general?.application?.appraisal;
        const extTasksData = result?.general?.application?.ext_tasks;
        setApplicationData(result);
        // Если есть данные оценки - записать отдельно в состояние
        if (appraisalData) setAppraisal(appraisalData);
        if (extTasksData) setExtTasks(extTasksData);
      }
      setIsLoading(false);
    } catch (error) {
      catchHandler(error, 'getApplicationData');
    }
  }

  async function getNewApplication(newId) {
    try {
      const reqData = {
        type: 'getApplicationData',
        application_id: newId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_application_data', reqData);
      if (result) {
        // оценка
        const appraisalData = result?.general?.application?.appraisal;
        const extTasksData = result?.general?.application?.ext_tasks;
        setApplicationData(result);
        // Если есть данные оценки - записать отдельно в состояние
        if (appraisalData) setAppraisal(appraisalData);
        if (extTasksData) setExtTasks(extTasksData);
      }
      setIsLoading(false);
      setIsTableVisible(true);
    } catch (error) {
      catchHandler(error, 'getApplicationData');
    }
  }

  // Сохранение заявления
  async function saveApplication() {
    try {
      const reqData = {
        type: 'saveApplication',
        application_id: id,
        application: {
          ...applicationData?.general?.application,
          appraisal,
          ext_tasks: extTasks,
        },
        defaultAppraisal,
        statusId,
        period,
        isNewMaster,
      };
      await clientSendData('POST', '/save_application', reqData);
      await getApplicationData();
    } catch (error) {
      catchHandler(error, 'saveApplication');
    }
  }

  // Проверка на наличие комментариев к оценкам, отличным от В
  function checkMarksAndComments(entries) {
    if (!isApprover) {
      for (const obj of entries) {
        if (obj.value_char !== 'B' && (!obj.hasOwnProperty('comment') || obj.comment === '')) { return false; }
      }
    }
    return true;
  }

  // Проверить, если комментарий от админа к измененной оценке
  function checkAdminMarksAndComments(entries1, entries2) {
    if (entries1 !== undefined && entries2 !== undefined) {
      for (let count = 0; count < entries1.length; count++) {
        if (entries1[count].value_int !== entries2[count].value_int || entries1[count].value_char !== entries2[count].value_char) {
          if (!entries2[count].hasOwnProperty('approver_comment') || entries2[count].approver_comment === '') { return false; }
          return true;
        }
      }
    }
    return true;
  }

  async function mainHandler() {
    switch (functionNumberState) {
      // Сохранить и утвердить
      case 1:
        secondApproveModifiedApp();
        break;
      // Утвердить
      case 2:
        secondApproveApp();
        break;
      // Вернуть на доработку
      case 3:
        returnForRevision();
        break;
      // Сохранить
      case 4:
        saveAsDraft();
        break;
      // Отменить изменения
      case 5:
        callChangesOff();
        break;
      // Отправить
      case 6:
        sendForApproval();
        break;
      case 7:
        familiarizeResult();
        break;
      default:
        break;
    }
    setIsYes(false);
    setfunctionNumberState(null);
    return false;
  }

  async function saveAsDraft() {
    try {
      if (isYes) {
        const applicationChange = applicationData?.general?.application;
        //   applicationChange.status_id = 10;
        setIsLoading(true);
        statusId = isNewMaster === true ? status : 10;
        await saveApplication();
        await getApplicationData();
        setIsLoading(false);
        setSearchParams('');
      }
    } catch (error) {
      catchHandler(error, 'saveAsDraft');
    }
  }

  // Отправить на согласование
  async function sendForApproval() {
    try {
      // Запросить подтверждение у пользователя
      const values = Object.values(appraisal);
      const entries = Object.values(values[0]);
      if (isYes) { // Если готов отправить
        setIsLoading(true);
        if (isChanged && checkMarksAndComments(entries)) {
          statusId = 2;
          await saveApplication(); // Сохранить изменения если они имеются
          const reqData = {
            type: 'sendForApproval',
            application_id: id,
            // status_id: status,
            status_id: statusId,
            url: operInfo?.url,
          };
          await clientSendData('POST', '/send_for_approval', reqData);
          setIsLoading(false);
          setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс сотрудника
        } else {
          handleAlert('Пожалуйста, заполните все необходимые комментарии', 'alert');
        } setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'sendForApproval');
    }
  }

  // Согласовать заявление (1 этап)
  async function firstApproveApp() {
    try {
      let message = 'Согласовать?';
      if (isChanged) message = 'Внесенные изменения не сохранятся, согласовать без корректировок?';

      // Запросить подтверждение у руководителя
      const confirm = window.confirm(message);
      if (confirm) { // Если готов отправить
        // const comment = window.prompt("Оставьте комментарий (необязательно)")
        const reqData = {
          type: 'firstApproveApplication',
          application_id: id,
          url: operInfo?.url,
          // comment: comment
        };

        setIsLoading(true);
        await clientSendData('POST', '/first_approve_application', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
      }
    } catch (error) {
      catchHandler(error, 'firstApproveApp');
    }
  }

  // Сохранить и согласовать заявление (1 этап)
  async function firstApproveModifiedApp() {
    try {
      // Запросить подтверждение у руководителя
      const confirm = window.confirm('Согласовать c учётом внесённых изменений?');
      if (confirm) { // Если готов отправить
        // const comment = window.prompt("Оставьте комментарий (необязательно)")
        const reqData = {
          type: 'firstApproveApplication',
          application_id: id,
          url: operInfo?.url,
          // comment: comment,
          modified: true,
        };

        setIsLoading(true);
        await saveApplication(); // Сохранить изменения
        await clientSendData('POST', '/first_approve_application', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
      }
    } catch (error) {
      catchHandler(error, 'firstApproveModifiedApp');
    }
  }

  // Утвердить заявление (2 этап)
  async function secondApproveApp() {
    try {
      if (isYes) { // Если готов отправить
        const reqData = {
          type: 'secondApproveApplication',
          application_id: id,
          url: operInfo?.url,
          // comment,
        };
        setIsLoading(true);
        statusId = 6;
        saveApplication();
        await clientSendData('POST', '/second_approve_application', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
      }
    } catch (error) {
      catchHandler(error, 'secondApproveApp');
    }
  }

  // Сохранить и утвердить заявление (2 этап)
  async function secondApproveModifiedApp() {
    const oldValues = Object.values(defaultAppraisal);
    const entries1 = Object.values(oldValues[0]);

    const newValues = Object.values(appraisal);
    const entries2 = Object.values(newValues[0]);

    try {
      if (isYes) { // Если готов отправить
        //  const comment = window.prompt('Оставьте комментарий (необязательно)');
        const reqData = {
          type: 'secondApproveApplication',
          application_id: id,
          url: operInfo?.url,
          //   commentText,
          modified: true,
        };
        setIsLoading(true);
        if (isChanged && checkAdminMarksAndComments(entries1, entries2)) {
          statusId = 7;
          await saveApplication(status); // Сохранить изменения
          await clientSendData('POST', '/second_approve_application', reqData);
          setIsLoading(false);
          setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс руководителя
        } else {
          handleAlert('Пожалуйста, заполните комментарии к измененным оценкам', 'alert');
        }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'secondApproveModifiedApp');
    }
  }

  // Вернуть на доработку
  async function familiarizeResult() {
    try {
      // Запросить подтверждение у сотрудника
      if (isYes) { // Если ознакомился
        const reqData = {
          type: 'familiarizeResult',
          application_id: id,
          alias: operInfo?.alias,
          period,
        };
        setIsLoading(true);
        await clientSendData('POST', '/familiarize_result', reqData);
        setIsLoading(false);
        setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс сотрудника
      }
    } catch (error) {
      catchHandler(error, 'familiarizeResult');
    }
  }

  // Вернуть на доработку
  async function returnForRevision() {
    const oldValues = Object.values(defaultAppraisal);
    const entries1 = Object.values(oldValues[0]);

    const newValues = Object.values(appraisal);
    const entries2 = Object.values(newValues[0]);
    try {
      if (isYes) { // Если подтвердил
        const reqData = {
          type: 'returnForRevision',
          application_id: id,
          url: operInfo?.url,
          modified: isChanged,
        };
        setIsLoading(true);
        if (isChanged && checkAdminMarksAndComments(entries1, entries2)) {
          statusId = 8;
          await saveApplication(); // Сохранить изменения
          await clientSendData('POST', '/return_for_revision', reqData);
          setIsLoading(false);
          setSearchParams(''); // сбросить параметры поиска для возвращения в интерфейс сотрудника
        } else {
          handleAlert('Пожалуйста, заполните комменатрии к измененным оценкам', 'alert');
        }
        setIsLoading(false);
      }
    } catch (error) {
      catchHandler(error, 'returnForRevision');
    }
  }

  // Отменить изменения
  function callChangesOff() {
    try {
      // Если подтвердил - сбросить объект оценки
      if (isYes) setAppraisal(defaultAppraisal);
    } catch (error) {
      catchHandler(error, 'callChangesOff');
    }
  }

  function buttonHandler(buttonType) {
    let message = '';
    switch (buttonType) {
      // Сохранить и утвердить
      case 1:
        handleAlert('Утвердить c учётом внесённых изменений?', 'confirm');
        setfunctionNumberState(1);
        break;
      // Утвердить
      case 2:
        message = 'Утвердить?';
        if (isChanged) message = 'Внесенные изменения не сохранятся, утвердить без корректировок?';
        handleAlert(message, 'confirm');
        setfunctionNumberState(2);
        break;
      // Вернуть на доработку
      case 3:
        message = 'Вернуть на доработку?';
        if (isChanged) message = 'Внесенные Вами изменения сохранятся. Вернуть на доработку?';
        handleAlert(message, 'confirm');
        setfunctionNumberState(3);
        break;
      // Сохранить
      case 4:
        handleAlert('Сохранить изменения?', 'confirm');
        setfunctionNumberState(4);
        break;
      // Отменить изменения
      case 5:
        handleAlert('Отменить внесенные изменения?', 'confirm');
        setfunctionNumberState(5);
        break;
        // Отправить
      case 6:
        message = 'Вы уверены, что готовы отправить?';
        if (isChanged) message = 'Перед отправкой будет выполнено сохранение изменений. Отправить на утверждение?';
        handleAlert(message, 'confirm');
        setfunctionNumberState(6);
        break;
        // Ознакомиться
      case 7:
        handleAlert('Ознакомиться?', 'confirm');
        setfunctionNumberState(7);
        break;
      default:
        break;
    }
    return false;
  }

  function handleAlert(text, type) {
    if (type === 'confirm') {
      setConfirmMessage((prev) => text);
      setAlertType((prev) => type);
    } if (type === 'alert') {
      setAlertMessage((prev) => text);
      setAlertType((prev) => type);
    }
    return false;
  }

  function confirmSaving(option) {
    if (option === true) {
      setIsYes((prev) => true);
      setAlertType((prev) => null);
    } else {
      setAlertType((prev) => null);
      setIsYes((prev) => false);
    }
  }

  // Если заузка - показать лоадер
  if (isLoading) return <Loader />;
  // Если нет данных об участи аудитора в указанном проекте

  if (!applicationData?.general) return <EvaluationCap title="Сведения об участии сотрудника не найдены" />;

  return (
      <Service id="evaluation__application" vertical>

        {/* Панель */}
        <ServicePanel>
          <div className="panel_buttons">
            {/* Инструкция */}
            {/* <Instruction /> */}

            {location === 'employee' && ( // Кнопки для сотрудника
            <>
                {/* {isChanged && <Button onClick={saveAsDraft}>Сохранить как черновик</Button>} */}
                {[1, 8, 9].includes(status) && <Button onClick={() => buttonHandler(6)}>Отправить</Button>}
                {familirizationStatuses.includes(status) && (
                <Button onClick={() => buttonHandler(7)}>Ознакомлен</Button>)}
            </>)}

            {/* {isApprover && defineIsEditable() && ( // Кнопки для первого согласования
            <>
                {isChanged && (
                <Button onClick={firstApproveModifiedApp}>Сохранить и согласовать</Button>)}
                <Button onClick={firstApproveApp}>Согласовать</Button>
                <Button onClick={returnForRevision}>Вернуть на доработку</Button>
            </>)} */}
           {/* <Button onClick={secondApproveModifiedApp}>Сохранить и утвердить</Button>)}
           <Button onClick={secondApproveApp}>Утвердить</Button>
             </>)}
           {isMonitoringUser && isChanged && <Button onClick={saveAsDraft}>Сохранить</Button>}
          {/* Общая кнопка }
          {isChanged && <Button onClick={callChangesOff}>Отменить изменения</Button>} */}

            {/* {(isMainApprover || isApprover || isAdmin) && defineIsEditable() && ( // Кнопки для второго согласования
            <>
                {isChanged && (
                <Button onClick={() => buttonHandler(1)}>Сохранить и утвердить</Button>)}
                <Button onClick={() => buttonHandler(2)}>Утвердить</Button>
                <Button onClick={() => buttonHandler(3)}>Вернуть на доработку</Button>
            </>)} */}

           {(location === 'admin' || location === 'approver') && defineIsEditable() && ( // Кнопки для второго согласования
            <>
                {isChanged && (
                <Button onClick={() => buttonHandler(1)}>Сохранить и утвердить</Button>)}
                <Button onClick={() => buttonHandler(2)}>Утвердить</Button>
                <Button onClick={() => buttonHandler(3)}>Вернуть на доработку</Button>
            </>)}

          {(isMonitoringUser || isNewMaster) && <Button onClick={() => buttonHandler(4)}>Сохранить</Button>}
          {/* Общая кнопка */}
          {isChanged && <Button onClick={() => buttonHandler(5)}>Отменить изменения</Button>}
          </div>
        </ServicePanel>

        {/* Блок основного контента */}
        <ServiceBody>

       {alertType === 'alert' && (
        <div className="upu-card__background">
            <div className="alert">
             {alertMessage}
              <div className="btn-container">
              <Button onClick={() => setAlertType(null)}>oк</Button>
              </div>
            </div>
        </div>
       )}

      {alertType === 'confirm' && (
              <div className="upu-card__background">
                  <div className="alert">
                  {confirmMessage}
              <div className="btn-container">

                    {/* <Button onClick={() => confirmSaving(true)}>ok</Button> */}
                    <Button onClick={() => confirmSaving(true)}>oк</Button>
                    <Button onClick={() => confirmSaving(false)}>отмена</Button>
              </div>
                  </div>
              </div>
      )}

          {applicationData && (
          <TableEvaluation
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            setAppraisal={setAppraisal}
            setIsNewMaster={setIsNewMaster}
            appraisal={appraisal}
            loading={isLoading}
            extTasks={extTasks}
            setExtTasks={setExtTasks}
            isEditable={defineIsEditable()}
            getApplicationData={getApplicationData}
            projectMasters={projectMasters}
            getNewApplication={getNewApplication}
            isTableVisible={isTableVisible}
            status={status}
            isAdmin={admin}
            location={location}
            isFinalApp={isFinalApp}
          />
          )}
        </ServiceBody>
      </Service>
  );
}

export default Application;
