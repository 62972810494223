export const headers = {
  access: [
    { title: 'ФИО', key: 'title' },
    { title: 'Выбрать', key: 'values' },
  ],
  education: [
    { title: 'Название', key: 'title' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
  company: [
    { title: 'Название', key: 'title' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
  grades: [
    { title: '№', key: 'id' },
    { title: 'Грейд', key: 'title' },
    { title: 'Описание', key: 'description' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
  notifications: [
    { title: '№', key: 'title' },
    { title: 'Тема', key: 'subject' },
    { title: 'Сообщение', key: 'message' },
  ],
  certificates: [
    { title: '№', key: 'id' },
    { title: 'Название', key: 'title' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
  levels: [
    { title: '№', key: 'title' },
    { title: 'Уровень', key: 'level' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
  mvz: [
    { title: '№', key: 'id' },
    { title: 'МВЗ', key: 'title' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
};
