import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import PaginationRow from '../../components/Table/PaginatedTable/PaginationRow';
import Card from '../../components/Card/Card';
import NewMessageCard from './NewMessageCard';
import ServiceBody from '../../components/Service/ServiceBody';
import PODFTpage from './PODFTpage';

export default function PODFTmailservice() {
  const headers = [ // заголовки
    { id: 1, title: 'Номер', field: 'id' },
    { id: 2, title: 'Клиент', field: 'client_title' },
    { id: 3, title: 'Номер договора', field: 'contract_num' },
    { id: 4, title: 'Статус', field: 'status_title' },
    { id: 5, title: 'Инициатор', field: 'initiator_title' },
    // { id: 6, title: 'Действие', field: 'action' },
  ];

  const testData = [{
    id: 1, client_title: 'клент1', contract_num: '1', status_title: 'new', initiator_title: 'Инициатор1',
  },
  {
    id: 2, client_title: 'клент2', contract_num: '2', status_title: 'new', initiator_title: 'Инициатор2',
  }];

  const messageId = useSearchParams()[0].get('PODFTmessage'); // id ПО из url

  const defaultTableState = {
    title: 'Сообщения',
    page: 1,
    limit: 20, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
      },
    },
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const [currentApplicationId, setCurrentApplicationId] = useState(null);
  const [showCreateCard, setShowCreateCard] = useState(false); // состояние отображения карточки создания
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url

  function openApplicationCard(id) {
    setCurrentApplicationId(id);
    setSearchParams({ PODFTmessage: id });
  }

  if (messageId) return <PODFTpage />;
  return (
    <ServiceBody>
    <PaginatedTable
      id="PODFT_table"
      headers={headers}
      setState={setTableState}
      state={tableState}
      button_place="row"
      total={1}
      optButton={{
        title: 'Создать сообщение',
        handler: () => setShowCreateCard(true),
      }}
    >
      {testData.length === 0 ? (
        <TRow>
          <TData>Результаты отсутствуют</TData>
          <TData />
          <TData />
          <TData />
        </TRow>) : (
        // Если результаты есть
        testData.map((row) => (
          <TRow key={row.id}>
            {headers.map((item) => {
              if (item.field !== 'action') return <TData key={item.field}>{row[item.field] ? row[item.field] : '-'}</TData>;
              return <TData key={item.field} onClick={() => openApplicationCard(row.id)}>Открыть карточку</TData>;
            })}
            <TData onClick={() => openApplicationCard(row.id)}>
              Открыть сообщение
            </TData>
          </TRow>
        )))}
    </PaginatedTable>

    { showCreateCard && (<NewMessageCard
      setShowCreateCard={setShowCreateCard}
    />)}
    </ServiceBody>
  );
}
