import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { authorization } from '../../utils/functions/authenticate';
import { alertFunction } from '../../utils/functions/alertFunction';
import { headers } from './common';
import Button from '../../components/UI/Button/Button';
import TFoot from '../../components/Table/TFoot';
import InputSearch from '../../components/UI/InputSearch';
import Cap from '../Cap/Cap';

function AccessStaffCV() {
  const [access, setAccess] = useState([]);
  const [newAccess, setNewAccess] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([]);

  useEffect(() => {
    awaitRequests();
    getAccessData();
  }, []);

  async function getAccessData() {
    try {
      const reqData = { type: 'getAdminDataStaffCV' };
      const result = await clientSendData('POST', '/get_admin_data_staff_cv', reqData);
      if (result) {
        setAccess(result.access);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataStaffCV');
    }
  }

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  const addNewAccess = async () => {
    // Создаём множество для отслеживания уникальных значений id
    const access1 = access;
    const elementExists = access1.find((item) => item.id === newAccess[0].id && item.title === newAccess[0].title);
    // Добавляем новый объект в массив, только если его id ещё не существует в множестве
    if (!elementExists) {
      setAccess((prevAccess) => [
        ...prevAccess,
        { id: newAccess[0].id, title: newAccess[0].title },
      ]);
      setNewAccess([]);
      // После добавления, вы можете сбросить состояние newAccess, если это необходимо
    } else {
      await alertFunction('sd_user_group_exist', 'client');
      setNewAccess([]);
    }
    // Присваиваем начальное значение
  };

  async function searchWrapper(e) {
    try {
      setIsSearching(true);
      const result = await searchUserData(e.target.value);
      setAdditionalInfo(result);
    } catch (error) {
      catchHandler(error, 'searchWrapper');
    } finally {
      setIsSearching(false);
    }
  }

  async function searchUserData(query) {
    try {
      const reqData = { type: 'cvGetUserData' };
      const result = await clientSendData('POST', `/cv_get_user_data/?query=${query}`, reqData);
      if (result?.success) {
        return result.data || [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'cvGetUserData');
      return [];
    }
  }

  const chooseData = (id) => {
    setSelectedUsers((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const addSelectedAccess = async () => {
    const ids = access.map((newSelected) => newSelected.id.toString());
    ids.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
    await editStaffCVAccess(ids);
    console.log(ids);
  };

  const deleteSelectedAccess = async () => {
    const newSelected = access.filter((l) => !selectedUsers.has(l.id));
    setAccess(newSelected);
    const ids = newSelected.map((newSelected) => newSelected.id.toString());
    ids.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
    console.log(ids);
    setSelectedUsers(new Set());
    await editStaffCVAccess(ids);
    console.log(ids);
  };

  const editStaffCVAccess = async (accessData) => {
    console.log(accessData);
    try {
      const reqData = {
        type: 'editStaffCVAccess',
        data: accessData,
      };
      const result = await clientSendData('POST', '/edit_staff_cv_access', reqData);
      if (result.success) {
        await alertFunction('save_settings', 'client');
        await getAccessData();
      } else {
        await alertFunction('required_fields', 'client');
      }
    } catch (error) {
      catchHandler(error, 'editStaffCVAccess');
    }
  };

  console.log(newAccess);
  console.log(access);

  if (isAuthorized) {
    return (
    // <div id="staff_cv" className="service">
          <ServiceBody>
            <Table id="admin_staff_cv">
              <THead title="Администраторы" headers={headers.access} className="table-header" />
              <TBody>
                  {/* Строка с InputSearch и кнопкой "Добавить" */}
                  <TRow>
                  <TData style={{ padding: '10px', textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <InputSearch
                        id="new_access"
                        array={additionalInfo} // Передаем весь массив объектов
                        onSearch={(e) => searchWrapper(e)}
                        onChoose={(user) => {
                          setNewAccess((state) => [...state, { id: user.id, title: user.title }]);
                        }}
                        placeholder="ФИ сотрудника"
                        defaultValue={newAccess?.title}
                        loading={isSearching}
                      />
                    </div>
                  </TData>
<TData style={{ padding: '10px', textAlign: 'center' }}>
                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Button
                        onClick={addNewAccess}
                        style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                      >
                        Добавить
                      </Button>

                       </div>
</TData>

                  </TRow>
                {/* Строки с данными */}
                {access.map((el) => (
                  <TRow key={el.id}>
                    <TData className="fio-cell">{el.title}</TData>
                    <TData className="checkbox-cell">
                      <input
                        type="checkbox"
                        checked={selectedUsers.has(el.id)}
                        onChange={() => chooseData(el.id)}
                      />
                    </TData>
                  </TRow>
                ))}

              </TBody>
              <TFoot>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={addSelectedAccess}
                    style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
                  >
                    Сохранить изменения
                  </Button>
                  <Button onClick={deleteSelectedAccess} style={{ marginLeft: '10px' }}>
                    Удалить выбранные
                  </Button>
                </div>
              </TFoot>
            </Table>
          </ServiceBody>
    // </div>
    );
  }
  return <Cap />;
}

export default AccessStaffCV;
