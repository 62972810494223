import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import { alertFunction } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import './adminrecrutment.scss';

function MvzRecrutment() {
  const [mvzs, setMvzs] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedMvzs, setSelectedMvzs] = useState(new Set());
  const [newMvz, setNewMvz] = useState({ id: '', title: '' }); // Изменено для хранения ID

  useEffect(() => {
    awaitRequests();
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = { type: 'getAdminDataRecrutment' };
      const result = await clientSendData('POST', '/get_admin_data_recrutment', reqData);
      if (result) {
        const data = JSON.parse(result.data);
        setMvzs(data.mvz);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataRecrutment');
    }
  }

  const handleTitle = (id, newTitle) => {
    setMvzs(mvzs.map((l) => (l.id === id ? { ...l, title: newTitle } : l)));
  };

  const handleIdChange = (id, newId) => {
    setMvzs(mvzs.map((l) => (l.id === id ? { ...l, id: newId } : l)));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  const toggleSelectMvz = (id) => {
    setSelectedMvzs((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const editRecrutmentMvz = async (mvzsData) => {
    try {
      const reqData = {
        type: 'editRecrutmentMvz',
        data: mvzsData,
      };
      const result = await clientSendData('POST', '/edit_recrutment_mvz', reqData);

      if (result.success) {
        await alertFunction('save_settings', 'client');
        await getPermissions();
      } else {
        await alertFunction('required_fields', 'client');
      }
    } catch (error) {
      catchHandler(error, 'editRecrutmentMvz');
    }
  };

  const deleteSelectedMvzs = async () => {
    const newMvzs = mvzs.filter((l) => !selectedMvzs.has(l.id));
    setMvzs(newMvzs);
    setSelectedMvzs(new Set());
    await editRecrutmentMvz(newMvzs);
  };

  const addSelectedMvz = async () => {
    const newLevels = mvzs.filter((l) => !selectedMvzs.has(l.id));
    setMvzs(newLevels);
    setSelectedMvzs(new Set());
    await editRecrutmentMvz(mvzs);
  };

  const addNewMvz = () => {
    if (newMvz.id && newMvz.title) {
      const newMvzObj = {
        id: newMvz.id, // Используем ID из формы
        title: newMvz.title,
      };
      setMvzs([...mvzs, newMvzObj]);
      setNewMvz({ id: '', title: '' }); // Сбросить форму
    } else {
      alertFunction('required_fields', 'client'); // Уведомление, если поля не заполнены
    }
  };

  if (isAuthorized) {
    return (
      <ServiceBody>
        <Table id="admin_staff_cv">
          <THead title="МВЗ" headers={headers.mvzs} className="table-header" />
          <TBody>
            {mvzs.map((el) => (
              <TRow key={el.id}>
                <TData className="id-cell">
                  <Input
                    type="text"
                    value={el.id}
                    onChange={(e) => handleIdChange(el.id, e.target.value)}
                    placeholder="ID"
                  />
                </TData>
                <TData className="full-name-cell">
                  <Input
                    type="text"
                    value={el.title}
                    onChange={(e) => handleTitle(el.id, e.target.value)}
                    placeholder="МВЗ"
                  />
                </TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedMvzs.has(el.id)}
                    onChange={() => toggleSelectMvz(el.id)}
                  />
                </TData>
              </TRow>
            ))}

            {/* Строка для добавления нового уровня */}
            <TRow>
              <TData className="id-cell">
                <Input
                  type="text"
                  value={newMvz.id}
                  onChange={(e) => setNewMvz({ ...newMvz, id: e.target.value })}
                  placeholder="Номер нового МВЗ"
                />
              </TData>
              <TData className="full-name-cell">
                <Input
                  type="text"
                  value={newMvz.title}
                  onChange={(e) => setNewMvz({ ...newMvz, title: e.target.value })}
                  placeholder="название МВЗ"
                />
              </TData>
              <TData className="add-button-cell">
                <Button onClick={addNewMvz} style={{ backgroundColor: '#28B47D', color: 'white' }}>
                  Добавить
                </Button>
              </TData>
            </TRow>
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={addSelectedMvz}
                style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
              >
                Сохранить изменения
              </Button>
              <Button
                onClick={deleteSelectedMvzs}
                style={{ marginLeft: '10px' }}
              >
                Удалить выбранные
              </Button>
            </div>
          </TFoot>
        </Table>
      </ServiceBody>
    );
  }
  return <Cap />;
}

export default MvzRecrutment;
