import React from 'react';

function TimeIndicatorSLA({ time, additionalInfo }) {
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;

  let maxMinutes;
  switch (additionalInfo) {
    case 'Низкий':
      maxMinutes = 2400; // 40 часов
      break;
    case 'Средний':
      maxMinutes = 960; // 16 часов
      break;
    case 'Высокий':
      maxMinutes = 480; // 8 часов
      break;
    case 'Наивысший':
      maxMinutes = 120; // 2 часа
      break;
    default:
      maxMinutes = 240; // 4 часа
  }

  let color;
  let remainingText;
  if (totalMinutes >= maxMinutes) {
    color = '#ff0000'; // красный
    const overdueMinutes = Math.abs(totalMinutes - maxMinutes);
    const overdueHours = Math.floor(overdueMinutes / 60).toString().padStart(2, '0');
    const overdueMinutesRemaining = (overdueMinutes % 60).toString().padStart(2, '0');
    remainingText = `просрочен на ${overdueHours}:${overdueMinutesRemaining}`;
  } else {
    color = '#28B47D'; // зеленый
    const remainingMinutes = maxMinutes - totalMinutes;
    const remainingHours = Math.floor(remainingMinutes / 60).toString().padStart(2, '0');
    const remainingMinutesRemaining = (remainingMinutes % 60).toString().padStart(2, '0');
    remainingText = `осталось ${remainingHours}:${remainingMinutesRemaining}`;
  }

  const dotStyle = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: color,
    display: 'inline-block',
  };

  return (
    <div>
      <span style={dotStyle} />
      <span>
{' '}
      </span>
      <span style={{ color }}>{remainingText}</span>
    </div>
  );
}

export default TimeIndicatorSLA;
