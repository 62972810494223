import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

/**
 * @component Рамка для показа изображения
 * @prop {size} string размер превью large/medium/small
 */

function ImgFrame(props) {
  const {
    size, imageSrc, setSize,
  } = props;

  const [isImageSrc, setImageSrc] = useState(imageSrc);
  const appNode = document.querySelector('.App'); // DOM элемент главного контейнера приложения

  // Эффект для обновления состояния при изменении props
  useEffect(() => {
    setImageSrc(imageSrc);
  }, [imageSrc]);

  const defineClass = () => {
    const classes = {
      active: 'img-frame img-frame_active',
      large: 'img-frame img-frame__large',
      medium: 'img-frame img-frame__medium',
      small: 'img-frame img-frame__small',
      default: 'img-frame img-frame__no-size',
    };

    if (isImageSrc) return classes.active;
    return classes[size] || classes.default;
  };

  if (isImageSrc) {
    return (
      createPortal( // создать портал в верхний div приложения и отобразить модальное окно
        <div className={defineClass()} onClick={() => setSize('default')}>
          <img src={isImageSrc} alt="Вложение к заявке" />
          <span
            className="img-frame__close"
            onClick={(event) => {
              event.stopPropagation();
              setImageSrc(null); // Закрываем изображение
              setSize('default');
            }}
          >
            ×
          </span>
        </div>,
        appNode,
      )
    );
  }
  return null; // Возвращаем null, если нет изображения
}

export default ImgFrame;
