import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import { alertFunction, alertFunctionLocal } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import './adminrecrutment.scss';

function LocationRecrutment() {
  const [locations, setLocations] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState(new Set()); // Состояние для хранения выбранных сертификатов
  const [newLocation, setNewLocation] = useState({ title: '' });

  useEffect(() => {
    awaitRequests();
    getPermissions();
  }, []);

  async function getPermissions() {
    try {
      const reqData = { type: 'getAdminDataRecrutment' };
      const result = await clientSendData('POST', '/get_admin_data_recrutment', reqData);
      if (result) {
        console.log(result.data);
        const data = JSON.parse(result.data);
        setLocations(data.location);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataRecrutment');
    }
  }

  const handleTitle = (id, newTitle) => {
    setLocations(locations.map((loc) => (loc.id === id ? { ...loc, title: newTitle } : loc)));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  const toggleSelectLocation = (id) => {
    setSelectedLocations((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  //  Функция отправки изменений на бэк
  const editRecrutmentLocations = async (locationsData) => {
    console.log(locationsData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editRecrutmentLocation',
        data: locationsData,
      };
      console.log(reqData);
      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_recrutment_locations', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('save_settings', 'client'); // Уведомление об успешном обновлени
        // Обновляем состояние, чтобы перерендерить компонент
        await getPermissions();
      } else {
        await alertFunction('required_fields', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editRecrutmentLocation'); // Обработка ошибок
    }
  };

  const deleteSelectedLocation = async () => {
    const newLocations = locations.filter((lang) => !selectedLocations.has(lang.id));
    setLocations(newLocations);
    setSelectedLocations(new Set()); // Сбросить выбранные сертификаты
    await editRecrutmentLocations(newLocations);
  };

  const addSelectedLocation = async () => {
    const newLocations = locations.filter((loc) => !selectedLocations.has(loc.id));
    setLocations(newLocations);
    setSelectedLocations(new Set()); // Сбросить выбранные сертификаты
    await editRecrutmentLocations(locations);
  };

  // Обработчик для добавления нового языка
  const addNewLanguage = () => {
    if (newLocation.title) {
      const newLoc = {
        id: (locations.length + 1).toString(), // Генерация ID (в реальном приложении лучше использовать уникальный идентификатор)
        title: newLocation.title,
      };
      setLocations([...locations, newLoc]);
      setNewLocation({ title: '' }); // Сбросить форму
    } else {
      alertFunction('Пожалуйста, заполните все поля!', 'client'); // Уведомление, если поля не заполнены
    }
  };

  if (isAuthorized) {
    return (
    // <div id="staff_cv" className="service">
                <ServiceBody>
                    <Table id="admin_staff_cv">
                        <THead title="Список городов" headers={headers.locations} className="table-header" />
                        <TBody>
                            {locations.map((el) => (
                                <TRow key={el.id}>
                                    <TData className="id-cell">
                                    {el.id}
                                    </TData>
                                    <TData className="title-cell">
    <Input
      type="text"
      value={el.title}
      onChange={(e) => handleTitle(el.id, e.target.value)}
      placeholder="Город"
    />
                                    </TData>
<TData className="checkbox-cell">
                                        <input
                                          type="checkbox"
                                          checked={selectedLocations.has(el.id)}
                                          onChange={() => toggleSelectLocation(el.id)}
                                        />
</TData>
                                </TRow>
                            ))}
                            {/* Строка для добавления нового языка */}
               <TRow>
                <TData className="id-cell" />
                <TData className="title-cell">
                  <Input
                    type="text"
                    value={newLocation.title}
                    onChange={(e) => setNewLocation({ ...newLocation, title: e.target.value })}
                    placeholder="город"
                  />
                </TData>
                <TData className="add-button-cell">
                  <Button onClick={addNewLanguage} style={{ backgroundColor: '#28B47D', color: 'white' }}>
                    Добавить
                  </Button>
                </TData>
               </TRow>
                        </TBody>
                        <TFoot>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
  <Button
    onClick={addSelectedLocation}
    style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }} // Отступ справа
  >
    Сохранить изменения
  </Button>
  <Button
    onClick={deleteSelectedLocation}
    style={{ marginLeft: '10px' }} // Отступ слева
  >
    Удалить выбранные
  </Button>
                        </div>

                        </TFoot>
                    </Table>
                </ServiceBody>
    // </div>
    );
  } return <Cap />;
}

export default LocationRecrutment;
