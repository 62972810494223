import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';
import ServiceBody from '../../../components/Service/ServiceBody';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import TRow from '../../../components/Table/TRow';
import TData from '../../../components/Table/TData';
import TempAccessCard from './TempAccessCard';
import NewTempAccessCard from './NewTempAccessCard';
import { setOperInfo } from '../../../utils/functions/others';
import { defaultTableState, headers } from '../common';
import Service from '../../../components/Service/Service';

function TempAccessControl() {
  const tableId = 'temporary_access_control';
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [displayedInfo, setDisplayedInfo] = useState([]); // отображаемая информация
  const [tableState, setTableState] = useState(
    JSON.parse(localStorage.getItem(tableId)) || defaultTableState.tempAccesses,
  );
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки  задачи
  const [showCreateCard, setShowCreateCard] = useState(false); // состояние отображения карточки создания
  const [searchParams] = useSearchParams();

  // Получить задачи
  useEffect(() => {
    getTmpTasks();
  }, [tableState]);

  useEffect(() => {
    // проверка параметров url при их изменении (для открытия карточки)
    if (searchParams.size > 0) { // если есть какие то параметры
      // если url содержит параметр поиска "ticket" открывается карточка вопроса
      if (searchParams?.get('task')) {
        setShowCard(true);
      }
      // else setSearchParams(''); // иначе это другие параметры - сбрасываем их
    }
  }, [searchParams]);

  async function getTmpTasks() {
    try {
      const reqData = {
        type: 'getTmpTasks',
        tableState,
        total,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_tmp_tasks', reqData);
      if (result?.success) {
        setDisplayedInfo(result.data); // Результат записывается в состояние
        setTotal(result.total);
      }
    } catch (error) {
      catchHandler(error, 'getTmpTasks');
    } finally {
      setIsLoading(false);
    }
  }

  // закрыть карточку
  function closeHandler() {
    setOperInfo({ subject_id: null }); // сброс id категории в оперативной информацию
    setShowCreateCard(false); // закрытие карточки
  }

  return (
    <Service>
    <ServiceBody>
    <PaginatedTable
      id={tableId}
      headers={headers.tempAccesses}
      state={tableState}
      setState={setTableState}
      total={total}
      optButton={{
        title: 'Создать задачу',
        handler: () => setShowCreateCard(true),
      }}
    >
     {displayedInfo.map((row) => (
          <TRow key={row.id}>
            {headers.tempAccesses.map((column) => {
              const { field, align, mobile_hide } = column;
              return (
                <TData
                  key={field}
                  align={align}
                  loading={isLoading}
                  mobileHide={mobile_hide}
                >
                 <div className="helpdesk__ticket-priority">
                  {row[field]}
                  {row.expired && field === 'id' && row.status_id !== 4 && <div style={{ background: 'red', opacity: '0.2' }} />}
                 </div>
                </TData>
              );
            })}
            <TData loading={isLoading}>
            <Link className="clickable" to={`?task=${row.id}`}>
              <img src="../../icons/file-text.svg" alt="file" />
            </Link>
            </TData>
          </TRow>
     ))}
    </PaginatedTable>

    {showCreateCard && (
        <NewTempAccessCard
          setShowCard={setShowCreateCard}
          refreshTable={() => setTableState({ ...tableState })}
          closeHandler={closeHandler}
        />
    )}

    {showCard && (
        <TempAccessCard
          setShowCard={setShowCard}
          refreshTable={() => setTableState({ ...tableState })}
        />
    )}
    </ServiceBody>
    </Service>
  );
}

export default TempAccessControl;
