// eslint-disable-next-line import/no-extraneous-dependencies
import { DocumentEditor } from '@onlyoffice/document-editor-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Service from '../../components/Service/Service';

function onDocumentReady(event) {
  console.log('Document is loaded');
}

function onLoadComponentError(errorCode, errorDescription) {
  switch (errorCode) {
    case -1: // Unknown error loading component
      console.log(errorDescription);
      break;

    case -2: // Error load DocsAPI from http://documentserver/
      console.log(errorDescription);
      break;

    case -3: // DocsAPI is not defined
      console.log(errorDescription);
      break;
    default:
      console.log('success');
      break;
  }
}
function DocumentsEditor() {
  const { id } = useParams(); // параметр ссылки

  const [token, setToken] = useState(null);
  const [serverAddress, setServerAddress] = useState('');
  const [config, setConfig] = useState({
    document: {
      bucket: null,
      fileType: null,
      title: null,
      url: null,
    },
    documentType: null,
    editorConfig: {
      lang: 'ru',
      callbackUrl: 'https://uniplace.unicon.ru:1443/onlyoffice_callback',
      close: {
        visible: true,
        text: 'Close file', // Tooltip for the close button
      },
      events: {
        onRequestClose: handleRequestClose, // Handle close button click
      },
    },
  });

  useEffect(() => {
    uploadDocuments(config);
  }, []);

  function handleRequestClose() {
    const documentEditor = window.DocEditor.instances.docxEditor;
    documentEditor.destroyEditor();
  }

  async function uploadDocuments(docConfig) {
    try {
      const reqData = {
        type: 'generateJWTToken',
        config: docConfig,
        docId: id,
      };
      const result = await clientSendData('POST', '/generate_jwt_token', reqData); // запрос в БД
      setToken(result.token);
      setConfig(result.config);
      setServerAddress(result.server);
    } catch (error) {
      catchHandler(error, 'clprtPutObjectsToBucket'); // обработка ошибок
    }
  }

  if (token) {
    return (
      <Service>
        <DocumentEditor
          id="docxEditor"
          documentServerUrl={serverAddress}
          config={config}
          token={token}
          events_onDocumentReady={onDocumentReady}
          onLoadComponentError={onLoadComponentError}
        />
      </Service>
    );
  }
}

export default DocumentsEditor;
