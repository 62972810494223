import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import CertificatesStaffCV from './CertificatesStaffCV';
import LanguagesStaffCV from './LanguagesStaffCV';
import AccessStaffCV from './AccessStaffCV';
import LevelLanguagesStaffCV from './LevelsLanguages';
import NotificationsStaffCV from './NotificationsStaffCV';

function AdminStaffCV() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const adminPages = [
    { id: 1, title: 'Сертификаты', component: <CertificatesStaffCV /> },
    { id: 2, title: 'Языки', component: <LanguagesStaffCV /> },
    { id: 3, title: 'Уровни языков', component: <LevelLanguagesStaffCV /> },
    { id: 3, title: 'Уведомления', component: <NotificationsStaffCV /> },
    { id: 3, title: 'Доступы', component: <AccessStaffCV /> },
  ];
  const [pages, setPages] = useState(adminPages);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  if (isAuthorized) {
    return <Service id="admin-staff-cv" pages={pages} horizontal />;
  } return <Cap />;
}

export default AdminStaffCV;
