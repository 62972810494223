import React, { useEffect, useState } from 'react';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Service from '../../components/Service/Service';
import Cap from '../Cap/Cap';
import RecrutmentTable from './RecrutmentTable';

function Recrutment() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const adminPages = [
    { id: 1, title: 'Подбор персонала', component: <RecrutmentTable /> },
    // { id: 2, title: 'Форма подбора персонала', component: <RecrutmentForm /> },
  ];
  const [pages, setPages] = useState(adminPages);

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  if (isAuthorized) {
    return <Service id="recrutment" pages={pages} horizontal />;
  } return <Cap />;
}

export default Recrutment;
