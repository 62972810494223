export const headers = {
  access: [
    { title: 'ФИО', key: 'title' },
    { title: 'Выбрать', key: 'values' },
  ],
  certificates: [
    { title: '№', key: 'title' },
    { title: 'Полное название', key: 'fullName' },
    { title: 'Короткое название', key: 'shortName' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
  notifications: [
    { title: '№', key: 'title' },
    { title: 'Тема', key: 'subject' },
    { title: 'Сообщение', key: 'message' },
  ],
  levels: [
    { title: '№', key: 'title' },
    { title: 'Уровень', key: 'level' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
  languages: [
    { title: '№', key: 'title' },
    { title: 'Язык', key: 'languages' },
    { title: 'Выбрать', key: 'checkbox' },
  ],
};
