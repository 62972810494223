import React, { useEffect, useState } from 'react';
import { alertFunction } from '../../../../utils/functions/alertFunction';
import { clientSendData } from '../../../../utils/functions/requests';
import { catchHandler } from '../../../../utils/error_handling/error_handling';
import { bookingStatuses } from '../../common';
import CardSetting from '../../../../components/Card/CardSetting';
import Loader from '../../../../components/UI/Loader';
import Button from '../../../../components/UI/Button/Button';
import classes from '../../../../components/UI/Button/button.module.scss';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
// import Input from '../../../../components/UI/Input';
import Select from '../../../../components/UI/Select';

/**
* @component  Карточка с данными бронирования
*/
function Main(props) {
  const { closeHandler, refreshTable, bookingId } = props;
  const [booking, setBooking] = useState({});
  const [changeBooking, setChangeBooking] = useState({});
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [isEditing, setIsEditing] = useState(false); // состояние изменения бронирования
  const smallButton = `${classes.button} + ${classes.small}`;

  useEffect(() => {
    getBookingCard();
  }, []);

  // Получить заявки
  async function getBookingCard() {
    try {
      const reqData = {
        type: 'getBookingCard',
        bookingId,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_booking_card', reqData);
      if (result?.success) {
        setBooking(result?.data); // Результат записывается в состояние
      }
    } catch (error) {
      catchHandler(error, 'getBookingCard');
    } finally {
      setIsLoading(false);
    }
  }

  async function cancelBooking() {
    setChangeBooking((state) => ({ ...state, status_id: 2 }));
    await editHandler({ status_id: 3 });
    await alertFunction('booking_canceled', 'clientPost');
    closeHandler();
  }

  function dateToHours(date) {
    const newDate = new Date(date);
    const time = `${newDate.getHours().toString().padStart(2, '0')}:${newDate.getMinutes().toString().padStart(2, '0')}`;
    return time;
  }

  function getDate() {
    const fullDate = new Date(booking.date_start);
    const date = `${fullDate.getFullYear()}-${fullDate.getMonth() + 1}-${fullDate.getDate()} `;
    return date;
  }

  const handleChange = () => {
    setIsEditing((state) => !state);
  };

  async function saveHandler() {
    await editHandler(changeBooking);
  }

  const editHandler = async (changeData) => {
    try {
      if (Object.keys(changeData).length > 0) {
        if (new Date(changeData?.date_start || booking?.date_start) < new Date(changeData?.date_end || booking?.date_end) && new Date(changeData?.date_end || booking?.date_end) - new Date(changeData?.date_start || booking?.date_start) > 3600000) {
          const reqData = {
            type: 'editBookingCard',
            bookingId,
            changeBooking: changeData,
          };
          setIsLoading(true);
          const result = await clientSendData('POST', '/edit_booking_card', reqData);
          if (result?.success) {
            await refreshTable();
            setIsEditing(false);
            await getBookingCard();
          }
        } else {
          await alertFunction('booking_dates_error', 'client');
        }
      } else {
        setIsEditing(false);
      }
    } catch (error) {
      catchHandler(error, 'editBookingCard');
    } finally {
      setIsLoading(false);
    }
  };

  function startTimeHandler(e) {
    setChangeBooking((state) => ({
      ...state,
      date_start: new Date(getDate() + e.title).toUTCString(),
    }));
    setBooking((state) => ({
      ...state,
      date_start: new Date(getDate() + e.title).toUTCString(),
    }));
  }

  function endTimeHandler(e) {
    setChangeBooking((state) => ({
      ...state,
      date_end: new Date(getDate() + e.title).toUTCString(),
    }));
    setBooking((state) => ({
      ...state,
      date_end: new Date(getDate() + e.title).toUTCString(),
    }));
  }

  if (isLoading) return <CardBody><Loader /></CardBody>;
  return (
    <>
      <CardBody>
        <CardSetting title="№:">{booking?.id}</CardSetting>
        <CardSetting title="Место:">{booking?.workplace}</CardSetting>
        <CardSetting title="Статус:">{booking?.status}</CardSetting>
        <CardSetting title="Дата начала:">
        {isEditing ? (
        /* <Input
          type="time"
          defaultValue={dateToHours(booking?.date_start)}
          onChange={(event) => setChangeBooking((state) => ({
            ...state,
            date_start: getDate() + event.target.value,
          }))}
        /> */
        <Select
          id="start_time"
          array={booking.time}
          onChoose={startTimeHandler}
          defaultValue={dateToHours(booking?.date_start)}
        />
        ) : (
        <span>{booking?.date_start}</span>
        )}
        </CardSetting>

        <CardSetting title="Дата окончания:">
        {isEditing ? (
        /*  <Input
          type="time"
          defaultValue={dateToHours(booking?.date_end)}
          onChange={(event) => setChangeBooking((state) => ({
            ...state,
            date_end: getDate() + event.target.value,
          }))}
        /> */
        <Select
          id="end_time"
          array={booking.time}
          onChoose={endTimeHandler}
          defaultValue={dateToHours(booking?.date_end)}
        />
        ) : (
        <span>{booking?.date_end}</span>
        )}
        </CardSetting>
        {booking?.status_id === bookingStatuses.reserved && !isEditing && (
        <CardSetting title="Отмена бронирования:">
           <Button className={smallButton} onClick={cancelBooking}>Отменить</Button>
        </CardSetting>)}
        {/* <CardSetting title="Дата окончания:">{booking?.date_end}</CardSetting> */}
      </CardBody>
      <CardFooter>
        {booking?.status_id === bookingStatuses.reserved && !isEditing && (
        <Button onClick={handleChange}>Редактировать</Button>)}
        {isEditing && (
        <Button onClick={saveHandler}>Сохранить</Button>)}

         {isEditing && (
        <Button onClick={handleChange}>Отмена</Button>)}

        {!isEditing && (<Button onClick={closeHandler}>Закрыть</Button>)}
      </CardFooter>
    </>

  );
}

export default Main;
