import React from 'react';
import TRow from '../../../../../components/Table/TRow';
import TData from '../../../../../components/Table/TData';
import {
  description,
} from '../options';

// Сервис оценок - страница заявления - заявление на оценку - таблица оценки - строка таблицы
function RowUnchangeable(props) {
  const { data, appraisal, role } = props;
  const {
    category_id, // id категории
    task_id, // id задачи
    task_title, // название задачи
    mark, // Параметр выделения строки цветом
  } = data;

  const taskData = appraisal?.[category_id]?.[task_id]; // данные задачи в appraisal
  const valueInt = taskData?.value_int; // числовая оценка задачи
  const valueChar = taskData?.value_char; // буквенная оценка задачи
  const comment = taskData?.comment; // комментарий аудитора к задаче
  const approverComment = taskData?.approver_comment; // комментарий руководителя к задаче

  return (
    <TRow mark={mark}>
        <TData>
{task_title}
<span className="task_info"><p title={description[task_id][role]}>?</p></span>
        </TData>
        <TData>{valueInt}</TData>
        <TData>{valueChar}</TData>
        <TData>{comment}</TData>
        <TData>{approverComment}</TData>
    </TRow>
  );
}

export default RowUnchangeable;
