import React, { useEffect, useState } from 'react';
import ServiceBody from '../../../components/Service/ServiceBody';
import Wrapper from '../../../components/Basic/Wrapper';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import { clientSendData } from '../../../utils/functions/requests';
import TableWrapper from './TableWrapper';
import DefaultTable from './DefaultTable';
import Select from '../../../components/UI/Select';

// Сервис оценок - Интерфейс руководителя
function Approver() {
  const [period, setPeriod] = useState(''); // период времени
  const [finYears, setFinYears] = useState([]);
  const isAdmin = true;
  const location = 'admin';

  useEffect(() => {
    getFinYears();
  }, []);

  // Поиск заявлений на выбранные даты
  async function getFinYears() {
    try {
      const reqData = {
        type: 'getAllFinYears',
      };
      const result = await clientSendData('POST', '/get_all_fin_years', reqData); // запрос в БД
      if (result) {
        setFinYears(result);
        setPeriod(result[0].title);
      }
    } catch (error) {
      catchHandler(error, 'findProjectEvs'); // обработка ошибок
    }
  }

  return (
    <ServiceBody>
      <Wrapper flexFlow="row wrap">
        Финансовый год
        <Select
          id="fin_year"
          array={finYears}
          onChoose={(finYear) => setPeriod(finYear.title)}
          defaultValue={period}
        />
      </Wrapper>
      {/* <Period period={period} setPeriod={writePeriodToLS} vertical>
        <Wrapper flexFlow="row wrap">
          {quarters.map((q) => (
              <IconButton key={q} onClick={() => setQuarterPeriod(q)}>{`Q${q}`}</IconButton>
          ))}
          <Button onClick={() => setOpenYear(!openYear)}>Выбрать финансовый год</Button>
          { openYear && <div className="openYear" onClick={() => chooseFinYear(currentYear, nextYear)}>{`${currentYear} - ${nextYear}`}</div>}
        </Wrapper>
        <Division />
      </Period> */}
{/*
      <TableWrapper
        title="Проектные оценки" */}
        {/* // button={showExTable ? 'Основная информация' : 'Расширенная информация'}
        // buttonHandler={() => setShowExTable(!showExTable)}
      > */}
        {/* {showExTable
          ? <ExtendedTable period={period} />
          : <DefaultTable period={period} />} */}
          <DefaultTable period={period} isAdmin={isAdmin} location={location} />
      {/* </TableWrapper> */}
    </ServiceBody>
  );
}

export default Approver;
