import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { headers } from './common';
import Select from '../../components/UI/Select';
import { alertFunction } from '../../utils/functions/alertFunction';
import TFoot from '../../components/Table/TFoot';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input';
import './adminrecrutment.scss';
import TextArea from '../../components/UI/TextArea';

function NotificationsRecrutment() {
  const [notifications, setNotifications] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    awaitRequests();
    getNotificationsData();
  }, []);

  async function getNotificationsData() {
    try {
      const reqData = { type: 'getAdminDataStaffCV' };
      const result = await clientSendData('POST', '/get_admin_data_staff_cv', reqData);
      if (result) {
        setNotifications(() => JSON.parse(result.notifications.value));
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataStaffCV');
    }
  }

  console.log(notifications);

  const handleSubject = (id, newSubject) => {
    setNotifications(notifications.map((n) => (n.id === id ? { ...n, subject: newSubject } : n)));
  };

  const handleMessage = (id, newMessage) => {
    setNotifications(notifications.map((n) => (n.id === id ? { ...n, message: newMessage } : n)));
  };

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  //  Функция отправки изменений на бэк
  const editStaffCVNotifications = async (notificationsData) => {
    console.log(notificationsData);
    try {
      // Создаем объект для отправки
      const reqData = {
        type: 'editStaffCVNotifications',
        data: notificationsData,
      };
      console.log(reqData);
      // Отправляем данные на сервер
      const result = await clientSendData('POST', '/edit_staff_cv_notifications', reqData);

      // Обработка ответа от сервера
      if (result.success) {
        await alertFunction('save_settings', 'client'); // Уведомление об успешном обновлении
        // Обновляем состояние, чтобы перерендерить компонент
        await getNotificationsData();
      } else {
        await alertFunction('required_fields', 'client'); // Уведомление об ошибке
      }
    } catch (error) {
      catchHandler(error, 'editStaffCVNotifications'); // Обработка ошибок
    }
  };

  const addSelectedCertificates = async () => {
    await editStaffCVNotifications(notifications);
  };

  if (isAuthorized) {
    return (
    // <div id="staff_cv" className="service">
        <ServiceBody>
          <Table id="admin_staff_cv">
            <THead title="Тема и текст уведомлений" headers={headers.notifications} className="table-header" />
            <TBody>
              {notifications.map((el) => (
                <TRow key={el.id}>
                  <TData className="id-cell">
                    {el.id}
                  </TData>
                  <TData className="full-name-cell">
                    <Input
                      type="text"
                      value={el.subject}
                      onChange={(e) => handleSubject(el.id, e.target.value)}
                      placeholder="Тема"
                    />
                  </TData>
                  <TData className="short-name-cell">
                    <TextArea
                      type="text"
                      value={el.message}
                      onChange={(e) => handleMessage(el.id, e.target.value)}
                      placeholder="Сообщение"
                    />
                  </TData>
                </TRow>
              ))}

            </TBody>
            <TFoot>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={addSelectedCertificates}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
                >
                  Сохранить изменения
                </Button>
              </div>
            </TFoot>
          </Table>

        </ServiceBody>
    // </div>
    );
  }

  return <Cap />;
}

export default NotificationsRecrutment;
