import React, { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import CardSetting from '../../components/Card/CardSetting';
import Input from '../../components/UI/Input';
import RadioButton from '../../components/UI/RadioButton';
import Button from '../../components/UI/Button/Button';
import { clientSendData } from '../../utils/functions/requests';
import { alertFunction } from '../../utils/functions/alertFunction';
import { catchHandler } from '../../utils/error_handling/error_handling';
import MessageToPdf from './MessageToPdf';
import Service from '../../components/Service/Service';

const ComponentToPrint = React.forwardRef((props, ref) => (
  <div
    id="message-to-pdf"
    ref={ref}
    style={{
      width: '191mm',
      minHeight: '295mm',
      // marginLeft: 'auto',
      // marginRight: 'auto',
    }}
  >
  <MessageToPdf
    message={props.message}
    defaultDecision={props.defaultDecision}
    messageId={props.messageId}
    signData={props.signData}
  />
  </div>
));

function Decision(props) {
  const { messageId, sdlUser, dir } = props;
  const decisions = {
    title1: 'Признать выявленную необычную операцию (сделку) подозрительной операцией (сделкой), осуществление которой может быть направлено на легализацию (отмывание) доходов, полученных преступным путем, или финансирование терроризма, или распространение оружия массового уничтожения;',
    title2: 'Принять дополнительные меры по изучению необычной операции (сделки) клиента;',
    title3: 'Представить информацию об операции, предусмотренной подпунктом "а" настоящего пункта, в Федеральную службу по финансовому мониторингу',
  };
  const [decisionsList, setDecisions] = useState(false); // список решений руководителя для выбора
  const [userRole, setUserRole] = useState('sdl');
  const [isSigned, setIsSigned] = useState(false); // индикатор подписи заявления (есть/нет)
  const [showDecisions, setShowDecisions] = useState(false);
  const [status, setStatus] = useState('');
  const [defaultDecision, setDefaultDecision] = useState({
    supervisorMark: {
      D1: '', D2: '', D3: '',
    },
  });
  const [message, setMessage] = useState({});
  const [signData, setSignData] = useState({}); // данные для эл. подписи
  // Объект для формирования сообщения и принятии решения
  const [decision, setDecision] = useState({
    compileDate: {
      date: '',
      time: '',
    },
    sdlRecieveDate: {
      date: '',
      time: '',
    },
    sdlDecisionMark: '',
    decisionReason: '',
    sdlDecisionDate: {
      date: '',
      time: '',
    },
    supervisorMark: {
      D1: '', D2: '', D3: '',
    },
    extraMeasuresMark: '',
    oddParameter: '',
  });

  useEffect(() => {
    getDecision();
    getMessageData();
  }, []);

  useEffect(() => {
    setDecision({ ...decision, decisionReason: message?.operationComment });
    setDefaultDecision({ ...defaultDecision, decisionReason: message?.operationComment });
  }, [message?.operationComment]);

  // Получить решение
  async function getDecision() {
    try {
      const reqData = {
        type: 'getDecision',
        message_id: messageId,
      };
      const result = await clientSendData('POST', '/get_decision', reqData);
      if (result.data[0].decision) {
        setDefaultDecision(result.data[0].decision);
        setDecision(result.data[0].decision);
        setStatus(result.data[0].status);
      }
    } catch (error) {
      catchHandler(error, 'getDecision'); // обработка ошибок
    }
  }

  // Получить данные сообщения
  async function getMessageData() {
    try {
      const reqData = {
        type: 'getMessageData',
        messageId,
      };
      const result = await clientSendData('POST', '/get_message_data', reqData);
      if (result) {
        setMessage(result.data[0].message);
        setSignData(result.sign_data[0]);
        setStatus(result.data[0].status_id);
      }
    } catch (error) {
      catchHandler(error, 'getMessageData');
    }
  }

  // eslint-disable-next-line no-return-assign, no-sequences
  const asnwer = Object.entries(decision.supervisorMark).reduce((acc, [k, v]) => ((acc[v] = acc[v] || []).push(k), acc), {});

  if (asnwer !== decision.supervisorMark) {
    if (asnwer['Да']?.length === 3) {
      decision.oddParameter = 'allYes';
    } else if (asnwer['Нет']?.length === 3) {
      decision.oddParameter = 'allNo';
    } else if (asnwer['Да']?.length < 3) {
      decision.oddParameter = 'minOneYes';
    } else {
      decision.oddParameter = '';
    }
  }

  // Если каждый из ключей в массиве прошел валидацию (кроме доп.мер)
  const validateDecision = () => ([
    'compileDate',
    'sdlRecieveDate',
    'sdlDecisionMark',
    // 'decisionReason',
    'sdlDecisionDate',
    'supervisorMark',
  //  'extraMeasuresMark',
  ].every((key) => validateField(key))
  );

  const validateSupervisorMark = () => (
    ['D1', 'D2', 'D3'].every((key) => validateMarkField(key))
  );
  // Валидация отдельного ключа
  const validateField = (field) => Boolean(decision[field] !== '');
  const validateMarkField = (field) => Boolean(decision.supervisorMark[field] !== '');
  console.log(decision);
  // Сохранить решение
  async function saveDecision(type) {
    try {
      const reqData = {
        type: 'podftDecisionSave',
        decision_type: type,
        defaultDecision,
        decision,
        message,
        messageId,
      };
      if (type !== 'sign' && validateDecision()) {
        await clientSendData('POST', '/podft_decision_save', reqData);
        await alertFunction('save_settings', 'clientPost');
        await getMessageData();
        await getDecision();
      } else {
        await alertFunction('required_fields', 'clientPost');
      }
      if (type === 'sign') {
        await clientSendData('POST', '/podft_decision_save', reqData);
        await alertFunction('save_settings', 'clientPost');
        await getMessageData();
        await getDecision();
        setIsSigned(true);
      }
    } catch (error) {
      catchHandler(error, 'podftDecisionSave'); // обработка ошибок
    }
  }

  function generateImg() {
    const input = document.getElementById('message-to-pdf');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 210;
      const pageHeight = 290;
      // eslint-disable-next-line no-mixed-operators
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight + 25);
      pdf.setDrawColor(255, 255, 255);
      pdf.setLineWidth(10);
      pdf.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = (heightLeft - imgHeight) + 6.5;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight + 25);
        pdf.setDrawColor(255, 255, 255);
        pdf.setLineWidth(9);
        pdf.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      pdf.save('Внутренее сообщение.pdf');
    });
  }

  function setMotivatedDecision(e) {
    setDecision({ ...decision, decisionReason: e.target.value });
    setMessage({ ...message, operationComment: e.target.value });
  }

  return (
    <Service>
    <div id="podft-decision">
    <div className="decision-wrapper">
      {/* Дата и время составления внутреннего сообщения об операции (сделке) */}
      <CardSetting title="Дата и время составления внутреннего сообщения об операции">
        <div className="dateTime">
        <Input defaultValue={decision?.compileDate?.date} disabled={dir} type="date" onChange={(e) => setDecision({ ...decision, compileDate: ({ ...decision.compileDate, date: e.target.value }) })} />
        <Input defaultValue={decision?.compileDate?.time} disabled={dir} type="time" onChange={(e) => setDecision({ ...decision, compileDate: ({ ...decision.compileDate, time: e.target.value }) })} />
        </div>
      </CardSetting>

      {/* Дата и время получения сообщения СДЛ */}
      <CardSetting title="Дата и время получения сообщения СДЛ">
      <div className="dateTime">
        <Input defaultValue={decision?.sdlRecieveDate?.date} disabled={dir} type="date" onChange={(e) => setDecision({ ...decision, sdlRecieveDate: ({ ...decision.sdlRecieveDate, date: e.target.value }) })} />
        <Input defaultValue={decision?.sdlRecieveDate?.time} disabled={dir} type="time" onChange={(e) => setDecision({ ...decision, sdlRecieveDate: ({ ...decision.sdlRecieveDate, time: e.target.value }) })} />
      </div>
      </CardSetting>

      {/* Запись (отметка) о решении СДЛ, принятом в отношении внутреннего сообщения об операции (сделке) */}
      <CardSetting title="Запись (отметка) о решении СДЛ, принятом в отношении внутреннего сообщения об операции (сделке)">
        <Input disabled={dir} defaultValue={decision?.sdlDecisionMark} onChange={(e) => setDecision({ ...decision, sdlDecisionMark: e.target.value })} />
      </CardSetting>

      {/* Мотивированное обоснование принятого СДЛ решения (в случае принятия решения о не направлении сведений об операции в Уполномоченный орган) */}
      <CardSetting title="Мотивированное обоснование принятого СДЛ решения*">
        <Input
        //  defaultValue={message?.operationComment}
          defaultValue={decision?.decisionReason}
          disabled={dir}
         // onChange={(e) => setDecision({ ...decision, decisionReason: e.target.value })}
          onChange={(e) => setMotivatedDecision(e)}
        />
      </CardSetting>

      {/* Дата и время принятия решения СДЛ */}
      <CardSetting title="Дата и время принятия решения СДЛ">
      <div className="dateTime">
        <Input defaultValue={decision?.sdlDecisionDate?.date} disabled={dir} type="date" onChange={(e) => setDecision({ ...decision, sdlDecisionDate: ({ ...decision.sdlDecisionDate, date: e.target.value }) })} />
        <Input defaultValue={decision?.sdlDecisionDate?.time} disabled={dir} type="time" onChange={(e) => setDecision({ ...decision, sdlDecisionDate: ({ ...decision.sdlDecisionDate, time: e.target.value }) })} />
      </div>
      </CardSetting>

      {/* Запись (отметка) о решении Руководителя Компании либо уполномоченного им должностного лица */}
      <CardSetting title="Запись (отметка) о решении Руководителя Компании/уполномоченного должностного лица" invalid={Boolean(!validateSupervisorMark())}>
       {/* <Select array={decisions} /> */}
      <p onClick={() => setDecisions(!decisionsList)}>
      <img
        onClick={() => setShowDecisions(!showDecisions)}
        className={`nav-button__chevron ${showDecisions ? 'nav-button__chevron_active' : ''}`}
        src="../../icons/chevron.svg"
        alt="chevron-down"
      />
      </p>
      {decisionsList && (
      <div className="decisions-container">
        <div className={decision.oddParameter ? `decisions-list ${decision?.oddParameter}` : `decisions-list ${decision?.oddParameter}`}>
        {/* Подпункт 1 */}
        <div className="decision-point">
{decisions.title1}
<div className={dir ? 'checkbox-container disabled' : 'checkbox-container'}>
  <p>
    <RadioButton
      value="Да"
      onChange={() => setDecision({ ...decision, supervisorMark: ({ ...decision.supervisorMark, D1: 'Да' }) })}
      defaultChecked={defaultDecision?.supervisorMark.D1 === 'Да'}
      group="D1"
    >
Да
    </RadioButton>
  </p>
  <p>
  <RadioButton
    value="Нет"
    onChange={() => setDecision({ ...decision, supervisorMark: ({ ...decision.supervisorMark, D1: 'Нет' }) })}
    defaultChecked={defaultDecision?.supervisorMark.D1 === 'Нет'}
    group="D1"
  >
Нет
  </RadioButton>
  </p>
</div>
        </div>
        {/* Подпункт 2 */}
        <div className="decision-point">
        {decisions.title2}
        <div className={dir ? 'checkbox-container disabled' : 'checkbox-container'}>
<p>
    <RadioButton
      value="Да"
      onChange={() => setDecision({ ...decision, supervisorMark: ({ ...decision.supervisorMark, D2: 'Да' }) })}
      defaultChecked={defaultDecision?.supervisorMark.D2 === 'Да'}
      group="D2"
    >
Да
    </RadioButton>
</p>
  <p>
  <RadioButton
    value="Нет"
    onChange={() => setDecision({ ...decision, supervisorMark: ({ ...decision.supervisorMark, D2: 'Нет' }) })}
    defaultChecked={defaultDecision?.supervisorMark.D2 === 'Нет'}
    group="D2"
  >
Нет
  </RadioButton>
  </p>
        </div>
        </div>
        {/* Подпункт 3 */}
        <div className="decision-point">
        {decisions.title3}
        <div className={dir ? 'checkbox-container disabled' : 'checkbox-container'}>
<p>
    <RadioButton
      value="Да"
      onChange={() => setDecision({ ...decision, supervisorMark: ({ ...decision.supervisorMark, D3: 'Да' }) })}
      defaultChecked={defaultDecision?.supervisorMark.D3 === 'Да'}
      group="D3"
    >
Да
    </RadioButton>
</p>
  <p>
  <RadioButton
    value="Нет"
    onChange={() => setDecision({ ...decision, supervisorMark: ({ ...decision.supervisorMark, D3: 'Нет' }) })}
    defaultChecked={defaultDecision?.supervisorMark.D3 === 'Нет'}
    group="D3"
    isMarked="true"
  >
Нет
  </RadioButton>
  </p>
        </div>
        </div>
        </div>
      </div>
      )}
      </CardSetting>

       {/* Запись (отметка) о дополнительных мерах (иных действиях) */}
       <CardSetting title="Запись (отметка) о дополнительных мерах (иных действиях)*">
        <Input defaultValue={decision?.extraMeasuresMark} disabled={dir} onChange={(e) => setDecision({ ...decision, extraMeasuresMark: e.target.value })} />
       </CardSetting>
    </div>

      <div className="buttons-container">
      {(sdlUser && [5].includes(status)) && <Button onClick={() => saveDecision('send')}>Направить сообщение руководителю Компании</Button>}
      {(sdlUser && [6, 7].includes(status) && (JSON.stringify(decision) !== JSON.stringify(defaultDecision))) && <Button onClick={() => saveDecision('save')}>Сохранить изменения</Button>}
        {(dir && !isSigned && status === 6) && (<div className="dir-buttons">
      <Button onClick={() => saveDecision('sign')}>Подписать сообщение</Button>
      <Button onClick={() => saveDecision('rework')}>Отправить на доработку</Button>
                                                </div>)}

   {(status === 7 && (sdlUser || dir)) && <Button disabled={Object.keys(message).length === 0} onClick={generateImg}>Сформировать внутреннее сообщение</Button>}
   {/* <Button onClick={generateImg}>Сформировать внутреннее сообщение</Button> */}
      </div>

<ComponentToPrint
  message={message}
  defaultDecision={defaultDecision}
  messageId={messageId}
  signData={signData}
/>
    </div>
    </Service>

  );
}

export default Decision;
