/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createAction } from '../../utils/redux/store';
import Input from './Input';

/**
 * @component Кастомный селект
@prop {id} string (Обязательный) Идентификатор DOM-элемента
@prop {array} array (Обязательный) массив вариантов выбора (объект должен содержать ключи id и title)
@prop {onChoose} function (Обязательный) обработчик выбора - получает на выход выбранный объект
@prop {cancelChoice} function обработчик выбора - удаляет выбранную опцию
@prop {defaultValue} string значение по умолчанию для отображения выбора
@prop {loading} boolean параметр загрузки (показывает анимацию при загрузке)
@prop {disabled} boolean сделать селект неактивным
@prop {search} boolean добавить поиск по вариантам
 */
function Select(props) {
  const {
    id,
    array = [],
    onChoose,
    defaultValue,
    loading,
    disabled,
    search,
    children,
    cancelChoice,
  } = props;

  // Отображаемый массив
  const [dispayArray, setDisplayArray] = useState(array);
  // Выбранная опция
  const [activeOption, setActiveOption] = useState('');
  // состояние отображения выпадающего меню
  const isActive = useSelector((state) => state.visibility.select[id]);
  // ссылка на обёртку компонента
  const customSelect = useRef(null);
  // класс для стилей выпадающего меню
  const [dropDownClass, setDropDownClass] = useState('');
  // Значение отображаемое по умолчанию
  const choosedTitle = defaultValue || children || 'Выберите вариант';

  useEffect(() => {
    /* Обновить класс выпадающего при обновлении customSelect и isActive */
    setDropDownClass(defineDropDownClass());
  }, [customSelect, isActive]);

  // Обработчик выбора
  function optionHandler(row) {
    onChoose(row);
    setActiveOption(row);
    createAction('TOGGLE_SELECT', { key: id, value: false });

    // if (onChoose(row)) {
    //   createAction('TOGGLE_SELECT', { key: id, value: false });
    // } else if (activeOption.id === row.id) {
    //   setActiveOption('');
    // } else {
    //     setActiveOption(row);
    //     createAction('TOGGLE_SELECT', { key: id, value: false });
    // }
  }

  // Определение класса для выпадающего меню
  const defineDropDownClass = () => {
    // Границы обёртки селекта на вьюпорте (видимой области)
    const rect = customSelect?.current.getBoundingClientRect();

    // Максимальная высота выпадающего меню
    const dropDownMaxHeight = 200;
    // Если нижняя граница обёртки больше (высоты вьюпорта минус высота меню)
    // Значит выпадающее меню не влезет вниз
    const isNotVisible = rect.bottom >= (
      window.innerHeight - dropDownMaxHeight
      || document.documentElement.clientHeight - dropDownMaxHeight
    );

    let result = 'custom-select__dropdown';
    // Если выпадающее меню не влезает - добавить стили для смещения его вверх
    if (isNotVisible) result += ' custom-select__dropdown-top';
    // Если isActive - разввернуть меню независимо от положения
    if (isActive) result += ' custom-select__dropdown_active';
    return result;
  };

  // Переключить видимость dropdown меню
  function toggleSelect(e) {
    e.stopPropagation();
    // Если disabled - клик ни на что не повлияет
    // Иначе закроет селект
    if (!disabled && !loading) createAction('TOGGLE_SELECT', { key: id, value: !isActive });
  }

  function replaceUnsafeSymbols(string) {
    const unsafeSymbols = '[]{}()+\\|/.?';
    unsafeSymbols.split('').forEach((s) => {
      string = string.replaceAll(s, '');
    });
    return string;
  }

  function localSearch(e) {
    const value = replaceUnsafeSymbols(e.target.value);
    if (value.length <= 0) setDisplayArray(array);
    else setDisplayArray(array.filter((item) => item.title.toLowerCase().match(value?.toLowerCase())));
  }

  return (
    <div id={id} ref={customSelect} className={`custom-select ${disabled ? 'custom-select_disabled' : ''} ${loading ? 'upu__loading' : ''}`}>
       {(cancelChoice && choosedTitle !== 'Выберите вариант') && <img
         className="custom-select__cancel"
         onClick={cancelChoice}
         src="../../icons/cancel.svg"
         alt="cancel"
       />}
      <div className="custom-select__header" onClick={toggleSelect}>
        <span className={cancelChoice ? 'custom-select__title-cancel' : 'custom-select__title'} dangerouslySetInnerHTML={{ __html: choosedTitle }} />
        <img
          className={isActive ? 'custom-select__chevron custom-select__chevron_active' : 'custom-select__chevron'}
          onClick={toggleSelect}
          src="../../icons/chevron.svg"
          alt="chevron"
        />
      </div>

      <div className={dropDownClass}>
        {search && <Input onClick={(e) => e.stopPropagation()} onChange={localSearch} />}
        {(search ? dispayArray : array).map((row) => (
          <span
            key={row.id}
            className={activeOption.id === row.id ? 'custom-select__option custom-select__option_active' : 'custom-select__option'}
            onClick={() => optionHandler(row)}
            dangerouslySetInnerHTML={{ __html: row.title }}
            title={row.description}
          />
        ))}
      </div>
    </div>
  );
}

export default Select;
