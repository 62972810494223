import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { authorization } from '../../utils/functions/authenticate';
import { alertFunction } from '../../utils/functions/alertFunction';
import { headers } from './common';
import Button from '../../components/UI/Button/Button';
import TFoot from '../../components/Table/TFoot';
import InputSearch from '../../components/UI/InputSearch';
import Cap from '../Cap/Cap';

function AccessRecrutment() {
  const [access, setAccess] = useState([]);
  const [zgds, setZgds] = useState([]);
  const [gds, setGds] = useState([]);
  const [orps, setOrps] = useState([]);
  const [feos, setFeos] = useState([]);
  const [performers, setPerformers] = useState([]);
  const [newAccess, setNewAccess] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState(new Set()); // Для администраторов
  const [selectedPerformers, setSelectedPerformers] = useState(new Set()); // Для исполнителей
  const [selectedZgds, setSelectedZgds] = useState(new Set()); // Для заместителей генерального директора
  const [selectedGds, setSelectedGds] = useState(new Set()); // Для генерального директора
  const [selectedOrps, setSelectedOrps] = useState(new Set()); // Для отдела ресурсного планирования

  useEffect(() => {
    awaitRequests();
    getAccessData();
  }, []);

  async function getAccessData() {
    try {
      const reqData = { type: 'getAdminDataRecrutment' };
      const result = await clientSendData('POST', '/get_admin_data_recrutment', reqData);
      if (result) {
        const data = JSON.parse(result.data);
        setAccess(data.admins);
        setOrps(data.orp);
        setFeos(data.feo);
        setZgds(data.zgd);
        setGds(data.gd);
        setPerformers(data.performers);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataRecrutment');
    }
  }

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  const addNewAccess = async (array, setArray, newAccess) => {
    const elementExists = array.find((item) => item.id === newAccess[0].id && item.title === newAccess[0].title);
    if (!elementExists) {
      setArray((prevArray) => [
        ...prevArray,
        { id: newAccess[0].id, title: newAccess[0].title },
      ]);
      setNewAccess([]);
    } else {
      await alertFunction('sd_user_group_exist', 'client');
      setNewAccess([]);
    }
  };

  const deleteSelectedAccess = async (array, setArray, selectedUsers, setSelectedUsers, editFunction) => {
    const newArray = array.filter((item) => !selectedUsers.has(item.id));
    setArray(newArray);

    // Получаем массив объектов с id и title
    const accessData = newArray.map((item) => ({
      id: item.id,
      title: item.title,
    }));

    setSelectedUsers(new Set());
    await editFunction(accessData);
  };

  const editRecrutmentAdmins = async (accessData, endpoint, type) => {
    try {
      const reqData = {
        type,
        data: accessData, // Здесь передаем массив объектов с id и title
      };
      const result = await clientSendData('POST', endpoint, reqData);
      console.log(reqData);
      if (result.success) {
        await alertFunction('save_settings', 'client');
        await getAccessData();
      } else {
        await alertFunction('required_fields', 'client');
      }
    } catch (error) {
      catchHandler(error, type);
    }
  };

  async function searchWrapper(e) {
    try {
      setIsSearching(true);
      const result = await searchUserData(e.target.value);
      setAdditionalInfo(result);
    } catch (error) {
      catchHandler(error, 'searchWrapper');
    } finally {
      setIsSearching(false);
    }
  }

  async function searchUserData(query) {
    try {
      const reqData = { type: 'cvGetUserData' };
      const result = await clientSendData('POST', `/cv_get_user_data/?query=${query}`, reqData);
      if (result?.success) {
        return result.data || [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'cvGetUserData');
      return [];
    }
  }

  const chooseData = (id, selectedUsers, setSelectedUsers) => {
    const newSelected = new Set(selectedUsers);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedUsers(newSelected);
  };

  if (isAuthorized) {
    return (
      <ServiceBody>
        {/* Таблица для администраторов */}
        <Table id="admin_staff_cv">
          <THead title="Администраторы" headers={headers.access} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <InputSearch
                    id="new_access_admin"
                    array={additionalInfo}
                    onSearch={(e) => searchWrapper(e)}
                    onChoose={(user) => {
                      setNewAccess([{ id: user.id, title: user.title }]);
                    }}
                    placeholder="ФИ сотрудника"
                    defaultValue={newAccess?.title}
                    loading={isSearching}
                  />
                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    onClick={() => addNewAccess(access, setAccess, newAccess)}
                    style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                  >
                    Добавить
                  </Button>
                </div>
              </TData>
            </TRow>
            {access.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedAdmins.has(el.id)}
                    onChange={() => chooseData(el.id, selectedAdmins, setSelectedAdmins)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                access,
                setAccess,
                selectedAdmins,
                setSelectedAdmins,
                (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_admins', 'editRecrutmentAdmins'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    access,
    setAccess,
    selectedAdmins,
    setSelectedAdmins,
    (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_admins', 'editRecrutmentAdmins'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
        <br />
        {/* Таблица для исполнителей */}
        <Table id="admin_staff_cv">
          <THead title="Исполнители" headers={headers.access} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <InputSearch
                    id="new_access"
                    array={additionalInfo}
                    onSearch={(e) => searchWrapper(e)}
                    onChoose={(user) => {
                      setNewAccess([{ id: user.id, title: user.title }]);
                    }}
                    placeholder="ФИ сотрудника"
                    defaultValue={newAccess?.title}
                    loading={isSearching}
                  />
                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    onClick={() => addNewAccess(performers, setPerformers, newAccess)}
                    style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                  >
                    Добавить
                  </Button>
                </div>
              </TData>
            </TRow>
            {performers.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedPerformers.has(el.id)}
                    onChange={() => chooseData(el.id, selectedPerformers, setSelectedPerformers)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                performers,
                setPerformers,
                selectedPerformers,
                setSelectedPerformers,
                (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_performers', 'editRecrutmentPerformers'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    performers,
    setPerformers,
    selectedPerformers,
    setSelectedPerformers,
    (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_performers', 'editRecrutmentPerformers'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
        <br />
        {/* Таблица для заместителей генерального директора */}
        <Table id="admin_staff_cv">
          <THead title="Заместитель генерального директора" headers={headers.access} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <InputSearch
                    id="new_access_zgd"
                    array={additionalInfo}
                    onSearch={(e) => searchWrapper(e)}
                    onChoose={(user) => {
                      setNewAccess([{ id: user.id, title: user.title }]);
                    }}
                    placeholder="ФИ сотрудника"
                    defaultValue={newAccess?.title}
                    loading={isSearching}
                  />
                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    onClick={() => addNewAccess(zgds, setZgds, newAccess)}
                    style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                  >
                    Добавить
                  </Button>
                </div>
              </TData>
            </TRow>
            {zgds.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedZgds.has(el.id)}
                    onChange={() => chooseData(el.id, selectedZgds, setSelectedZgds)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                zgds,
                setZgds,
                selectedZgds,
                setSelectedZgds,
                (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_zgds', 'editRecrutmentZgd'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    zgds,
    setZgds,
    selectedZgds,
    setSelectedZgds,
    (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_zgds', 'editRecrutmentZgd'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
<br />
        {/* Таблица для генерального директора */}
        <Table id="admin_staff_cv">
          <THead title="Генеральный директор" headers={headers.access} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <InputSearch
                    id="new_access_gd"
                    array={additionalInfo}
                    onSearch={(e) => searchWrapper(e)}
                    onChoose={(user) => {
                      setNewAccess([{ id: user.id, title: user.title }]);
                    }}
                    placeholder="ФИ сотрудника"
                    defaultValue={newAccess?.title}
                    loading={isSearching}
                  />
                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    onClick={() => addNewAccess(gds, setGds, newAccess)}
                    style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                  >
                    Добавить
                  </Button>
                </div>
              </TData>
            </TRow>
            {gds.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedGds.has(el.id)}
                    onChange={() => chooseData(el.id, selectedGds, setSelectedGds)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                gds,
                setGds,
                selectedGds,
                setSelectedGds,
                (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_gds', 'editRecrutmentGd'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    gds,
    setGds,
    selectedGds,
    setSelectedGds,
    (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_gds', 'editRecrutmentGd'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
<br />
        {/* Таблица для отдела ресурсного планирования */}
        <Table id="admin_staff_cv">
          <THead title="Отдел ресурсного планирования" headers={headers.access} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <InputSearch
                    id="new_access_orp"
                    array={additionalInfo}
                    onSearch={(e) => searchWrapper(e)}
                    onChoose={(user) => {
                      setNewAccess([{ id: user.id, title: user.title }]);
                    }}
                    placeholder="ФИ сотрудника"
                    defaultValue={newAccess?.title}
                    loading={isSearching}
                  />
                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    onClick={() => addNewAccess(orps, setOrps, newAccess)}
                    style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                  >
                    Добавить
                  </Button>
                </div>
              </TData>
            </TRow>
            {orps.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedOrps.has(el.id)}
                    onChange={() => chooseData(el.id, selectedOrps, setSelectedOrps)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                orps,
                setOrps,
                selectedOrps,
                setSelectedOrps,
                (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_orps', 'editRecrutmentOrp'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    orps,
    setOrps,
    selectedOrps,
    setSelectedOrps,
    (ids) => editRecrutmentAdmins(ids, '/edit_recrutment_orps', 'editRecrutmentOrp'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
      </ServiceBody>
    // </div>
    );
  }
  return <Cap />;
}

export default AccessRecrutment;
