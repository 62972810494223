import React, { useEffect, useState } from 'react';
import ServiceBody from '../../components/Service/ServiceBody';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead';
import TBody from '../../components/Table/TBody';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { clientSendData } from '../../utils/functions/requests';
import { authorization } from '../../utils/functions/authenticate';
import { alertFunction } from '../../utils/functions/alertFunction';
import { headers } from './common';
import Button from '../../components/UI/Button/Button';
import TFoot from '../../components/Table/TFoot';
import InputSearch from '../../components/UI/InputSearch';
import Cap from '../Cap/Cap';
import Input from '../../components/UI/Input';

function CompanyRecrutment() {
  const [companies, setCompanies] = useState([]);
  const [trips, setTrips] = useState([]);
  const [dates, setDates] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [types, setTypes] = useState([]);
  const [modes, setModes] = useState([]);
  const [newAccess, setNewAccess] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [selectedTrips, setSelectedTrips] = useState(new Set());
  const [selectedDates, setSelectedDates] = useState(new Set());
  const [selectedContracts, setSelectedContracts] = useState(new Set());
  const [selectedTypes, setSelectedTypes] = useState(new Set());
  const [selectedModes, setSelectedModes] = useState(new Set());
  const [newCompanies, setNewCompanies] = useState({ title: '' }); // Для образования
  const [newTrip, setNewTrip] = useState({ title: '' }); // Для профиля
  const [newDate, setNewDate] = useState({ title: '' }); // Для программ
  const [newContract, setNewContract] = useState({ title: '' }); // Для специализаций

  useEffect(() => {
    awaitRequests();
    getAccessData();
  }, []);

  async function getAccessData() {
    try {
      const reqData = { type: 'getAdminDataRecrutment' };
      const result = await clientSendData('POST', '/get_admin_data_recrutment', reqData);
      if (result) {
        const data = JSON.parse(result.data);
        setCompanies(data.company);
        setContracts(data.contracts);
        setTrips(data.business_trip);
        setDates(data.closing_date);
      }
    } catch (error) {
      catchHandler(error, 'getAdminDataRecrutment');
    }
  }

  async function awaitRequests() {
    const checkAuthorization = await authorization();
    setIsAuthorized(checkAuthorization);
  }

  const addNewAccess = async (array, setArray, newItem) => {
    if (newItem.title) {
      setArray((prevArray) => [
        ...prevArray,
        { id: (prevArray.length + 1).toString(), title: newItem.title },
      ]);
      // Сбрасываем состояние после добавления
      if (newItem === newCompanies) setNewCompanies({ title: '' });
      if (newItem === newTrip) setNewTrip({ title: '' });
      if (newItem === newDate) setNewDate({ title: '' });
      if (newItem === newContract) setNewContract({ title: '' });
    } else {
      await alertFunction('sd_user_group_exist', 'client');
    }
  };

  const deleteSelectedAccess = async (array, setArray, selectedUsers, setSelectedUsers, editFunction) => {
    const newArray = array.filter((item) => !selectedUsers.has(item.id));
    setArray(newArray);

    // Получаем массив объектов с id и title
    const accessData = newArray.map((item) => ({
      id: item.id,
      title: item.title,
    }));

    setSelectedUsers(new Set());
    await editFunction(accessData);
  };

  const editRecrutmentAllEducations = async (accessData, endpoint, type) => {
    try {
      const reqData = {
        type,
        data: accessData, // Здесь передаем массив объектов с id и title
      };
      const result = await clientSendData('POST', endpoint, reqData);
      console.log(reqData);
      if (result.success) {
        await alertFunction('save_settings', 'client');
        await getAccessData();
      } else {
        await alertFunction('required_fields', 'client');
      }
    } catch (error) {
      catchHandler(error, type);
    }
  };

  async function searchWrapper(e) {
    try {
      setIsSearching(true);
      const result = await searchUserData(e.target.value);
      setAdditionalInfo(result);
    } catch (error) {
      catchHandler(error, 'searchWrapper');
    } finally {
      setIsSearching(false);
    }
  }

  async function searchUserData(query) {
    try {
      const reqData = { type: 'cvGetUserData' };
      const result = await clientSendData('POST', `/cv_get_user_data/?query=${query}`, reqData);
      if (result?.success) {
        return result.data || [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'cvGetUserData');
      return [];
    }
  }

  const chooseData = (id, selectedUsers, setSelectedUsers) => {
    const newSelected = new Set(selectedUsers);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedUsers(newSelected);
  };

  if (isAuthorized) {
    return (
      <ServiceBody>
        {/* Таблица для администраторов */}
        <Table id="admin_staff_cv">
          <THead title="Название компании" headers={headers.company} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="name"
                  type="text"
                  value={newCompanies.title}
                  onChange={(e) => setNewCompanies({ ...newCompanies, title: e.target.value })}
                  placeholder="Компания"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(companies, setCompanies, newCompanies)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {companies.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedCompanies.has(el.id)}
                    onChange={() => chooseData(el.id, selectedCompanies, setSelectedCompanies)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                companies,
                setCompanies,
                selectedCompanies,
                setSelectedCompanies,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_education', 'editRecrutmentEducation'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    companies,
    setCompanies,
    selectedCompanies,
    setSelectedCompanies,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_education', 'editRecrutmentEducation'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
        <br />

        {/* Таблица для заместителей генерального директора */}
        <Table id="admin_staff_cv">
          <THead title="Командировки" headers={headers.company} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="profile"
                  type="text"
                  value={newTrip.title}
                  onChange={(e) => setNewTrip({ ...newTrip, title: e.target.value })}
                  placeholder="Название"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(trips, setTrips, newTrip)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {trips.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedTrips.has(el.id)}
                    onChange={() => chooseData(el.id, selectedTrips, setSelectedTrips)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                trips,
                setTrips,
                selectedTrips,
                setSelectedTrips,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_profile', 'editRecrutmentProfile'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    trips,
    setTrips,
    selectedTrips,
    setSelectedTrips,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_profile', 'editRecrutmentProfile'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
<br />
        {/* Таблица для генерального директора */}
        <Table id="admin_staff_cv">
          <THead title="Дата закрытия" headers={headers.company} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="date"
                  type="text"
                  value={newDate.title}
                  onChange={(e) => setNewDate({ ...newDate, title: e.target.value })}
                  placeholder="Дата"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(dates, setDates, newDate)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {dates.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedDates.has(el.id)}
                    onChange={() => chooseData(el.id, selectedDates, setSelectedDates)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                dates,
                setDates,
                selectedDates,
                setSelectedDates,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_programms', 'editRecrutmentProgramms'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    dates,
    setDates,
    selectedDates,
    setSelectedDates,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_programms', 'editRecrutmentProgramms'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
<br />
        {/* Таблица для отдела ресурсного планирования */}
        <Table id="admin_staff_cv">
          <THead title="Планируемая продолжительность ТД" headers={headers.company} className="table-header" />
          <TBody>
            <TRow>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  id="contracts"
                  type="text"
                  value={newContract.title}
                  onChange={(e) => setNewContract({ ...newContract, title: e.target.value })}
                  placeholder="Продолжительность"
                />

                </div>
              </TData>
              <TData style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  onClick={() => addNewAccess(contracts, setContracts, newContract)}
                  style={{ backgroundColor: '#28B47D', color: 'white', marginLeft: '10px' }}
                >
  Добавить
                </Button>
                </div>
              </TData>
            </TRow>
            {contracts.map((el) => (
              <TRow key={el.id}>
                <TData className="fio-cell">{el.title}</TData>
                <TData className="checkbox-cell">
                  <input
                    type="checkbox"
                    checked={selectedContracts.has(el.id)}
                    onChange={() => chooseData(el.id, selectedContracts, setSelectedContracts)}
                  />
                </TData>
              </TRow>
            ))}
          </TBody>
          <TFoot>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => deleteSelectedAccess(
                contracts,
                setContracts,
                selectedContracts,
                setSelectedContracts,
                (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_specialities', 'editRecrutmentSpecialities'),
              )}
              style={{ backgroundColor: '#28B47D', color: 'white', marginRight: '10px' }}
            >
  Сохранить изменения
            </Button>
<Button
  onClick={() => deleteSelectedAccess(
    contracts,
    setContracts,
    selectedContracts,
    setSelectedContracts,
    (ids) => editRecrutmentAllEducations(ids, '/edit_recrutment_specialities', 'editRecrutmentSpecialities'),
  )}
  style={{ marginLeft: '10px' }}
>
  Удалить выбранные
</Button>
            </div>
          </TFoot>
        </Table>
      </ServiceBody>
    // </div>
    );
  }
  return <Cap />;
}

export default CompanyRecrutment;
