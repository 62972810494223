/* eslint-disable no-lonely-if */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Service from '../../components/Service/Service';
import ServiceBody from '../../components/Service/ServiceBody';
import Button from '../../components/UI/Button/Button';
import NewTaskCard from './NewTaskCard';
import TaskCard from './TaskCard';
import CheckBox from '../../components/UI/CheckBox';
import InputSearch from '../../components/UI/InputSearch';
import Input from '../../components/UI/Input';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import BulkFileUpload from '../../components/UI/FileUpload/BulkFileUpload';
import { alertFunction } from '../../utils/functions/alertFunction';

function Common({ project, setProject, getProject }) {
  const statuses = [
    { id: 1, title: 'В работе' },
    { id: 2, title: 'Завершен' },
  ];
  const [isEditing, setIsEditing] = useState(false);

  function newStatus(status) {
    const updatedProject = { ...project, status: status.title, status_id: status.id };
    // const updatedTask = { ...activeTask, status: title };
    updateStatus(updatedProject);
    setIsEditing(false);
    // saveNewItem(updatedTask, 'edit');
  }

  async function updateStatus(updatedProject) {
    try {
      const reqData = {
        type: 'clprtChangeItemStatus',
        itemType: 'project',
        item: updatedProject,
      };
      const result = await clientSendData('POST', '/clprt_change_item_status', reqData); // запрос в БД
      if (result) {
        getProject();
      }
    } catch (error) {
      catchHandler(error, 'clprtChangeItemStatus'); // обработка ошибок
    }
  }

  return (
    <div className="common-wrapper">
      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Статус:</p>
        <p className="active" onClick={() => setIsEditing(!isEditing)}>{project.status}</p>
        {isEditing && <div className="statuses">
          {statuses.map((el) => <p key={el.id} className="statuses-item" onClick={() => newStatus(el)}>{el.title}</p>)}
                      </div>}
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Название проекта:</p>
        <p>{project.project_name}</p>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Описание:</p>
        <p>{project.project_description}</p>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Менеджер проекта:</p>
        <p>{project.project_manager?.title}</p>
      </div>

      <div className="common-wrapper_line">
        <p className="common-wrapper_line_header">Дата создания:</p>
        <p>{project.date_created}</p>
      </div>
    </div>
  );
}

function ProjectStructure({
  setActiveSection,
  setActiveTask,
  template,
  setTemplate,
  newFolder,
  setNewFolder,
  getProjectStructure,
}) {
  const [createFolderOn, setCreateFolderOn] = useState(false);
  const alpha = [
    'А',
    'Б',
    'В',
    'Г',
    'Д',
    'е',
    'Е',
    'Ж',
    'З',
    'и',
    'й',
    'к',
    'л',
    'м',
    'н',
    'о',
    'п',
    'р',
    'с',
    'т',
    'у',
    'ф',
    'х',
    'ц',
    'ч',
    'ш',
    'щ',
    'ъ',
    'ы',
    'ь',
    'э',
    'ю',
    'я',
  ];
  function createFolder(e) {
    setNewFolder({ title: e.target.value, id: template.length + 1 });
  }

  async function addFolder() {
    template.push({
      parentId: 0,
      title: newFolder.title,
      id: newFolder.id,
      type: 'folder',
      section: alpha[template.filter((el) => el.parentId === 0).length],
    });
    setNewFolder({});
    setCreateFolderOn(false);
  }

  return (
    <div style={{ height: '70vh', overflow: 'auto' }}>
      <TasksFolders
        template={template}
        setTemplate={setTemplate}
        subTemplate={template.filter((el) => el.parentId === 0)}
        setActiveSection={setActiveSection}
        setActiveTask={setActiveTask}
        newFolder={newFolder}
        setNewFolder={setNewFolder}
        getProjectStructure={getProjectStructure}
      />
      {/* <div className="new-parent-folder">
        <p style={{ fontSize: '18px' }}>⤷</p>
        <p onClick={() => setCreateFolderOn(true)}>Добавить новую папку</p>
      </div>
      {createFolderOn && (
        <div className="create-folder">
          <textarea
            className="create-folder-line"
            onChange={(e) => createFolder(e)}
          />
          <p className="create-btn" onClick={addFolder}>
            ✔
          </p>
        </div>
      )} */}
    </div>
  );
}

function TasksFolders({
  template,
  setTemplate,
  taskFolder,
  setActiveSection,
  setActiveTask,
  newFolder,
  setNewFolder,
  subTemplate,
  getProjectStructure,
}) {
  const [openedFolders, setOpenedFolders] = useState([]);

  // const [createNewFolder, setCreateNewFolder] = useState({});
  const [parentItem, setParentItem] = useState({});
  // const activeFolder = template?.find((el) => el.id === createNewFolder.id);
  // const [newFolder, setNewFolder] = useState({ });
  const [itemType, setItemType] = useState(null);

  const toggleItem = (e, id) => {
    e.stopPropagation();
    setOpenedFolders(
      openedFolders.includes(id)
        ? openedFolders.filter((item) => item !== id)
        : [...openedFolders, id],
    );
  };

  // Начать создание задачи
  function addNewTask(folder) {
    setParentItem(folder);
    setItemType('task');
  }

  // создать папку
  function createFolder(e) {
    setNewFolder({
      title: e.target.value,
      id: template.length + 1,
      parentId: parentItem.id,
      section: `${parentItem.section}.${template.filter((el) => el.parentId === parentItem.id).length + 1}`,
      type: itemType,
    });
  }

  async function addNewItem() {
    await saveNewItem(newFolder, 'create');
    getProjectStructure();
    setOpenedFolders([...openedFolders, parentItem.id]);
    setParentItem({});
    setItemType(null);
    setNewFolder({});
  }

  // Удалить элемент структуры
  async function deleteItem(id) {
    const delFolder = template?.find((el) => el.id === id);
    delFolder.visibility = false;
    setOpenedFolders([openedFolders.filter((item) => item !== id)]);
  }
  // -----------------------------------------//
  function cancelCreation() {
    setParentItem({});
    setItemType(null);
    setNewFolder({});
  }
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const Id = useSearchParams()[0].get('projId'); // id ПО из url

  function setTaskOpen(taskId, taskSection) {
    setActiveSection('task');
    setSearchParams({ projId: Id, taskId, taskSection });
    setActiveTask(template.find((el) => el.id === taskId));
  }

  const projId = useSearchParams()[0].get('projId'); // id ПО из url;

  async function saveNewItem(newItem, action) {
    try {
      const reqData = {
        type: 'clprtSaveNewItem',
        newItem,
        projId,
        action,
      };
      const result = await clientSendData('POST', '/clprt_save_new_item', reqData); // запрос в БД
      if (result) {
        await alertFunction('save_settings', 'clientPost');
      }
    } catch (error) {
      catchHandler(error, 'clprtSaveNewItem'); // обработка ошибок
    }
  }

  return (
    <div className="folders-container">
      {subTemplate.length > 0
        && subTemplate?.map((folder, index) => (
          <div className="folder-wrapper" key={folder.section}>
            {/* Отображение основных папок */}
            {folder.type === 'folder' && (
              <div className="taskfolders_folder" key={folder.title}>
                <p className="taskfolders_folder_header">
                  🗁
                  {' '}
                  {folder.section}
                </p>
                <p
                  className="folder-title"
                  onClick={(e) => toggleItem(e, folder.id)}
                >
                  {folder.title}
                </p>
                <p
                  className="folder-add"
                  onClick={() => setParentItem(folder)}
                  title="Создть папку"
                >
                  +
                </p>
                <p
                  className="folder-delete"
                  title="Удалить элемент"
                  onClick={() => deleteItem(folder.id)}
                >
                  ⛌
                </p>
                {parentItem?.id === folder.id && (
                  <div id={folder.section} className="creation-options">
                    <p
                      className="option"
                      onClick={() => setItemType('subfolder')}
                    >
                      Папка
                    </p>
                    <p className="option" onClick={() => setItemType('task')}>
                      Задача
                    </p>
                  </div>
                )}
              </div>
            )}
            {/* Отображение вложенных папок папок */}
            {folder.type === 'subfolder' && folder.visibility !== false && (
              <div className="taskfolders_folder" key={folder.title}>
                <p className="taskfolders_folder_header">
                  🗀
                  {folder.section}
                </p>
                <p
                  className="folder-title"
                  onClick={(e) => toggleItem(e, folder.id)}
                >
                  {folder.title}
                </p>
                <p
                  className="folder-add"
                  onClick={() => setParentItem(folder)}
                >
                  +
                </p>
                <p
                  className="folder-delete"
                  title="Удалить элемент"
                  onClick={() => deleteItem(folder.id)}
                >
                  ⛌
                </p>
                {parentItem?.id === folder.id && (
                  <div id={folder.section} className="creation-options">
                    <p
                      className="option"
                      onClick={() => setItemType('subfolder')}
                    >
                      Папка
                    </p>
                    <p className="option" onClick={() => setItemType('task')}>
                      Задача
                    </p>
                  </div>
                )}
              </div>
            )}
            {/* Отображение задач внутри папок и подзадач внутри задач */}
            {folder.type === 'task' && folder.visibility !== false && (
              <div className="taskfolders_task" key={folder.title}>
                <p className="taskfolders_task_header">
                  {folder.section}
                </p>
                <p
                  className="folder-title"
                  onClick={() => setTaskOpen(folder.id, folder.section)}
                >
                  {folder.title}
                </p>
                <p className="folder-add" onClick={() => addNewTask(folder)}>
                +
                </p>
                <p
                  className="folder-delete"
                  title="Удалить элемент"
                  onClick={() => deleteItem(folder.id)}
                >
                  ⛌
                </p>
              </div>
            )}
            {(folder.type === 'task' && folder.visibility !== false) && (
              <div className="sub-section-wrapper">
                <TasksFolders
                  template={template}
                  subTemplate={template.filter((el) => el.parentId === folder.id)}
                  setTemplate={setTemplate}
                  taskFolder={folder.section}
                  setActiveSection={setActiveSection}
                  setActiveTask={setActiveTask}
                  newFolder={newFolder}
                  setNewFolder={setNewFolder}
                  getProjectStructure={getProjectStructure}
                />
              </div>
            )}

            {parentItem.id === folder.id && itemType === 'subfolder' && (
              <div className="create-folder">
                <textarea
                  key={folder.title}
                  className="create-folder-line"
                  onChange={(e) => createFolder(e)}
                />
                <p className="create-btn" onClick={addNewItem}>
                  ✔
                </p>
              </div>
            )}

            {openedFolders.includes(folder.id) && (
              <div className="sub-section-wrapper">
                <TasksFolders
                  template={template}
                  setTemplate={setTemplate}
                  subTemplate={template.filter((el) => el.parentId === folder.id)}
                  taskFolder={folder.section}
                  setActiveSection={setActiveSection}
                  setActiveTask={setActiveTask}
                  newFolder={newFolder}
                  setNewFolder={setNewFolder}
                  getProjectStructure={getProjectStructure}
                />
              </div>
            )}
          </div>
        ))}

      {parentItem?.id && itemType === 'task' && (
        <NewTaskCard
          setNewFolder={setNewFolder}
          newFolder={newFolder}
          addNewItem={addNewItem}
          parentItem={parentItem}
          template={template}
          cancelCreation={cancelCreation}
          getProjectStructure={getProjectStructure}
        />
      )}
    </div>
  );
}

function Documents({ projectId }) {
  const [documents, setDocuments] = useState([]);
  const editExtensions = ['doc', 'docx', 'xls', 'xlsx', 'csv', 'txt', 'pdf'];

  useEffect(() => {
  //  getDocuments();
    getProjectDocuments();
  }, []);

  async function getDocuments() {
    try {
      const reqData = {
        bucket: 'test2',
        type: 'clprtGetObjectsFromBucket',
      };
      const result = await clientSendData('POST', '/clprt_get_objects_from_bucket', reqData); // запрос в БД
      setDocuments(result.data.map((el, index) => ({ ...el, id: index })));
    } catch (error) {
      catchHandler(error, 'clprtGetObjectsFromBucket'); // обработка ошибок
    }
  }

  async function getProjectDocuments() {
    try {
      const reqData = {
        type: 'clprtGetProjDocuments',
        projId: projectId,
      };
      const result = await clientSendData('POST', '/clprt_get_proj_documents', reqData); // запрос в БД
      setDocuments(result.data);
    } catch (error) {
      catchHandler(error, 'clprtGetProjDocuments'); // обработка ошибок
    }
  }

  async function uploadDocuments(fls) {
    try {
      const reqData = {
        type: 'clprtPutObjectsToBucket',
        projectId,
        fl: fls,
      };
      const result = await clientSendData('POST', '/clprt_put_objects_to_bucket', reqData); // запрос в БД
      getProjectDocuments();
    } catch (error) {
      catchHandler(error, 'clprtPutObjectsToBucket'); // обработка ошибок
    }
  }

  function fileHandler(files) {
    for (const file of files) {
      const reader = new FileReader(); // API чтения файлов
      reader.onloadend = (e) => {
        uploadDocuments({ path: e.target.result, name: file.name, type: file.type });
      };
      reader.readAsDataURL(file); // Прочитать как DataURL ("data:image/png;base64,iVBORw...");
    }
  }

  async function deleteDocs() {
    try {
      const reqData = {
        type: 'clprtDeleteObjectsFromBucket',
        projectId,
        chosenDocs,
      };
      const result = await clientSendData('POST', '/clprt_delete_objects_from_bucket', reqData); // запрос в БД
      getProjectDocuments();
    } catch (error) {
      catchHandler(error, 'clprtDeleteObjectsFromBucket'); // обработка ошибок
    }
  }

  const [chosenDocs, setChosenDocs] = useState([]);
  function collectDocuments(id) {
    if (chosenDocs.find((el) => el === id)) {
      setChosenDocs(chosenDocs.filter((el) => el !== id));
    } else setChosenDocs([...chosenDocs, id]);
  }

  return (
    <>
      <div className="docs-panel">
        <div className="docs-panel-item">
          ➕
           <p>Новый файл</p>
        </div>
        <div className="docs-panel-item">
          <img src="../../icons/upload.png" alt="upload" />
          <BulkFileUpload
            handler={fileHandler}
          >
              <img className="ticket-send__attach" src="../../icons/button/paperclip.svg" alt="paperclip" title="Добавить вложение" />
            <p>Загрузить файл</p>
          </BulkFileUpload>
        </div>
        {chosenDocs.length > 0 && <>
          <div className="docs-panel-item">
        🢃
          <p>Скачать</p>
          </div>
        <div className="docs-panel-item">
        🗑️
          <p onClick={deleteDocs}>Удалить</p>
        </div>
                                  </>}

      </div>
      <div className="docs-wrapper">
        {documents.map((doc, index) => (
          <div className="docs-wrapper_line" key={doc.id}>
            <CheckBox onChange={() => collectDocuments(doc.id)} />
            <img src="../../icons/document.png" alt="document" />
            <div className="doc-item">
              <p className="doc-item_title">
                {editExtensions.includes(doc.extension) ? <Link to={`/document_editor/${doc.id}`}>{doc.title}</Link> : doc.title}
              </p>
              <div className="doc-item_data">
                <p>{doc.author}</p>
                <span className="splitter" />
                <p>{`Кому назначена: ${'исполнитель'}`}</p>
                <span className="splitter" />
                <p>20.02.2025</p>
                <p style={{ display: 'flex', marginLeft: 'auto', marginRight: '5px' }}>
                  {doc.task_title}
                </p>
              </div>

            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function Team({ team, projectId, getProject }) {
  const roles = ['участник', 'клиент', 'менеджер проекта'];
  const sortlist = ['Крайний срок', 'Дата создания', 'Дата начала', 'Приоритет', 'Название'];
  const [isAdding, setIsAdding] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectUsers, setSelectUsers] = useState({});
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      const reqData = {
        type: 'getUsersPortal',
      };
      const result = await clientSendData('POST', '/get_users_portal', reqData); // запрос в БД
      if (result) {
        setEmployees(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getUsersPortal'); // обработка ошибок
    }
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'clientsPortalSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData(
          'POST',
          '/clients_portal_search_user',
          reqData,
        );
        setSelectUsers({
          id: e.target.value,
          title: e.target.value,
          mail: e.target.value,
        });
        if (result?.success) setEmployees(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'clientsPortalSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  // Добавление пользователей
  async function addEmployees(role) {
    if (Object.keys(selectUsers).length > 0) {
      if (team.filter((el) => el.id === selectUsers.id).length === 0) {
        const updatedTeam = [...team, { ...selectUsers, role }];
        updateTeam(updatedTeam);
        setIsAdding(false);
        setSelectUsers({});
      }
    }
  }

  // Удалить участника проекта
  async function deleteMember(id) {
    const allMembers = team;
    const filteredMembers = allMembers.filter((el) => el.id !== id);
    updateTeam(filteredMembers);
  }

  async function updateTeam(updatedTeam) {
    try {
      const reqData = {
        type: 'clprtUpdateTeam',
        updatedTeam,
        projectId,
      };
      const result = await clientSendData('POST', '/clprt_update_team', reqData); // запрос в БД
      if (result.success) {
        getProject();
      }
    } catch (error) {
      catchHandler(error, 'clprtUpdateTeam'); // обработка ошибок
    }
  }

  return (
    <div className="common-wrapper_team">
      {team.length > 0 && team.map((member) => (
        <div className="teamline" key={member.id}>
          <p>{member.title}</p>
          <span style={{
            width: '150px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
          }}
          >
          <p className="role">{member.role}</p>
          <p style={{ cursor: 'pointer' }} onClick={() => deleteMember(member.id)}>⛌</p>
          </span>
        </div>
      ))}
      <span>
      <div className="new-parent-folder" onClick={() => setIsAdding(!isAdding)}>
        <p style={{ fontSize: '18px' }}>⤷</p>
        <p>Добавить участника проекта</p>
      </div>
    {isAdding && <InputSearch
      array={employees}
      onSearch={(e) => searchWrapper(e)}
      onChoose={(user) => setSelectUsers({ id: user.id, title: user.title, mail: user.mail })}
      defaultValue={selectUsers?.title}
    />}
      {Object.keys(selectUsers).length > 0 && <div className="statuses">
          {roles.map((el) => <p className="statuses-item" onClick={() => addEmployees(el)}>{el}</p>)}
                                              </div>}
      </span>
    </div>
  );
}

function ProjectTasks({
  projectTasks, setActiveSection, setActiveTask, tableState, setTableState,
}) {
  const Id = useSearchParams()[0].get('projId'); // id ПО из url
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [isSort, setIsSort] = useState(false);
  const [search, setSearch] = useState('');
  const sortlist = [
    { title: 'Крайний срок', field: 'date_end' },
    { title: 'Дата создания', field: 'date_created' },
    { title: 'Дата начала', field: 'date_start' },
    { title: 'Приоритет', field: 'priority' },
    { title: 'Название', field: 'title' },
  ];

  function setTaskOpen(taskSection, taskId) {
    setActiveSection('task');
    setSearchParams({ projId: Id, taskId, taskSection });
    setActiveTask(projectTasks.find((el) => el.id === taskId));
  }

  function fieldSorting(opt) {
    setTableState({ ...tableState, sort: { field: opt.field, direction: 'desc', title: opt.title } });
    setIsSort(!isSort);
  }

  function addSearch() {
    setTableState({ ...tableState, searchData: [...tableState.searchData, search] });
    setSearch('');
    document.getElementById('clprt_filter').value = '';
  }

  return (
    <>
        <div className="clprt_filter">
<p style={{ fontSize: '13px' }}>Фильтр</p>
<div className="clprt_filter-input">
{tableState.searchData.map((el) => <p style={{
  border: '1px solid #d4e4ec',
  background: '#edf6fd',
  padding: '0px 2px',
  borderRadius: '3px',
  color: '#1a6db3',
}}
>
{el}
                                   </p>)}
  <p className="input-add" onClick={addSearch}>+</p>
  <input
    placeholder="введите запрос"
    id="clprt_filter"
    name="filter"
  //  onChange={(e) => setTableState({ ...tableState, data: e.target.value })}
    onChange={(e) => setSearch(e.target.value)}
  />
  <img src="../../icons/button/search.svg" alt="search" width="16px" height="18px" style={{ marginLeft: 'auto' }} />
</div>
<div className="sort">
  Сортировать по:
{' '}
<p className="sort-option" onClick={() => setIsSort(!isSort)}>
{tableState.sort.title}
{' '}
↴
</p>
<p
  style={{
    marginLeft: '10px', cursor: 'pointer', transform: tableState.sort?.direction === 'desc' ? 'rotate(180deg)' : 'none', transition: '0.2s',
  }}
  onClick={() => setTableState({ ...tableState, sort: { ...tableState.sort, direction: tableState.sort.direction === 'desc' ? 'asc' : 'desc' } })}
>
▲
</p>
</div>
    {isSort && <div className="sort-list">
    {sortlist.map((opt) => <p className="sort-list-item" onClick={() => fieldSorting(opt)}>{opt.title}</p>)}
               </div> }
        </div>
    <div className="common-wrapper_tasks">

      <div className="tasks-header">
        <p style={{ width: '500px' }}>название</p>
        <p>дата отправки запроса</p>
        <p>ожидаемая дата получения ответа по запросу</p>
        <p>статус</p>
        <p>инициатор</p>
        <p>исполнитель</p>
      </div>
      {projectTasks.map((task) => (
        <td className="taskline" key={task.title}>
          <p
            title={task.title}
            onClick={() => setTaskOpen(task.section, task.id)}
            style={{
              width: '490px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: '500px', cursor: 'pointer',
            }}
          >
          {task.title}
          </p>
         <p style={{
           fontSize: '10px', paddingTop: '3px', color: 'grey', paddingLeft: 'auto',
         }}
         >
          {task.date_start}
         </p>
         <p style={{ fontSize: '10px', paddingTop: '3px', color: '#C80018' }}>{task.date_end}</p>
          <p style={{ fontSize: '13px', color: '#878484' }}>{task.status.toLowerCase()}</p>
          <p className="taskline-inDuty">Инициаторов инициатор инициаторович</p>
        {'in_duty' in task ? task?.in_duty.map((person) => <p className="taskline-inDuty">{person.title}</p>)
          : <p className="taskline-inDuty">Без ответственного</p>}
        </td>
      ))}
    </div>
    </>
  );
}

function ProjectPage({ projectId }) {
  const [activeSection, setActiveSection] = useState('tasksFolders');
  const [searchParams, setSearchParams] = useSearchParams(''); // параметры поиска url
  const [activeTask, setActiveTask] = useState(null);
  const [template, setTemplate] = useState([]);
  const [project, setProject] = useState({});
  const [team, setTeam] = useState([]);
  const [newFolder, setNewFolder] = useState({});
  const [projectTasks, setProjectTasks] = useState([]);
  const defaultTableState = {
    page: 1,
    limit: 50, // количество строк в таблице
    sort: { field: 'id', direction: 'asc', title: 'Название' },
    searchData: [],
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const taskId = useSearchParams()[0].get('taskId'); // id ПО из url;

  useEffect(() => {
    // getProjectStructure();
    getProject();
  }, []);

  useEffect(() => {
    getProjectStructure();
  }, [tableState]);

  async function getProjectStructure() {
    try {
      const reqData = {
        type: 'getProjectStructure',
        projectId,
        tableState,
      };
      const result = await clientSendData('POST', '/get_project_structure', reqData); // запрос в БД
      if (result.data) {
        setTemplate(result.data);
        setProjectTasks(result.data.filter((el) => el.type === 'task'));
      }
    } catch (error) {
      catchHandler(error, 'getProjectStructure'); // обработка ошибок
    }
  }

  async function getProject() {
    try {
      const reqData = {
        type: 'clprtGetProject',
        projectId,
      };
      const result = await clientSendData('POST', '/clprt_get_project', reqData); // запрос в БД
      if (result.data) {
        setProject(result.data);
        setTeam(result.data.project_team);
      }
    } catch (error) {
      catchHandler(error, 'getProjectStructure'); // обработка ошибок
    }
  }

  const renderSection = () => {
    switch (activeSection) {
      case 'common':
        return <Common project={project} setProject={setProject} getProject={getProject} />;
      case 'tasksFolders':
        return (
          <ProjectStructure
            setActiveSection={setActiveSection}
            setActiveTask={setActiveTask}
            template={template}
            setTemplate={setTemplate}
            newFolder={newFolder}
            setNewFolder={setNewFolder}
            getProjectStructure={getProjectStructure}
          />
        );
      case 'projDocs':
        return <Documents projectId={projectId} />;
      case 'projTeam':
        return <Team team={team} projectId={projectId} getProject={getProject} />;
      case 'projectTasks':
        return <ProjectTasks
          projectTasks={projectTasks}
          setActiveSection={setActiveSection}
          setActiveTask={setActiveTask}
          tableState={tableState}
          setTableState={setTableState}
        />;
      default:
        return (
          <ProjectStructure
            setActiveSection={setActiveSection}
            setActiveTask={setActiveTask}
            template={template}
            setTemplate={setTemplate}
            newFolder={newFolder}
            setNewFolder={setNewFolder}
            getProjectStructure={getProjectStructure}
          />
        );
    }
  };

  function onBackClick() {
    setSearchParams('');
  }

  if (activeSection === 'task' && taskId) {
    return (
      <TaskCard
        activeTask={activeTask}
        setActiveTask={setActiveTask}
        setActiveSection={setActiveSection}
        template={template}
        setTemplate={setTemplate}
        newFolder={newFolder}
        setNewFolder={setNewFolder}
        getProjectStructure={getProjectStructure}
      />
    );
  }
  return (
    <Service id="projectPage">
      <ServiceBody>
        <div className="proj-wrapper">
          <Button
            onClick={onBackClick}
            className="text-button"
            style={{ marginLeft: '-8px', fontWeight: 'bold' }}
          >
            <div>
              <span className="arrow">←</span>
              Вернуться на главную
            </div>
          </Button>

          <div className="proj-title">{project?.project_name}</div>
          <div className="project-panel">
            <p
              className={activeSection === 'common' ? 'tab_active' : 'tab'}
              onClick={() => setActiveSection('common')}
            >
              Общие сведения
            </p>
            <span className="splitter" />
            <p
              className={
                activeSection === 'tasksFolders' ? 'tab_active' : 'tab'
              }
              onClick={() => setActiveSection('tasksFolders')}
            >
              Структура проекта
            </p>
            <span className="splitter" />
            <p
              className={activeSection === 'projDocs' ? 'tab_active' : 'tab'}
              onClick={() => setActiveSection('projDocs')}
            >
              Документы
            </p>
            <span className="splitter" />
            <p
              className={activeSection === 'projTeam' ? 'tab_active' : 'tab'}
              onClick={() => setActiveSection('projTeam')}
            >
              Команда
            </p>

            <span className="splitter" />
            <p
              className={activeSection === 'projectTasks' ? 'tab_active' : 'tab'}
              onClick={() => setActiveSection('projectTasks')}
            >
              Задачи (
{projectTasks.length}
)
            </p>
          </div>

          {/* ТЕКУЩИЙ РАЗДЕЛ ПРОЕКТА */}
          {renderSection()}
        </div>
      </ServiceBody>
    </Service>
  );
}

export default ProjectPage;
