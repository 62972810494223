import React, { useEffect, useState } from 'react';
import Button from '../../components/UI/Button/Button';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import Select from '../../components/UI/Select';
import InputSearch from '../../components/UI/InputSearch';
import { NPF_template } from './template';
import { alertFunction } from '../../utils/functions/alertFunction';

function NewProjectCard1(props) {
  const { setShowCreateCard, getProjects } = props;

  const [tasks, setTasks] = useState([]);
  const templates = [
    { id: 1, title: 'НПФ', type: 'npf' },
    { id: 2, title: 'Банки', type: 'bank' },
    { id: 3, title: 'Страховые', type: 'security' },
    { id: 4, title: 'Самостоятельно', type: 'basic' },
  ];
  const [newProjectData, setNewProjectData] = useState({
    project_name: '',
    project_description: '',
    project_manager: {},
    project_team: [],
    status: 'В работе',
  });
  const [projStructure, setProjStructure] = useState([]);
  const [isSearching, setIsSearching] = useState(false); // состояние загрузки при поиске
  const [users, setUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState({});
  const [participiants, setParticipiants] = useState([]); // участники команды
  const [isAdding, setIsAdding] = useState(false);
  const [schemaType, setSchemaType] = useState('');

  useEffect(() => {
    getTasks();
    getUsers();
  }, []);

  // Получить номера заданий из аск
  async function getTasks() {
    try {
      const reqData = {
        type: 'getPODFTtasks',
      };
      const result = await clientSendData('POST', '/get_podft_tasks', reqData); // запрос в БД
      if (result.data) {
        setTasks(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getPODFTtasks'); // обработка ошибок
    }
  }
  // Пользователи для выбора
  async function getUsers() {
    try {
      const reqData = {
        type: 'getUsersPortal',
      };
      const result = await clientSendData('POST', '/get_users_portal', reqData); // запрос в БД
      if (result) {
        setUsers(result.data);
      }
    } catch (error) {
      catchHandler(error, 'getUsersPortal'); // обработка ошибок
    }
  }

  async function searchWrapper(e) {
    setIsSearching(true); // поиск выполняется
    if (e.target.value.length === 0) setSelectUsers((prev) => ({ ...prev }));
    try {
      if (e.target.value.length > 2) {
        const reqData = {
          type: 'clientsPortalSearchUser',
          query: e.target.value,
        };
        const result = await clientSendData(
          'POST',
          '/clients_portal_search_user',
          reqData,
        );
        setSelectUsers({
          id: e.target.value,
          title: e.target.value,
          mail: e.target.value,
        });
        if (result?.success) setUsers(result.data);
        setIsSearching(false);
        return [];
      }
      return [];
    } catch (error) {
      catchHandler(error, 'clientsPortalSearchUser');
      setIsSearching(false);
      return [];
    }
  }

  // Добавление пользователей в команду проекта
  async function addParticipiants(participiant) {
    if (Object.keys(participiant).length > 0) {
      if (
        participiants.filter((el) => el.id === participiant.id).length === 0
      ) {
        setParticipiants((state) => [...state, participiant]);
        const newUsers = newProjectData.project_team;
        newUsers.push({
          id: participiant.id,
          title: participiant.title,
          mail: participiant.mail,
          role: 'участник',
        });
        setNewProjectData((state) => ({ ...state, project_team: newUsers }));
        setSelectUsers({});
      }
    }
  }

  function deleteInduty(id) {
    const allUsers = participiants;
    const filteredUsers = allUsers.filter((el) => el.id !== id);
    setParticipiants(filteredUsers);
  }

  function chooseProjectSchema(schema) {
    setSchemaType(schema.title);
    if (schema.type === 'npf') {
      setProjStructure(NPF_template);
    } else if (schema.type === 'basic') {
      setProjStructure([
        {
          parentId: 0,
          id: 1,
          section: 'А',
          title: 'Планирование',
          type: 'folder',
        },
        {
          parentId: 0,
          id: 2,
          section: 'Б',
          title: 'Аудиторские процедуры на уровне предпосылок',
          type: 'folder',
        },
        {
          parentId: 0,
          id: 3,
          section: 'В',
          title: 'Общие аудиторские процедуры',
          type: 'folder',
        },
        {
          parentId: 0,
          id: 4,
          section: 'Г',
          title: 'Завершение аудита',
          type: 'folder',
        },
      ]);
    }
  }

  async function saveNewProject() {
    try {
      const reqData = {
        type: 'clprtSaveNewProject',
        newProjectData,
        projStructure,
      };
      const result = await clientSendData('POST', '/clprt_save_new_project', reqData); // запрос в БД
      if (result.data) {
        await alertFunction('save_settings', 'clientPost');
        getProjects();
        setShowCreateCard(false);
      }
    } catch (error) {
      catchHandler(error, 'clprtSaveNewProject'); // обработка ошибок
    }
  }

  return (
    <div id="new-project-card">
        <div className="task-card_background" />
              <div className="task-card">
                <p className="task-card_header">
                  Создать новый проект
                  <p className="close" onClick={() => setShowCreateCard(false)}>x</p>
                </p>

                <div className="task-card_container">
                {/* Название */}
                <span className="task-card_inputs">
                    <p className="title">Название*</p>
                    <textarea
                      className="create-folder-line"
                      onChange={(e) => setNewProjectData({
                        ...newProjectData,
                        project_name: e.target.value,
                      })
                      }
                    />
                </span>
                {/* Описание */}
                <span className="task-card_inputs">
                    <p className="title">Описание</p>
                    <textarea
                      className="create-folder-line"
                      onChange={(e) => setNewProjectData({
                        ...newProjectData,
                        project_description: e.target.value,
                      })
                      }
                    />
                </span>
                {/* Номер задания из АСК */}
                <span className="task-card_inputs" style={{ marginBottom: '15px' }}>
                    <p className="title">Номер задания из АСК*</p>
                    <Select
                      id="ASK_tasks"
                      search
                      array={tasks}
                      defaultValue={newProjectData?.task1from_number}
                      onChoose={(e) => setNewProjectData({
                        ...newProjectData,
                        task1from_number: e.id,
                        task1form_title: e.task_title,
                      })}
                    />
                </span>
                {/* Менеджер проекта */}
                <span className="task-card_inputs" style={{ marginBottom: '15px' }}>
                    <p className="title">Менеджер проекта*</p>
                    <Select
                      id="manager"
                      array={users}
                      onChoose={(e) => setNewProjectData({
                        ...newProjectData,
                        project_manager: {
                          id: e.id, title: e.title, role: 'менеджер проекта', mail: e.mail,
                        },
                        project_team: [...newProjectData.project_team,
                          {
                            id: e.id, title: e.title, role: 'менеджер проекта', mail: e.mail,
                          }],
                      })
            }
                      defaultValue={
              newProjectData?.project_manager.title
              || 'Назначьте менеджера проекта'
            }
                      search
                    />
                </span>
                {/* Команда */}
                <span className="task-card_inputs" style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
                    {/* <p className="title">Команда*</p> */}
                    <span className="data-container" style={{ marginBottom: '5px' }}>
                    <p className="title">Команда*</p>
                    <p className="action-btn" onClick={() => setIsAdding(true)}>добавить</p>
                    {isAdding
                   && <span className="add-induty">
                   <InputSearch
                     array={users}
                     onSearch={(e) => searchWrapper(e)}
                   //  onChoose={(user) => addEmployees(user)}
                     onChoose={(e) => addParticipiants(e)}
                     defaultValue={selectUsers?.title}
                     placeholder="Введите пользователя Юникона"
                   />
                   <ul className="employees-list">
                    {participiants.map((user) => <li key={user.id}>
                     {user.title}
                      <p style={{ width: '10px', cursor: 'pointer' }} onClick={() => deleteInduty(user.id)}>x</p>
                                                 </li>)}
                   </ul>
                   <div
                     style={{
                       display: 'flex', flexDirection: 'row', gap: '15px', marginTop: 'auto', justifyContent: 'space-between',
                     }}
                   >
                    <p className="comment-line_btn" onClick={() => setIsAdding(false)}>Сохранить</p>
                    <p className="comment-line_btn" onClick={() => setIsAdding(false)}>Отмена</p>
                   </div>
                      </span>
                   }
                    </span>
                </span>
                {/* Шаблон проекта */}
                <span className="task-card_inputs" style={{ marginBottom: '15px' }}>
                    <p className="title">Как создать проект?*</p>
                    <Select
                      id="template"
                      array={templates}
                      onChoose={(e) => chooseProjectSchema(e)}
                      defaultValue={schemaType}
                    />
                </span>
                <span className="data-container">
                    <p className="data-container_title">Дата начала:</p>
                    <input type="date" onChange={(e) => setNewProjectData({ ...newProjectData, date_start: e.target.value })} />
                </span>
                  <span className="data-container">
                    <p className="data-container_title">Крайний срок:</p>
                    <input type="date" onChange={(e) => setNewProjectData({ ...newProjectData, date_end: e.target.value })} />
                  </span>
                </div>
                <div className="footer">
                  <Button onClick={saveNewProject}>Сохранить</Button>
                </div>
              </div>
    </div>
  );
}

export default NewProjectCard1;
