export const orderedKeys = [
  'Ф.И.О.',
  'Занимаемая должность',
  'Подразделение',
  'Стаж работы в компании',
  'Дата рождения',
  'Адрес электронной почты',
  'Образование и профессиональная квалификация',
  'Знание иностранных языков',
  'Аттестаты и сертификаты',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного аттестата аудитора',
  'Наличие квалификационного аттестата главного бухгалтера',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера коммерческой организации',
  'Номер квалификационного аттестата ИПБ профессионального финансового директора',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера – эксперта в области внутреннего контроля',
  'Номер квалификационного сертификата налогового консультанта',
  'Стаж финансово-экономической деятельности',
  'Стаж юридической деятельности',
  'Стаж оценочной деятельности',
  'Сведения о трудовой деятельности',
  'Ключевые компетенции',
  'Участие в профессиональных сообществах',
  'Основные проекты',
  'Достоверность сведений, представленных в резюме, подтверждаю',
];

// Определите массив ключей, для которых редактирование запрещено
export const disabledFields = [
  'Ф.И.О.',
  'Занимаемая должность',
  'Подразделение',
  'Стаж работы в компании',
  'Дата рождения',
  'Адрес электронной почты',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного сертификата налогового консультанта',
  'Наличие квалификационного аттестата главного бухгалтера',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера коммерческой организации',
  'Номер квалификационного аттестата ИПБ профессионального финансового директора',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера – эксперта в области внутреннего контроля',
];

export const specialKey = [
  'Сведения о трудовой деятельности',
  'Образование и профессиональная квалификация',
  'Основные проекты',
  'Достоверность сведений, представленных в резюме, подтверждаю',
  'Ключевые компетенции',
  'Знание иностранных языков',
  'Аттестаты и сертификаты',
  'Участие в профессиональных сообществах',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного аттестата аудитора',
  'Номер квалификационного сертификата налогового консультанта',
  'Наличие квалификационного аттестата главного бухгалтера',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера коммерческой организации',
  'Номер квалификационного аттестата ИПБ профессионального финансового директора',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера – эксперта в области внутреннего контроля',
  'Стаж финансово-экономической деятельности',
  'Стаж юридической деятельности',
  'Стаж оценочной деятельности',
  'Сведения о трудовой деятельности',
];

export const topBorderKey = [
  'Образование и профессиональная квалификация',
  'Сведения о трудовой деятельности',
  'Ключевые компетенции',
  'Участие в профессиональных сообществах',
  'Достоверность сведений, представленных в резюме, подтверждаю',
  'Знание иностранных языков',
  'Аттестаты и сертификаты',
  'Участие в профессиональных сообществах',
  'Номер квалификационного сертификата аудитора',
  'Номер квалификационного аттестата аудитора',
  'Номер квалификационного сертификата налогового консультанта',
  'Наличие квалификационного аттестата главного бухгалтера',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера коммерческой организации',
  'Номер квалификационного аттестата ИПБ профессионального финансового директора',
  'Номер квалификационного аттестата ИПБ профессионального главного бухгалтера – эксперта в области внутреннего контроля',
  'Стаж финансово-экономической деятельности',
  'Стаж юридической деятельности',
  'Стаж оценочной деятельности',
  'Сведения о трудовой деятельности',
  'Основные проекты',
];

export const filtersData = {
  certificate: [
    { id: 1, title: 'ACCA', value: false },
    { id: 2, title: 'САР', value: false },
    { id: 3, title: 'CFA', value: false },
    { id: 4, title: 'CIMA', value: false },
    { id: 5, title: 'СIРA', value: false },
    { id: 6, title: 'CISA', value: false },
    { id: 7, title: 'CIA', value: false },
    { id: 8, title: 'СРА', value: false },
    { id: 9, title: 'FRM', value: false },
    { id: 10, title: 'ICFM', value: false },
    { id: 11, title: 'MBA', value: false },
    { id: 12, title: 'АНК', value: false },
    { id: 13, title: 'БАА', value: false },
    { id: 14, title: 'ЕАА', value: false },
    { id: 15, title: 'ЕАА new', value: false },
    { id: 16, title: 'ДипИФР', value: false },
    { id: 17, title: 'ДипНРФ', value: false },
    { id: 18, title: 'КА "Оценка бизнеса"', value: false },
    { id: 19, title: 'КА «Оценка движимого имущества»', value: false },
    { id: 20, title: 'КА «Оценка недвижимости»', value: false },
    { id: 21, title: 'КА ИПБ главбух', value: false },
    { id: 22, title: 'КА ИПБ главбух – эксперта в области внутреннего контроля', value: false },
    { id: 23, title: 'КА ИПБ финдир', value: false },
    { id: 24, title: 'КА Минфина РФ по банковскому аудиту', value: false },
    { id: 25, title: 'КСА', value: false },
    { id: 26, title: 'ОАА', value: false },
    { id: 27, title: 'САА', value: false },
    { id: 28, title: 'Свидетельство АНО "ИППК ПБИА" по программе ПОД/ФТ', value: false },
  ],
};
