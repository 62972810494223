import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import moment from 'moment-timezone'; // Import moment-timezone instead of moment
// eslint-disable-next-line
import 'moment-timezone/builds/moment-timezone-with-data'; // Import moment-timezone data
import './tvpanel.scss'; // импортируем файл со стилями
import QRCode from 'qrcode.react';
import WeatherWidget from './WeatherWidget';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import { authorization } from '../../utils/functions/authenticate';
import Cap from '../Cap/Cap';

/**
* @component Информационная панель
*/
function TVPanel() {
  const [dataTV, setDataTV] = useState({
    runningText: '',
    videoLink: '',
    cities: [{
      name: '', timezone: '', visible: 'false', id: '',
    }],
    qrCodeLink: '',
  });
  const [now, setNow] = useState(moment().format('DD.MM.YY'));
  const [time, setTime] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState(dataTV?.qrCodeLink);
  const [currentCityIndex, setCurrentCityIndex] = useState(0); // индекс первого города
  const [runningText, setRunningText] = useState(dataTV?.runningText);
  const [videoLink, setVideoLink] = useState(dataTV?.videoLink);
  const [cities, setCities] = useState(dataTV?.cities);
  const [isAuthorized, setIsAuthorized] = useState(false); // Состояние авторизации

  useEffect(() => {
    awaitRequests();
  }, []);

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  let cityIntervalId = null;

  useEffect(() => {
    getDataForTVPanel();
    const intervalId = setInterval(() => {
      if (cities[currentCityIndex].visible) {
        const currentTime = moment.tz(cities[currentCityIndex].timezone).format('HH:mm'); // использование текущего индекса для получения времени
        setTime(currentTime);
      }
    }, 3000);

    cityIntervalId = setInterval(() => {
      let nextCityIndex = (currentCityIndex + 1) % cities.length;
      while (!cities[nextCityIndex].visible) {
        nextCityIndex = (nextCityIndex + 1) % cities.length;
      }
      setCurrentCityIndex(nextCityIndex); // обновление индекса города
    }, 30000); // 10 секунд

    return () => {
      clearInterval(intervalId);
      clearInterval(cityIntervalId);
    };
  }, [currentCityIndex, setCurrentCityIndex, time]);

  async function getDataForTVPanel() {
    try {
      const reqData = {
        type: 'getTVPanelData',
      };
      const result = await clientSendData('POST', '/get_tv_panel_data', reqData); // запрос в БД
      if (result) {
        const obj = JSON.parse(result.data.value);
        const storedData = localStorage.getItem('tvPanelData');
        const storedDataObj = storedData ? JSON.parse(storedData) : {};

        setRunningText(obj.runningText !== storedDataObj.runningText ? obj.runningText : storedDataObj.runningText);
        setVideoLink(obj.videoLink !== storedDataObj.videoLink ? obj.videoLink : storedDataObj.videoLink);
        setCities(JSON.stringify(obj.cities) !== JSON.stringify(storedDataObj.cities) ? obj.cities : storedDataObj.cities);
        setQrCodeUrl(obj.qrCodeLink !== storedDataObj.qrCodeLink ? obj.qrCodeLink : storedDataObj.qrCodeLink);

        localStorage.setItem('tvPanelData', JSON.stringify(obj));
      }
    } catch (error) {
      catchHandler(error, 'getTVPanelData'); // обработчик ошибок
    }
  }

  if (isAuthorized) {
    return (
    <div className="containertv">
      <div className="block-left1">
        <h1>
          {now}
        </h1>

          {cities[currentCityIndex].visible === true && (
             <>
            <div className="city-time">
              <div className="city">
                <h2>{cities[currentCityIndex]?.name}</h2>
              </div>
              <div className="time">
                <h2>{time}</h2>
              </div>
            </div>
            {cities[currentCityIndex].visible === true && (
  <WeatherWidget ids={cities[currentCityIndex]?.id} />
            )}
             </>
          )}

      </div>
      <div className="block-left2" style={{ '--qr-margin-top': '20px' }}>
        <div className="telegram-header"><h1>Telegram</h1></div>
        <div className="qrcode-container">
          <QRCode value={qrCodeUrl} size={220} />
        </div>
      </div>
      <div className="block-right">
      <iframe
        src={videoLink}
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock; muted;"
        title="Видео"
      />
        <div className="marquee-container">
          <span className="scrolling-text">{runningText}</span>
        </div>
      </div>
    </div>
    );
  } return <Cap />;
}

export default TVPanel;
