import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authorization } from '../../utils/functions/authenticate';
import { setOperInfo } from '../../utils/functions/others';
import Cap from '../Cap/Cap';
import ServiceBody from '../../components/Service/ServiceBody';
import PaginatedTable from '../../components/Table/PaginatedTable/PaginatedTable';
import TRow from '../../components/Table/TRow';
import TData from '../../components/Table/TData';
import RecrutmentForm from './RecrutmentForm';
import { clientSendData } from '../../utils/functions/requests';
import { catchHandler } from '../../utils/error_handling/error_handling';
import RecrutmentCard from './RecrutmentCard';
import RecrutmentMainCard from './RecrutmentMainCard';

function RecrutmentTable() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();
  const [showCreateCard, setShowCreateCard] = useState(false); // состояние отображения карточки создания
  const [tableState, setTableState] = useState({
    title: 'Сервис подбора персонала. Общее количество запросов',
    page: 1,
    limit: 50, // количество строк в таблице
  });
  const [total, setTotal] = useState(0); // Общее количество записей
  const [filter, setFilter] = useState({}); // Фильтры
  const [showCard, setShowCard] = useState(false); // состояние отображения карточки
  const [rTickets, setRTickets] = useState([]); // отображаемые заявки
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [selectedTicket, setSelectedTicket] = useState(null); // Состояние для хранения выбранной заявки
  const [selectedTicketStatusID, setSelectedTicketStatusID] = useState(null);
  const [selectedTicketID, setSelectedTicketID] = useState('');
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска в url
  const tableId = 'recrutment__tickets';

  useEffect(() => {
    setOperInfo();
    awaitRequests();
  }, []);

  useEffect(() => {
    localStorage.setItem(tableId, JSON.stringify(tableState));
    getRTickets();
  }, [tableState]);

  useEffect(() => { // При закрытии карточки обновить данные таблицы
    if (!showCard) {
      getRTickets();
      setSelectedTicket(null);
      setSelectedTicketID('');
      setSelectedTicketStatusID(1);
    }
  }, [showCard]);

  useEffect(() => {
    // Проверка параметров URL при их изменении (для открытия карточки)
    if (searchParams.size > 0) { // Если есть какие-то параметры
      // Проверка наличия параметра "recrutmentTicket"
      const ticketId = searchParams.get('rTicket');
      if (ticketId) {
        setSelectedTicketID(ticketId); // Устанавливаем ID выбранной заявки
        setShowCard(true); // Открываем карточку
      } else {
        // Если другие параметры, можно сбросить их, если это необходимо
        // setSearchParams(''); // Раскомментируйте, если нужно сбрасывать
      }
    }
  }, [searchParams]);
  useEffect(() => {
    if (!showCreateCard) {
      getRTickets(); // Обновляем таблицу при закрытии карточки
      setSelectedTicket(null);
      setSelectedTicketID('');
      setSelectedTicketStatusID(1);
    }
  }, [showCreateCard]);

  const headers = [ // Заголовки таблицы
    { id: 1, title: '№', field: 'id' },
    { id: 2, title: 'Инициатор', field: 'initiator' },
    { id: 3, title: 'Статус', field: 'status' },
    { id: 4, title: 'Дата', field: 'date' },
    { id: 5, title: 'МВЗ', field: 'mvz' },
  ];

  async function awaitRequests() {
    const checkAuthorization = await authorization(); // авторизация
    setIsAuthorized(checkAuthorization);
  }

  // Получить заявки
  async function getRTickets() {
    try {
      const reqData = {
        type: 'getRTickets',
        tableState,
        total,
      };
      setIsLoading(true);
      const result = await clientSendData('POST', '/get_r_tickets', reqData);
      if (result?.success) {
        setRTickets(result.tickets); // Результат записывается в состояние
        setTotal(result.total);
        console.log(result.total);
      }
    } catch (error) {
      catchHandler(error, 'getRTickets');
    } finally {
      setIsLoading(false);
    }
  }

  console.log(total);

  if (isAuthorized) {
    return (
      <ServiceBody>
<PaginatedTable
  id={tableId}
  headers={headers}
  state={tableState}
  setState={setTableState}
  total={total}
  filterFields={filter}
  filterFieldsFunc={setFilter}
  optButton={{
    title: 'Создать',
    handler: () => {
      setSelectedTicket(null); // Обнуляем выбранную заявку
      setShowCreateCard(true); // Показываем форму
      setSelectedTicketStatusID(1);
      setSelectedTicketID('');
    },
  }}
>
  {/* Если данных нет, отображаем пустую таблицу */}
  {rTickets.length === 0 ? (
    <TRow>
      <TData colSpan={headers.length}>Нет данных для отображения</TData>
    </TRow>
  ) : (
    rTickets.map((ticket) => (
      <TRow key={ticket.id}>
        {headers.map((header) => {
          const { id, field } = header;
          let value;

          // Получаем только нужные поля
          if (field === 'id') {
            value = ticket.id;
          } else if (field === 'initiator') {
            value = ticket.initiator;
          } else if (field === 'mvz') {
            value = ticket.mvz;
          } else if (field === 'status') {
            value = ticket.status;
          } else if (field === 'date') {
            value = ticket.date_created;
          }

          return (
            <TData key={id}>{value}</TData>
          );
        })}
          <TData loading={isLoading}>
                    <div style={{ display: 'flex', alignItems: 'right' }}>
                    <div
                      onClick={() => {
                        setSelectedTicket(ticket.data); // Устанавливаем id выбранной заявки
                        setSelectedTicketStatusID(ticket.status_id); // Устанавливаем id выбранной заявки
                        setShowCard(true); // Открываем карточку
                        setSelectedTicketID(ticket.id);
                        navigate(`?rTicket=${ticket.id}`); // Перенаправляем на нужный URL
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                        <img src="../../icons/file.png" alt="карточка заявки" />
                    </div>
                    </div>
          </TData>
      </TRow>
    ))
  )}
</PaginatedTable>

        { showCreateCard && (<RecrutmentForm
          setShowCreateCard={setShowCreateCard}
          ticketData={selectedTicket} // Передаем id заявки в компонент
          statusID={selectedTicketStatusID} // Передаем id заявки в компонент
          ticketID={selectedTicketID}
        />)}

{ showCard && (<RecrutmentMainCard
  setShowCard={setShowCard}
  ticketData={selectedTicket} // Передаем id заявки в компонент
  statusID={selectedTicketStatusID} // Передаем id заявки в компонент
  ticketID={selectedTicketID}
/>)}

      </ServiceBody>

    );
  }

  return <Cap />;
}

export default RecrutmentTable;
