import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { clientSendData } from '../../../utils/functions/requests';
import { catchHandler } from '../../../utils/error_handling/error_handling';
import ApplicationCard from './ApplicationCard/ApplicationCard';
import ServiceBody from '../../../components/Service/ServiceBody';
import Loader from '../../../components/UI/Loader';
import TData from '../../../components/Table/TData';
import TRow from '../../../components/Table/TRow';
import ServicePanel from '../../../components/Service/ServicePanel';
import CardSetting from '../../../components/Card/CardSetting';
import PaginatedTable from '../../../components/Table/PaginatedTable/PaginatedTable';

// Сервис оценок - Интерфейс заполнения проектов
function Employee() {
  const [searchParams, setSearchParams] = useSearchParams(); // параметры поиска url
  const [isLoading, setIsLoading] = useState(false); // состояние загрузки
  const [applications, setApplications] = useState([]); // найденные заявки
  const [info, setInfo] = useState({}); // информация о сотруднике
  const [showCard, setShowCard] = useState(false);
  const [currentApplicationId, setCurrentApplicationId] = useState(null);
  const headers = [ // заголовки
    { id: 1, title: 'Клиент', field: 'client_title' },
    { id: 2, title: 'Проект', field: 'project_title' },
    { id: 3, title: 'Руководитель', field: 'approver_title' },
    { id: 4, title: 'Партнер', field: 'partner_title' },
    { id: 5, title: 'Директор', field: 'director_title' },
    { id: 6, title: 'Менеджер', field: 'manager_title' },
    { id: 7, title: 'Кол-во часов', field: 'hours_amount' },
    { id: 8, title: 'Грейд', field: 'grade' },
    { id: 9, title: 'Статус', field: 'status_title' },
    { id: 10, title: 'Действие', field: 'action' },
    // { id: 10, title: 'История', field: 'history' },
  ];
  // Поиск разрешен если есть даты и дата начала раньше даты завершения
  const defaultTableState = {
    title: 'Проектные оценки',
    page: 1,
    limit: 50, // количество строк в таблице
    switch_data: {
      current: 'active',
      titles: {
        all: 'Все',
        active: 'Активные',
        //   new: 'Новые',
      },
    },
    button_place: 'row',
  };
  const [tableState, setTableState] = useState(defaultTableState);
  const [total, setTotal] = useState(0);
  const isAdmin = false;

  useEffect(() => {
    findApplications();
  }, [tableState]);

  useEffect(() => {
    getEmployeeAddInfo();
  }, []);

  // Поиск заявлений на выбранные даты
  async function findApplications() {
    try {
      const reqData = {
        type: 'findApplications',
        tableState,
      };
      const result = await clientSendData('POST', '/find_applications', reqData); // запрос в БД

      setApplications(result.result);
      setTotal(result.total);
    } catch (error) {
      catchHandler(error, 'findApplications'); // обработка ошибок
    }
  }

  // Открыть заявление
  function openApplication(id, project_id, userGrade = null) {
    // setCurrentProjectId(project_id);
    //  setCurrentApplicationId(id);
    let search;
    if (id === 0) {
      search = { app: id, projectId: project_id, isAdmin };
    } else {
      search = { app: id, isAdmin };
      // if (userGrade) search.grade = userGrade;
    }
    setSearchParams(search);
  }

  // Открыть карточку заявления
  function openApplicationCard(id) {
    setShowCard(true);
    setCurrentApplicationId(id);
  }

  // Поиск заявлений
  async function getEmployeeAddInfo() {
    try {
      // setIsLoading(true);
      const reqData = { type: 'getEmployeeAddInfo' };
      const result = await clientSendData('POST', '/get_employee_add_info', reqData);
      if (result) {
        setInfo(result?.info);
        //   setProjects(result?.projects);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      catchHandler(error, 'getEmployeeAddInfo'); // обработка ошибок
    }
  }

  // Определить отображаемое значение
  const defineValue = (key) => {
    if (info[key]) {
      const data = info[key];
      if (key === 'experience') {
        return `${data?.years || 0}y ${data?.months || 0}m`;
      } return data;
    }
    return '-';
  };

  return (

    <ServiceBody>
        {/* <ServicePanel id="evaluation__employee-info-panel">
        <div className="my-data"> | Мои данные |</div>
        <CardSetting title="ФИО:">{defineValue('title')}</CardSetting>
        <CardSetting title="Грейд:">{defineValue('grade')}</CardSetting>
        <CardSetting title="Группа:">{defineValue('group')}</CardSetting>
        <CardSetting title="МВЗ:">{defineValue('mvz')}</CardSetting>
        <CardSetting title="Стаж:">{defineValue('experience')}</CardSetting>
        <CardSetting title="Утилизация (ч):">{defineValue('total')}</CardSetting>
        <CardSetting title="Наставник:">{defineValue('mentor')}</CardSetting>
        <CardSetting title="Грейд наставника:">{defineValue('mentor_grade')}</CardSetting>
        <CardSetting title="Опыт в текущей должности:">{defineValue('position_experience')}</CardSetting>
        <CardSetting title="Норматив:">{defineValue('norm')}</CardSetting>
        </ServicePanel> */}

        {isLoading ? <Loader /> : (
        <PaginatedTable
          id="evaluation__employee-table"
          headers={headers}
          setState={setTableState}
          state={tableState}
          total={total}
        >
                {/* Если нет результатов */}
                {applications.length === 0
                  ? <TRow>
                    <TData>Результаты отсутствуют</TData>
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    <TData />
                    </TRow>
                // Если результаты есть
                  : applications.map((row) => {
                    const {
                      id, draft, project_id, grade,
                    } = row;
                    return (
                        <TRow key={id}>
                            {headers.map((item) => {
                              if (item.field !== 'action') {
                                return (
                                    <TData key={item.id}>{row[item.field] ? row[item.field] : '-'}</TData>
                                );
                              }
                              return (
                                    <TData key={item.id} onClick={() => openApplication((id || 0), project_id, grade)}>
                                       {/* {draft ? 'Посмотреть' : 'Заполнить'} */}
                                      Открыть карточку
                                    </TData>
                              );
                            })}
                            <TData />
                            {/* <TData onClick={() => openApplicationCard(id)}>
                                <img src="../../icons/file-text.svg" alt="edit" />
                            </TData> */}
                        </TRow>
                    );
                  })}

                {/* <TRow>
                    <TData onClick={() => openApplicationCard('new')}>
                        Создать
                    </TData>
                </TRow> */}

        </PaginatedTable>)}

        {showCard && (
        <ApplicationCard
          id={currentApplicationId}
          setShowCard={setShowCard}
          findApplications={findApplications}
        />)}
    </ServiceBody>
  );
}

export default Employee;
